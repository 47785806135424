<template>
    <div class="content-wrapper settlement-dashboard h-theme">
        <rq-banner
            v-for="(alert,index) in activeAlerts"
            :key="index"
            :message="alert.message"
            :variant="alert.severity === 1 ? 'warn' : 'error'"
            icon="fas fa-exclamation-triangle"
            :visible="showAlerts"
            dismissable
            sticky
        />
        <rq-page-section title="SETTLEMENT STATEMENT" header-size="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_edit_statement" variant="theme" v-focus @click="onEditStatementSettings" :disabled="isFileLocked">Manage Statement</b-btn>
                        <b-btn automation_id="btn_reset_statement" variant="theme" @click="onResetStatement" :disabled="readOnly || !localSecurity.AllowResetHUDorCDF">Reset Statement</b-btn>
                        <b-btn automation_id="btn_cdf_data_entry" v-if="cdfEditorEnabled && isCdf" variant="theme" @click="onCdfDataEntry" :disabled="isFileLocked">CDF EDITOR</b-btn>
                    </li>
                </ul>
            </template>
                <div class="label-section">
                    <ul class="nav section-label-display">
                        <li class="nav-item">
                            <div class="section-label-name">SETTLEMENT TYPE:</div>
                            <div class="section-label-value">{{settlementDescription}}</div>
                        </li>
                        <li class="nav-item">
                            <div class="section-label-name">Template:</div>
                            <div class="section-label-value">{{templateFile}}</div>
                        </li>
                        <li class="nav-item">
                            <div class="section-label-name">STATUS:</div>
                            <div class="section-label-value">{{watermarkStatusName}}</div>
                        </li>
                        <li class="nav-item">
                            <div class="section-label-name">LOAN:</div>
                            <div class="section-label-value">{{activeLoanDescription}}</div>
                        </li>          
                    </ul>
                </div>
        </rq-page-section>

        <settlement-routes-section>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-section-expand-collapse-all section-group="settlement-dashboard" />
                    </li>
                </ul>
            </template>
        </settlement-routes-section>

        <rq-page-section title="VIEW CASH TO CLOSE" header-size="sm" section-group="settlement-dashboard" collapsible>
            <!-- Calculations -->
            <div class="row mb-3" :class="{'justify-content-md-center':!isWithOutSeller}">
                <div class="col-12" :class="{'col-xl-6':!isWithOutSeller}">
                    <debit-credit-calculations
                        :title-options="borrowerTitleOptions"
                        :totalUpperSection="totalBorrowerDebits"
                        :totalBottomSection="totalBorrowerCredits"
                        :netTotal="netBorrowerTotal"
                        :isWithOutSellerView="isWithOutSeller"
                        :isCdf="isCdf"
                        :loanAmountValue="defaultLoan.amount"
                        :totalPaidBeforeClosingValue="totalPaidBeforeClosing"
                    />
                </div>
                <div v-if="!isWithOutSeller" class="col-12 col-xl-6">
                    <debit-credit-calculations
                        :title-options="sellerTitleOptions"
                        :totalUpperSection="totalSellerCredits"
                        :totalBottomSection="totalSellerDebits"
                        :isCdf="isCdf"
                        :netTotal="netSellerTotal"
                    />
                </div>
            </div>
        </rq-page-section>
        <!-- <rq-page-section title="PRINT SETTLEMENT STATEMENT" header-size="sm" header-only collapsible>
        </rq-page-section> -->

        <download-settlement-doc
            :loanId="defaultLoanId"
            :isHasRateAlerts="isHasRateAlerts"
        />

        <missing-settlement-section
            :validateSection="validateMissingItems"
            v-model:isMissingItems="isMissingItems"
        />
    </div>
</template>

<script>
    import { computed } from "vue";
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { SETTLEMENT_TYPE, SettlementTypeOption, RateEngineAlert } from "@settlement/models";
    import { SET_PAGE, ORDER_ACTIONS, RATE_ACTIONS } from "@/store/actions";
    import { OrderSettingsViewModel } from "@/shared/models/models";
    import SettlementSettingsDetail from "./SettlementSettingsDetail";
    import { HudStatusDto } from "@config/ssAndCalcs/models";
    import DebitCreditCalculations from "@settlement/components/debitcredits-section/components/DebitCreditCalculations";
    import { SettlementMissingItemDto } from "./models";
    import SettlementRoutesSection from "./SettlementRoutesSection";
    import MissingSettlementSection from "./MissingSettlementSection";
    import DownloadSettlementDoc from "./SettlementDocSection";
    import { useLicenseStore } from "@/store/modules/license";
    import { useGlobalDialogs } from "@/shared/composables";

    export default {
        name: "SettlementDashboard",

        components: {
            SettlementRoutesSection,
            DebitCreditCalculations,
            MissingSettlementSection,
            DownloadSettlementDoc
        },

        setup() {
            const licenseStore = useLicenseStore();
            const cdfEditorEnabled = computed(() => licenseStore.features?.cdfEditor || false);
            const { openCdfEditorDialog } = useGlobalDialogs();
            return {
                cdfEditorEnabled,
                openCdfEditorDialog
            };
        },

        data() {
            return {
                orderSettingsData: new OrderSettingsViewModel(),
                cashToCloseFromOptions: [
                    { id: 1, name: 'Populate from Cash to Close table' },
                    { id: 2, name: 'Populate from Cash To/From Borrower' },
                ],
                watermarkStatuses: [],
                watermarkStatusOptions: [],
                selectedWatermarkStatus: {},
                cashToCloseData: [],
                defaultMessage: "Missing values for settlement items.",
                salesAndLoanChangedMessage: ["The Sales Price has changed. Premiums need to be recalculated.", "The Loan Amount has changed. Premiums need to be recalculated."],
                salesPriceOrLoanAmountChangedMessage: "Sales Price or Loan Amount has changed. Please recalculate Premiums.",
                isMissingItems: false,
                isHasRateAlerts: false,
                activeAlerts: [],
                MainID: 0,
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "orderSettings"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                isFileLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
                isWithOutSeller: state => _.getBool(state, "orders.orderSummary.isWithOutSeller"),
                loans: state => state.orders.loans || [],
                cdfSections: state => state.orders.orderLookups.cdfSections,
                readOnly: state => _.parseBool(state.isPageReadOnly),
                alerts: state => _.map(state.rateEngine.alerts, a => new RateEngineAlert(a)),
                premiums: state => state.rateEngine.premiums[0],
            }),
            settlementDescription() {
                return `${SettlementTypeOption.displayLabel(this.orderSummary.settlementStatementType,
                             _.parseBool(this.orderSummary.isWithOutSeller),
                             _.parseBool(this.orderSummary.includeAlta),
                             _.parseBool(this.orderSummary.useTemplatePage2A2B))}`
            },
            hasEscrowChecks() { return _.get(this, "orderSummary.hasEscrowChecks", false); },
            showAlerts() { return this.isMissingItems || this.isHasRateAlerts },
            validateMissingItems() { return false; },
            defaultLoan() { return _.find(this.loans, l => l.loanID === this.defaultLoanId); },
            defaultLoanId() { return this.orderSummary.defaultLoanID; },
            includeAlta() { return this.orderSummary.includeAlta; },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
            SettlementTypes() { return SETTLEMENT_TYPE; },
            withoutSellerLabel() { return this.isWithOutSeller ? "YES" : "NO"; },
            templateFile() { return _.get(this, "orderSummary.settlementTemplateFile", ""); },
            activeLoanDescription() {
                const self = this;
                let defaultLoanId = self.$store.state.orders.orderSummary.defaultLoanID;

                let getDefaultLoanOptions = function(loanId) {
                    // default to first loan if no default loanId specified
                    if (_.isNil(loanId))
                        return _.find(self.lookupHelpers.getLoanOptions(), i => i.id == self.loans[0].loanID);

                    // get loan options from loanId
                    let loan = _.find(self.loans, l => l.loanID === loanId);
                    return _.find(self.lookupHelpers.getLoanOptions(), i => i.id == loan.loanID);
                };

                let defaultLoan = getDefaultLoanOptions(defaultLoanId);
                return defaultLoan.name;
            },
            watermarkStatusName() {
                let selectedWatermarkStatus = _.find(this.watermarkStatusOptions, w => w.id === this.orderSettings.watermarkStatus);
                return _.get(selectedWatermarkStatus, "name", "");
            },
            borrowerDebitTitle() {
                let textLabel = "";

                if (this.isCdf) {
                    textLabel = this.isWithOutSeller ? "Total Closing Costs (J)" : "Total Due from Borrower at Closing (K)";
                }

                if (this.isHud)
                    textLabel = this.isWithOutSeller ? "Minus Total Settlement Charges (Line 1400)" : "Gross Amount due from borrower (line 120)";

                return textLabel;
            },
            borrowerCreditTitle() {
                let textLabel = "";

                if (this.isCdf) {
                    textLabel = this.isWithOutSeller ? "Total Payoffs & Payments (K)" : "Total Paid Already by or on Behalf of Borrower at Closing (L)";
                }

                if (this.isHud)
                    textLabel = this.isWithOutSeller ? "Minus Total Disbursements to Others (Line 1520)" : "Less amounts paid by/for borrower (line 220)";

                return textLabel;
            },
            borrowerCashToCloseTitle() {
                if (this.isWithOutSeller) {
                    let total = _.get(this.results, "totalCashToClose", 0);
                    if (this.isCdf)
                        return (total > 0) ? "Cash To Borrower" : "Cash From Borrower";
                    if (this.isHud)
                        return (total > 0) ? "Equals Disbursements To Borrower" : "Equals Disbursements From Borrower";
                }

                return (this.totalBorrowerDebits > this.totalBorrowerCredits) ? "Cash From Borrower" : "Cash To Borrower";
            },
            totalCashToClose() {
                return _.get(this.results, "totalCashToClose", 0);
            },
            totalBorrowerDebits() {
                return this.isWithOutSeller ? _.get(this.results, "totalClosingCosts", 0) : _.get(this.results, "borrowerDebitsTotal", 0);
            },
            totalBorrowerCredits() {
                return this.isWithOutSeller ? _.get(this.results, "payoffAndPaymentTotal", 0) : _.get(this.results, "borrowerCreditsTotal", 0);
            },
            totalPaidBeforeClosing() {
                return this.isWithOutSeller ? _.get(this.results, "totalPaidBeforeClosing", 0) : 0;
            },
            netBorrowerTotal() {
                // RQO-30192 pull directly from cash to close totals 
                // when cashToClosePopulateFrom setting (1 - from cash to close table)
                // versus calculating through borrower/seller charges
                let showCashToCloseFromTable = this.isCdf && this.orderSummary.cashToClosePopulateFrom === 1;
                if (this.isWithOutSeller || showCashToCloseFromTable) {
                    let total = _.parseNumber(this.totalCashToClose, 0);
                    if (this.isCdf)
                        total = Math.abs(total);
                    return total;
                }

                return _.parseNumber(this.totalBorrowerDebits, 0) - _.parseNumber(this.totalBorrowerCredits, 0);
            },
            borrowerTitleOptions() {
                let sectionTitle = (this.isHud && !this.isWithOutSeller) ? "300. Cash at Settlement From/To Borrower" : "Calculation";
                 return {
                     sectionTitle: sectionTitle,
                     topTitle: this.borrowerDebitTitle,
                     bottomTitle: this.borrowerCreditTitle,
                     totalTitle: this.borrowerCashToCloseTitle
                };
            },
            sellerCreditTitle() {
                let textLabel = "";

                if (this.isCdf)
                    textLabel = "Total Due To Seller at Closing (M)";

                if (this.isHud)
                    textLabel = "Gross Amount due to seller (line 420)";

                return textLabel;
            },
            sellerDebitTitle() {
                let textLabel = "";

                if (this.isCdf)
                    textLabel = "Total Due From Seller at Closing (N)";

                if (this.isHud)
                    textLabel = "Less reductions in amt. due seller (line 520)";

                return textLabel;
            },
            sellerCashToCloseTitle() {
                return (this.totalSellerCredits > this.totalSellerDebits) ? "Cash To Seller" : "Cash From Seller";
            },
            totalSellerDebits() {
                return _.get(this.results, "sellerDebitsTotal", 0);
            },
            totalSellerCredits() {
                return _.get(this.results, "sellerCreditsTotal", 0);
            },
            netSellerTotal() {
                return _.parseNumber(this.totalSellerCredits, 0) - _.parseNumber(this.totalSellerDebits, 0);
            },
            sellerTitleOptions() {
                let sectionTitle = this.isHud ? "600.  Cash at Settlement From/To Seller" : "Calculation";
                return {
                    sectionTitle: sectionTitle,
                    topTitle: this.sellerCreditTitle,
                    bottomTitle: this.sellerDebitTitle,
                    totalTitle: this.sellerCashToCloseTitle
                };
            },

            results() {
                if (this.cashToCloseData.length === 0) return;
                let results = this.cashToCloseData[0];

                if (this.isCdf)
                    results = _.find(this.cashToCloseData, i => i.settlementType === SETTLEMENT_TYPE.CDF);

                if (this.isHud1974)
                    results = _.find(this.cashToCloseData, i => i.settlementType === SETTLEMENT_TYPE.HUD_1974);

                if (this.isHud2010)
                    results = _.find(this.cashToCloseData, i => i.settlementType === SETTLEMENT_TYPE.HUD_2010);

                return results;
            },
            localSecurity() {
                 return this.securitySettings.findValues(["AllowResetHUDorCDF"]);
            },
        },

        created() {
            // this.fetchData();
            // GlobalEventManager.onSave(this, this.onSaveClicked);
            // GlobalEventManager.onCancel(this, this.onCancelClicked);
            this.updateAndMapData();
            GlobalEventManager.onFireAction(this, this.handleAction);
        },

        watch: {
            orderId: function() {
                this.updateAndMapData();
            },
            
            isMissingItems: function () {
                if(_.isEmpty(this.activeAlerts)) {
                    this.activeAlerts = [{message : this.defaultMessage, severity : 1}];
                }
                else {
                    this.activeAlerts.push({message : this.defaultMessage, severity : 1});
                }
            },           
        },

        mounted() {
            if(this.$route.query?.launchEditor !== "cdf" || this.selectedView != SETTLEMENT_TYPE.CDF) return;
            this.onCdfDataEntry();
        },

        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },

        methods: {
            onUpdateMissingItems(e) {
                this.fetchMissingItems();
            },
            fetchMissingItems() {
                const self = this;
                let defaultLoanId = self.orderSummary.defaultLoanID;
                let apiPromise = self.$api.SettlementDashboardApi.getMissingSettlementItems(defaultLoanId, self.selectedView);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let missingItems = _.map(result, i => new SettlementMissingItemDto(i));
                        self.missingSettlementItems = _.filter(missingItems, i => i.isMissingValues);
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error loading missing items.` });
                    });
            },
            fetchData() {
                const self = this;
                self.getPremiumRateInfo().then(result => {
                    self.updatePremiumAlerts();
                });
                let apiPromise = self.$api.SettlementDashboardApi.getSSDashboardData(self.defaultLoanId, self.selectedView);

                return self.$rqBusy.wait(apiPromise, { topLevel: true })
                    .then(result => {
                        self.watermarkStatuses = _.map(result.hudStatuses, i => new HudStatusDto(i));
                        self.watermarkStatusOptions = _.map(self.watermarkStatuses, i => { return { id: i.hudStatusID,  name: i.description } });
                        self.cashToCloseData = _.map(result.cashToCloseResults);
                        self.MainID = result.mainID;
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error fetching data.` });
                        return error;
                    });
                
            },

            updatePremiumAlerts() {
                this.isHasRateAlerts = false;
                if(!_.isNil(this.premiums) && !_.isEmpty(this.alerts)){
                    let fileChangeAlerts = _.filter(this.alerts.slice(), a => _.parseNumber(a.orderRateCalculationID, 0) === 0 || this.premiums.orderRateCalculationID == a.orderRateCalculationID);
                    let fileChangeAlertMsgs = fileChangeAlerts.map(s => s.message);
                    if(fileChangeAlerts.length > 0){
                        if(this.salesAndLoanChangedMessage.every(alertMsg => fileChangeAlertMsgs.includes(alertMsg))){
                            this.isHasRateAlerts = true;
                            this.activeAlerts = [{message : this.salesPriceOrLoanAmountChangedMessage, severity : 1}];
                        }
                        else if(this.salesAndLoanChangedMessage.some(alertMsg => fileChangeAlertMsgs.includes(alertMsg))){
                            this.isHasRateAlerts = true;
                            this.activeAlerts = fileChangeAlerts.filter(f => this.salesAndLoanChangedMessage.includes(f.message));
                        }
                    }
                }
            },            

            async getPremiumRateInfo(){
                await this.$store.dispatch(RATE_ACTIONS.GET_RATES)
            },
            updateSettingsData() {
                this.orderSettingsData = new OrderSettingsViewModel(this.orderSettings);
            },
            updateAndMapData() {
                this.updateSettingsData();
                this.onMapSettlementData(this.orderSettingsData.settlementStatementType, false, false);
            },
            onEditStatementSettings() {
                let self = this;
                self.updateSettingsData();
                let options = {
                    title: `MANAGE SETTLEMENT STATEMENT`,
                    data: _.toPlainObject(self.orderSettingsData),
                    isEditMode: true,
                    readOnly: self.readOnly,
                    watermarkOptions: self.watermarkStatusOptions
                };
                this.showEditDialog(options);
            },
            onCdfDataEntry() {
                this.openCdfEditorDialog(this.defaultLoanId);
            },
            showEditDialog(options) {
                let self = this;

                let cancelHandler = function(e) {
                    return true;
                };

                let okHandler = function(e) {
                    let changes = self.getAuditChanges(self.orderSettings, e.component.detail);

                    if (changes.length > 0) {
                        self.saveOrderSettings(e.component.detail).then(() => {
                            self.updateAndMapData();
                        });
                    } else {
                        // No changes -- display message (if necessary) and allow navigation
                        // GlobalEventManager.saveCompleted({ success });
                    }
                    return true;
                };
                self.$dialog.open({
                    title: options.title,
                    width: 1100,
                    component: SettlementSettingsDetail,
                    adaptive: true,
                    props: {
                        detail: options.data,
                        isEditMode: options.isEditMode,
                        readOnly: options.readOnly,
                        settlementType: self.selectedView,
                        watermarkOptions: self.watermarkStatusOptions,
                        cashToCloseFromOptions: self.cashToCloseFromOptions
                    },
                    onOk: okHandler,
                    onCancel: cancelHandler,
                    okTitle: "Save"
                });
            },
            onResetStatement() {
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowResetHUDorCDF) return;
                if (self.hasEscrowChecks)
                    self.onResetStatementWarning();
                else
                    self.onResetStatementConfirmation();
            },
            onResetStatementWarning() {
                const self = this;
                self.$dialog.confirm(`Warning`, `Unable to reset Settlement Statement, disbursements must be manually removed in Check Writing.`);
            },
            onResetStatementConfirmation() {
                const self = this;
                let selectedLoanId = _.gt(self.defaultLoanId, 0) ? self.defaultLoanId : self.loans[0].loanID;

                let confirmMessage = `<p>Are you sure you want to reset the Settlement Statement? Selecting Yes will remove all entries for all Statements on this file.</p>`;

                let okHandler = function () {
                    let mapPromise = self.$api.SettlementDashboardApi.resetSettlementData(self.orderId, selectedLoanId);
                    return self.$rqBusy.wait(mapPromise, { topLevel: true })
                    .then(() =>{
                        self.fetchData();
                        self.$toast.success(`Settlement Statement Reset Successfully`);
                        return true;
                    }).catch(err => {
                        self.$toast.error(`Settlement Statement Failed to Reset`);
                        return err;
                    });
                }

                self.$dialog.confirm("Confirm", confirmMessage, okHandler, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            saveOrderSettings(detail) {
                const self = this;
                // let success = true;
                detail.getWFNotification = false;
                let storePromise = self.$store.dispatch(ORDER_ACTIONS.UPDATE_ORDER_SETTINGS, _.toPlainObject(detail));
                return self.$rqBusy.wait(storePromise, { topLevel: true })
                    .then(() => {
                        self.$store.dispatch(SET_PAGE, self.$route);
                        self.$toast.success({ message: 'Settlement Settings Saved Successfully' });
                    })
                    .catch(errorInfo => {
                        console.log(errorInfo);
                        self.$toast.error({ message: errorInfo.message });
                        // success = false;
                    })
                    .finally(() => {
                        // GlobalEventManager.saveCompleted({ success });
                    });
            },
            handleAction (event) {
                switch (event.key) {
                    case "mapSS":
                        this.onMapSettlementData(this.selectedView, true);
                        break;
                }
            },
            onMapSettlementData(settlementType, mapOverride, onUserInitiated = true) {
                const self = this;
                let updatedItem = SettlementTypeOption.displayLabel(settlementType);
                let selectedLoanId = _.gt(self.defaultLoanId, 0) ? self.defaultLoanId : self.loans[0].loanID;

                // Save before mapping in case anything to save
                let mapPromise = mapOverride ? self.$api.SettlementDashboardApi.mapSettlementDataOverride(settlementType, selectedLoanId)
                                                : self.$api.SettlementDashboardApi.mapSettlementData(settlementType, selectedLoanId);
                return self.$rqBusy.wait(mapPromise, { topLevel: true }).then(() =>{
                    self.fetchData();

                    if (onUserInitiated)
                        self.$toast.success(`${updatedItem} updated`);
                }).catch(err => {
                    self.$toast.error(`${updatedItem} failed to update`);
                });
            },
        }
    };
</script>