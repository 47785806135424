<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :automation_id="automation_id"
        :data-source="gridDataSource"
        :config="gridConfig"
        search-mode="field"
        v-model:search-value="searchTextValue"
        export-file-name="RQO-OrderTemplates"
        :fixed-header="fixedHeader"
        @rowDoubleClick="onRowDoubleClick"
        @selectionChanged="onSelectionChanged"
        rq-filters
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { SearchRequest, SortOption } from "@/shared/models/models";
    import { OrderStatus } from "@/shared/models/enums";
    import { ORDER_ACTIONS } from "@/store/actions";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import BranchItemTemplate from "@/modules/home/components/BranchItemTemplate.vue";

    export default {
        name: "OrderSelection",

        props: {
            automation_id: { type: String, default: "dg_select_order" },
            visibleColumns: { type: Array, default: () => [] },
            hiddenColumnsInChooser: { type: Array, default: () => []},
            searchTerm: { type: String, default: null },
            statusFilter: { type: String, default: null },
            orderFilter: { type: Array, default: () => [] },
            templateTarget: { type: String, default: null },
            emptyResultText: { type: String, default: null },
            fixedHeader: { type: Boolean, default: false },
            selectedOrder: { type: Object, default: () => ({}) }
        },

        data () {
            return {
                searchTextValue: "",
                lastSearchRequest: null,
                selectedOrderValue: null,
                selectedGFNO: "",
                selectedDefaultSSType: 0,
                selectedDefaultIncludeAlta: false,
                selectedOrderId: 0
            };
        },

        computed: {
            ...mapState({
                allBranches: state => _.get(state, "system.lookups.branches", [])
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            hasStatusFilter() { return !_.isEmpty(this.statusFilter); },
            gridInstance () { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            hasSelection() { return _.gt(this.selectedOrderId, 0); },
            regions() { return this.lookupHelpers.getRegions(); },
            orderStatus() { return OrderStatus.lookupItems; }
        },

        watch: {
            searchTerm: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.searchTextValue) return;
                    this.searchTextValue = newValue;
                },
                immediate: true
            },
            searchTextValue(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.refresh();
                if(newValue === this.searchTerm) return;
                this.$emit("update:searchTerm", newValue);
            },
            visibleColumns(newValue, oldValue) {
                let diffs = this.diff(newValue, oldValue);
                if(diffs.length === 0) return;
                let gridColumns = this.getGridColumns(newValue)
                this.gridInstance.option("columns", gridColumns);
            },
            selectedOrder(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.selectedOrderValue) return;
                console.warn("OrderSelection.selectedOrder is a readonly prop.  Apply the \"sync\" modifier to retrive the selected order data.");
            },
            selectedOrderValue(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit("update:selectedOrder", newValue);
            }
        },

        created () {
            const self = this;
            self.initGridConfig();
        },

        methods: {

            onSearchAllChange(val) {
                const self = this;
                if(val && _.isEmpty(self.searchTerm)) return;
                self.refresh();
            },

            initGridConfig() {
                const self = this;
                let companyFilterOptions = (includeEscrowAccounts = false) =>({
                    filterType: "tags",
                    valueExpr: "id",
                    displayExpr: "name",
                    noDataText: "Enter a value to search for.",
                    placeholder: "Search...",
                    minSearchLength: 3,
                    maxDisplayedTags: 10,
                    showDropDownButton: false,
                    persistSelectedItems: true,
                    dataSource: {
                        pageinate: true,
                        pageSize: 50,
                        load: _.debounce(function(e,selected) {
                            return self.fetchCompanyData(e,selected, includeEscrowAccounts);
                        }, 300, { leading: true, trailing: true })
                    }
                });
                let branchDataSource = _.map(self.allBranches, b => {
                    let branchItem = _.clone(b);
                    let branchRegion = self.lookupHelpers.getRegion(b.regionID);
                    branchItem.regionDisplay = _.isNil(branchRegion)
                        ? "Region not found"
                        : branchRegion.description;
                    return branchItem;
                });

                self.defaultColumns = [
                    { dataField: "ordersID", caption: "File ID", visible: false },
                    { dataField: "gfNo", caption: "File Number" },
                    {
                        caption: "Status",
                        dataField: "status",
                        lookup: {
                            dataSource: _.filter(self.orderStatus, s => s.name !== 'PaperlessCloser'),
                            displayExpr: "name",
                            valueExpr: "id"
                        }
                    },
                    {
                        caption: "Lender",
                        dataField: "lenderCompanyID",
                        alignment: "left",
                        rqFilter: companyFilterOptions,
                        calculateDisplayValue: "lender",
                        calculateSortValue: "lender"
                    },
                    { dataField: "buyer", visible: false },
                    { dataField: "seller", visible: false },
                    DxGridUtils.dateColumn({
                        dataField: 'openDate',
                    }),
                    DxGridUtils.dateColumn({
                        dataField: 'closeDate',
                    }),
                    DxGridUtils.dateColumn({
                        dataField: 'cancelledDate',
                        visible: false,
                    }),
                    DxGridUtils.dateColumn({
                        dataField: 'escrowLockDate',
                        visible: false,
                    }),
                    {
                        caption: "Region",
                        dataField: "regionID",
                        lookup: {
                            valueExpr: "regionID",
                            displayExpr: "displayName",
                            dataSource: self.regions.slice()
                        },
                        calculateSortValue: "region"
                    },
                    {
                        caption: "Branch",
                        dataField: "branchID",
                        rqFilter: {
                            valueExpr: "id",
                            displayExpr: "name",
                            dataSource: branchDataSource,
                            itemTemplate: BranchItemTemplate
                        },
                        alignment: "left",
                        calculateDisplayValue: "branch",
                        calculateSortValue: "branch"
                    },
                    {
                        caption: "Underwriter",
                        dataField: "underwriterCompanyID",
                        alignment: "left",
                        rqFilter: companyFilterOptions,
                        calculateDisplayValue: "underwriter",
                        calculateSortValue: "underwriter"
                    },
                    { dataField: "propertyAddress", visible: false },
                    {
                        caption: "Close Agent",
                        dataField: "closeAgentStaffID",
                        visible: false,
                        alignment: "left",
                        rqFilter: companyFilterOptions,
                        calculateDisplayValue: "closeAgent",
                        calculateSortValue: "closeAgent"
                    },
                    {
                        caption: "Escrow Account",
                        dataField: "bankCompanyID",
                        visible: false,
                        alignment: "left",
                        rqFilter: companyFilterOptions(true),
                        calculateDisplayValue: "escrowBank",
                        calculateSortValue: "escrowBank"
                    },
                    {
                        dataField: "salesPrice",
                        format: { type:"currency", precision: 2 },
                        visible: false
                    },
                    {
                        dataField: "loanAmount",
                        format: { type:"currency", precision: 2 },
                        visible: false
                    },
                    {
                        dataField: "earnestMoney",
                        format: { type:"currency", precision: 2 },
                        visible: false
                    },
                    { dataField: "ownerPolicyNumber", caption: "Owner Policy #", visible: false },
                    { dataField: "mortgagePolicyNumber", caption: "Mortage Policy #", visible: false },
                    {
                        caption: "Sales Rep",
                        dataField: "salesRepresentativeStaffID",
                        visible: false,
                        alignment: "left",
                        rqFilter: companyFilterOptions,
                        calculateDisplayValue: "salesRep",
                        calculateSortValue: "salesRep"
                    },
                    {
                        caption: "Title Company",
                        dataField: "titleCompanyCompanyID",
                        visible: false,
                        alignment: "left",
                        rqFilter: companyFilterOptions,
                        calculateDisplayValue: "titleCompany",
                        calculateSortValue: "titleCompany"
                    },
                    {
                        caption: "Listing Agent",
                        dataField: "listingAgentCompanyID",
                        visible: false,
                        alignment: "left",
                        rqFilter: companyFilterOptions,
                        calculateDisplayValue: "listingAgent",
                        calculateSortValue: "listingAgent"
                    },
                    {
                        caption: "Selling Agent",
                        dataField: "sellingAgentCompanyID",
                        visible: false,
                        alignment: "left",
                        rqFilter: companyFilterOptions,
                        calculateDisplayValue: "sellingAgent",
                        calculateSortValue: "sellingAgent"
                    },
                    { dataField: "legalDescription", visible: false, }
                ];

                let columns = this.getGridColumns();

                columns = self.applyColumnsHiddenInChooser(columns);

                self.gridConfig = {
                    columns,
                    columnMinWidth: 80,
                    remoteOperations: { paging: true, sorting: true, filtering: true },
                    noDataText: "No files found matching the search term that was entered.",
                    selection: { mode: "single" }
                };
                self.gridDataSource = {
                    key: "ordersID",
                    load: self.fetchGridData
                };
            },

            onRowDoubleClick (e) {
                this.selectedOrderId = _.parseNumber(e.key,0);
                this.selectedGFNO =  e.data.gfNo;
                this.selectedDefaultSSType = _.parseNumber(e.data.settlementStatementType,0);
                this.selectedDefaultIncludeAlta = _.parseBool(e.data.includeAlta);
                this.$emit("double-click", _.parseNumber(e.key, 0));
            },

            onSelectionChanged (e) {
                let selectedData = _.get(e, "selectedRowsData[0]", {});
                this.selectedOrderId = _.getNumber(selectedData, "ordersID", 0);
                this.selectedOrderValue = selectedData;
                this.$emit("single-click", _.parseNumber(this.selectedOrderId, 0));
            },

            fetchGridData (loadOptions) {
                const self = this;
                if (self.orderFilter.length > 0) {
                    if (_.has(loadOptions, 'filter')) {
                        loadOptions.filter.push("and");
                        loadOptions.filter.push(self.orderFilter);
                    } else {
                        loadOptions.filter = self.orderFilter;
                    }
                }
                
                if(self.hasStatusFilter && _.isEmpty(self.searchTextValue) && !(loadOptions.filter)) {
                    let statusList = _.split(self.statusFilter, ',' )
                    let statusFilterOption = [];
                    _.forEach(statusList, s => {
                        let option = [];
                        option.push("status");
                        option.push("=");
                        option.push(_.toNumber(s));

                        if(statusFilterOption.length > 0)
                            statusFilterOption.push("or");
                        
                        statusFilterOption.push(option);
                    })

                    loadOptions.filter = statusFilterOption;
                }

                let request = new SearchRequest({
                    searchTerm: self.searchTextValue,
                    pagingEnabled: true
                });
                request.parseLoadOptions(loadOptions);

                //Devextreme passes a default sort of ordersID/asc despite there being nothing configured to require that behavior. This results in any default
                //sort server-side being overridden, so for now we simply have to manually pass the sort value.  This is intentionally not handled by column sortIndex property
                //since this is how the data should be returned when all filters are removed and configuring it via the grid would allow it to be removed and that grid state persisted.
                if(request.sortBy.length === 1 && request.sortBy[0].field === "ordersID") {
                    request.sortBy.unshift(new SortOption({
                        field: "openDate",
                        direction: SortOption.SortDirection.Descending
                    }));
                }

                let searchParams = { };
                if(!_.isEmpty(self.templateTarget))
                    searchParams.templateTarget = self.templateTarget;

                request.parameters = searchParams;

                let apiPromise = self.$api.OrdersApi.getSimpleSearchResult(request);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        self.lastSearchRequest = request;
                        return { data: response.results, totalCount: response.totalRecords };
                    })
                    .catch(err => {
                        console.error(err);
                        self.$toast.error("A problem occurred while searching for files.");
                        return err;
                    });
            },

            fetchCompanyData(loadOptions, selected, includeEscrowAccounts = false) {
                const self = this;
                if(_.isEmpty(loadOptions.searchValue)) return Promise.resolve([]);
                let searchRequest = SearchRequest.fromLoadOptions(loadOptions);

                searchRequest.parameters.includeEscrowAccounts = includeEscrowAccounts;

                return self.$api.CompaniesApi.search(searchRequest)
                    .then(response => {
                        let mappedResult = _.map(response.results, item => Object.create({ id: item.companyID, name: `${item.companyID} - ${item.name}` }));
                        let combinedResult = _.isEmpty(selected)
                                ? mappedResult
                                : _.uniqBy(_.concat(selected, mappedResult), "id");
                        return {
                            data: combinedResult,
                            totalCount: response.totalRecords
                        };
                    });
            },

            updateFilterLookupSelected(e, dataField) {
                const self = this;
                let added = _.get(e, "addedItems", []);
                let removed = _.get(e, "removedItems", []);
                if(_.isEmpty(added) && _.isEmpty(removed)) return;
                let currentSelected = _.get(self, `filterLookups.${dataField}.selected`, []) || [];
                _.forEach(removed, item => {
                    let itemIndex = _.findIndex(currentSelected, sItem => sItem.companyID === item.companyID);
                    if(itemIndex < 0) return;
                    currentSelected.splice(itemIndex, 1);
                });
                currentSelected.push(...added);
                self.filterLookups[dataField].selected = currentSelected;
            },

            getGridColumns(visibleFields) {
                let gridColumns = this.defaultColumns.slice();
                let visibleColumns = visibleFields || this.visibleColumns;
                if(_.isEmpty(visibleColumns)) return gridColumns;
                _.forEach(gridColumns, c => {
                    c.visible = _.includes(visibleColumns, c.dataField);
                });
                return gridColumns;
            },

            clearSearch () {
                this.searchTextValue = "";
            },

            refresh: _.debounce(function() {
                const self = this;
                if (!self.gridInstance) return;
                self.gridInstance.clearSelection();
                self.gridInstance.refresh();
            }, 500),

            //debounced in case called from resizable dialog
            updateDimensions: _.debounce(function() {
                const self = this;
                if (!self.gridInstance) return;
                self.gridInstance.updateDimensions();
            }, 500),

            applyColumnsHiddenInChooser(columns) {
                let self = this;
                if(self.hiddenColumnsInChooser.length === 0) return columns;
                return _.map(columns, x => {
                    if(_.includes(self.hiddenColumnsInChooser, x.dataField)) x.showInColumnChooser = false;
                    return x;
                });
            }
        }

    };
</script>
