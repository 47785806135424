<template>
    <div class="content-wrapper">
        <rqValidationSummary :dataSource="validationErrors" />
        <rq-page-section title="Loan Terms" header-size="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box
                            v-model="selectedLoanId"
                        />
                    </li>
                </ul>
            </template>
        </rq-page-section>

        <!-- Loan Section -->
        <rq-page-section title="Loan" collapsible>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-section-expand-collapse-all />
                    </li>
                </ul>
            </template>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_loan_amount">Loan Amount</label>
                        <rqInputNumber
                            id="txt_loan_amount"
                            automation_id="txt_loan_amount"
                            ref="loanAmountInput"
                            defaultValue="0"
                            decimals="2"
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            @blur="onEditLoanAmount"
                            v-model="selectedLoanTerm.loanAmount"
                        />
                    </div>
                    <div v-if="!selectedLoanTerm.isLoanAmountOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_max_loan_amount">Can Go As High As</label>
                        <rqInputNumber
                            id="txt_max_loan_amount"
                            ref="txt_max_loan_amount"
                            automation_id="txt_max_loan_amount"
                            :disabled="readOnly || !selectedLoanTerm.isLoanAmountIncrease"
                            defaultValue="0"
                            decimals="2"
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedLoanTerm.maxLoanAmount"
                        />
                    </div>
                    <div v-if="!selectedLoanTerm.isLoanAmountOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_max_loan_year">Can increase until year</label>
                        <rqInputNumber
                            id="txt_max_loan_year"
                            automation_id="txt_max_loan_year"
                            :disabled="readOnly || !selectedLoanTerm.isLoanAmountIncrease"
                            defaultValue="0"
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.loanIncreaseYear"
                            :commas="false"
                        />
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group pt-4">
                        <b-form-checkbox
                            automation_id="chk_loanamount_override"
                            ref= "chk_loanamount_override"
                            id="chk_loanamount_override"
                            :disabled="readOnly || !selectedLoanTerm.isLoanAmountIncrease"
                            v-model="selectedLoanTerm.isLoanAmountOverride">Override Loan Terms</b-form-checkbox>
                    </div>
                </div>
            </fieldset>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col form-group">
                        <label for="txt_increase_amount">Can this amount increase?</label>
                        <rq-radio-group
                            v-model="selectedLoanTerm.isLoanAmountIncrease"
                            :inline="true"
                            :options="[
                                { automation_id: 'radio_no', value: false, text: 'NO'},
                                { automation_id: 'radio_yes', value: true, text: 'YES'}
                            ]"
                            />
                    </div>
                </div>

                <!-- Loan Amount Text Override -->
                <div v-if="selectedLoanTerm.isLoanAmountOverride" class="row">
                    <div class="col col-2 col-sm-12 col-md-12 col-lg-12 form-group">
                        <label for="loanAmount_rtf_template">Loan Amount Text</label>
                        <rq-html-editor
                            id="loanAmount_rtf_template"
                            automationId="loanAmount_rtf_template"
                            placeholder="Loan Amount Text"
                            :autoHideToolbar="true"
                            v-model="selectedLoanTerm.loanAmountOverrideText"
                            :height="200"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!-- Interest Rate Section -->
        <rq-page-section title="Interest Rate" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_loan_interest_rate">Interest Rate</label>
                        <div class="input-group">
                            <rqInputNumber
                                automation_id="txt_loan_interest_rate"
                                ref="txt_loan_interest_rate"
                                defaultValue="0"
                                decimals="3"
                                minValue="0"
                                maxValue="100"
                                v-focus-select-all
                                cssClass="form-control"
                                @blur="onEditLoanInterestRate"
                                v-model="selectedLoanTerm.loanInterestRate"
                            />
                            <span class="input-group-text">%</span>
                        </div>
                    </div>

                    <div v-if="!selectedLoanTerm.isInterestRateOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_interest_years">Number of years until first adjustment </label>
                        <rqInputNumber
                            id="txt_interest_years"
                            automation_id="txt_interest_years"
                            ref="txt_interest_years"
                            :disabled="readOnly || !selectedLoanTerm.isInterestRateIncrease"
                            defaultValue="0"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.years"
                        />
                    </div>
                    <div v-if="!selectedLoanTerm.isInterestRateOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_start_year">Start Year </label>
                        <rqInputNumber
                            id="txt_start_year"
                            automation_id="txt_start_year"
                            :disabled="readOnly || !selectedLoanTerm.isInterestRateIncrease"
                            :defaultValue="0"
                            formatType="basic"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.year1"
                        />
                    </div>
                    <div class="col col-6 col-sm-12 col-md-12 col-lg-3 form-group pt-4">
                        <b-form-checkbox
                            automation_id="chk_interest_override"
                            id="chk_interest_override"
                            :disabled="readOnly || !selectedLoanTerm.isInterestRateIncrease"
                            v-model="selectedLoanTerm.isInterestRateOverride">Override Interest Rate Terms</b-form-checkbox>
                    </div>
                </div>
            </fieldset>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-2 col-md-6 col-lg-3 form-group">
                        <label for="txt_increase_amount">Can this amount increase?</label>
                        <rq-radio-group
                            v-model="selectedLoanTerm.isInterestRateIncrease"
                            :inline="true"
                            :options="[
                                { automation_id: 'radio_no', value: false, text: 'NO'},
                                { automation_id: 'radio_yes', value: true, text: 'YES'}
                            ]"
                            />
                    </div>
                    <div v-if="!selectedLoanTerm.isInterestRateOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_rate_high_percentate">Can go as high as</label>
                        <div class="input-group">
                            <rqInputNumber
                                id="txt_rate_high_percentate"
                                automation_id="txt_rate_high_percentate"
                                :disabled="readOnly || !selectedLoanTerm.isInterestRateIncrease"
                                defaultValue="0"
                                decimals="3"
                                minValue="0"
                                maxValue="100"
                                cssClass="form-control"
                                v-focus-select-all
                                v-model="selectedLoanTerm.interestRateHighPercentage"
                            />
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                    <div v-if="!selectedLoanTerm.isInterestRateOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_max_rate_year">Max Year</label>
                        <rqInputNumber
                            id="txt_max_rate_year"
                            automation_id="txt_max_rate_year"
                            :disabled="readOnly || !selectedLoanTerm.isInterestRateIncrease"
                            :defaultValue="0"
                            formatType="basic"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.year2"
                        />
                    </div>
                    <div class="col col-6 col-sm-12 col-md-12 col-lg-3 form-group pt-4">
                        <b-form-checkbox
                            automation_id="chk_displayAIR"
                            id="displayAIR"
                            :disabled="readOnly || !selectedLoanTerm.isInterestRateIncrease"
                            v-model="selectedLoanTerm.isNotDisplayAIR">Do Not Display AIR Table</b-form-checkbox>
                    </div>
                </div>

                <!-- Interest Rate Override Text -->
                <div v-if="selectedLoanTerm.isInterestRateOverride" class="row">
                    <div class="col col-2 col-sm-12 col-md-12 col-lg-12 form-group">
                        <label for="interest_rtf_template">Interest Text</label>
                        <rq-html-editor
                            id="interest_rtf_template"
                            automationId="interest_rtf_template"
                            placeholder="Interest Text"
                            v-model="selectedLoanTerm.interestRateOverrideText"
                            :height="200"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!-- Monthly Principal & Interest section -->
        <rq-page-section title="Monthly Principal and Interest" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Monthly Principal and Interest"
                            labelFor="txt_mpi_amount"
                            :show-action="selectedLoanTerm.monthlyPIOverridden"
                            @action-click="onRevertMonthlyPIOverride"
                            action-automation-id="btn_revert_monthly_pi_override"
                            action-label="Revert"
                        >
                            <template #default>
                                <rqInputNumber
                                    id="txt_mpi_amount"
                                    automation_id="txt_mpi_amount"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="selectedLoanTerm.monthlyPIValue"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>

                    <div v-if="!selectedLoanTerm.isMonthlyPrincipalInterestOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_mpi_years">Number of years until first adjustment</label>
                        <rqInputNumber
                            id="txt_mpi_years"
                            automation_id="txt_mpi_years"
                            ref="txt_mpi_years"
                            :disabled="readOnly || !selectedLoanTerm.isMonthlyPrincipalInterestIncrease"
                            defaultValue="0"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.monthlyPrincipalInterestYears"
                        />
                    </div>
                    <div v-if="!selectedLoanTerm.isMonthlyPrincipalInterestOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_mpi_start_year">Start Year </label>
                        <rqInputNumber
                            id="txt_mpi_start_year"
                            automation_id="txt_mpi_start_year"
                            :disabled="readOnly || !selectedLoanTerm.isMonthlyPrincipalInterestIncrease"
                            :defaultValue="0"
                            formatType="basic"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.monthlyPrincipalInterestYear1"
                        />
                    </div>
                    <div class="col col-6 col-sm-12 col-md-12 col-lg-3 form-group pt-4">
                        <b-form-checkbox
                            automation_id="chk_displayAP"
                            id="displayAP"
                            :disabled="readOnly || !selectedLoanTerm.isMonthlyPrincipalInterestIncrease"
                            v-model="selectedLoanTerm.isNotDisplayAP">Do Not Display AP Table</b-form-checkbox>
                    </div>
                </div>
            </fieldset>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-2 col-md-6 col-lg-3 form-group">
                        <label for="txt_mpi_increase_amount">Can this amount increase?</label>
                        <rq-radio-group
                            v-model="selectedLoanTerm.isMonthlyPrincipalInterestIncrease"
                            :inline="true"
                            :options="[
                                { automation_id: 'radio_no', value: false, text: 'NO'},
                                { automation_id: 'radio_yes', value: true, text: 'YES'}
                            ]"
                            />
                    </div>

                    <div v-if="!selectedLoanTerm.isMonthlyPrincipalInterestOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_max_mpi_amount">Can Go As High As</label>
                        <rqInputNumber
                            id="txt_max_mpi_amount"
                            automation_id="txt_max_mpi_amount"
                            :disabled="readOnly || !selectedLoanTerm.isMonthlyPrincipalInterestIncrease"
                            defaultValue="0"
                            format-type="money"
                            decimals="2"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedLoanTerm.monthlyPrincipalInterestHigh"
                        />
                    </div>

                    <div v-if="!selectedLoanTerm.isMonthlyPrincipalInterestOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_mpi_year2">Max Year</label>
                        <rqInputNumber
                            id="txt_mpi_year2"
                            automation_id="txt_mpi_year2"
                            :disabled="readOnly || !selectedLoanTerm.isMonthlyPrincipalInterestIncrease"
                            :defaultValue="0"
                            formatType="basic"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.monthlyPrincipalInterestYear2"
                        />
                    </div>
                    <div class="col col-2 col-sm-12 col-md-12 col-lg-3 form-group pt-4">
                        <b-form-checkbox
                            automation_id="chk_mip_override"
                            id="chk_mip_override"
                            :disabled="readOnly || !selectedLoanTerm.isMonthlyPrincipalInterestIncrease"
                            v-model="selectedLoanTerm.isMonthlyPrincipalInterestOverride">{{`Override Monthly Principal & Interest Loan Terms`}}</b-form-checkbox>
                    </div>
                </div>
                <div class=row>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        &nbsp;
                    </div>
                    <div v-if="!selectedLoanTerm.isMonthlyPrincipalInterestOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label title="INCLUDES ONLY INTEREST AND NO PRINCIPAL UNTIL YEAR" for="txt_mpi_year3">Includes only interest and no principal until year</label>
                        <rqInputNumber
                            id="txt_mpi_year3"
                            automation_id="txt_mpi_year3"
                            :disabled="readOnly || !selectedLoanTerm.isMonthlyPrincipalInterestIncrease"
                            defaultValue="0"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.monthlyPrincipalInterestYear3"
                        />
                    </div>
                </div>

                <!-- Monthly Principal Interest Override Text -->
                <div v-if="selectedLoanTerm.isMonthlyPrincipalInterestOverride" class="row">
                    <div class="col col-2 col-sm-12 col-md-12 col-lg-12 form-group">
                        <label for="mip_rtf_template">Monthly Interest and Principal Text</label>
                        <rq-html-editor
                            id="mip_rtf_template"
                            automationId="mip_rtf_template"
                            placeholder="Monthly Interest and Principal Text"
                            v-model="selectedLoanTerm.monthlyPrincipalInterestOverrideText"
                            :height="200"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!-- Prepayment Penalty section -->
        <rq-page-section title="Prepayment Penalty" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-2 col-md-6 col-lg-3 form-group">
                        <label for="txt_pp_increase_amount">Does the loan have this feature?</label>
                        <rq-radio-group
                            v-model="selectedLoanTerm.isPrepaymentPenaltyIncrease"
                            :inline="true"
                            :options="[
                                { automation_id: 'radio_no', value: false, text: 'NO'},
                                { automation_id: 'radio_yes', value: true, text: 'YES'}
                            ]"
                            />
                    </div>

                    <div v-if="!selectedLoanTerm.isPrepaymentPenaltyOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_pp_amount">Penalty as high as</label>
                        <rqInputNumber
                            id="txt_max_pp_amount"
                            automation_id="txt_max_pp_amount"
                            ref="txt_max_pp_amount"
                            :disabled="readOnly || !selectedLoanTerm.isPrepaymentPenaltyIncrease"
                            defaultValue="0"
                            format-type="money"
                            decimals="2"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedLoanTerm.prepaymentPenaltyAmount"
                        />
                    </div>
                    <div v-if="!selectedLoanTerm.isPrepaymentPenaltyOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_penalty_year">Penalty when paid during these first years</label>
                        <rqInputNumber
                            id="txt_penalty_year"
                            automation_id="txt_penalty_year"
                            :disabled="readOnly || !selectedLoanTerm.isPrepaymentPenaltyIncrease"
                            :defaultValue="0"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.prepaymentPenaltyYears"
                        />
                    </div>
                    <div class="col col-2 col-sm-12 col-md-12 col-lg-3 form-group pt-4">
                        <b-form-checkbox
                            automation_id="chk_pp_override"
                            id="chk_pp_override"
                            :disabled="!selectedLoanTerm.isPrepaymentPenaltyIncrease"
                            v-model="selectedLoanTerm.isPrepaymentPenaltyOverride">Override Prepayment Penalty Terms</b-form-checkbox>
                    </div>
                </div>

                <!-- Prepayment Override Text -->
                <div v-if="selectedLoanTerm.isPrepaymentPenaltyOverride" class="row">
                    <div class="col col-2 col-sm-12 col-md-12 col-lg-12 form-group">
                        <label for="pp_rtf_template">Prepayment Text</label>
                        <rq-html-editor
                            id="pp_rtf_template"
                            automationId="pp_rtf_template"
                            placeholder="Prepayment Text"
                            v-model="selectedLoanTerm.prepaymentPenaltyOverrideText"
                            :height="200"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!-- Balloon Payment section -->
        <rq-page-section title="Balloon Payment" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_balloon_increase_amount">Does the loan have this feature?</label>
                        <rq-radio-group
                            v-model="selectedLoanTerm.isBalloonPaymentIncrease"
                            :inline="true"
                            :options="[
                                { automation_id: 'radio_no', value: false, text: 'NO'},
                                { automation_id: 'radio_yes', value: true, text: 'YES'}
                            ]"
                            />
                    </div>
                    <div v-if="!selectedLoanTerm.isBalloonPaymentOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_balloon_amount">You will have to pay</label>
                        <rqInputNumber
                            id="txt_balloon_amount"
                            automation_id="txt_balloon_amount"
                            ref="txt_balloon_amount"
                            :disabled="readOnly || !selectedLoanTerm.isBalloonPaymentIncrease"
                            defaultValue="0"
                            format-type="money"
                            decimals="2"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedLoanTerm.balloonPaymentAmount"
                        />
                    </div>
                    <div v-if="!selectedLoanTerm.isBalloonPaymentOverride" class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <label for="txt_balloon_years">At end of year</label>
                        <rqInputNumber
                            id="txt_balloon_years"
                            automation_id="txt_balloon_years"
                            :disabled="readOnly || !selectedLoanTerm.isBalloonPaymentIncrease"
                            :defaultValue="0"
                            formatType="basic"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedLoanTerm.balloonPaymentYears"
                        />
                    </div>
                    <div class="col col-2 col-sm-12 col-md-12 col-lg-3 form-group pt-4">
                        <b-form-checkbox
                            automation_id="chk_balloon_override"
                            id="chk_balloon_override"
                            :disabled="readOnly || !selectedLoanTerm.isBalloonPaymentIncrease"
                            v-model="selectedLoanTerm.isBalloonPaymentOverride">Override Balloon Payment Terms</b-form-checkbox>
                    </div>
                </div>

                <!-- Balloon Payment Override Text -->
                <div v-if="selectedLoanTerm.isBalloonPaymentOverride" class="row">
                    <div class="col col-2 col-sm-12 col-md-12 col-lg-12 form-group">
                        <label for="balloon_rtf_template">Balloon Payment Text</label>
                        <rq-html-editor
                            id="balloon_rtf_template"
                            automationId="balloon_rtf_template"
                            placeholder="Balloon Payment Text"
                            v-model="selectedLoanTerm.balloonPaymentOverrideText"
                            :height="200"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>

<script>
    import { GlobalEventManager } from '@/app.events';
    import { LoanTermSectionDataModel } from '@settlement/models';
    import { ORDER_ACTIONS } from "@/store/actions";
    import RqHtmlEditor from '@/shared/components/rq/editors/RqHtmlEditor';
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { DateTime } from "luxon";

    export default {

        name: "LoanTerms",
        mixins: [BaseSettlementMixin],
        components: { RqHtmlEditor },
        data(){
            return{
                selectedLoanTerm: {},
                originalLoanTerms:[],
            }
        },
        computed:{
            currentYear() {
                return DateTime.now().year;
            },
        },
        watch:{
            selectedLoanId(newVal, oldVal){
                const self = this;
                if (newVal === oldVal || _.parseNumber(oldVal, 0) === 0) return;
                self.save()
                    .then(()=>{
                        self.fetchData();
                    });
            },
            "selectedLoanTerm.isLoanAmountIncrease": {
                immediate: false,
                handler: function(newValue, oldValue) {
                    const self = this;
                    if (oldValue === newValue || oldValue === undefined) return;

                    if (newValue == false) {
                        this.resetLoanSectionData();
                    }else{
                        self.$refs.txt_max_loan_amount.focus();
                    }
                }
            },
            "selectedLoanTerm.isInterestRateIncrease": {
                immediate: false,
                handler: function(newValue, oldValue) {
                    const self = this;
                    if (oldValue === newValue || oldValue === undefined) return;

                    if (newValue == false) {
                        self.resetInterestSectionData();
                    }else{
                        self.$refs.txt_interest_years.focus();
                    }
                }
            },
            "selectedLoanTerm.isMonthlyPrincipalInterestIncrease": {
                immediate: false,
                handler: function(newValue, oldValue) {
                    const self = this;
                    if (oldValue === newValue || oldValue === undefined) return;

                    if (newValue == false) {
                        self.resetMPISectionData();
                    }else{
                        self.$refs.txt_mpi_years.focus();
                    }
                }
            },
            "selectedLoanTerm.isPrepaymentPenaltyIncrease": {
                immediate: false,
                handler: function(newValue, oldValue) {
                    const self = this;
                    if (oldValue === newValue || oldValue === undefined) return;

                    if (newValue == false) {
                        self.resetPrepayPenaltySectionData();
                    }else{
                        self.$refs.txt_max_pp_amount.focus();
                    }
                }
            },
            "selectedLoanTerm.isBalloonPaymentIncrease": {
                immediate: false,
                handler: function(newValue, oldValue) {
                    const self = this;
                    if (oldValue === newValue || oldValue === undefined) return;

                    if (newValue == false) {
                        self.resetBalloonPaymentSectionData();
                    }else{
                        self.$refs.txt_balloon_amount.focus();
                    }
                }
            }
        },
        mounted() {
            this.resetFocus();
        },
        created(){
            this.baseInit();
            this.fetchData();
        },
        methods:{
            onRevertMonthlyPIOverride() {
                if(this.selectedLoanTerm)
                    this.selectedLoanTerm.monthlyPrincipalInterestOverride = null;
            },
            onEditLoanAmount(e) {
                const self = this;
                let loan = self.selectedLoan(self.selectedLoanId);
                let newLoanAmount = _.getNumber(e, "value", 0);
                let origLoanAmount = _.getNumber(loan, "amount", 0);

                let handleOk = function(e) {
                    return true;
                }

                let handleCancel = function(e) {
                    self.selectedLoanTerm.loanAmount = origLoanAmount;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newLoanAmount !== origLoanAmount) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Loan Amount for Loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },

            onEditLoanInterestRate(e) {
                const self = this;
                let loan = self.selectedLoan(self.selectedLoanId);
                let newLoanInterestRate = e.value;
                let origLoanInterestRate = loan.interestRate;

                let handleOk = function(e) {
                    return true;
                }

                let handleCancel = function(e) {
                    self.selectedLoanTerm.loanInterestRate = origLoanInterestRate;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newLoanInterestRate !== origLoanInterestRate) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Interest Rate for loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },

            resetLoanSectionData() {
                this.selectedLoanTerm.maxLoanAmount = null;
                this.selectedLoanTerm.loanIncreaseYear = null;
                this.selectedLoanTerm.isLoanAmountOverride = false;
            },
            resetInterestSectionData() {
                this.selectedLoanTerm.years = 0;
                this.selectedLoanTerm.year1 = 0;
                this.selectedLoanTerm.isInterestRateOverride = false;
                this.selectedLoanTerm.interestRateHighPercentage = 0;
                this.selectedLoanTerm.year2 = 0;
                this.selectedLoanTerm.isNotDisplayAIR = false;
            },

            resetMPISectionData() {
                this.selectedLoanTerm.monthlyPrincipalInterestYears = null;
                this.selectedLoanTerm.monthlyPrincipalInterestYear1 = 0;
                this.selectedLoanTerm.isNotDisplayAP = false;
                this.selectedLoanTerm.monthlyPrincipalInterestHigh = 0;
                this.selectedLoanTerm.monthlyPrincipalInterestYear2 = 0;
                this.selectedLoanTerm.isMonthlyPrincipalInterestOverride = false;
                this.selectedLoanTerm.monthlyPrincipalInterestYear3 = 0;
            },

            resetPrepayPenaltySectionData() {
                this.selectedLoanTerm.prepaymentPenaltyAmount = null;
                this.selectedLoanTerm.prepaymentPenaltyYears = null;
                this.selectedLoanTerm.isPrepaymentPenaltyOverride = false;
            },

            resetBalloonPaymentSectionData() {
                this.selectedLoanTerm.balloonPaymentAmount = null;
                this.selectedLoanTerm.balloonPaymentYears = null;
                this.selectedLoanTerm.isBalloonPaymentOverride = false;
            },
            selectedLoan(loanId){
                const self = this;
                let selected = _.find(self.loans, l => l.loanID === loanId);
                return selected;
            },
            onSave(e){
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            onCancel(e){
                const self = this;
                let changes = self.getChanges();
                if(_.isEmpty(changes)) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            save(toastIfNoChanges=false){
                const self = this;
                let original = _.find(self.originalLoanTerms, s => s.loanTermSectionDataID === self.selectedLoanTerm.loanTermSectionDataID);
                if(_.isNil(original)) return Promise.resolve(false);
                let changes = self.getChanges(original);

                if(_.isEmpty(changes)){
                    if(toastIfNoChanges)
                        self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let promise = self.$api.LoanTermApi.update(self.selectedLoanTerm, changes).then(()=>{
                    self.syncUpdates().then(() => {
                        self.$toast.success("Loan Terms Saved.");
                        GlobalEventManager.saveCompleted({success: true});
                        return true;
                    });
                }).catch(err =>{
                    self.$toast.error(err.message);
                    GlobalEventManager.saveCompleted({success: false});
                    return false;
                });

                self.$rqBusy.wait(promise);
                return promise;
            },

            getChanges(original=null) {
                const self = this;
                let originalTerms = _.isNil(original) ? _.find(self.originalLoanTerms, s => s.loanTermSectionDataID === self.selectedLoanTerm.loanTermSectionDataID) : original;
                return self.getAuditChanges(originalTerms, self.selectedLoanTerm);
            },

            syncUpdates(){
                const self = this;

                //refresh the store
                let promise = self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, {orderId: self.orderId, refresh:true});

                self.$rqBusy
                    .wait(promise)
                    .then(() => {
                        // Re-query to enforce new values.
                        self.fetchData();
                    })
                return promise;
            },

            fetchData(){
                const self = this;
                let promise = self.$api.LoanTermApi.getByOrderId(self.orderId);
                self.$rqBusy.wait(promise).then(result => {
                    self.originalLoanTerms = _.map(result, i => new LoanTermSectionDataModel(i));
                    self.selectedLoanTerm = _.cloneDeep(_.find(self.originalLoanTerms, s => s.loanID === self.selectedLoanId));

                    // Calculated Monthly Principal & Interest value
                    let loan = self.selectedLoan(self.selectedLoanId);
                    let calculatedMPI = loan.monthlyPrincipal + loan.monthlyInterest;

                    self.selectedLoanTerm.monthlyPrincipalInterest = calculatedMPI;
                })
                .catch(err =>{
                    self.$toast.error(err.errorMessage);
                });
            },

            resetFocus() {
                if (this.loans.length == 1)
                    this.$refs.loanAmountInput.focus();
            }
        }
    }
</script>