<template>
    <div class="rq-container content-wrapper recording-fees">
        <div class="row">
            <div v-if="hasSyncedLineItems" :class="{'col-12 col-sm-12 col-lg-3 form-required form-group' : true, 'has-error': invalidPayee }">
                <label for="pic_Payee_company">{{activeLineNumber}} Payee</label>
                <company-picker
                    ref="refPayeeCompany"
                    automation_id="pic_Payee_company"
                    dialogTitle="Select Payee"
                    :disabled="readOnly"
                    v-model:text="defaultPayeeText"
                    @changeName="defaultPayeeNameChange"
                    v-model="defaultPayee">
                </company-picker>
                <rq-validation-feedback :offset="payeeErrorOffset">{{activeLineNumber}} Payee is Required</rq-validation-feedback>
            </div>
            <div v-if="isCdf && hasE02TransferTaxes" :class="{'col-12 col-sm-12 col-lg-3 form-required form-group' : true, 'has-error': invalidE02TransferPayee }">
                <label class="form-control-label"
                    for="payee_company"
                    :class="{'has-error':invalidE02TransferPayee}">E02 Payee</label>
                <company-picker
                    ref="refPayeeCompany"
                    automation_id="pic_Payee_company"
                    dialogTitle="Select Payee"
                    :disabled="readOnly"
                    v-model:text="defaultPayeeText"
                    v-model="defaultE02TransferPayee">
                </company-picker>
                <rq-validation-feedback
                    :offset="payeeErrorOffset"
                    :messages="{
                        'E02 Payee is Required' : invalidE02TransferPayee
                    }"
                />
            </div>
            <div v-if="isHud1974 && has1202TransferTaxes" :class="{'col-12 col-sm-12 col-lg-3 form-required form-group' : true, 'has-error': invalid1202TransferPayee }">
                <label class="form-control-label"
                    for="payee_company"
                    :class="{'has-error':invalid1202TransferPayee}">1202 Payee</label>
                <company-picker
                    ref="refPayeeCompany"
                    automation_id="pic_Payee_company"
                    dialogTitle="Select Payee"
                    :disabled="readOnly"
                    v-model:text="defaultPayeeText"
                    v-model="default1202TransferPayee">
                </company-picker>
                <rq-validation-feedback
                    :offset="payeeErrorOffset"
                    :messages="{
                        '1202 Payee is Required' : invalid1202TransferPayee
                    }"
                />
            </div>
            <div v-if="isHud1974 && has1203TransferTaxes" :class="{'col-12 col-sm-12 col-lg-3 form-required form-group' : true, 'has-error': invalid1203TransferPayee }">
                <label class="form-control-label"
                    for="payee_company"
                    :class="{'has-error':invalid1203TransferPayee}">1203 Payee</label>
                <company-picker
                    ref="refPayeeCompany"
                    automation_id="pic_Payee_company"
                    dialogTitle="Select Payee"
                    :disabled="readOnly"
                    v-model:text="defaultPayeeText"
                    v-model="default1203TransferPayee">
                </company-picker>
                <rq-validation-feedback
                    :offset="payeeErrorOffset"
                    :messages="{
                        '1203 Payee is Required' : invalid1203TransferPayee
                    }"
                />
            </div>
            <div v-if="isHud2010 && has1204TransferTaxes" :class="{'col-12 col-sm-12 col-lg-3 form-required form-group' : true, 'has-error': invalid1204TransferPayee }">
                <label class="form-control-label"
                    for="payee_company"
                    :class="{'has-error':invalid1204TransferPayee}">1204 Payee</label>
                <company-picker
                    ref="refPayeeCompany"
                    automation_id="pic_Payee_company"
                    dialogTitle="Select Payee"
                    :disabled="readOnly"
                    v-model:text="defaultPayeeText"
                    v-model="default1204TransferPayee">
                </company-picker>
                <rq-validation-feedback
                    :offset="payeeErrorOffset"
                    :messages="{
                        '1204 Payee is Required' : invalid1204TransferPayee
                    }"
                />
            </div>
            <div v-if="isHud2010 && has1205TransferTaxes" :class="{'col-12 col-sm-12 col-lg-3 form-required form-group' : true, 'has-error': invalid1205TransferPayee }">
                <label class="form-control-label"
                    for="payee_company"
                    :class="{'has-error':invalid1205TransferPayee}">1205 Payee</label>
                <company-picker
                    ref="refPayeeCompany"
                    automation_id="pic_Payee_company"
                    dialogTitle="Select Payee"
                    :disabled="readOnly"
                    v-model:text="defaultPayeeText"
                    v-model="default1205TransferPayee">
                </company-picker>
                <rq-validation-feedback
                    :offset="payeeErrorOffset"
                    :messages="{
                        '1205 Payee is Required' : invalid1205TransferPayee
                    }"
                />
            </div>
        </div>
        <rqdx-action-data-grid
            v-if="initialized"
            ref="dataGrid"
            automation_id="tbl_recording_fee_grid"
            class="rq-tab-content-grid"
            :config="gridConfig"
            :data-source="gridDataSource"
            :actions="gridActions"
            export-file-name="recording-fee-data"
            @delete="onDeleteItem"
            @revert="onRevertItem"
            @netfund="onChangeNetFundItems"
            @reset="onGridReset"
            :rq-editable="!this.readOnly"
            hide-search
            rq-filters
        />
    </div>
</template>
<script>

    import { mapState, mapGetters } from "vuex";
    import { ref, computed } from "vue";
    import { SETTLEMENT_TYPE, RECORDING_DOC_SEED_TYPE, POCWhoOptions, POCNetFundOptions, SsGridActions } from "@settlement/models";
    import { CombinedCalculation, ManualRecordingFeeCalculation, ManualTransferTaxCalculation, SettlementTypeOption } from "../../../models";
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import { CompanyPicker } from "@/shared/components/rq";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import {
        useGridInvokerMethods,
        useGridCompanyPicker
    } from "@/shared/composables/";
    import { CDFTaxType, Hud1974TaxType, Hud2010TaxType } from "@config/enums";
    import { RecordingFeeGridType } from "@/modules/file/settlement/models/enums";
    import { ORDER_ACTIONS } from "@/store/actions";
    import { useLicenseStore } from "@/store/modules/license";

    const LocalGridUtils = {
        lookupCol: col => {
            let colOptions = _.pickBy(col, (v,k) => k !== "dataSource");
            let defaultOpts = {
                dataType: "number",
                alignment: "left",
                lookup: { displayExpr: "name", valueExpr: "id" },
            };
            if(!_.has(colOptions.lookup))
                colOptions.lookup = { dataSource: col.dataSource };
            return _.defaultsDeep({}, colOptions, defaultOpts);
        },
        currencyCol: col => {
            let colOptions = _.pickBy(col, (v,k) => k !== "overrideField");
            let defaultOpts = {
                dataType: "number",
                format: { type: "currency", precision: 2 },
                editorOptions: { format: { type: "currency", precision: 2 } },
                cellTemplate: DxGridUtils.moneyCellTemplate
            };
            if(!_.isEmpty(col.overrideField)) {
                colOptions.cellTemplate = function (cellElement, cellInfo) {
                    cellElement.removeClass("rq-overridden");
                    if(_.getBool(cellInfo, `data.${col.overrideField}`))
                        cellElement.addClass("rq-overridden");
                    cellElement.append(cellInfo.text);
                };
            }
            return _.defaultsDeep({}, colOptions, defaultOpts);
        },
        summaryTotalCol: (column, visible) => ({
            column,
            summaryType: "sum",
            valueFormat: "currency",
            customizeText: d => _.formatMoney(d.value)
        })
    };

    const LINE_ASSOC_TYPE = {
        "E01": SETTLEMENT_TYPE.CDF,
        "1201": SETTLEMENT_TYPE.HUD_1974,
        "1202": SETTLEMENT_TYPE.HUD_2010
    };

    export default {
        name: "RecordingFeeGridCalculator",
        mixins: [
            GridCompanyPickerMixin,
            GridSystemLookupMixin
        ],
        components: { CompanyPicker },
        props:{
            loanId: { type: Number, default: 0 },
            validationErrors: { type: Array, default: () => [] },
            displayMode: {type: String, default: "route"}
        },
        emits: ["updated", "initialized", "clearQuestions", "update:validationErrors", "updateRateAndFeeWarnings"],
        setup() {
            const {
                dataGrid,
                invokeGridMethod,
                invokeGridComponentMethod
            } = useGridInvokerMethods();

            const {
                getCompanyContactGridColumn
            } = useGridCompanyPicker();

            const licenseStore = useLicenseStore();
            // const { popoverInfo } = useGridIconPopover("standard-fees");
            const standardFeesEnabled = computed(() => licenseStore.features?.standardFees);
            const recordingDocumentsEnabled = computed(() => licenseStore.features?.recordingDocuments);
            return {
                dataGrid,
                invokeGridMethod,
                invokeGridComponentMethod,
                getCompanyContactGridColumn,
                standardFeesEnabled,
                recordingDocumentsEnabled
            };
        },
        data(){
            return{
                initialized: false,
                originalItems:[],
                items:[],
                deleteditems: [],
                gridDataSource: {},
                gridConfig: {},
                defaultPayee: {
                    companyID: 0,
                    companyName: null
                },
                defaultE02TransferPayee: {
                    companyID: 0,
                    companyName: null
                },
                default1202TransferPayee: {
                    companyID: 0,
                    companyName: null
                },
                default1203TransferPayee: {
                    companyID: 0,
                    companyName: null
                },
                default1204TransferPayee: {
                    companyID: 0,
                    companyName: null
                },
                default1205TransferPayee: {
                    companyID: 0,
                    companyName: null
                },
                defaultPayeeText: null,
                newRecordingFeeGridType: null,
                validationErrorsValue: []
            }
        },
        computed: {
            ...mapState({
                order: state => state.orders.order,
                readOnly: state => state.isPageReadOnly,
                orderId: state => state.orders.orderId,
                settlementType: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
                isWithoutSeller: state => _.getBool(state, "orders.orderSummary.isWithOutSeller"),
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "orderRegionId"
            ]),
            invalidE02TransferPayee() {
                return (this.validationErrorsValue.length > 0 &&
                    ( _.isNil(this.defaultE02TransferPayee) || _.isNil(this.defaultE02TransferPayee.companyID) || this.defaultE02TransferPayee.companyID === 0));
            },
            invalid1202TransferPayee() {
                return (this.validationErrorsValue.length > 0 &&
                    ( _.isNil(this.default1202TransferPayee) || _.isNil(this.default1202TransferPayee.companyID) || this.default1202TransferPayee.companyID === 0));
            },
            invalid1203TransferPayee() {
                return (this.validationErrorsValue.length > 0 &&
                    ( _.isNil(this.default1203TransferPayee) || _.isNil(this.default1203TransferPayee.companyID) || this.default1203TransferPayee.companyID === 0));
            },
            invalid1204TransferPayee() {
                return (this.validationErrorsValue.length > 0 &&
                    ( _.isNil(this.default1204TransferPayee) || _.isNil(this.default1204TransferPayee.companyID) || this.default1204TransferPayee.companyID === 0));
            },
            invalid1205TransferPayee() {
                return (this.validationErrorsValue.length > 0 &&
                    ( _.isNil(this.default1205TransferPayee) || _.isNil(this.default1205TransferPayee.companyID) || this.default1205TransferPayee.companyID === 0));
            },
            hasE02TransferTaxes() {
                const self=this;
                return (!_.isNil(self.lineE02TransferTaxes) && !_.isEmpty(self.lineE02TransferTaxes));
            },
            lineE02TransferTaxes() {
                const self = this;
                return _.filter(self.transferTaxItems, function(item) {
                    return _.includes([CDFTaxType.E02CityTransferTax, CDFTaxType.E02CountyTransferTax, CDFTaxType.E02StateTransferTax], item.cdfLineType)
                });
            },
            has1202TransferTaxes() {
                const self=this;
                return (!_.isNil(self.line1202TransferTaxes) && !_.isEmpty(self.line1202TransferTaxes));
            },
            line1202TransferTaxes() {
                const self = this;
                return _.filter(self.transferTaxItems, function(item) {
                    return _.includes([Hud1974TaxType.Line1202CityCountyDeed, Hud1974TaxType.Line1202CityCountyMortgage], item.hud1974LineType)
                });
            },
            has1203TransferTaxes() {
                const self=this;
                return (!_.isNil(self.line1203TransferTaxes) && !_.isEmpty(self.line1203TransferTaxes));
            },
            line1203TransferTaxes() {
                const self = this;
                return _.filter(self.transferTaxItems, function(item) {
                    return _.includes([Hud1974TaxType.Line1203StateDeed, Hud1974TaxType.Line1203StateMortgage], item.hud1974LineType)
                });
            },
            has1204TransferTaxes() {
                const self=this;
                return (!_.isNil(self.line1204TransferTaxes) && !_.isEmpty(self.line1204TransferTaxes));
            },
            line1204TransferTaxes() {
                const self = this;
                return _.filter(self.transferTaxItems, function(item) {
                    return _.includes([Hud2010TaxType.Line1204CityCountyDeed, Hud2010TaxType.Line1204CityCountyMortgage], item.hud2010LineType)
                });
            },
            has1205TransferTaxes() {
                const self=this;
                return (!_.isNil(self.line1205TransferTaxes) && !_.isEmpty(self.line1205TransferTaxes));
            },
            line1205TransferTaxes() {
                const self = this;
                return _.filter(self.transferTaxItems, function(item) {
                    return _.includes([Hud2010TaxType.Line1205StateDeed, Hud2010TaxType.Line1205StateMortgage], item.hud2010LineType)
                });
            },
            isMixedE02TransferPayeeCollection() {
                const self = this;
                if (!self.hasE02TransferTaxes) return false;
                let arbitraryTransferTaxItem = self.lineE02TransferTaxes[0]; // select the first one to compare all amongst

                return !(_.every(self.lineE02TransferTaxes, item => item.payeeCompanyId === arbitraryTransferTaxItem.payeeCompanyId));
            },
            isMixed1202TransferPayeeCollection() {
                const self = this;
                if (!self.has1202TransferTaxes) return false;
                let arbitraryTransferTaxItem = self.line1202TransferTaxes[0]; // select the first one to compare all amongst

                return !(_.every(self.line1202TransferTaxes, item => item.payeeCompanyId === arbitraryTransferTaxItem.payeeCompanyId));
            },
            isMixed1203TransferPayeeCollection() {
                const self = this;
                if (!self.has1203TransferTaxes) return false;
                let arbitraryTransferTaxItem = self.line1203TransferTaxes[0]; // select the first one to compare all amongst

                return !(_.every(self.line1203TransferTaxes, item => item.payeeCompanyId === arbitraryTransferTaxItem.payeeCompanyId));
            },
            isMixed1204TransferPayeeCollection() {
                const self = this;
                if (!self.has1204TransferTaxes) return false;
                let arbitraryTransferTaxItem = self.line1204TransferTaxes[0]; // select the first one to compare all amongst

                return !(_.every(self.line1204TransferTaxes, item => item.payeeCompanyId === arbitraryTransferTaxItem.payeeCompanyId));
            },
            isMixed1205TransferPayeeCollection() {
                const self = this;
                if (!self.has1205TransferTaxes) return false;
                let arbitraryTransferTaxItem = self.line1205TransferTaxes[0]; // select the first one to compare all amongst

                return !(_.every(self.line1205TransferTaxes, item => item.payeeCompanyId === arbitraryTransferTaxItem.payeeCompanyId));
            },
            gridActions() {
                const self = this;
                return [
                    { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: "Delete", disabled: self.readOnly },
                    { name: "revert", text: "Revert", eventName: "revert", allowMultiSelection: true, disabled: e => (!_.some(e.data, "overrideCalculations")), tooltip: "Revert" },
                    {
                        name: "Apply-Net-Fund", text: "Apply Net Fund", eventName: "netfund", requireSelection: true, allowMultiSelection: true,
                        disabled: e => self.readOnly || (self.isHud2010 && _.some(e.data, item => item.hudLine === 1201)),
                        children: [ SsGridActions.NETFUND_NONE, SsGridActions.NETFUND_POSITIVE, SsGridActions.NETFUND_NEGATIVE ]
                    },
                ];
            },
            gridStorageKey() { return `settlement_recording_fees_${this.settlementType}_${this.isWithoutSeller}`; },
            isDisplayModal() {
                return this.displayMode === "modal";
            },

            /* Payee info */
            payeeErrorOffset() { return _.isEmpty(this.defaultPayeeText) ? 40 : 75; },
            invalidPayee() { return this.validationErrorsValue.length > 0 && _.getNumber(this, "defaultPayee.companyID", 0) <= 0; },

            /* Lookups */
            netFundItems() { return this.lookupHelpers.getLookupItems(this.lookupItems.NET_FUND); },
            accountCodes() { return this.lookupHelpers.getAllLookupItems(this.lookupItems.ACCOUNTING_CODES); },
            recordingDocumentLineTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.RECORDING_DOCUMENTS);},
            taxCdfLineTypes() { return CDFTaxType.lookupItems },
            taxHud1974LineTypes() { return Hud1974TaxType.lookupItems; },
            taxHud2010LineTypes() { return Hud2010TaxType.lookupItems; },
            pocWhoOptions() { return POCWhoOptions.lookupItems; },

            /* SS Type helpers */
            isCdf() { return this.settlementType === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.settlementType === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.settlementType === SETTLEMENT_TYPE.HUD_2010; },

            /* Synced SS Type specific line data */
            syncedLineItems() { return this.getSyncedLineItems(); },
            hasSyncedLineItems() { return !_.isEmpty(this.syncedLineItems); },
            activeLineNumber() { return _.findKey(LINE_ASSOC_TYPE, v => v === this.settlementType); },
            activeSeedType() { return SettlementTypeOption.getSeedType(this.settlementType); },
            activeLineTypes() { return _.filter(this.recordingDocumentLineTypes, lt => _.parseNumber(lt.data) === this.activeSeedType); },
            recordingFeeGridTypes() { return RecordingFeeGridType.lookupItems; },
            recordingFeeItems() { return _.filter(this.items, i => i.recordingFeeGridType == RecordingFeeGridType.Fee );},
            transferTaxItems() { return _.filter(this.items, i => i.recordingFeeGridType == RecordingFeeGridType.Tax );},
            recordingDocumentTypes() {return this.lookupHelpers.getLookupItems(this.lookupItems.RECORDING_DOCUMENT_TYPES); },
        },
        watch:{
            loanId(newVal){
                if(newVal && newVal > 0) {
                    const self = this;                    
                    this.fetchData(true).then(res => {
                        self.syncPayeeWithLineItems();
                        self.updateE02TransferPayeeView();
                    });
                }
            },
            settlementType(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.initGridConfig(true);
            },
            isWithoutSeller(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.initGridConfig(true);
            },
            defaultPayee(newVal, oldVal) {
                let newId = _.getNumber(newVal, "companyID", 0);
                let oldId = _.getNumber(oldVal, "companyID", 0);
                if (newId === oldId || newId <= 0) return;
                this.syncWithDefaultPayee();
            },
            validationErrorsValue: {
                handler(val) {
                    this.$emit("update:validationErrors", val);
                },
                deep: true
            },
            defaultE02TransferPayee(newVal, oldVal) {
                const self = this;
                if (newVal === oldVal) return;

                if(newVal && newVal.companyID > 0) {
                    self.updateDefaultE02TransferPayees();
                }
            },
            default1202TransferPayee(newVal,oldVal) {
                const self = this;
                if (newVal === oldVal) return;

                if(newVal && newVal.companyID > 0) {
                    self.updateDefault1202TransferPayees();
                }
            },
            default1203TransferPayee(newVal, oldVal) {
                const self = this;
                if (newVal === oldVal) return;

                if(newVal && newVal.companyID > 0) {
                    self.updateDefault1203TransferPayees();
                }
            },
            default1204TransferPayee(newVal, oldVal) {
                const self = this;
                if (newVal === oldVal) return;

                if(newVal && newVal.companyID > 0) {
                    self.updateDefault1204TransferPayees();
                }
            },
            default1205TransferPayee(newVal, oldVal) {
                const self = this;
                if (newVal === oldVal) return;

                if(newVal && newVal.companyID > 0) {
                    self.updateDefault1205TransferPayees();
                }
            },
        },
        created() {
            const self = this;
            self.initGridConfig();
            self.fetchData();
        },
        methods:{
            getRowLineTypes({ recordingFeeGridType=null }={ recordingFeeGridType: null }) {
                const self = this;
                let mappedTransferTaxTypes = _.map(self.getTransferTaxLineTypes(), lt => ({ ...lt, id: lt.id + 100 }));
                if(_.isNil(recordingFeeGridType)){
                    return _.concat(self.activeLineTypes, mappedTransferTaxTypes);
                }
                if(!_.includes([RecordingFeeGridType.Fee, RecordingFeeGridType.Tax], recordingFeeGridType)) return [];
                return recordingFeeGridType === RecordingFeeGridType.Fee
                    ? self.activeLineTypes
                    : mappedTransferTaxTypes;
            },

            initGridConfig(reinitialize=false){
                const self = this;
                if(reinitialize) self.initialized = false;
                let ssTypeName = SettlementTypeOption.getTypeName(self.settlementType);
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeCompanyId",
                    companyNameExpr: "payeeCompanyName",
                    showContactPicker: true,
                    activeOnly: true
                };

                const getAccountCodes = selectedVal => self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES, self.orderRegionId, selectedVal);

                // TG - To be enabled/fine-tuned with standard fees functionality
                // const mapPopoverDetail = item => ({
                //     description: item.description,
                //     amount: item.buyerAmount + item.sellerAmount,
                //     items: [
                //         { description: "Flat Fee Amount", amount: item.flatFeeAmount || 0 },
                //         { description: "Initial Page Amount", amount: item.initialPageAmount || 0 },
                //         { description: "Additional Page Amount", amount: item.additionalPageAmount || 0 },
                //     ]
                // });

                let columns = [
                    {
                        dataField: "recordingFeeGridType",
                        caption: "Type",
                        dataType: "number",
                        width: 100,
                        lookup: {
                            dataSource(opts) {
                                return self.recordingFeeGridTypes;
                            },
                            displayExpr: "name",
                            valueExpr: "id"
                        },
                        rqFilter: {
                            valueExpr: "id",
                            displayExpr: "name",
                            dataSource: self.recordingFeeGridTypes
                        }
                    },
                    {
                        dataField: "recordingDocumentTypeID",
                        dataType: "number",
                        caption: "Document Type",
                        editorOptions: { showClearButton: true },
                        lookup: {
                            dataSource: self.recordingDocumentTypes,
                            displayExpr: "name",
                            valueExpr: "id"
                        },
                        calculateSortValue: function(rowData) {
                            let value = this.calculateCellValue(rowData);
                            return this.lookup.calculateCellValue(value);
                        },
                        setCellValue: function(rowData, value) {
                            var recordingDocumentType = _.find(self.recordingDocumentTypes, t => t.id == value);
                            rowData.description = recordingDocumentType?.name || null;
                            rowData.recordingDocumentTypeID = value;
                        },
                    },
                    {
                        caption: "Description",
                        dataField: "descriptionDisplay",
                        dataType: "string",
                        editorOptions: { maxLength: 250 },
                        allowFiltering: false,
                        cellTemplate(rowData, value) {
                            let displayText = value.text;
                            if(value.data.descriptionOverridden)
                                    rowData.addClass("rq-overridden");

                            rowData.append(displayText);
                        },
                    },
                    // self.getCompanyContactGridColumn({
                    //     column: { dataField: "payeeCompanyName", caption: "Payee", allowFiltering: false },
                    //     dialogTitle: "Select Payee",
                    //     companyIDExpr:"payeeCompanyId",
                    //     companyNameExpr:"payeeCompanyName",
                    //     contactIDExpr:"payeeContactId",
                    //     contactNameExpr:"payeeContactName",
                    //     showContactPicker: true
                    // }),
                    self.getCompanyContactGridColumn({
                        column: {
                            dataField: "payeeCompanyName",
                            caption: "Payee",
                        },
                        ...payeePickerInfo
                    }),
                    {
                        dataField: "pagesCountDisplay",
                        dataType: "number",
                        caption: "Page Count",
                        width: 105,
                        allowFiltering: false,
                        cellTemplate(rowData, value) {
                            let displayText = value.text;
                            if(value.data.pagesCountOverridden)
                                    rowData.addClass("rq-overridden");

                            rowData.append(displayText);
                        },
                    },
                    {
                        name: "borrowerGroup",
                        caption: "Borrower",
                        alignment: "center",
                        columns: [
                            {
                                dataField: "buyerAmountDisplay",
                                caption: "At Closing",
                                width: 90,
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate(rowData, value) {
                                    let displayText = value.text;
                                    if(value.data.buyerAmountOverridden)
                                            rowData.addClass("rq-overridden");

                                    rowData.append(accounting.formatMoney(displayText));
                                },
                            },
                            LocalGridUtils.currencyCol({
                                dataField: "paidForBorrowerBeforeClosing",
                                caption: "Before Closing",
                                width: 105,
                                visible: self.isCdf,
                                allowFiltering: false
                            }),
                        ]
                    },
                    {
                        name: "sellerGroup",
                        caption: "Seller",
                        alignment: "center",
                        visible: !self.isWithoutSeller,
                        columns: [
                            {
                                dataField: "sellerAmountDisplay",
                                width: 90,
                                caption: "At Closing",
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate(cellElement, cellInfo) {
                                    let displayText = cellInfo.text;
                                    if(cellInfo.data.sellerAmountOverridden)
                                        cellElement.addClass("rq-overridden");

                                    cellElement.append(accounting.formatMoney(displayText));
                                }
                            },
                            LocalGridUtils.currencyCol({
                                dataField: "paidForSellerBeforeClosing",
                                caption: "Before Closing",
                                width: 105,
                                visible: self.isCdf && !self.isWithoutSeller,
                                allowFiltering: false
                            }),
                        ],
                        allowFiltering: false
                    },
                    LocalGridUtils.lookupCol({
                        dataField: "pocWhom",
                        caption: "POC Whom",
                        dataSource: self.pocWhoOptions,
                        width: 80,
                        visible: self.isHud,
                        editorOptions: { searchMode: "startswith" },
                        allowFiltering: false
                    }),
                    LocalGridUtils.currencyCol({
                        dataField: "amountPOC",
                        caption: "POC Amount",
                        width: 90,
                        visible: self.isHud,
                        allowFiltering: false
                    }),
                    LocalGridUtils.lookupCol({
                        dataField: "netFund",
                        dataSource: self.netFundItems,
                        width: 60,
                        allowFiltering: false
                    }),
                    {
                        dataField: "accountCodeId",
                        caption: "Account Code",
                        dataType: "number",
                        alignment: "left",
                        editorOptions: { showClearButton: true },
                        lookup: {
                            dataSource(opts) {
                                let rowValue = _.getNumber(opts, "data.accountCodeId", 0);
                                let store = opts?.isEditing
                                    ? getAccountCodes(rowValue)
                                    : self.accountCodes;
                                return { store };
                            },
                            displayExpr: "name",
                            valueExpr: "id"
                        },
                        allowFiltering: false
                    },
                    LocalGridUtils.lookupCol({
                        dataField: "cdfSection",
                        caption:"CDF Section",
                        calculateCellValue: () => "E",
                        calculateDisplayValue: () => "E - Taxes and Other Government Fees",
                        visible: self.isCdf,
                        allowEditing: false,
                        allowFiltering: false
                    }),
                    {
                        dataField: "lineType",
                        caption: `${self.isCdf ? "CDF" : "HUD"} Line Type`,
                        lookup: {
                            dataSource(opts) {
                                let store = self.getRowLineTypes(opts?.data);
                                return { store };
                            },
                            displayExpr: "name",
                            valueExpr: "id"
                        },
                        allowFiltering: false
                    }
                ];

                self.gridConfig = {
                    scrolling: { useNative: self.isDisplayModal },
                    columns,
                    summary: {
                        totalItems: [
                            { column: "description", alignment: "left", displayFormat: "TOTALS" },
                            LocalGridUtils.summaryTotalCol("buyerAmountDisplay"),
                            LocalGridUtils.summaryTotalCol("paidForBorrowerBeforeClosing"),
                            LocalGridUtils.summaryTotalCol("sellerAmountDisplay"),
                            LocalGridUtils.summaryTotalCol("paidForSellerBeforeClosing"),
                            LocalGridUtils.summaryTotalCol("paidForBorrower"),
                            LocalGridUtils.summaryTotalCol("paidForSeller"),
                            LocalGridUtils.summaryTotalCol("amountPOC"),
                        ]
                    },
                    onEditorPreparing: self.prepareEditor,
                    onInitNewRow: self.initNewRow,
                    onEditingStart: self.onEditingStart,
                    storageKey: self.gridStorageKey
                };

                self.gridDataSource = {
                    key: "clientKey",
                    load: () => Promise.resolve(self.items),
                    update: self.onGridUpdate,
                    insert: self.onGridInsert
                };

                if(!reinitialize) return;

                self.$nextTick(() => {
                    self.initialized = true;
                });
            },

            getTransferTaxLineTypes(){
                const self = this;
                let lineTypes = [];
                if(self.isCdf)
                    lineTypes = self.taxCdfLineTypes;
                else if(self.isHud1974)
                    lineTypes = self.taxHud1974LineTypes
                else
                    lineTypes = self.taxHud2010LineTypes

                return lineTypes;
            },
            prepareEditor(e) {
                const self = this;

                if(e.type === "selection" || e.row.rowType !== "data") return;

                e.editorOptions.disabled = self.isColumnDisabled(e.dataField, e.row.data);
            },

            isColumnDisabled(dataField, data) {
                if (this.readOnly) return true;
                // Stub out for any future logic for disabling columns
                var recordingFeeGridType = _.parseNumber(data?.recordingFeeGridType, 0);
                if(dataField === "recordingFeeGridType") return true;
                if(dataField === "pagesCount" && recordingFeeGridType === RecordingFeeGridType.Tax) return true;

                return false;
            },
            resetPayees(){
                this.defaultPayee = {
                    companyID: 0,
                    companyName: null
                };
                this.defaultE02TransferPayee = {
                    companyID: 0,
                    companyName: null
                };
            },
            async fetchData(isLoanChanged = false) {
                const self = this;
                try{
                    let apiPromises = [
                        self.$api.RecordingFeesApi.getList(self.loanId),
                        self.$api.TransferTaxesApi.getList(self.loanId)
                    ];

                    var result = await self.$rqBusy.wait(Promise.all(apiPromises));
                    if(isLoanChanged){
                        self.resetPayees();
                    }
                    self.mapAndUpdate(result[0], result[1]);

                    if (self.isCdf)
                        self.updateE02TransferPayeeView();

                    if (self.isHud1974) {
                        self.update1202TransferPayeeView();
                        self.update1203TransferPayeeView();
                    }

                    if (self.isHud2010) {
                        self.update1204TransferPayeeView();
                        self.update1205TransferPayeeView();
                    }
                    
                    await self.updateRateAndFeeWarnings();
                    
                }
                catch(err){
                    self.$toast.error("An issue occurred while retrieving Recording Fees and Transfer Taxes.");
                    console.error(err);
                }
            },
            async updateRateAndFeeWarnings(){
                const self = this;
                let rateAndFeeWarnings = self.$api.RecordingFeesApi.validateRatesAndFees(self.loanId);
                let apiResult = await self.$rqBusy.wait(rateAndFeeWarnings);
                self.$emit('updateRateAndFeeWarnings', apiResult);

                self.validationErrorsValue = [];
            },
            async pull() {
                const self = this;

                try{
                    let apiPromises = [
                        self.$api.RecordingFeesApi.pullList(self.loanId),
                        self.$api.TransferTaxesApi.pullList(self.loanId)
                    ];

                    var result = await self.$rqBusy.wait(Promise.all(apiPromises));

                    self.mapAndUpdate(result[0], result[1]);

                    //transfer taxes logic -start
                    if (self.isCdf) {
                        self.updateE02TransferPayeeView();
                    }

                    if (self.isHud1974) {
                        self.update1202TransferPayeeView();
                        self.update1203TransferPayeeView();
                    }

                    if (self.isHud2010) {
                        self.update1204TransferPayeeView();
                        self.update1205TransferPayeeView();
                    }
                    //transfer taxes logic - end

                    await self.updateRateAndFeeWarnings();

                    self.$store.dispatch(ORDER_ACTIONS.GET_LOANS); // To Refresh Loan State
                }
                catch(err){
                    self.$toast.error("An issue occurred while retrieving Recording Fees and Transfer Taxes.");
                    console.error(err);
                }
            },

            mapAndUpdate(recordingFeeResult, transferTaxResult) {
                const self = this;
                self.mapItems(recordingFeeResult, transferTaxResult);
                self.reconcilePayee();
                self.validationErrorsValue = [];
                self.gridClearSelectionRefresh();
            },

            add(newRecordingFeeGridType){
                this.newRecordingFeeGridType = newRecordingFeeGridType;
                this.invokeGridMethod("addRow");
            },
            
            async save(userInitiated, refreshData = true, autoGenerateRecordings = true){
                const self = this;
                if (self.readOnly) return;
                await self.invokeGridMethod("saveEditData");

                if (!self.validate()) return false;

                if (!self.isDirty() && refreshData) {
                    if(!userInitiated) return true;
                    self.$toast.info("No changes detected.");
                    return true;
                }

                try{
                    let saveItems = _.concat(self.items, self.deleteditems);

                    let recordingFeeItems = _.map(_.filter(saveItems, item => item.recordingFeeGridType === RecordingFeeGridType.Fee), i => new ManualRecordingFeeCalculation(i));
                    let recordingFeeData = { loanID: self.loanId, settlementType: self.settlementType, calculations: recordingFeeItems};
                    let transferTaxItems = _.map(_.filter(saveItems, item => item.recordingFeeGridType === RecordingFeeGridType.Tax), i => new ManualTransferTaxCalculation(i));
                    let transferTaxesData = { loanID: self.loanId, settlementType: self.settlementType, calculations: transferTaxItems};

                    // RQO-25500 Split recording fees and transfer tax save calls due to race condition
                    await self.$rqBusy.wait(self.$api.RecordingFeesApi.save(recordingFeeData));
                    await self.$rqBusy.wait(self.$api.TransferTaxesApi.save(transferTaxesData));

                    if (self.standardFeesEnabled && self.items.length == 0 && self.deleteditems.length > 0)
                    {
                        var clearQuestions = this.$api.RecordingFeesApi.clearQuestions(this.loanId);
                        await this.$rqBusy.wait(clearQuestions).then(x => {
                            self.$emit("clearQuestions");
                        });
                    }

                    if(self.recordingDocumentsEnabled && autoGenerateRecordings){
                        var autoGenerateRecordingInfo = this.$api.RecordingInformationApi.autogenerate(this.loanId);
                        await this.$rqBusy.wait(autoGenerateRecordingInfo);
                    }
                    if(refreshData){
                        await self.fetchData();
                    }
                    self.$toast.success("Saved Recording Fees and Transfer Taxes Successfully.");
                    return true;
                }
                catch(err) {
                        self.$toast.error("Error Saving Recording Fees and Transfer Taxes");
                        console.error(err);
                        return false;
                }
            },

            async calculateRatesAndFees(){
                const self = this;
                try{
                    var result = await self.save(true, false);
                    if (!result) return false;
                    var ratesAndFeesPromise = self.$api.RecordingFeesApi.calculateRatesAndFees({loanID: self.loanId });
                    var apiResult = await self.$rqBusy.wait(ratesAndFeesPromise);
                    
                    if (apiResult == 200){
                        if(self.recordingDocumentsEnabled){
                            var autoGenerateRecordingInfo = this.$api.RecordingInformationApi.autogenerate(this.loanId);
                            await this.$rqBusy.wait(autoGenerateRecordingInfo);
                        }

                        await self.fetchData();
                        self.$toast.success("Calculated Rates And Fees Successfully.");
                        return true;
                    }
                    else if (apiResult == 204){
                        self.$toast.warn("Calculation complete, not enough information to return fees");
                        return true;
                    }
                    else{
                        throw("SMKT result code = " + apiResult);
                    }
                }catch(err){
                    self.$toast.error("Error Calculating Rates and Fees");
                    console.error(err);
                    /*
                    The below nested try/catch block may be bad practice, but in current state (10/10/2023)
                    the calculation API is not reliable.  In order for the warning banners to be testable, we
                    need to re-fetch the data the populates the page after a successful or failed calculation.
                    This may be something we would like to persist even when the API connection is fixed so that
                    we can re-fetch the validation warning messages which will still be relevant after a failed calculation.
                    */
                    try {
                        await self.fetchData();
                    } catch (err) {
                        self.$toast.error("Error Refreshing Data");
                        console.error(err);
                    }
                    return false;
                }
            },

            cancel(e){
                const self = this;
                if(!self.isDirty()) {
                    if(!self.invalidPayee) self.$toast.info("No changes detected.");
                    self.validationErrorsValue = [];
                    self.reconcilePayee();
                    return;
                }
                let okHandler = () => {
                    self.deleteditems.length = 0;
                    self.fetchData();
                };
                self.$dialog.confirm("Cancel Changes", "Are you sure you want to cancel and undo the current recording fee changes?", okHandler);
            },

            isDirty() {
                const self = this;
                //see if items were added/removed
                if(_.some(self.items, i => i.manualCalculationID === 0)) return true;
                if(self.items.length !== _.get(self, "originalItems.length",0)) return true;

                //Tranfer taxes logic - start
                if (self.isCdf)
                    if (_.isNil(self.defaultE02TransferPayee)) return true; // If default payee is not set allow refresh

                if (self.isHud1974) {
                    if (_.isNil(self.default1202TransferPayee) || _.isNil(self.default1203TransferPayee)) return true; // If default payee is not set allow refresh
                }

                if (self.isHud2010) {
                    if (_.isNil(self.default1204TransferPayee) || _.isNil(self.default1205TransferPayee)) return true; // If default payee is not set allow refresh
                }
                //Tranfer taxes logic - end

                //This logic seems contradictory and/or redundant; clearing out the Payee does not clear out the individual line items, so we still need to rely on those to detect changes.
                //if (_.isEmpty(self.defaultPayee)) return true; // If default payee is not set allow refresh

                let itemChanges = self.getAuditChanges(self.items, _.get(self, "originalItems", []));
                return itemChanges.length > 0;
            },

            gridClearSelectionRefresh() {
                const self = this;
                self.invokeGridMethod("clearSelection");
                self.invokeGridMethod("refresh");
            },

            mapItems(recordingFeeItems, transferTaxItems){
                const self = this;
                var taxType = RecordingFeeGridType.Tax;
                self.items = _.map(recordingFeeItems, i => new CombinedCalculation(i, self.settlementType));
                self.items = _.concat(self.items,  _.map(transferTaxItems, i => new CombinedCalculation(i, self.settlementType)));

                self.originalItems = _.cloneDeep(self.items);
                self.deleteditems.length = 0;

                let eventName = self.initialized ? "updated" : "initialized";
                if(!self.initialized) self.initialized = true;
                self.items.forEach(item => {
                    item.setOverrideCalculations();
                });
                self.$emit(eventName, self.items);
            },

            mapLineTypes(seedType){
                const self = this;
                let seedLineTypes = _.filter(self.recordingDocumentLineTypes, item => item.data === seedType);
                return _.map(seedLineTypes, slt => ({ id: slt.id, name: slt.name }));
            },

            initNewRow(e){
                const self = this;
                let lineTypes = self.getRowLineTypes({ recordingFeeGridType: self.newRecordingFeeGridType });
                let lineTypeItem = _.find(lineTypes, rdt => _.startsWith(rdt.name, "Additional"));
                e.data = new CombinedCalculation({
                    ordersId: self.orderId,
                    loanId: self.loanId,
                    cdfsection: "E",
                    recordingFeeGridType: self.newRecordingFeeGridType
                }, self.settlementType);
                e.data.setLineType(lineTypeItem.id);
                // self.onEditingStart(e);
            },

            onDeleteItem(e){

                const self = this;
                let data = e.data;
                let okHandler = function (ee) {
                    _.forEach(data, (item)=>{
                        item.isDeleted = true;
                        if(item.manualCalculationID > 0)
                            self.deleteditems.push(item);
                        for(let x = 0; x < self.items.length; x++){
                            if(self.items[x].clientKey === item.clientKey){
                                self.items.splice(x,1);
                                break;
                            }
                        }
                    });
                    self.gridClearSelectionRefresh();

                    self.$emit("updated", self.items);
                    return true;
                }
                self.$dialog.confirm("Confirm Delete", "Are you sure you want to delete the selected recording fee(s)?", okHandler);
            },

            onRevertItem(e){
                const self = this;
                let data = e.data;
                let okHandler = function (ee) {
                    _.forEach(data, (item)=>{
                        let configurationID = item.recordingFeeGridType === RecordingFeeGridType.Fee ? item.manualRecordingFeeCalculationID : item.manualTransferTaxCalculationID;
                        if (_.parseNumber(configurationID) > 0){
                            item.overrideBuyerAmount = null;
                            item.overrideSellerAmount = null;
                            item.overrideCalculations = false;
                            item.overrideDescription = null;
                            item.overridePagesCount = null;
                        } else item.overrideDescription = null;
                    });
                    self.gridClearSelectionRefresh();
                    self.save(false);

                    return true;
                }
                self.$dialog.confirm("Confirm Revert", "Are you sure you want to revert the selected recording fee(s) to their default calculation?", okHandler);
            },

            onChangeNetFundItems(e) {
                const self = this;
                if (!e || !e.data) return;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    item.netFund = _.getNumber(e.action, "key", 0);
                });

                self.gridClearSelectionRefresh();
            },

            async onGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.items, i => i.clientKey === key);
                _.assign(item, values);

                if(_.has(values, "descriptionDisplay")) {
                    item.descriptionDisplay = values.descriptionDisplay;
                }

                if(_.has(values, "pagesCountDisplay")) {
                    item.pagesCountDisplay = values.pagesCountDisplay;
                }

                if(_.has(values, "buyerAmountDisplay")) {
                    item.buyerAmountDisplay = values.buyerAmountDisplay;
                }

                if(_.has(values, "sellerAmountDisplay")) {
                    item.sellerAmountDisplay = values.sellerAmountDisplay;
                }

                item.calculate();
                item.setOverrideCalculations();
                self.reconcilePayee();
                self.updatePayeeViews(item);
                self.$emit("updated", self.items);
                return item;
            },

            async onGridInsert(values){
                const self = this;
                let newItem = new CombinedCalculation(values, self.settlementType);
                self.items.push(newItem);
                self.updatePayeeViews(newItem);
                self.reconcilePayee();
                self.$emit("updated", self.items);
                return newItem;
            },

            onEditingStart(e) {
                let cssClassAttr = e.component.columnOption("payeeCompanyName", "cssClass") || "";
                let colCssClasses = _.split(cssClassAttr, " ");

                if (_.isNil(e.data)) return;
                //RQO-29567 the commented lines were added previously and may need to be reconsidered
                // if (this.isSyncedLineItem(e.data)) {
                //     e.component.columnOption("payeeCompanyName", "allowEditing", false);
                //     colCssClasses.push("rq-readonly-cell");
                // }
                // else {
                e.component.columnOption("payeeCompanyName", "allowEditing", true);
                colCssClasses = _.remove(colCssClasses, "rq-readonly-cell");
                //}

                e.component.columnOption("payeeCompanyName", "cssClass", _.join(colCssClasses, " "));
            },

            onGridReset() {
                localStorage.removeItem(this.gridStorageKey);
                this.$nextTick(() => {
                    this.initGridConfig(true);
                });
            },

            // Synchronize all ss-type-associated line items with default payee value (Recording Fee types only)
            syncWithDefaultPayee() {
                const self = this;
                let companyId = _.getNumber(self, "defaultPayee.companyID", 0);
                let companyName = _.get(self, "defaultPayee.companyName", null);
                if(companyId <= 0) return;
                _.forEach(self.items, item => {
                    if(item.recordingFeeGridType !== RecordingFeeGridType.Fee) return; // has to be a recording fee type to attempt to sync
                    if(!self.isSyncedLineItem(item)) return;
                    item.payeeCompanyId = companyId;
                    item.payeeCompanyName = companyName;
                });
                self.gridClearSelectionRefresh();
            },

            // Update the default payee to the first valid company value found from within ss-type-associated line items
            syncPayeeWithLineItems() {
                const self = this;
                let firstItemWithCompany = _.find(self.syncedLineItems, item => _.parseNumber(item.payeeCompanyId, 0) > 0);
                let noData = _.isEmpty(self.syncedLineItems) || _.isEmpty(firstItemWithCompany);
                let isMixedPayees = self.isMixedCollection();
                self.defaultPayee = {
                    companyID: isMixedPayees || noData ? 0 : firstItemWithCompany.payeeCompanyId,
                    companyName: isMixedPayees || noData ? null : firstItemWithCompany.payeeCompanyName
                };
            },

            // If the default payee input currently has a value, update all current ss-type-associated line items with that payee;
            // otherwise, update the default payee to the first valid company value, then resyncronizes all items to that value
            reconcilePayee() {
                const self = this;
                if (_.isEmpty(self.syncedLineItems)) return;

                if (_.getNumber(self.defaultPayee, "companyID", 0) > 0) {
                    self.syncWithDefaultPayee();
                    return;
                }
                self.syncPayeeWithLineItems();
                self.syncWithDefaultPayee();
            },

            // gets all line items that correspond with the passed line number or, if null, the current, ss-type-associated
            // line number, ("E01", "1201", or "1202")
            getSyncedLineItems(line=null) {
                let lineNum = line || this.activeLineNumber;
                if(this.settlementType !== LINE_ASSOC_TYPE[lineNum]) return [];
                return _.filter(this.items, item => item.recordingFeeGridType == RecordingFeeGridType.Fee && this.isItemOfLine(lineNum, item));
            },

            // Checks whether the item corresponds with the current, ss-type-associated line number, ("E01", "1201", or "1202")
            isSyncedLineItem(item) {
                return this.isItemOfLine(this.activeLineNumber, item);
            },

            // Checks whether the item corresponds with the line number passed, ("E01", "1201", or "1202")
            isItemOfLine(line, item) {
                let lineTypes = _.filter(this.recordingDocumentLineTypes, item => _.includes(item.name, line));
                let ssTypeName = SettlementTypeOption.getTypeName(this.settlementType);
                return _.some(lineTypes, t => _.includes(t.name, line) && t.id === item[`${ssTypeName}LineType`]);
            },

            // Checks whether the current ss-type-associated line items have different payee values
            isMixedCollection() {
                const self = this;
                if (_.isEmpty(self.syncedLineItems)) return false;
                let firstCompanyId = _.getNumber(self, "syncedLineItems[0].payeeCompanyId", 0);
                return _.some(self.syncedLineItems, item => item.payeeCompanyId !== firstCompanyId);
            },

            validate() {
                const self = this;
                self.validationErrorsValue = [];

                //Recording Fees
                if(!(_.isEmpty(self.syncedLineItems) || _.getNumber(self, "defaultPayee.companyID", 0) > 0))
                    self.validationErrorsValue.push(`${self.activeLineNumber} Recording Fee payee required.`);

                //Transfer Taxes
                if (self.isCdf) {
                    if (self.hasE02TransferTaxes && (_.isNil(self.defaultE02TransferPayee) || _.parseNumber(self.defaultE02TransferPayee.companyID, 0) === 0)) {
                        // Default Transfer Tax Payee needs to be valid
                        self.validationErrorsValue.push("E02 Transfer Tax payee required.");
                    }

                    if (self.isMixedE02TransferPayeeCollection) {
                        // Cannot set transfer tax item to payee different from other payees
                        self.validationErrorsValue.push("E02 Transfer Tax payees must all match. Set with E02 payee input field.");
                    }
                }

                if (self.isHud1974) {
                    if (self.has1202TransferTaxes && (_.isNil(self.default1202TransferPayee) || _.parseNumber(self.default1202TransferPayee.companyID, 0) === 0)) {
                        // Default Transfer Tax Payee needs to be valid
                        self.validationErrorsValue.push("1202 Transfer Tax payee required.");
                    }

                    if (self.isMixed1202TransferPayeeCollection) {
                        // Cannot set transfer tax item to payee different from other payees
                        self.validationErrorsValue.push("1202 Transfer Tax payees must all match. Set with 1202 payee input field.");
                    }

                    if (self.has1203TransferTaxes && (_.isNil(self.default1203TransferPayee) || _.parseNumber(self.default1203TransferPayee.companyID, 0) === 0)) {
                        // Default Transfer Tax Payee needs to be valid
                        self.validationErrorsValue.push("1203 Transfer Tax payee required.");
                    }

                    if (self.isMixed1203TransferPayeeCollection) {
                        // Cannot set transfer tax item to payee different from other payees
                        self.validationErrorsValue.push("1203 Transfer Tax payees must all match. Set with 1203 payee input field.");
                    }
                }

                if (self.isHud2010) {
                    if (self.has1204TransferTaxes && (_.isNil(self.default1204TransferPayee) || _.parseNumber(self.default1204TransferPayee.companyID, 0) === 0)) {
                        // Default Transfer Tax Payee needs to be valid
                        self.validationErrorsValue.push("1204 Transfer Tax payee required.");
                    }

                    if (self.isMixed1204TransferPayeeCollection) {
                        // Cannot set transfer tax item to payee different from other payees
                        self.validationErrorsValue.push("1204 Transfer Tax payees must all match. Set with 1204 payee input field.");
                    }

                    if (self.has1205TransferTaxes && (_.isNil(self.default1205TransferPayee) || _.parseNumber(self.default1205TransferPayee.companyID, 0) === 0)) {
                        // Default Transfer Tax Payee needs to be valid
                        self.validationErrorsValue.push("1205 Transfer Tax payee required.");
                    }

                    if (self.isMixed1205TransferPayeeCollection) {
                        // Cannot set transfer tax item to payee different from other payees
                        self.validationErrorsValue.push("1205 Transfer Tax payees must all match. Set with 1205 payee input field.");
                    }
                }

                return self.validationErrorsValue.length === 0;
            },

            updateDimensions() {
                this.invokeGridMethod("updateDimensions");
                this.invokeGridMethod("repaint");
            },

            defaultPayeeNameChange(companyName){
                // Identify Recording Fees types and 1201 Deeds / E01 lines
                const self = this;
                let companyId = _.getNumber(self, "defaultPayee.companyID", 0);
                self.defaultPayee.companyName = companyName;
                if(companyId <= 0) return;
                _.forEach(self.recordingFeeItems, item => {
                    if (item.recordingFeeGridType === RecordingFeeGridType.Fee && item.payeeCompanyId != companyId) return;
                    item.payeeCompanyName = companyName;
                });
                self.gridClearSelectionRefresh();
            },
            updateDefaultE02TransferPayees() {
                const self = this;

                // Update all Transfer E02 items to one payeeId
                _.forEach(self.lineE02TransferTaxes, function (i) {
                    i.payeeCompanyId = self.defaultE02TransferPayee.companyID;
                    i.payeeCompanyName = self.defaultE02TransferPayee.companyName;
                });
                self.gridClearSelectionRefresh();
            },
            updateE02TransferPayeeView() {
                const self = this;

                if (!self.hasE02TransferTaxes) return;
                // RQO-6997: Don't reset default payee on a pull if it has been already set.
                // also make sure pulled transfer taxes reflect the default payee.
                if (self.defaultE02TransferPayee != null && self.defaultE02TransferPayee.companyID != 0){
                    self.updateDefaultE02TransferPayees();
                    return;
                }
                let isMixedPayees = self.isMixedE02TransferPayeeCollection;
                self.defaultE02TransferPayee = {
                    companyID: isMixedPayees ? 0 : self.lineE02TransferTaxes[0].payeeCompanyId,
                    companyName: isMixedPayees ? null : self.lineE02TransferTaxes[0].payeeCompanyName
                }

                self.updateDefaultE02TransferPayees();
            },

            updateDefault1202TransferPayees() {
                const self = this;
                _.forEach(self.line1202TransferTaxes, function (i) {
                    i.payeeCompanyId = self.default1202TransferPayee.companyID;
                    i.payeeCompanyName = self.default1202TransferPayee.companyName;
                });
                self.gridClearSelectionRefresh();
            },
            update1202TransferPayeeView() {
                const self = this;

                if (!self.has1202TransferTaxes) return;
                // RQO-6997: Don't reset default payee on a pull if it has been already set.
                // also make sure pulled transfer taxes reflect the default payee.
                if (self.default1202TransferPayee != null && self.default1202TransferPayee.companyID != 0){
                    self.updateDefault1202TransferPayees();
                    return;
                }

                let isMixedPayees = self.isMixed1202TransferPayeeCollection;
                self.default1202TransferPayee = {
                    companyID: isMixedPayees ? 0 : self.line1202TransferTaxes[0].payeeCompanyId,
                    companyName: isMixedPayees ? null : self.line1202TransferTaxes[0].payeeCompanyName
                }

                self.updateDefault1202TransferPayees();
            },
            updateDefault1203TransferPayees() {
                const self = this;

                // Update all Transfer 1203 items to one payeeId
                _.forEach(self.line1203TransferTaxes, function (i) {
                    i.payeeCompanyId = self.default1203TransferPayee.companyID;
                    i.payeeCompanyName = self.default1203TransferPayee.companyName;
                });
                self.gridClearSelectionRefresh();
            },
            update1203TransferPayeeView() {
                const self = this;

                if (!self.has1203TransferTaxes) return;
                // RQO-6997: Don't reset default payee on a pull if it has been already set.
                // also make sure pulled transfer taxes reflect the default payee.
                if (self.default1203TransferPayee != null && self.default1203TransferPayee.companyID != 0){
                    self.updateDefault1203TransferPayees();
                    return;
                }

                let isMixedPayees = self.isMixed1203TransferPayeeCollection;
                self.default1203TransferPayee = {
                    companyID: isMixedPayees ? 0 : self.line1203TransferTaxes[0].payeeCompanyId,
                    companyName: isMixedPayees ? null : self.line1203TransferTaxes[0].payeeCompanyName
                }

                self.updateDefault1203TransferPayees();
            },
            updateDefault1204TransferPayees() {
                const self = this;

                // Update all Transfer 1204 items to one payeeId
                _.forEach(self.line1204TransferTaxes, function (i) {
                    i.payeeCompanyId = self.default1204TransferPayee.companyID;
                    i.payeeCompanyName = self.default1204TransferPayee.companyName;
                });
                self.gridClearSelectionRefresh();
            },
            update1204TransferPayeeView() {
                const self = this;

                if (!self.has1204TransferTaxes) return;
                // RQO-6997: Don't reset default payee on a pull if it has been already set.
                // also make sure pulled transfer taxes reflect the default payee.
                if (self.default1204TransferPayee != null && self.default1204TransferPayee.companyID != 0){
                    self.updateDefault1204TransferPayees();
                    return;
                }

                let isMixedPayees = self.isMixed1204TransferPayeeCollection;
                self.default1204TransferPayee = {
                    companyID: isMixedPayees ? 0 : self.line1204TransferTaxes[0].payeeCompanyId,
                    companyName: isMixedPayees ? null : self.line1204TransferTaxes[0].payeeCompanyName
                }

                self.updateDefault1204TransferPayees();
            },
            updateDefault1205TransferPayees() {
                const self = this;

                // Update all Transfer 1205 items to one payeeId
                _.forEach(self.line1205TransferTaxes, function (i) {
                    i.payeeCompanyId = self.default1205TransferPayee.companyID;
                    i.payeeCompanyName = self.default1205TransferPayee.companyName;
                });
                self.gridClearSelectionRefresh();
            },
            update1205TransferPayeeView() {
                const self = this;

                if (!self.has1205TransferTaxes) return;
                // RQO-6997: Don't reset default payee on a pull if it has been already set.
                // also make sure pulled transfer taxes reflect the default payee.
                if (self.default1205TransferPayee != null && self.default1205TransferPayee.companyID != 0){
                    self.updateDefault1205TransferPayees();
                    return;
                }

                let isMixedPayees = self.isMixed1205TransferPayeeCollection;
                self.default1205TransferPayee = {
                    companyID: isMixedPayees ? 0 : self.line1205TransferTaxes[0].payeeCompanyId,
                    companyName: isMixedPayees ? null : self.line1205TransferTaxes[0].payeeCompanyName
                }

                self.updateDefault1205TransferPayees();
            },
            updatePayeeViews(item){
                const self = this;
                if (!item.isTransferTaxType) return;
                if (self.isCdf) {
                    self.updateE02TransferPayeeView();
                }
                else if (self.isHud1974) {
                    self.update1202TransferPayeeView();
                    self.update1203TransferPayeeView();
                }
                else if (self.isHud2010) {
                    self.update1204TransferPayeeView();
                    self.update1205TransferPayeeView();
                }
            },
        }
    }
</script>
