<template>
    <div class="content-wrapper recording-itemization">
        <rq-page-section borderless>
            <div class="row">
                <div class="col col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3 mb-0">
                    <rq-action-form-group
                        action-class="overridden"
                        label="Deed Total"
                        labelFor="txt_deed_amt_override"
                        :show-action="deedTotalOverridden"
                        @action-click="onRevertDeedAmount"
                        action-automation-id="btn_revert_deed_amount"
                        action-label="Revert"
                    >
                        <template #default>
                            <rqInputNumber id="txt_deed_amt_override"
                                automation_id="txt_deed_amt_override"
                                formatType="money"
                                defaultValue="0"
                                :minValue="0"
                                decimals="2"
                                value-event="input"
                                @input="onDeedAmountChange"
                                v-model="main.overrideDeedAmount"
                                prepend-text="$"
                                :disabled="readOnly"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3 mb-0">
                    <rq-action-form-group
                        action-class="overridden"
                        label="Mortgage Total"
                        labelFor="txt_mort_amt_override"
                        :show-action="mortTotalOverridden"
                        @action-click="onRevertMortAmount"
                        action-automation-id="btn_revert_mort_amount"
                        action-label="Revert"
                    >
                        <template #default>
                            <rqInputNumber id="txt_mort_amt_override"
                                automation_id="txt_mort_amt_override"
                                formatType="money"
                                defaultValue="0"
                                :minValue="0"
                                decimals="2"
                                value-event="input"
                                @input="onMortgageAmountChange"
                                v-model="main.overrideMortgageAmount"
                                prepend-text="$"
                                :disabled="readOnly"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="itemizationGrid"
            automation_id="tbl_itemization_grid"
            :config="gridConfig"
            :data-source="gridDataSource"
            :actions="gridActions"
            export-file-name="recording-itemization-data"
            :rq-editable="!readOnly"
            hide-search
            @delete="onDeleteItem"
            @netfund="onChangeNetFundItems"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { RECORDING_ACTIONS } from "@/store/actions";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { SsGridActions } from "@settlement/models";
    import { RecordingItemizationModel } from "./models";
    import { useGridCompanyPicker } from "@/shared/composables/"

    export default {
        name: "RecordingItemGridCalculator",
        props: {
            loanId: { type: Number, default: 0 },
            isWithoutSeller: { default: false },
            displayMode: {type: String, default: "route"}
        },
        setup() {
            return useGridCompanyPicker();
        },
        data(){
            return {
                alertMessage: "",
                originalData: {},
                main: {},
                itemKey: "clientKey",
                items:[],
                deleteditems: [],
                gridDataSource: {},
                gridConfig: {}
            };
        },
        computed:{
            ...mapState({
                order: state => state.orders.order,
                readOnly: state => state.isPageReadOnly,
                orderId: state => state.orders.orderId,
                loans: state => state.orders.loans || []
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            showAlert() { return !_.isEmpty(this.alertMessage); },
            itemizationGrid(){ return _.get(this, "$refs.itemizationGrid.gridInstance", null) || {}; },
            deedTotalOverridden(){
                let amount = _.get(this, "main.deedAmount", 0);
                let override = _.get(this, "main.overrideDeedAmount", 0);
                return amount !== override;
            },
            mortTotalOverridden(){
                let amount = _.get(this, "main.mortgageAmount", 0);
                let override = _.get(this, "main.overrideMortgageAmount", 0);
                return amount !== override;
            },
            gridStorageKey() { return `recording_items_${this.isWithoutSeller}`; },
            gridActions() {
                const self = this;
                return [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        allowMultiSelection: true,
                        tooltip: "Delete",
                        disabled: function(e) {
                            if (self.readOnly) return self.readOnly;

                            let row = _.find(e.data, (d) => { return d.line < 3;});
                            if(!_.isNil(row)){
                                return "Unselect required lines to delete";
                            } else {
                                return false;
                            }
                        }
                    },
                    {
                        name: "Apply-Net-Fund",
                        text: "Apply Net Fund",
                        eventName: "netfund",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            return self.readOnly;
                        },
                        children: [
                            SsGridActions.NETFUND_NONE,
                            SsGridActions.NETFUND_POSITIVE,
                            SsGridActions.NETFUND_NEGATIVE
                        ]
                    },
                ];
            },
            cdfSections(){
                return [{id:"E", name: "E"}];
            },
            grandTotal(){
                const self = this;
                let borrowerTotal = _.sumBy(self.items, "borrowerAmount");
                let sellerTotal = _.sumBy(self.items, "sellerAmount");
                let pfBorrowerTotal = _.sumBy(self.items, "paidForBorrower");
                let pfSellerTotal = _.sumBy(self.items, "paidForSeller");
                return borrowerTotal + sellerTotal + pfBorrowerTotal + pfSellerTotal;
            },
            accountCodes() { return this.lookupHelpers.getAllLookupItems(this.lookupItems.ACCOUNTING_CODES); },
            netFundItems() { return this.lookupHelpers.getLookupItems(this.lookupItems.NET_FUND); },
            isDisplayModal() {
                return this.displayMode === "modal";
            }
        },
        created() {
            const self = this;
            self.initGridConfig();

            let storePromise = this.$store.dispatch(RECORDING_ACTIONS.GET_LOOKUPS, self.orderId);
            this.$rqBusy.wait(storePromise)
                .then(()=>{
                    self.fetchData();
                });
        },

        watch:{
            loanId(newVal, oldVal){
                const self = this;
                if(_.isNil(newVal)) return;
                if(self.isDirty()){
                    self.save(false).then(()=>{
                        self.fetchData();
                    });
                }
                else{
                    self.fetchData();
                }
            },
            isWithoutSeller(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeWithOutSeller(newValue);
            }
        },
        methods:{
            initGridConfig(){
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr:"payeeCompanyID",
                    companyNameExpr:"payeeCompanyName",
                    contactIDExpr:"payeeContactID",
                    contactNameExpr:"payeeContactName",
                    showContactPicker: true
                };

                const getAccountCodes = selectedVal => self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES, self.orderRegionId, selectedVal);

                self.gridConfig = {
                    onEditorPreparing: self.prepareEditor,
                    onInitNewRow: self.initNewRow,
                    onCellPrepared: self.cellPrepared,
                    scrolling: { useNative: self.isDisplayModal },
                    columns: [
                        {
                            dataField: "recordingItemizationDetailID",
                            visible: false,
                            allowEditing: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "line",
                            allowEditing: false
                        },
                        {
                            dataField: "description",
                            allowEditing: true,
                            editorOptions: { maxLength: 250 }
                        },
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "payeeCompanyName",
                                caption: "Payee"
                            },
                            ...payeePickerInfo
                        }),
                        {
                            caption: "Borrower",
                            alignment: "center",
                            columns: [
                            {
                                dataField: "borrowerAmount",
                                setCellValue: this.onBorrowerAmountChange,
                                caption: "At Closing",
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            },
                            {
                                dataField: "paidForBorrowerBeforeClosing",
                                caption: "Before Closing",
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            }]
                        },
                        {
                            caption: "Seller",
                            alignment: "center",
                            visible: !self.isWithoutSeller,
                            dataField: "",
                            columns: [
                            {
                                dataField: "sellerAmount",
                                setCellValue: this.onSellerAmountChange,
                                caption: "At Closing",
                                dataType: "number",
                                showInColumnChooser: !self.isWithoutSeller,
                                visible: !self.isWithoutSeller,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            },
                            {
                                dataField: "paidForSellerBeforeClosing",
                                caption: "Before Closing",
                                dataType: "number",
                                showInColumnChooser: !self.isWithoutSeller,
                                visible: !self.isWithoutSeller,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            }]
                        },
                        {
                            caption: "Paid for",
                            alignment: "center",
                            dataField: "",
                            columns: [
                            {
                                dataField: "paidForBorrower",
                                caption: "Borrower",
                                dataType: "number",
                                allowEditing: false,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            },
                            {
                                dataField: "paidForSeller",
                                caption: "Seller",
                                dataType: "number",
                                showInColumnChooser: !self.isWithoutSeller,
                                visible: !self.isWithoutSeller,
                                allowEditing: false,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            }]
                        },
                        {
                            dataField: "netFund",
                            minWidth: 75,
                            lookup: {
                                dataSource: self.netFundItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            caption: "Net Fund",
                        },
                        {
                            dataField: "cdfSection",
                            lookup: {
                                dataSource: self.cdfSections,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            caption: "CDF Section"
                        },
                        {
                            dataField: "accountCodeId",
                            dataType: "number",
                            caption: "Account Code",
                            editorOptions: { showClearButton: true },
                            lookup: {
                                dataSource(opts) {
                                    let rowValue = _.getNumber(opts, "data.accountCodeId", 0);
                                    let store = opts?.isEditing
                                        ? getAccountCodes(rowValue)
                                        : self.accountCodes;
                                    return { store };
                                },
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        {
                            dataField: "doNotPrint",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            caption: "Do Not Print"
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    storageKey: self.gridStorageKey,

                    summary: {
                        totalItems: [
                        {
                            column: "line",
                            summaryType: "sum",
                            valueFormat: "currency",
                            alignment: "left",
                            customizeText: function(data){
                                return "TOTALS";
                            }
                        },
                        {
                            column: "paidForBorrowerBeforeClosing",
                            summaryType: "sum",
                            valueFormat: "currency",
                            alignment: "right",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "borrowerAmount",
                            summaryType: "sum",
                            valueFormat: "currency",
                            alignment: "right",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "paidForSellerBeforeClosing",
                            summaryType: "sum",
                            valueFormat: "currency",
                            alignment: "right",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "sellerAmount",
                            summaryType: "sum",
                            valueFormat: "currency",
                            alignment: "right",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "paidForBorrower",
                            summaryType: "sum",
                            valueFormat: "currency",
                            alignment: "right",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "paidForSeller",
                            summaryType: "sum",
                            valueFormat: "currency",
                            alignment: "right",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        // Comment out Grand Total
                        // {
                        //     column: "netFund",
                        //     summaryType: "sum",
                        //     valueFormat: "currency",
                        //     alignment: "right",
                        //     cssClass:"summary-column",
                        //     customizeText: function(data) {
                        //         return "Grand Total: " + self.formatMoney(self.grandTotal);
                        //     }
                        // },
                        ]
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    update: self.onItemizationGridUpdate,
                    insert: self.onItemizationGridInsert
                };
            },

            add(){
                this.itemizationGrid.addRow();
            },

            initNewRow(e){
                const self = this;
                let maxitem = _.maxBy(self.items, "line");
                let maxLine = maxitem.line + 1;
                let firstPayee = self.getFirstPayee();
                e.data = {
                    recordingItemizationID: self.main.recordingItemizationID,
                    line: maxLine,
                    payeeCompanyID: firstPayee.payeeCompanyID,
                    payeeCompanyName: firstPayee.payeeCompanyName,
                    payeeContactID: firstPayee.payeeContactID,
                    payeeContactName: firstPayee.payeeContactName,
                    cdfSection: "E",
                    doNotPrint: false
                };
            },

            onDeleteItem(e){

                const self = this;
                let data = e.data;
                let okHandler = function (ee) {
                    _.forEach(data, (item)=>{
                        if(item.line < 3) return;
                        item.isDeleted = true;
                        if(item.recordingItemizationDetailID > 0)
                            self.deleteditems.push(item);
                        for(let x = 0; x < self.items.length; x++){
                            if(self.items[x].recordingItemizationDetailID === item.recordingItemizationDetailID){
                                self.items.splice(x,1);
                                break;
                            }
                        }
                    });
                    self.itemizationGrid.refresh();

                    return true;
                }
                self.$dialog.confirm("Confirm Delete", "Are you sure you want to delete the selected recording itemization(s)?", okHandler);
            },

            onChangeNetFundItems(e) {
                const self = this;
                if (!e || !e.data) return;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    item.netFund = _.getNumber(e.action, "key", 0);
                });

                self.itemizationGrid.clearSelection();
                self.itemizationGrid.refresh();
            },

            onDeedAmountChange(e) {
                const self = this;
                let deedLine = _.find(self.items, i => i.line === 1);
                if (deedLine != undefined){
                    deedLine.borrowerAmount = e.valueOf() - deedLine.sellerAmount - deedLine.paidForBorrower - deedLine.paidForSeller;
                    self.itemizationGrid.refresh();
                }

            },

            onMortgageAmountChange(e) {
                const self = this;
                let mortgageLine = _.find(self.items, i => i.line === 2);
                if (mortgageLine != undefined){
                    mortgageLine.borrowerAmount = e.valueOf() - mortgageLine.sellerAmount - mortgageLine.paidForBorrower - mortgageLine.paidForSeller;
                    self.itemizationGrid.refresh();
                }
            },

            onBorrowerAmountChange(newData, value, currentData) {
                const self = this;
                newData.borrowerAmount = value;

                // Deed line
                if(currentData.line === 1){
                    newData.sellerAmount = self.main.overrideDeedAmount - newData.borrowerAmount - currentData.paidForBorrower - currentData.paidForSeller;
                }

                // Mortgage line
                if(currentData.line === 2){
                    newData.sellerAmount = self.main.overrideMortgageAmount - newData.borrowerAmount - currentData.paidForBorrower - currentData.paidForSeller;
                }
            },

            onSellerAmountChange(newData, value, currentData) {
                const self = this;
                newData.sellerAmount = value;

                // Deed line
                if(currentData.line === 1){
                    newData.borrowerAmount = self.main.overrideDeedAmount - newData.sellerAmount - currentData.paidForBorrower - currentData.paidForSeller;
                }

                // Mortgage line
                if(currentData.line === 2){
                    newData.borrowerAmount = self.main.overrideMortgageAmount - newData.sellerAmount - currentData.paidForBorrower - currentData.paidForSeller;
                }
            },

            onItemizationGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.items, i => i.clientKey === key);
                _.assign(item, values);

                return Promise.resolve(item);
            },

            onItemizationGridInsert(values){
                const self = this;
                let options = {
                    recordingItemizationDetailID: 0,
                    recordingItemizationID: self.main.recordingItemizationID,
                    manualRecordingFeeCalculationID: null,
                    manualTransferTaxCalculationID: null,
                    line: 0,
                    description: "",
                    payeeCompanyID: null,
                    payeeContactID: null,
                    borrowerAmount: 0,
                    sellerAmount: 0,
                    cdfSection: "E",
                    netFund: null,
                    accountCodeID: null,
                    doNotPrint: false,
                    paidForBorrower: null,
                    paidForSeller: null
                }

                let newItem = new RecordingItemizationModel(options);

                _.assign(newItem, values);
                self.items.push(newItem);
                return Promise.resolve(newItem);
            },

            prepareEditor(e){
                if(e.dataField !== "description") return;
                let data = e.row.data;
                e.editorOptions.readOnly = data.line < 3;
            },

            isNil(e){
                return _.isNil(e);
            },
            onSaveClick(e) {
                let userInitiated = _.get(e, "userInitiated", false);
                this.itemizationGrid.saveEditData();
                this.save(userInitiated);
            },

            cancel(e) {
                const self = this;
                if(!self.isDirty()) {
                    self.$toast.info("No changes detected.");
                    return;
                }
                let okHandler = () => {
                    self.deleteditems.length = 0;
                    self.fetchData();
                };
                self.$dialog.confirm("Cancel Changes", "Are you sure you want to cancel and undo the current recording itemization changes?", okHandler);
            },

            onRevertDeedAmount(e){
                const self = this;
                self.main.overrideDeedAmount = self.main.deedAmount;
                let original = _.find(self.originalData.details, (d) => { return d.line === 1;});
                let current = _.find(self.items, (i) => { return i.line === 1;});

                self.revertAmounts(current, original);

                self.itemizationGrid.refresh();
            },

            onRevertMortAmount(e){
                const self = this;
                self.main.overrideMortgageAmount = self.main.mortgageAmount;
                let original = _.find(self.originalData.details, (d) => { return d.line === 2;});
                let current = _.find(self.items, (i) => { return i.line === 2;});

                self.revertAmounts(current, original);

                self.itemizationGrid.refresh();
            },

            revertAmounts(current, original){
                current.borrowerAmount = original.borrowerAmount;
                current.sellerAmount = original.sellerAmount;
                // current.paidForBorrowerBeforeClosing = original.paidForBorrowerBeforeClosing;
                // current.paidForSellerBeforeClosing = original.paidForSellerBeforeClosing;
            },

            isDirty() {
                const self = this;
                //see if items were added/removed
                if(_.isNil(self.originalData.main)) return false;
                if(_.some(self.items, i => i.recordingItemizationDetailID === 0)) return true;
                if(self.items.length !== _.get(self, "originalData.details.length",0)) return true;

                let mainChanges = self.getAuditChanges(self.main, _.get(self, "originalData.main", {}));
                if(mainChanges.length > 0) return true;
                let itemChanges = self.getAuditChanges(self.items, _.get(self, "originalData.details", []));
                if(itemChanges.length > 0) return true;

                return false;
            },

            fetchData(){
                const self = this;

                let promise = self.$api.RecordingItemizationApi.getByLoanId(self.loanId).then((result) =>{
                    self.initData(result);
                })
                .catch(err =>{
                    self.$toast.error(err.errorMessage);
                });

                self.$rqBusy.wait(promise);
            },

            save(userInitiated=false) {
                const self = this;
                if (self.readOnly) return;
                if(!self.isDirty()) {
                    GlobalEventManager.saveCompleted({success: true});
                    if(!userInitiated) return Promise.resolve(false);
                    self.$toast.info("No changes detected.");
                    return Promise.resolve(false);
                }

                let data = { main: self.main, details: _.concat(self.items, self.deleteditems)};

                let promise = self.$api.RecordingItemizationApi.save(data).then((result)=>{
                    GlobalEventManager.saveCompleted({success: true});
                    self.initData(result);
                    self.$toast.success("Saved Recording Itemizations Successfully.")
                })
                .catch(err => {
                    GlobalEventManager.saveCompleted({success: false});
                    self.$toast.error(err.errorMessage);
                });

                self.$rqBusy.wait(promise);

                return promise;
            },

            initData(data){
                const self = this;
                let items = _.map(data.details, (d)=>{ return new RecordingItemizationModel(d);});
                let originalData = {main: _.cloneDeep(data.main), details: _.cloneDeep(items)};
                self.originalData = originalData;
                self.main = data.main;
                self.items = items;

                // RQO-5644: update borrower amount calculations for the deed and mortgage lines
                self.onDeedAmountChange(self.main.overrideDeedAmount);
                self.onMortgageAmountChange(self.main.overrideMortgageAmount);

                self.itemizationGrid.refresh();
            },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },

            getFirstPayee(){
                const self = this;
                let item = _.find(self.items, (i)=>{ return i.payeeCompanyID > 0;});
                return {
                    payeeCompanyID: _.get(item,"payeeCompanyID", null),
                    payeeCompanyName: _.get(item,"payeeCompanyName",null),
                    payeeContactID: _.get(item, "payeeContactID", null),
                    payeeContactName: _.get(item, "payeeContactName", null)
                }
            },

            printReport(){
                const self = this;
                self.save().then(() => {
                    self.$dialog.confirm("Print Report", "Coming Soon! Reporting hasn't been implemented yet.");
                });
            },

            cellPrepared(e){
                //this formats the html in the footers.  Adds a line break after the title and makes the value bold.
                const self = this;
                if(e.rowType==="totalFooter"){
                    let text = e.cellElement[0].innerText;
                    if(text.includes(":")){
                        let html = e.cellElement[0].innerHTML;
                        let parts = _.split(text, ":");
                        let newHtml = parts[0] + "<br/><b>" + parts[1] + "</b>";
                        html = _.replace(html, text, newHtml);
                        e.cellElement[0].innerHTML = html;
                    }
                }
            },

            onChangeWithOutSeller(e) {
                const self = this;
                self.initGridConfig();
                self.$nextTick().then(() => {
                    _.invoke(self, "itemizationGrid.loadGrid");
                });
            },

            updateDimensions() {
                _.invoke(this, "itemizationGrid.updateDimensions");
                _.invoke(this, "itemizationGrid.repaint");
            }
        }
    }
</script>