<template>
    <div class="content-wrapper paid-by-others">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="hasErrors"
            dismissable
        />
        <rq-page-section title="Paid By Others" header-size="lg" header-only borderless>
            <template #header-actions>
                <!-- <ul class="nav">
                    <li class="nav-item ms-4 pt-1">
                        <b-form-radio-group disabled v-model="selectedView">
                            <b-form-radio automation_id="radio_cdf" :value="SettlementTypes.CDF">CDF</b-form-radio>
                            <b-form-radio automation_id="radio_hud" :value="SettlementTypes.HUD_1974">HUD</b-form-radio>
                        </b-form-radio-group>
                    </li>
                </ul> -->
                <ul class="nav">
                    <li class="nav-item">
                        <rq-report-button
                            text="Print Report"
                            :disabled="readOnly || listItems.length === 0"
                            :path="reportOptions.path"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
                <ul class="nav ms-auto grid-tools-offset">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
            <!-- TODO: Commented out an implemented version of grid filter for sections until we finalize how we want it to work -->
            <!--
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                        <label for="drp_section">Section</label>
                        <dx-tag-box
                            automation_id="tb_sections"
                            class="form-control"
                            :data-source="currentView.sectionItems"
                            display-expr="name"
                            value-expr="id"
                            :show-selection-controls="true"
                            :show-clear-button="true"
                            :max-displayed-tags="3"
                            :show-drop-down-button="true"
                            apply-value-mode="useButtons"
                            :value.sync="selectedSectionFilters"
                            @valueChanged="onSectionChanged"
                        />
                    </div>
                </div>
            -->
        </rq-page-section>
        <rqdx-action-data-grid
            ref="actionGridComponent"
            class="task-grid"
            automation_id="dg_available"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            search-mode="field"
            export-file-name="paidByOther-data"
            v-model:validation-errors="validationErrors"
            :rq-editable="!readOnly"
            @clear="onClearData"
            @clear-filters="onClearFilters"
            hide-search
            rq-filters>
                <template #toolbar>
                    <div class="form-group mb-1 w-25 me-auto">
                        <rq-select-box
                            id="drp_filters"
                            size="sm"
                            :focusOnMount="true"
                            :defaultItem="{ text:'Filter by section...' }"
                            :items="isCdf ? filterCDFOptions : filterHUDOptions"
                            v-model="filterValue"
                        />
                    </div>
                </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { GlobalEventManager } from '@/app.events';
    import { PaidByOthersModel, SETTLEMENT_TYPE } from "../../models";
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { SettlementReport } from '@settlement/components/dashboard/models';

    const mapLookupNumberKeys = items => _.map(items, item => ({ id: _.parseNumber(item.id), name: item.name }));

    export default {
        name: "PaidByOthers",
        mixins: [BaseSettlementMixin, GridCompanyPickerMixin],
        data(){
            return {
                selectedSectionFilters: [],
                itemTypeNamePlural: "Paid By Others" ,
                itemKey: "clientKey",
                itemTypeName: "Paid By Other",
                listItems: [],
                originalItems: [],
                availablePayors: [],
                filterValue: "",
                gridConfig: {},
                filterCDFOptions:[
                    {id:"A", name: "A - Loan Charges"},
                    {id:"B", name: "B - Services Borrower Did Not Shop For"},
                    {id:"C", name: "C - Services Borrower Did Shop For"},
                    {id:"E", name: "E - Taxes and Other Government Fees"},
                    {id:"F", name: "F - Prepaids"},
                    {id:"G", name: "G - Initial Escrow Payment at Closing"},
                    {id:"H", name: "H - Other"},
                ],
                filterHUDOptions:[
                    {id:"800", name: "800 - Loan Charges"},
                    {id:"900", name: "900 - Prepaids"},
                    {id:"1100", name: "1100 - Title Charges"},
                    {id:"1200", name: "1200 - Recording Fees & Taxes"},
                    {id:"1300", name: "1300 - Other Charges"},
                ],
                viewList: [{
                        name: "CdfPaidByOthers",
                        itemTitle: "CDF - Paid By Others",
                        listTitle: "CDF - Paid By Others",
                        viewTitle: "CDF - Paid by Others",
                        tabLabel: "CDF",
                        selectedIndex: 0,
                        active: true,
                        sectionItems:[
                            {id:"A", name: "A - Loan Charges"},
                            {id:"B", name: "B - Services Borrower Did Not Shop For"},
                            {id:"C", name: "C - Services Borrower Did Shop For"},
                            {id:"E", name: "E - Taxes and Other Government Fees"},
                            {id:"F", name: "F - Prepaids"},
                            {id:"G", name: "G - Initial Escrow Payment at Closing"},
                            {id:"H", name: "H - Other"},
                            ]
                    },
                    {
                        name:"HudPaidByOthers",
                        itemTitle: "HUD - Paid By Others",
                        listTitle: "HUD - Paid By Others",
                        viewTitle: "HUD - Paid by Others",
                        tabLabel: "HUD",
                        selectedIndex: 0,
                        active: false,
                        sectionItems:[
                                {id:"All", name: "All"}
                            ]
                    },
                    {
                        name:"CssPaidByOthers",
                        itemTitle: "CSS - Paid By Others",
                        listTitle: "CSS - Paid By Others",
                        viewTitle: "CSS - Paid by Others",
                        tabLabel: "CSS",
                        selectedIndex: 0,
                        active: false,
                        sectionItems:[
                                {id:"All", name: "All"}
                            ]
                    }
                ],
            }
        },
        props: {
            displayMode: {type: String, default: "route"}
        },
        computed:{
            gridStorageKey() { return `settlement_paidby_others_${this.selectedView}_${this.isWithOutSeller}`; },
            gridInstance () { return this.$refs.actionGridComponent.gridInstance; },
            currentView() {
                let currentViewIndex = this.isCdf ? 0 : 1;
                return this.viewList[currentViewIndex];
            },
            gridComponent() { return _.get(this, "$refs.actionGridComponent", null); },
            reportOptions() { return SettlementReport.reportOptionsPaidByOthers(this.orderId, this.selectedLoanId);},
            isDisplayModal() {
                return this.displayMode === "modal";
            }
        },
        watch:{
            selectedLoanId(newVal, oldVal) {
                if (newVal === oldVal || _.parseNumber(oldVal, 0) === 0) return;
                this.mapSettlementStatementOverride()
                    .then(result => {
                        if(!result.success) return;
                        this.fetchData();
                    });
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.mapSettlementStatement()
                    .then(result => {
                        if(!result.success) return;
                        this.onChangeSSViewSelected(newValue);
                    });
            },
            isWithOutSeller(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.mapSettlementStatement()
                    .then(result => {
                        if(!result.success) return;
                        this.onChangeWithOutSeller(newValue);
                    });
            },
            filterValue(newValue, oldValue){
                if(newValue === oldValue) return;
                if(_.isNil(newValue)){
                    this.gridInstance.clearFilter("dataSource");
                    return;
                }
                this.gridInstance.filter(["sectionName", "=", newValue]);
            },
        },
        created(){
            const self = this;
            self.baseInit();
            self.selectionActions = [
                {
                    name: "Clear",
                    text: "Clear",
                    eventName: "clear",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if (_.some(e.data, item => _.parseNumber(item.payorCompanyId) > 0)) {
                            return false;
                        }

                        return true;
                    },
                    tooltip: `Clear ${self.itemTypeName}`
                }
            ];
            self.initGridConfig();
            self.initGridDataSource();
            // RQO-19010 - map SS data to cover aggregated lines not in generic tables but from ss mapped data (i.e Recording Fees / Transfer Taxes)
            self.mapSettlementStatement()
                .then(result => {
                    if(!result.success) return;
                    self.fetchData();
                });
        },
        methods:{
            onMapSSComplete() {
                /* Overriding base handler to fetch data on "then" */
            },            
            initGridConfig() {
                const self = this;

                self.gridConfig = {
                    focusedRowEnabled: false,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    scrolling: { useNative: self.isDisplayModal },
                    columns: [
                        {
                            dataField: "lineDescription",
                            caption: "Line",
                            allowEditing: false
                        },
                        {
                            dataField: "itemDescription",
                            caption: "Description",
                            allowEditing: false
                        },
                        {
                            dataField: "sectionName",
                            caption: "Section",
                            visible: false,
                            allowEditing: false
                        },
                        {
                            dataField: "payorCompanyId",
                            caption: 'Payor',
                            lookup: {
                                dataSource: {
                                    loadMode: "raw",
                                    load: function(e) {
                                        return Promise.resolve(self.availablePayors.slice());
                                    }
                                },
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [
                                {
                                    type: "async",
                                    validationCallback: function(params) { return self.isValidData(params); },
                                    message: "Payor is Required"
                                }
                            ]
                        },
                        {
                            dataField: "paidForBorrower",
                            caption: "Paid For Borrower",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.tooltipCellTemplate({
                                content: cellInfo => accounting.formatMoney(cellInfo.data.borrowerAtClosingValue),
                                showEvent: {
                                    name: "mouseenter",
                                    delay: 100
                                }
                            })
                        },
                        {
                            dataField: "paidForSeller",
                            caption: "Paid For Seller",
                            dataType: "number",
                            visible: !self.isWithOutSeller,
                            showInColumnChooser: !self.isWithOutSeller,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.tooltipCellTemplate({
                                content: cellInfo => accounting.formatMoney(cellInfo.data.sellerAtClosingValue),
                                showEvent: {
                                    name: "mouseenter",
                                    delay: 100
                                }
                            })
                        },
                        {
                            dataField: "totalCharges",
                            caption: "Total Charges",
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "reducePayorCheck",
                            caption: "Reduce Payor Check",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            showInColumnChooser: self.isCdf,
                            visible: self.isCdf
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    storageKey: self.gridStorageKey,
                }
            },
            initGridDataSource() {
                const self = this;
                self.gridDataSource = {
                    key: self.itemKey,
                    load(){
                        return Promise.resolve(self.listItems);
                    },
                    update(key, values) {
                        self.onGridUpdate(key, values);
                    }
                };
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.listItems, item => item.clientKey === key);
                if(itemIndex < 0) return Promise.resolve(false);

                let originalItem = _.cloneDeep(self.listItems[itemIndex]);
                let updatedItem = _.assign(originalItem, values);
                updatedItem.totalCharges = updatedItem.paidForBorrower + updatedItem.paidForSeller;
                self.listItems[itemIndex] = updatedItem;

                return Promise.resolve(updatedItem);
            },
            onChangeWithOutSeller(e) {
                const self = this;
                self.initGridConfig();
                self.fetchData();
                self.$nextTick().then(() => {
                    _.invoke(self, "$refs.actionGridComponent.loadGrid");
                });
            },
            onChangeSSViewSelected(e) {
                this.fetchData(e);

                this.initGridConfig();
                this.$nextTick().then(() => {
                    _.invoke(this, "$refs.actionGridComponent.loadGrid");
                })
            },
            onSave(e){
                let userInitiated = _.getBool(e, "userInitiated");
                this.gridInstance.saveEditData()
                    .then(() => {
                        this.save(userInitiated);
                    });
            },
            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },
            fetchData(type=null){
                const self = this;
                let ssType = _.isNil(type) ? self.selectedView : type;
                let promise = ssType === self.SettlementTypes.CDF ? self.$api.PaidByOthersApi.getCdfPaidByOthers(self.selectedLoanId) :
                 self.$api.PaidByOthersApi.getHudPaidByOthers(self.selectedLoanId, self.selectedView);

                if (!_.isNil(promise)) {
                    return self.$rqBusy.wait(promise).then(result => {
                        self.availablePayors = mapLookupNumberKeys(result.availablePayors);
                        let selectedItems = _.filter(result.items, function (i) {
                            if (ssType === SETTLEMENT_TYPE.CDF){
                                return i;
                            }
                            if (ssType === SETTLEMENT_TYPE.HUD_1974) {
                                return (i.statementType === ssType && i.line !== 1109 && i.line !== 1110)
                                        || i.statementType === null;
                            }
                            if (ssType === SETTLEMENT_TYPE.HUD_2010){
                                return (i.statementType === ssType
                                    && i.line !== 801 && i.line !== 1104 && i.line !== 1105
                                    && i.line !== 1106 && i.line !== 1107 && i.line !== 1108)
                                    || (i.statementType === null
                                    && i.line !== 1202 && i.line !== 1204 && i.line !== 1205);
                            }
                            if (i.statementType === null) return i;
                        });

                        self.listItems = _.map(selectedItems, i => new PaidByOthersModel(i));
                        self.originalItems = _.cloneDeep(self.listItems);
                        self.gridRefresh();
                    });
                }
                else {
                    // Empty data if not any of the settlement statements
                    self.availablePayors = [];
                    self.listItems = [];
                    self.originalItems = [];
                    self.gridRefresh();
                }

                return new Promise.resolve(false);
            },
            save(toastIfNoChanges=false){
                const self = this;

                if(self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false, abort: true});
                    return Promise.resolve(false) ;
                }

                if(!self.hasChanges()){
                    if(toastIfNoChanges)
                        self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true) ;
                }

                let changedItems = []
                _.forEach(self.listItems, i =>{
                    let original = _.find(self.originalItems, o => { return o.clientKey === i.clientKey;});
                    let itemChanges = self.getAuditChanges(i, original);
                    if(itemChanges.length > 0)
                        changedItems.push(i);
                });

                let promise = null;

                if (self.isCdf) {
                    promise = self.$api.PaidByOthersApi.saveCdfPaidByOthers(changedItems).then(() => {
                        self.originalItems = _.cloneDeep(self.listItems);
                        self.$toast.success("Paid by Others saved successfully");
                        GlobalEventManager.saveCompleted({success: true});
                        //reload on user-initiated save
                        if(toastIfNoChanges){
                            self.fetchData(self.SettlementTypes.CDF);
                        }
                        return true;
                    }).catch(err => {
                        self.$toast.error(err.errorMessage);
                        GlobalEventManager.saveCompleted({success: false});
                        return false;
                    });
                }

                if (self.isHud1974) {
                    promise = self.$api.PaidByOthersApi.saveHudPaidByOthers(changedItems, self.SettlementTypes.HUD_1974).then(() => {
                        self.originalItems = _.cloneDeep(self.listItems);
                        self.$toast.success("Paid by Others saved successfully");
                        GlobalEventManager.saveCompleted({success: true});
                        //reload on user-initiated save
                        if(toastIfNoChanges){
                            self.fetchData(self.SettlementTypes.HUD_1974);
                        }
                        return true;
                    }).catch(err => {
                        self.$toast.error(err.errorMessage);
                        GlobalEventManager.saveCompleted({success: false});
                        return false;
                    });
                }

                if (self.isHud2010) {
                    promise = self.$api.PaidByOthersApi.saveHudPaidByOthers(changedItems, self.SettlementTypes.HUD_2010).then(() => {
                        self.originalItems = _.cloneDeep(self.listItems);
                        self.$toast.success("Paid by Others saved successfully");
                        GlobalEventManager.saveCompleted({success: true});
                        //reload on user-initiated save
                        if(toastIfNoChanges){
                            self.fetchData(self.SettlementTypes.HUD_2010);
                        }
                        return true;
                    }).catch(err => {
                        self.$toast.error(err.errorMessage);
                        GlobalEventManager.saveCompleted({success: false});
                        return false;
                    });
                }

                if (!_.isNil(promise))
                    self.$rqBusy.wait(promise);

                return promise;
            },

            hasChanges() {
                let changes = this.getAuditChanges(this.originalItems, this.listItems);
                //ignore changes to selected property
                changes = _.filter(changes, c => !_.endsWith(c.name, "selected"));
                return changes.length > 0;
            },

            onSectionChanged (e) {
                this.gridComponent.updateFilters([
                    { dataField: "sectionName", values: this.selectedSectionFilters },
                ]);
            },
            gridRefresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
            onClearData(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    item.payorCompanyId = null;
                    item.payorCompanyName = "";
                    item.paidForBorrower = 0;
                    item.paidForSeller = 0;
                    item.totalCharges = 0;
                });

                self.gridRefresh();
            },
            onClearFilters() {
                this.selectedSectionFilters = [];
            },
            isValidData(item) {
                const self = this;

                if (((item.data.paidForBorrower > 0) || (item.data.paidForSeller > 0)) && _.isNullOrEmpty(item.data.payorCompanyId)) {
                    return Promise.resolve(false);
                } else {
                    return Promise.resolve(true);
                }
            },
        }
    }
</script>