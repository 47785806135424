<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && (v$.$error || !customDataIsValid) && showValidationError"
            dismissable
        />
        <rq-banner
            v-for="(warning, index) in doNotDoBusinessWithWarnings"
            :key="index"
            :message="warning"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="doNotDoBusinessWithWarnings.length > 0"
            dismissable
            sticky
        />
        <rq-page-section :title="labels.FirstSectionTitle" headerSize="lg">
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown" v-if="!addMode">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly">Add File Contact</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_add_buyer" @click="onAddBuyer">Buyer</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_add_seller" @click="onAddSeller">Seller</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_add_other" @click="onAddOtherRole">Other Role</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item" v-rq-tooltip.hover.top="{ html: true, title: deleteTooltip }">
                        <b-btn
                            automation_id="btn_delete_buyer"
                            variant="theme"
                            :disabled="readOnly || hasOutstandingChecks"
                            v-if="!addMode"
                            @click="onDeleteItem" >
                            {{labels.DeleteButton}}
                        </b-btn>
                    </li>
                    <li v-if="multipleItems" class="nav-item">
                        <b-btn
                            automation_id="btn_reorder_buyer"
                            variant="theme"
                            :disabled="readOnly"
                            @click="onReorderBuyerSellers">
                            {{labels.ReorderButton}}
                        </b-btn>
                    </li>
                </ul>
                <ul class="nav col-3 ms-auto">
                    <li class="nav-item">
                        <dx-select-box
                            v-if="showBuyerSellerDropdown"
                            :input-attr="$utils.idAttrs('drp_buyer_seller')"
                            :element-attr="{ class: 'form-control form-control-sm' }"
                            value-expr="id"
                            display-expr="name"
                            :items="buyerSellerDropdownData"
                            v-model="selectedBuyerSellerId"
                            @value-changed="onBuyerSellerDropdownChange"
                        />
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <b-btn automation_id="btn_view_order_contacts" variant="link" class="btn-theme" :to="{ name:'oe:oc:contacts', params: { orderId } }">View File Contacts</b-btn>
            </template>

            <div class="row">
                <div class="col-3 d-md-none d-pc-block"></div>
                <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'form-required' : entityNameRequired, 'has-error' : v$.buyerSeller.businessName.$error && showValidationError}">
                    <label for="txt_entity_name">Entity Name</label>
                    <input
                        id="txt_entity_name"
                        automation_id="txt_entity_name"
                        type="text"
                        class="form-control"
                        placeholder="Entity Name"
                        :disabled="readOnly"
                        maxlength="300"
                        v-model="buyerSeller.businessName"
                        @change="updateBusinessName"
                        v-focus
                    >
                    <rq-validation-feedback>Entity Name is required if First and Last Name are not provided</rq-validation-feedback>
                </div>

                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="vesting_info">Vesting Information</label>
                    <dx-select-box
                        :input-attr="$utils.idAttrs('drp_vesting_info')"
                        value-expr="id"
                        display-expr="name"
                        :items="vestingInformationTypes"
                        :disabled="readOnly"
                        v-model="buyerSeller.vestingInformationID"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-auto">
                    <div class="form-group-action">
                        <button automation_id="btn_override_formal_name_dialog" type="button" class="btn btn-link btn-theme" @click="showEditFormalNameDialog" :disabled="readOnly">{{labels.FormalNameButton}}</button>
                        <button automation_id="btn_remove_formal_name_override" type="button" class="btn btn-link btn-theme" v-show="buyerSeller.formalNameOverride" @click="removeFormalNameOverride" :disabled="readOnly">Remove Formal Name Override</button><br />
                        <button automation_id="btn_signature_dialog" type="button" class="btn btn-link btn-theme" @click="showSignatureDialog" :disabled="readOnly">{{labels.SignatureButton}}</button>
                        <button automation_id="btn_remove_signature_block" type="button" class="btn btn-link btn-theme" v-show="showRemoveSignatureLink" @click="removeSignature" :disabled="readOnly">Delete Signature</button><br />
                        <button automation_id="btn_signature_image_dialog" type="button" class="btn btn-link btn-theme" @click="showSignatureImageDialog" :disabled="readOnly || beforeSave">Update Signature Image</button>
                    </div>
                </div>
                <div class="col-auto ms-auto d-flex flex-column justify-content-end">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
        </rq-page-section>

        <rq-page-section title="Name 1" collapsible>
            <contact-details
                ref="refName1ContactDetail"
                :buyerSeller="buyerSeller"
                :originalBuyerSeller="originalItem"
                :nameNumber="1"
                :read-only="readOnly"
                :emailTitle="labels.EmailTitle"
                :emailSubject="'File: ' + fileNo"
                :show-validation-error="showValidationError"
                :refreshContact="refreshContact"
                @nameChange="updateFormalName"  />
        </rq-page-section>

        <rq-page-section title="Name 2" collapsible>
            <contact-details
                ref="refName2ContactDetail"
                :buyerSeller="buyerSeller"
                :originalBuyerSeller="originalItem"
                :nameNumber="2"
                :read-only="readOnly"
                :emailTitle="labels.EmailTitle"
                :emailSubject="'File: ' + fileNo"
                :show-validation-error="showValidationError"
                :refreshContact="refreshContact"
                @nameChange="updateFormalName"
            />
            <div class="row">
                <div class="col-3 form-group">
                    <label for="drp_relationship">Relationship to Name 1</label>
                    <dx-select-box
                        :input-attr="{ automation_id:'drp_relationship', id: 'drp_relationship' }"
                        :items="buyerSellerRelationshipTypes"
                        :disabled="relationshipDisabled || readOnly"
                        value-expr="id"
                        display-expr="name"
                        v-model="buyerSeller.buyerSellerRelationshipID"
                        :search-enabled="true"
                        @value-changed="updateFormalName"
                    />
                </div>
            </div>
        </rq-page-section>

        <rq-page-section automation_id="txt_primary_xxx" title="Primary Address" collapsible hide-collapsed-actions>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <span v-rq-tooltip.html.hover :title="accessRestrictedTooltip">
                            <button
                                automation_id="btn_copy_property"
                                type="button"
                                class="btn btn-link btn-theme"
                                :disabled="readOnly"
                                @click="copyPropertyAddress">Use Property Address
                            </button>
                        </span>
                    </li>
                    <li class="nav-item">
                        <b-form-checkbox
                            automation_id="chk_primary_military_address"
                            :disabled="readOnly"
                            v-model="buyerSeller.currentMilitary"
                            >Military Address
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item ms-2">
                        <b-form-checkbox
                            id="chk_primary_foreign_address"
                            automation_id="chk_primary_foreign_address"
                            :disabled="readOnly"
                            v-model="buyerSeller.isForeignAddress">Foreign Address
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item ms-2">
                        <b-form-checkbox
                            automation_id="chk_primary_nonresident_address"
                            :disabled="readOnly"
                            @click="copyPrimaryAddress"
                            v-if="isSeller"
                            v-model="buyerSeller.nonResident"
                            >Non-Resident Address
                        </b-form-checkbox>
                    </li>
                </ul>
            </template>
            <address-details
                ref="refPrimaryAddress"
                :buyerSeller="buyerSeller"
                addressType="current"
                automationIdPart="primary"
                :read-only="readOnly"
            />
        </rq-page-section>

        <rq-page-section title="Forwarding Address" collapsible  hide-collapsed-actions>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <span v-rq-tooltip.html.hover :title="accessRestrictedTooltip">
                            <button automation_id="btn_copy_primary" type="button" class="btn btn-link btn-theme" :disabled="readOnly" @click="copyPrimaryAddress">Use Primary Address</button>
                        </span>
                    </li>
                    <li class="nav-item" v-if="!isSeller" >
                        <span v-rq-tooltip.html.hover :title="accessRestrictedTooltip">
                            <button
                                automation_id="btn_copy_property"
                                type="button"
                                class="btn btn-link btn-theme"
                                :disabled="readOnly"
                                @click="copyPropertyAddressToForwarding">Use Property Address
                            </button>
                        </span>
                    </li>
                    <li class="nav-item">
                        <b-form-checkbox
                            automation_id="chk_forwarding_military_address"
                            :disabled="readOnly"
                            v-model="buyerSeller.forwardingMilitary"
                            >Military Address
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item ms-2">
                        <b-form-checkbox
                            id="chk_forwarding_foreign_address"
                            automation_id="chk_forwarding_foreign_address"
                            :disabled="readOnly"
                            v-model="buyerSeller.isForeignFowardingAddress">Foreign Address
                        </b-form-checkbox>
                    </li>
                </ul>
            </template>
            <address-details
                :buyerSeller="buyerSeller"
                addressType="forwarding"
                automationIdPart="forwarding"
                :read-only="readOnly"
            />
        </rq-page-section>

        <rq-page-section title="Parties" collapsible>
            <div class="row">
                <div class="col-3 form-group" :class="{'has-error' : v$.realtor.$error && showValidationError}">
                    <label for="selling-agent">{{ labels.RealtorTitle }} {{companyTitle}}</label>
                    <company-picker
                        ref="refRealtor"
                        automation_id="pic_selling_agent"
                        companyRoleName="Realtor"
                        :companyRoleId="10"
                        :dialogTitle="labels.RealtorCompanyDialogTitle"
                        :disabled="readOnly"
                        v-model="buyerSeller.realtorCompany"
                        show-selection-summary
                    />
                    <rq-validation-feedback :offset="90">Enter a valid {{ labels.RealtorTitle }}</rq-validation-feedback>
                </div>
                <div class="col-3 form-group">
                    <label for="selling-agent">{{ labels.RealtorTitle }} Contact</label>
                    <contact-picker
                        automation_id="drp_selling_agent_contact"
                        :dialogTitle="labels.RealtorContactDialogTitle"
                        :disabled="readOnly"
                        :company-id="buyerSeller.realtorID"
                        v-model:contact-name="buyerSeller.realtorContact"
                        v-model="buyerSeller.realtorContactID" />
                </div>

                <div class="col-3 form-group" :class="{'has-error' : v$.attorney.$error && showValidationError}">
                    <label>Attorney Company</label>
                    <company-picker
                        ref="refAttorney"
                        automation_id="pic_attorney"
                        companyRoleName="Attorney"
                        :companyRoleId="4"
                        dialogTitle="Select Attorney"
                        :disabled="readOnly"
                        v-model="buyerSeller.attorneyCompany"
                        show-selection-summary
                    />
                    <rq-validation-feedback :offset="90">Enter a valid Attorney</rq-validation-feedback>
                </div>
                <div class="col-3 form-group">
                    <label>Attorney Contact</label>
                    <contact-picker
                        automationId="drp_attorney"
                        dialogTitle="Select Attorney Contact"
                        :disabled="readOnly"
                        :company-id="buyerSeller.attorneyID"
                        v-model:contact-name="buyerSeller.attorneyContact"
                        v-model="buyerSeller.attorneyContactID" />
                </div>

                <div class="col-3 form-group">
                    <label for="drp_notary">Notary</label>
                    <dx-select-box
                        :input-attr="{ automation_id:'drp_notary', id: 'drp_notary' }"
                        :items="notaries"
                        :disabled="readOnly"
                        value-expr="id"
                        display-expr="name"
                        v-model="buyerSeller.notaryStaffID"
                    />
                </div>
            </div>
        </rq-page-section>


        <rq-page-section v-if="isSeller" title="1099 Information" collapsible hide-collapsed-actions>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <span v-if="multipleItems" v-rq-tooltip.html.hover :title="accessRestrictedTooltip">
                            <button type="button" @click="onEditProceeds" class="btn btn-theme" :disabled="!userAccess.full || readOnly">Edit 1099 Distribution</button>
                        </span>
                    </li>
                </ul>
            </template>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <b-form-checkbox automation_id="chk_1099_use_forwarding_address" v-model="buyerSeller.useForwardingAddressfor1099">Use Forwarding Address</b-form-checkbox>
                        </div>
                        <div class="form-group">
                            <b-form-checkbox automation_id="chk_1099_do_not_generate_report" v-model="buyerSeller.ten99DoNotGenerateReport">Do NOT generate 1099</b-form-checkbox>
                        </div>
                        <div class="form-group">
                            <b-form-checkbox automation_id="chk_1099_property_or_services_received" v-model="buyerSeller.ten99Box4">Property or Services Received</b-form-checkbox>
                        </div>
                        <div class="form-group">
                            <b-form-checkbox automation_id="chk_1099_is_trasferor_foreign" v-model="buyerSeller.isTransferorForeign">Transferor is a Foreign Person</b-form-checkbox>
                        </div>
                    </div>

                    <div class="col-3 form-group">
                        <label for="allocationPercent">Allocation %</label>
                        <div class="input-group">
                            <rqInputNumber id="txt_1099_allocation_percent" defaultValue="0" :decimals="percentPrecision" v-model="buyerSeller.ten99AllocationPercent" readonly />
                            <span class="input-group-text">%</span>
                        </div>
                    </div>

                    <div class="col-3 form-group">
                        <label for="allocationAmount">Allocation Amount</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber id="txt_1099_allocation_amount" defaultValue="0" decimals="2" v-model="buyerSeller.ten99AllocationAmount" readonly />
                        </div>
                    </div>

                    <div class="col-3 form-group">
                        <label for="allocationAmount">Override Tax Reporting Name</label>
                        <div class="input-group">
                            <input type="text" class="form-control" maxlength="1000" v-model="buyerSeller.taxNameText" />
                        </div>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <rq-page-section title="Notes" collapsible>
            <div class="form-group">
                <textarea id="ta_buyerseller_notes" automation_id="ta_buyerseller_notes" class="form-control buyer-seller-notes" :disabled="readOnly" v-model="buyerSeller.notes"></textarea>
            </div>
        </rq-page-section>
        <rq-page-section title="Custom Data" collapsible>
            <custom-data-container
                ref="customDataContainer"
                :reference-table="referenceTable"
                :reference-table-pk-value="buyerSellerId"
                :custom-data-tab-id="customDataTabID"
                v-model:value="customData"
                v-model:is-valid="customDataIsValid"
                :read-only="customDataReadOnly"
                @custom-data-loaded="onCustomDataLoaded"
            ></custom-data-container>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { RoleType } from "@/shared/models/enums";
    import { BuyerSellerDto, AddressModel, SignatureBlockModel } from "./models";
    import { CompanyPicker, ContactPicker, HtmlEditorDialog, SortableList, CustomDataContainer} from "@/shared/components";
    import SignatureImage from "./SignatureImage";
    import AddressDetails from "./AddressDetails";
    import ContactDetails from "./ContactDetails";
    import AllocationDistribution from './AllocationDistribution';
    import { RoleList, CompanyContactLookup } from "../contacts/components";
    import { ORDER_ACTIONS, CONTACT_ACTIONS } from '@/store/actions';
    import { ORDER_MUTATIONS } from '@/store/mutations';
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { CustomDataReferenceTable } from "@/shared/components/customdata/models";
    import { UserScreenAccessLevel } from "@/shared/models/enums";

    const WARNING_MESSAGE = "Entity is on the do not do business with list";

    export default {
        name: "BuyerSellerForm",

        components: {
            AddressDetails,
            CompanyPicker,
            ContactDetails,
            ContactPicker,
            CustomDataContainer
        },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            const self = this;
            return {
                buyerSellerId: 0,
                items: [],
                buyerSeller: new BuyerSellerDto(),
                originalValues: [],
                originalItem: {},
                validationErrors: [],
                signatureBlock: new SignatureBlockModel(),
                addressTabIndex: 0,
                dataLoaded: false,
                skipSave: false,
                selectedBuyerSellerId: 0,
                showBanner: false,
                refreshOrderContacts: false,
                showValidationError: true,
                customDataTabID: 0,
                doNotDoBusinessWithWarnings: [],
                customDataIsValid: true,
                customData: [],
                originalCustomData: [],
                refreshContact: false
            };
        },

        computed: {
            ...mapState({                
                readOnly: state => _.parseBool(state.isPageReadOnly),
                contacts: state => state.orders.contacts,
                fileNo: state => state.orders.order.gfNo || "",
                orderId: state => state.orders.orderId,
                regionId: state => state.orders?.region?.regionID,
                defaultSellers1099: state => state.orders.branch.defaultSellers1099,
                systemDefaults: state => state.system.systemDefaults || {}
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            localSecurity() {
                 return this.securitySettings.findValues([
                     "AdditionalFields_ScreenAccess"
                ]);
            },
            percentPrecision() { return 8; },
            hasOutstandingChecks() { return _.some(this.items, {hasOutstandingchecks: true}); },
            customDataReadOnly() { return this.readOnly || this.localSecurity.AdditionalFields_ScreenAccess !== UserScreenAccessLevel.Full; },
            roleType() { return this.$route.name === "oe:oc:buyer" ? RoleType.Buyer : RoleType.Seller; },
            isSeller() { return this.roleType === RoleType.Seller; },
            multipleItems() { return this.items.length > 1; },
            useBuyerRealtorAsSellingAgent() { return _.get(this.systemDefaults, "useBuyerRealtorAsSellingAgent", false); },
            useSellerRealtorAsListingAgent() { return _.get(this.systemDefaults, "useSellerRealtorAsListingAgent", false); },
            companyTitle(){
                if(self.roleType === RoleType.Buyer){
                    return this.useBuyerRealtorAsSellingAgent ? "Company" : "";
                }
                else{
                    return this.useSellerRealtorAsListingAgent ? "Company" : "";
                }
            },
            buyerRealtorTitle () { return this.useBuyerRealtorAsSellingAgent ? "Selling Agent" : "Buyer's Real Estate Agent"; },
            sellerRealtorTitle() { return this.useSellerRealtorAsListingAgent ? "Listing Agent" : "Seller's Real Estate Agent"; },
            relationshipDisabled () { return _.isEmpty(_.get(this.buyerSeller, "fullName2", "")); },
            accessRestrictedTooltip() { return this.userAccess.full ? "" : "Access Restricted<br/>Contact your administrator"; },
            labels() {
                const self = this;
                let buyerSellerEntity = self.roleType === RoleType.Buyer ? "Buyer" : "Seller";
                let result = {
                    FirstSectionTitle: "[ENTITY]",
                    AddButton: "Add [ENTITY]",
                    DeleteButton: "Delete [ENTITY]",
                    ReorderButton: "Re-order [ENTITY]s",
                    EmailTitle: "Email [ENTITY]",
                    FormalNameButton: _.get(self, "buyerSeller.formalNameOverride", false) ? "Edit Formal Name" : "Assign Formal Name",
                    FormalNameDialogTitle: _.get(self, "buyerSeller.formalNameOverride", false) ? "Edit [ENTITY] Formal Name" : "Assign [ENTITY] Formal Name",
                    SignatureButton: (this.signatureBlock && this.signatureBlock.signatureBlockOverride) ? "Edit Signature" : "Add Signature",
                    SignatureImageButton: (this.buyerSeller.scannedSignatureExists) ? "Edit Signature Image" : "Upload Signature Image",
                    RealtorTitle: self.roleType === RoleType.Buyer ? self.buyerRealtorTitle : self.sellerRealtorTitle,
                    RealtorCompanyDialogTitle: self.roleType === RoleType.Buyer ? `Select ${self.buyerRealtorTitle}` : `Select ${self.sellerRealtorTitle}`,
                    RealtorContactDialogTitle: self.roleType === RoleType.Buyer ? `Select ${self.buyerRealtorTitle} Contact` : `Select ${self.sellerRealtorTitle} Contact`,
                    InvalidRealtorMessage: self.roleType === RoleType.Buyer
                        ? `Select a valid ${(self.useBuyerRealtorAsSellingAgent) ? "Selling Agent" : "Buyer's Realtor"}`
                        : `Select a valid ${(self.useSellerRealtorAsListingAgent) ? "Listing Agent" : "Seller's Realtor"}`,
                    ReorderDialogTitle: "Set [ENTITY] Priority"
                };

                _.forEach(result, (v,k) => {
                    if(!_.includes(v, "[ENTITY]")) return;
                    result[k] = v.replace("[ENTITY]", buyerSellerEntity);
                });

                return result;
            },
            showRemoveSignatureLink () { return this.signatureBlock && this.signatureBlock.signatureBlockOverride; },
            buyerSellerCount() { return this.items.length; },
            addMode() { return _.lte(this.buyerSellerId, 0); },
            buyerSellerDropdownData() {
                return _.map(this.items, (item, index) => {
                    let formalName = _.getWithNullCheck(item, "formalName","");
                    return {
                        id: item.buyerSellerID,
                        name: `${this.isSeller ? 'Seller': 'Buyer'} ${index + 1} of ${this.items.length}: ${formalName}`
                    }
                });
            },
            showBuyerSellerDropdown() {
                return !this.addMode && this.items.length > 1;
            },
            entityNameRequired(){
                if(this.v$.buyerSeller.businessName.required || this.v$.buyerSeller.businessName.$error)
                    return true;
                return false;
            },
            buyerSellerRelationshipTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.BUYER_SELLER_RELATIONSHIP_TYPES, this.regionId); },
            vestingInformationTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.VESTING_INFORMATION_TYPES, this.regionId); },
            notaries() { return this.lookupHelpers.getOrderLookupItems(this.lookupItems.NOTARIES, this.buyerSeller.notaryStaffID); },
            referenceTable() {
                return this.isSeller ? CustomDataReferenceTable.Seller : CustomDataReferenceTable.Buyer;
            },
            beforeSave() {
                if(this.buyerSellerId < 1)
                    return true;
                return false;
            },
            deleteTooltip() {
                if (!this.items || !this.items.length) return null;
                const buyerSellerType = this.items[0].buyerSellerType === 0 ? "Buyer" : "Seller";
                if(this.hasOutstandingChecks) return `Unable to delete this ${ buyerSellerType }, due to outstanding checks`;
                return null;
            }
        },

        watch:{
            $route(newVal, oldVal) {
                let newBsId = _.getNumber(newVal, "params.buyerSellerId", 0);
                if(newBsId === this.buyerSellerId) return;
                this.buyerSellerId = newBsId;
                this.fetchData();
            }
        },

        created () {
            const self = this;
            if (self.userAccess.full) {
                GlobalEventManager.onSave(self, self.onSave);
                GlobalEventManager.onCancel(self, self.onCancel);
            }
            self.buyerSellerId = _.parseNumber(_.get(self, "$route.params.buyerSellerId", 0), 0);
            self.fetchData();

            self.$nextTick(() => {
                //registering in next tick in case unregistered in previous route's beforeUnmount (passing function as argument in "off" doesn't work)
                self.$events.on("apply-template-success", e => {
                    self.$router.push({ name:"oe:oc:contacts", params: { orderId: self.orderId, showValidationError: false, appliedTemplate: true } });
                });
            });
        },

        beforeUnmount () {
            GlobalEventManager.unregister(this);
            this.$events.off("apply-template-success");
        },

        beforeRouteLeave (to, from, next) {
            let self = this;
            if(!self.userAccess.full) {
                next();
                return;
            }

            let showValidationError = _.get(to, "params.showValidationError", true);
            let appliedTemplate = _.get(to, "params.appliedTemplate", false);
                
            self.save({showValidationError: showValidationError, appliedTemplate: appliedTemplate})
                .then(isValid => {
                    if(!isValid) return;
                    if(self.refreshOrderContacts && to.name !== "oe:oc:contacts") {
                        self.refreshOrderContacts = false;
                        let storePromise = self.$store.dispatch(CONTACT_ACTIONS.GET_ORDER_CONTACTS);
                        self.$rqBusy.wait(storePromise)
                            .then(() => next());
                        return;
                    }
                    next();
                });
        },

        beforeRouteUpdate(to, from, next) {
            const self = this;
            if (self.userAccess.full) {
                self.save()
                    .then((canContinue) => {
                        if(canContinue) next();
                    });
            } else {
                next();
            }
        },

        validations: function(){
            const self = this;
            const name1Valid = () => _.getBool(self, "$refs.refName1ContactDetail.isValid", true);
            const name2Valid = () => _.getBool(self, "$refs.refName2ContactDetail.isValid", true);
            const primaryAddressValid = () => _.getBool(self, "$refs.refPrimaryAddress.isValid", true);
            const isRealtorValid = () => _.getBool(self, "$refs.refRealtor.isValid", true);
            const isAttorneyValid = () => _.getBool(self, "$refs.refAttorney.isValid", true);

            let result = {
                buyerSeller: {
                    businessName: {}
                },
                name1: { name1Valid },
                name2: { name2Valid },
                primaryAddress: { primaryAddressValid },
                realtor: { isRealtorValid },
                attorney: { isAttorneyValid }
            }

            if(_.isEmpty(self.buyerSeller.firstName1) && _.isEmpty(self.buyerSeller.lastName1)){
                result.buyerSeller.businessName = { required }
            }

            return result;
        },
        methods: {
            fetchData (selectLast=false) {
                const self = this;
                if (self.addMode) {
                    self.createNewBuyerSeller();
                    self.originalItem = self.buyerSeller.toDataObject();
                    self.dataLoaded = true;
                    return;
                }
                return this.roleType === RoleType.Buyer
                    ? this.fetchBuyers(selectLast)
                    : this.fetchSellers(selectLast);
            },

            createNewBuyerSeller() {
                let self = this;
                self.buyerSeller = new BuyerSellerDto({
                    ordersID: self.orderId,
                    buyerSellerID: -1,
                    buyerSellerType: self.isSeller ? 1 : 0,
                    sequence: -1,
                    ten99DoNotGenerateReport:  self.isSeller ? !_.parseBool(self.defaultSellers1099): false,
                    socialSecurityNumber1: null,
                    socialSecurityNumber2: null
                });
                this.refreshContact = !this.refreshContact;
                self.reset();
            },

            fetchBuyers(selectLast){
                const self = this;
                self.validationErrors = [];
                self.refreshContact = false;
                let apiPromise = self.$api.OrdersApi.getBuyers(self.orderId);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        self.items = _.map(response, item => new BuyerSellerDto(item));
                        self.setSelectedBuyerSeller(selectLast);
                        return response;
                    })
                    .then(() => {
                        return self.getSelectedItemSignature();
                    })
                    .finally(() => {
                        self.$nextTick().then(() => { self.dataLoaded = true; });
                        self.refreshContact = true;
                    })
                    .catch(error => {
                        console.error("Failed to acquire buyer information", error);
                    });
            },

            fetchSellers(selectLast){
                const self = this;
                let apiPromise = self.$api.OrdersApi.getSellers(self.orderId);
                self.refreshContact = false;
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        self.items = _.map(response, item => new BuyerSellerDto(item));
                        self.setSelectedBuyerSeller(selectLast);
                        self.salesPrice = self.items.length > 0 ? self.items[0].salesPrice : Number(0).toFixed(2);
                        self.override1099Proceeds = self.items.length > 0 ? self.items[0].overrideAuto1099ProceedsDistribution : false;
                        return response;
                    })
                    .then(() => {
                        return self.getSelectedItemSignature();
                    })
                    .finally(() => {
                        self.$nextTick().then(() => { self.dataLoaded = true; });
                        self.refreshContact = true;
                    })
                    .catch(error => {
                        console.error("Failed to acquire Seller information", error);
                    });
            },

            setSelectedBuyerSeller (selectLast=false) {
                const self = this;
                let item = null;

                if (selectLast) {
                    item = selectLast ? self.getLast(self.items) : self.getFirst(self.items);
                } else if (self.buyerSellerId) {
                    item = _.find(self.items, { buyerSellerID: self.buyerSellerId });
                }
                self.buyerSeller = item || self.items[0];
                self.buyerSellerId = self.buyerSeller.buyerSellerID;
                self.selectedBuyerSellerId = self.buyerSeller.buyerSellerID;
                self.originalItem = self.buyerSeller.toDataObject();
            },

            onAddBuyer () {
                const self = this;
                self.validationErrors = [];
                self.save()
                    .then(canContinue => {
                        if(!canContinue) return;
                        self.$router.push({ name: "oe:oc:buyer", params: { orderId: self.orderId, buyerSellerId: 0 } });
                        
                    });
            },
            onAddSeller () {
                const self = this;
                self.validationErrors = [];
                self.save()
                    .then(canContinue => {
                        if(!canContinue) return;
                        self.$router.push({ name: "oe:oc:seller", params: { orderId: self.orderId, buyerSellerId: 0 } });
                        
                    });
            },

            onAddOtherRole () {
                const self = this;
                self.validationErrors = [];
                if (self.readOnly) return;
                self.save()
                    .then((resolved) => {
                        if (resolved){
                            let roles = self.lookupHelpers.getRoles([],'canAdd', true);
                            self.$dialog.open({
                                title: "Contact Role",
                                width: 500,
                                component: RoleList,
                                props: { roles },
                                adaptive: true,
                                onOk (e) {
                                    if(!e.component.isValid()) return false;
                                    let roleTypeId = _.parseNumber(e.component.selectedValue)
                                    let roleTypeName = e.component.selectedItem.name;
                                    self.showCompanyLookup(roleTypeId, roleTypeName);
                                    return true;
                                }
                            });
                        }
                });
            },

            onDeleteItem () {
                const self = this;
                let buyerSellerLabel = self.isSeller ? "seller" : "buyer";
                let lastBuyerSeller = self.items.length === 1;
                let ok = async function (args) {
                    await self.reset1099Allocation();
                    let apiPromise = self.isSeller
                        ? self.$api.OrdersApi.deleteSeller(self.orderId, self.buyerSeller.buyerSellerID)
                        : self.$api.OrdersApi.deleteBuyer(self.orderId, self.buyerSeller.buyerSellerID);
                    self.$rqBusy.wait(apiPromise)
                        .then(() => {
                            self.$toast.success(`${_.startCase(buyerSellerLabel)} successfully deleted.`);
                            self.$store.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
                            if(self.isSeller){
                                self.$store.dispatch(ORDER_ACTIONS.GET_LOANS);
                            }
                            if(lastBuyerSeller) {
                                self.skipSave = true;
                                self.$router.push({ name:"oe:oc:contacts", params: { orderId: self.orderId }});
                                return;
                            }
                            self.validationErrors = [];
                            self.fetchData(false);
                        })
                        .catch(errorInfo => {
                            self.$toast.error(`Error deleting ${buyerSellerLabel}`);
                        });
                    return true;
                };
                let specifyingText = _.isEmpty(self.buyerSeller.formalName)
                    ? `this ${buyerSellerLabel}`
                    : `the ${buyerSellerLabel}, ${self.buyerSeller.formalName}`;

                let confirmMessage = `Are you sure you want to delete ${specifyingText}?`;
                self.$dialog.confirm(`${self.fileNo} : Confirm Delete`, confirmMessage, ok);
            },

            onBuyerSellerDropdownChange(e) {
                if (_.isNil(e?.event)) return;
                let routeName =  this.isSeller ? "oe:oc:seller" : "oe:oc:buyer";

                if (this.isValid()) {
                    this.$router.push({
                        name: routeName,
                        params: {
                            orderId: this.orderId,
                            buyerSellerId: e?.value
                        }
                    });
                }
                else {
                    this.selectedBuyerSellerId = e?.previousValue;
                }
            },

            async reset1099Allocation() {
                const self=this;
                let data = {
                            ordersID: self.orderId,
                            overrideFlag: false,
                        };
                        let saveRequest = { data };
                        self.$api.OrdersApi.updateSellerProceeds(saveRequest);
                        // self.$rqBusy.wait(apiPromise);
            },

            onReorderBuyerSellers() {
                const self = this;

                let items = _.map(self.items, item => {
                    return {
                        id: item.buyerSellerID,
                        description: item.formalName,
                        ordinal: item.sequence
                    };
                });

                let orderId = self.orderId;
                self.$dialog.open({
                    title: self.labels.ReorderDialogTitle,
                    width:500,
                    adaptive: true,
                    component: SortableList,
                    props: { orderId, items },
                    onOk(e) {
                        let changes = e.component.getChanges();
                        if(_.isEmpty(changes)) return true;

                        let request = { orderId, changes };

                        let apiPromise = self.$api.OrdersApi.reorderBuyerSellers(request);
                        self.$rqBusy.wait(apiPromise)
                            .then(() => {
                                self.fetchData(false);
                                self.$store.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
                            });
                        return true;
                    }
                });
            },

            onSave: _.debounce(async function(eventArgs){
                const self = this;
                let success = await self.save(eventArgs);
                GlobalEventManager.saveCompleted({ success });
            }, 500, { leading: true, trailing: false }),

            save(args={}) {
                const self = this;
                self.showValidationError = _.get(args, "showValidationError", true);
                let appliedTemplate = _.get(args, "appliedTemplate", false);
                
                let now = new Date();

                self.$api.DoNotDoBusinessWithApi
                    .getByEntityName(this.buyerSeller.businessName)
                    .then(x => {
                        if (x.filter(x => new Date(x.effectiveDate) <= now && new Date(x.expirationDate) >= now).length > 0){
                            if (!this.doNotDoBusinessWithWarnings.includes(WARNING_MESSAGE)) {
                                this.doNotDoBusinessWithWarnings.push(WARNING_MESSAGE);
                            }
                        }
                        else {
                            this.doNotDoBusinessWithWarnings = [];
                        }
                    });

                if (!self.dataLoaded || self.readOnly || self.skipSave) return Promise.resolve(true);

                // stop from adding more than one buyer when View File Contacts is clicked repeatedly
                self.skipSave = true;

                let userInitiated = _.get(args, "userInitiated", false) || false;
                let item = self.buyerSeller.toDataObject();
                let changes = self.getAuditChanges(self.originalItem, item);
                let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);

                if (appliedTemplate && changes.length === 0) {
                    return Promise.resolve(true);                    
                }

                if((_.isEmpty(args) && changes.length === 0) || !self.showValidationError){
                    self.skipSave = false;
                    return Promise.resolve(true);
                }

                _.invoke(self, "$refs.customDataContainer.validate");

                if (!self.isValid() || !self.customDataIsValid) {
                    self.skipSave = false;
                    return Promise.resolve(false);
                }

                item.vestingInformationName = self.buyerSeller.vestingInformationName = self.lookupHelpers.getLookupItemName(self.lookupItems.VESTING_INFORMATION_TYPES, self.buyerSeller.vestingInformationID);
                item.buyerSellerRelationshipName = self.buyerSeller.buyerSellerRelationshipName = self.lookupHelpers.getLookupItemName(self.lookupItems.BUYER_SELLER_RELATIONSHIP_TYPES, self.buyerSeller.buyerSellerRelationshipID);
                item.notaryStaffName = self.buyerSeller.notaryStaffName = self.lookupHelpers.getLookupItemName(self.lookupItems.NOTARIES, self.buyerSeller.notaryStaffID);

                if(changes.length > 0 || customDataChanges.length > 0) {

                    // The signature block override state may have changed, preserve it.
                    item.signatureBlockOverride = self.signatureBlock.signatureBlockOverride;

                    let apiPromise = self.isSeller
                        ? self.$api.OrdersApi.saveSeller(item, changes)
                        : self.$api.OrdersApi.saveBuyer(item, changes);
                    let success = true;
                    //if userInitiated (save clicked), show busy spinner; otherwise save in the background
                    let savePromise = userInitiated
                        ? self.$rqBusy.wait(apiPromise)
                        : apiPromise;
                    return savePromise
                        .then(buyersellerId => {
                            self.refreshOrderContacts = true;
                            self.$store.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
                            self.$store.commit(ORDER_MUTATIONS.SET_COMMITMENT_DATA, null);  //null out the commitment data, since name changes may cause TP data to change, this will force a refresh.

                            self.originalItem = self.buyerSeller.toDataObject();

                            if(self.addMode)
                            {
                                // when adding new buyerseller, use the newly created buyersellerId customData
                                customDataChanges = _.map(customDataChanges, x => {
                                                        x.referenceTablePKValue = buyersellerId;
                                                        return x;
                                                    });
                            }

                            if(customDataChanges.length > 0) {
                                return self.$rqBusy.wait(self.$api.CustomDataApi.saveCustomData(customDataChanges))
                                        .then(() => {
                                            self.originalCustomData = self.customData;
                                            return buyersellerId;
                                        });
                            }
                            return buyersellerId;
                        })

                        .then(buyersellerId => {
                             let toastMessage = `${self.isSeller ? "Seller" : "Buyer"} information saved`;
                            if (self.addMode && userInitiated) {
                                let routeName = self.isSeller ? "oe:oc:seller" : "oe:oc:buyer";
                                self.$router.replace({ name: routeName, params: { orderId: self.orderId, buyerSellerId: buyersellerId } });
                                toastMessage = _.replace(toastMessage, "saved", "added");
                            }
                            
                            self.buyerSellerId = buyersellerId;
                            self.$toast.success(toastMessage);
                            self.skipSave = false;
                            self.fetchData();
                            if(!self.addMode)
                                _.invoke(self, "$refs.customDataContainer.initialize");
                            
                            return true;
                        })
                        .catch(errorInfo => {
                            if(errorInfo?.errorCode == 'TAXIDVERIFICATIONERROR') {
                                self.onTaxIdVerificationError(errorInfo?.errorMessage, args);
                            }
                            else {
                                self.$log.fatal("[modules/file/order-entry/buyer-seller/buyers.vue] - Error saving data", errorInfo);
                                self.$toast.error(`Error while updating ${self.isSeller ? "seller" : "buyer"} information`);
                            }
                            success = false;
                            self.skipSave = false;
                            return errorInfo;
                        })
                        .finally(()=> {
                            self.showValidationError = true;
                        });
                } else {
                    self.skipSave = false;
                    self.showValidationError = true;
                    let navigatingTo = _.get(args, "navigatingTo", '') || '';
                    if (userInitiated && navigatingTo != 'cdf-wysiwyg') self.$toast.info("No changes detected");
                    return Promise.resolve(true);
                }
            },

            onCancel (args) {
                this.cancel();
            },

            cancel () {
                const self = this;
                _.invoke(self, "$refs.customDataContainer.resetFieldValidations");
                if(self.addMode) {
                    self.skipSave = true;
                    self.$router.push({ name:"oe:oc:contacts", params: { orderId: self.orderId }});
                }
                else {
                    let changes = self.getAuditChanges(self.originalItem, self.buyerSeller.toDataObject());
                    let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);
                    let hasChanges = () => {
                        return changes.length > 0 || customDataChanges.length > 0;
                    };
                    GlobalEventManager.confirmCancel({
                        hasChanges,
                        callback: () => {
                            self.validationErrors = [];
                            _.invoke(self, "$refs.customDataContainer.initialize");
                            self.fetchData();
                        }
                    });
                }
            },

            isValid () {
                const self = this;
                self.showBanner = true;
                _.invoke(self, "$refs.refName1ContactDetail.touch");
                _.invoke(self, "$refs.refName2ContactDetail.touch");
                _.invoke(self, "$refs.refPrimaryAddress.touch");
                self.v$.$touch();
                return !self.v$.$error && 
                (!self.$refs.refName1ContactDetail.taxIdVerificationError) &&
                (!self.$refs.refName2ContactDetail.taxIdVerificationError);
            },

            onNoteChanged (notes) {this.buyerSeller.noteChanged(notes);},

            showCompanyLookup(roleTypeId, roleTypeName) {
                const self = this;
                let role = { orderId: self.orderId, roleTypeId, roleTypeName };
                let handleOk = function(e) {
                    return new Promise((resolve,reject) => {
                        let result = _.get(e, "originalEvent.data", e.data);

                        role.companyId = result.companyID;
                        role.contactId = result.contactID;
                        let apiPromise2 = self.$api.OrdersApi.addRole(role);
                        self.$rqBusy.wait(apiPromise2, { topLevel: true })
                            .then(newRole => {
                                self.$router.push({ name:"oe:oc:contacts"});
                                resolve(true);
                            })
                            .catch(reject);
                    });
                };

                self.$dialog.open({
                    title: "Select Company",
                    height:"85%",
                    width:"85%",
                    component: CompanyContactLookup,
                    props: { roleTypeId },
                    onOk (e) {
                        return handleOk(e);
                    },
                });

            },

            showSignatureDialog () {
                const self = this;

                let okHandler = (e) => {
                    let htmlResult = e.component.dialogResult();
                    self.signatureBlock.signatureHtmlValue = htmlResult;
                    self.signatureBlock.signatureBlockOverride = (htmlResult.length > 0);
                    self.updateSignatureBlock();
                };

                let content = (self.signatureBlock && self.signatureBlock.signatureBlockOverride) ? self.signatureBlock.signatureHtmlValue : "";
                self.$dialog.open({
                    title: "Add/Edit Signature Block Override",
                    height: 500,
                    width: 800,
                    resizable: true,
                    scrollable: false,
                    draggable: false,
                    component: HtmlEditorDialog,
                    props: { content, includeStandardLanguage: false },
                    onOk: okHandler
                });
            },

            removeSignature () {
                const self = this;
                let okHandler = () => {
                    self.signatureBlock = new SignatureBlockModel({
                        ordersID: self.orderId,
                        buyerSellerID: self.buyerSeller.buyerSellerID
                    });
                    self.updateSignatureBlock();
                };
                self.$dialog.confirm("Confirm Remove Signature", `Are you sure you want to remove the signature block override for the current ${self.isSeller ? 'seller' : 'buyer'}?`, okHandler);
            },

            getSelectedItemSignature () {
                const self = this;
                return self.$api.OrdersApi.getSignatureBlock(self.buyerSeller.buyerSellerID)
                    .then(result => {
                        self.signatureBlock = new SignatureBlockModel(result);
                    })
                    .catch(err => {
                        console.error("Get signature block failed:", err);
                        self.$toast.error({ message: "Get signature block failed" });
                    });
            },

            updateSignatureBlock () {
                const self = this;
                let apiPromise = self.$api.OrdersApi.updateSignatureBlock(self.signatureBlock);
                const message = `${self.isSeller ? "Seller" : "Buyer"} information saved`;
                return self.$rqBusy.wait(apiPromise)
                    .then(() => self.$toast.success({ message }))
                    .catch(err => {
                        console.error("Signature block override update failed", err);
                        self.$toast.error("Signature block override update failed");
                    });
            },

            showSignatureImageDialog () {
            const self = this;
            self.$dialog.open({
                title: "Upload Signature Image",
                height: 500,
                width: 500,
                component: SignatureImage,
                args: { buyerSellerId: self.buyerSeller.buyerSellerID },
                closeOnEsc: true,
                customFooter: true,
              });
            },

            removeSignatureImage () {
                const self = this;
                self.$api.OrdersApi.removeSignatureImage(self.buyerSeller.buyerSellerID)
                    .then(() => {self.buyerSeller.scannedSignatureExists = false;})
                    .catch(err => {
                        console.error("Signature image removal failed: ", err);
                        self.$toast.error({ message: "Signature image removal failed" });
                    });
            },

            showEditFormalNameDialog () {
                const self = this;
                let rs = self.getCurrentRelationshipItem();
                let defaultFormalName = self.buyerSeller.getDefaultFormalName(rs, true);
                let formalName = self.buyerSeller.formalName || defaultFormalName;
                self.$dialog.promptInput({
                    title: self.labels.FormalNameDialogTitle,
                    label: "Formal Name:",
                    value: formalName,
                    requiredMessage: "Formal Name Required",
                    onOk: (e) => {
                        self.buyerSeller.formalName = e.value;
                        self.buyerSeller.formalNameOverride = (e.value !== defaultFormalName);
                        self.save();
                        return true;
                    }
                });
            },

            updateFormalName(e) {
                const self = this;
                self.touch();
                if (!self.buyerSeller.updateFormalName) return;
                let rs = self.getCurrentRelationshipItem();
                self.buyerSeller.updateFormalName(rs, true);
            },

            updateBusinessName(e) {
                const self = this;
                self.updateFormalName(e);
                self.buyerSeller.taxIdVerificationStatus1 = '';
                self.buyerSeller.taxIdVerificationStatus2 = '';
            },

            removeFormalNameOverride() {
                const self = this;
                let rs = self.getCurrentRelationshipItem();
                self.buyerSeller.formalName = self.buyerSeller.getDefaultFormalName(rs, true);
                self.buyerSeller.formalNameOverride = false;
                self.buyerSeller.isDirty = true;
                self.save();
            },

            getPropertyAddress() {
            const self = this;
            // TODO: Refactor this...
            let apiPromise = self.$api.PropertiesApi.getProperties(self.orderId);
            return self.$rqBusy.wait(apiPromise)
                .then(properties => {
                    if (properties.length > 0) {
                        const address = new AddressModel(
                            properties[0].property.address1,
                            properties[0].property.address2,
                            properties[0].property.city,
                            properties[0].property.state,
                            properties[0].property.zip,
                            "",
                            false
                        );
                        return address;
                    }
                });
            },

            copyPropertyAddress () {
                const self = this;
                self.getPropertyAddress().then((address) => {
                    self.buyerSeller.primaryAddress = address;
                });
            },

            copyPropertyAddressToForwarding () {
                const self = this;
                self.getPropertyAddress().then((address) => {
                    self.buyerSeller.forwardingAddress = address;
                });
            },

            copyPrimaryAddress () {
                const self = this;
                self.buyerSeller.forwardingAddress = _.clone(self.buyerSeller.primaryAddress);
            },

            onEditProceeds() {
                const self = this;
                let sellers =  _.map(self.items, item => {
                    return {
                        id: item.buyerSellerID,
                        seller: item.formalName || '<Enter Seller Information>',
                        sequence: item.sequence,
                        salesPrice: self.salesPrice,
                        percent: item.ten99AllocationPercent || 0,
                        amount: item.ten99AllocationAmount || 0
                    };
                });

                let original = {
                    ordersID: self.orderId,
                    overrideFlag: self.override1099Proceeds,
                    sellers: _.map(self.items, item => {
                        return {
                            buyerSellerID: item.id,
                            overridePercent: Number(item.percent).toFixed(self.percentPrecision), // correlate with the percent precision
                            overrideAmount: item.amount
                        };
                    })
                };

                let okHandler = function(e) {
                    let dialogResult = e.component.dialogResult();

                    if(!e.component.onOk())
                        return false;

                    //[RQO-1936] Check to see if dialogResult is valid before processing
                    if(_.isNil(dialogResult))
                        return false;

                    if (dialogResult.hasChanges) {
                        let data = {
                            ordersID: self.orderId,
                            overrideFlag: dialogResult.override,
                            sellers: _.map(dialogResult.sellers, item => {
                                return {
                                    buyerSellerID: item.id,
                                    overridePercent: Number(item.percent).toFixed(self.percentPrecision), // correlate with the percent precision
                                    overrideAmount: item.amount
                                };
                            })
                        };

                        let changes = self.getAuditChanges(original, data);
                        let saveRequest = { changes, data };
                        let apiPromise = self.$api.OrdersApi.updateSellerProceeds(saveRequest);
                        self.$rqBusy.wait(apiPromise)
                            .then(result => {
                                self.$toast.success({ message: '1099 Distribution Updated' });
                                self.fetchData();
                            })
                            .catch(errorInfo => {
                                self.$toast.error({ message: 'Error while updating 1099 Distribution' });
                            });
                    }
                };
                self.$dialog.open({
                    title: "Seller 1099 Distribution",
                    height: 500,
                    width: 1000,
                    draggable: false,
                    scrollable: true,
                    component: AllocationDistribution,
                    args: { override: self.override1099Proceeds, items: sellers },
                    onOk: okHandler
                });

                //self.allocationDistributionArgs = { override: self.override1099Proceeds, items: sellers };
                //self.$root.$emit('bv::show::modal','allocation-dist-modal');
            },

            getFirst (items) {return _.find(items, { sequence: _.min(_.map(items, "sequence")) });},
            getLast (items) {return _.find(items, { sequence: _.max(_.map(items, "sequence")) });},
            getCurrentRelationshipItem() {
                let rtId = _.get(this, "buyerSeller.buyerSellerRelationshipID", null);
                return rtId ? this.lookupHelpers.getLookupItem(this.lookupItems.BUYER_SELLER_RELATIONSHIP_TYPES, rtId) : null;
            },
            updateValidationSummary (isValid, errorMsg) {
                if (isValid) {_.pull(this.validationErrors, errorMsg);} else if (!_.includes(this.validationErrors, errorMsg)) {this.validationErrors.push(errorMsg);}
                // force the validation summary to reset
                if (this.validationErrors.length === 0) {this.validationErrors = [];}
            },
            touch(){
                const self = this;
                self.v$.$touch();
                _.invoke(self, "$refs.refName1ContactDetail.touch");
                _.invoke(self, "$refs.refName2ContactDetail.touch");
                _.invoke(self, "$refs.refPrimaryAddress.touch");
            },
            reset(){
                const self = this;
                self.showBanner = false;
                _.invoke(self, "v$.$reset");
                _.invoke(self, "$refs.refName1ContactDetail.reset");
                _.invoke(self, "$refs.refName2ContactDetail.reset");
                _.invoke(self, "$refs.refPrimaryAddress.reset");
            },
            onCustomDataLoaded(e) {
                this.originalCustomData = _.cloneDeep(e);
            },
            onTaxIdVerificationError(errorMessage, saveArgs) {
                const self = this;
                let errorJson = JSON.parse(errorMessage);

                let ok = function () {
                    _.each(errorJson, err => {
                        if(err.nameIndex == "1" || err.nameIndex == "3") {
                            self.buyerSeller.taxIdVerificationDocumentId1 = err.documentId;
                            self.buyerSeller.taxIdVerificationStatus1 = err.status;
                        }
                        else {
                            self.buyerSeller.taxIdVerificationDocumentId2 = err.documentId;
                            self.buyerSeller.taxIdVerificationStatus2 = err.status;
                        }
                    });

                    let success = self.save(saveArgs);
                    GlobalEventManager.saveCompleted({ success });
                };

                let cancel = function () {
                     _.each(errorJson, err => {
                        if(err.nameIndex == "1" || err.nameIndex == "3") {
                            _.invoke(self, "$refs.refName1ContactDetail.setTaxIdVerificationError");
                        }
                        else {
                            _.invoke(self, "$refs.refName2ContactDetail.setTaxIdVerificationError");
                        }
                    });
                };

                self.$dialog.confirm("Confirm", `The SSN/Tax ID could not be verified. Do you want to proceed?`, ok, cancel, { cancelTitle: 'No', okTitle: 'Yes'});
            }
        }

    };
</script>

<style>
    .buyer-seller-notes { min-height: 300px; }
    .form-group-action {display: block  }
    .form-group-action button.btn.btn-link.btn-theme { padding: 0 5px; font-size:0.75rem; text-decoration: underline }
</style>
