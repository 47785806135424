<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner"
        />
        <rqdx-action-data-grid
            ref="dataGrid"
            title="Recording Documents"
            automation_id="tbl_recording_docs"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            @delete="onDelete"
            hide-show-column-chooser
            integrated-search
            rq-editable
            :hide-clear-filters="true"
        >
        <template #toolbar>
            <ul class="nav">
                <li class="nav-item">
                    <button type="button" class="btn btn-primary" @click="onAdd">Add</button>
                </li>
                <li v-if="multipleDocuments" 
                    class="nav-item"
                    v-rq-tooltip.hover :title="hasDocumentChanges ? 'Save or cancel your changes to enable' : ''">
                    <b-btn 
                        automation_id="btn_reorder_documents" 
                        variant="theme" 
                        :disabled="readOnly || hasDocumentChanges" 
                        @click="onReorderDocuments">
                        Reorder Documents
                    </b-btn>
                </li>
            </ul>
        </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted, onUnmounted } from "vue";
    import { useRecordingStore } from "@/store/modules/recording-documents";
    import { useRqBusy, useRqToast, useRqDialog } from "@/shared/plugins/composables";
    import { GlobalEventManager } from "@/app.events";
    import { useStore } from "vuex";
    import { useRoute } from "vue-router";
    import { SortableList } from "@/shared/components/rq";
import { onBeforeRouteLeave } from "vue-router";


    const recordingStore = useRecordingStore();
    const { waitFor } = useRqBusy();
    const { toastSuccess, toastError, toastInfo } = useRqToast();
    const { showConfirm, openDialog } = useRqDialog();

    const showBanner = ref(false);

    const route = useRoute();
    const store = useStore();
    const dataGrid = ref(null);
    const recordingStatuses = computed(() => store.getters.lookupHelpers.getLookupItems(store.getters.lookupItems.RECORDING_STATUSES));
    const recordingDocumentTypes = computed(() => store.getters.lookupHelpers.getAllLookupItems(store.getters.lookupItems.RECORDING_DOCUMENT_TYPES));
    const recordingTypes = computed(() => _.filter(store.getters.lookupHelpers.getAllLookupItems(store.getters.lookupItems.RECORDING_TYPES), item => item.inactive != true));
    const recordingRejectionReasons = computed(() => store.getters.lookupHelpers.getAllLookupItems(store.getters.lookupItems.RECORDING_REJECTION_REASONS));
    const readOnly = computed(() => _.parseBool(store.state.isPageReadOnly));
    const multipleDocuments = computed(() => { return recordingStore.recordingDocuments.length > 1 });
    const hasDocumentChanges = computed(() => recordingStore.hasChanges);

    const gridConfig = ref({
        keyExpr: "recordingInformationID",
        onEditorPreparing: prepareEditor,
        columns: [
            { dataField: "recordingInformationOrder", dataType: "number",caption: "No.", width:50, sortIndex: 0, sortOrder: "asc", allowEditing: false },
            {
                dataField: "recordingDocumentStatus",  
                caption: "Recording Document Status",
                dataType: "number",
                lookup: {
                    dataSource: recordingStatuses,
                    displayExpr: "name",
                    valueExpr: "id"
                }
            },
            {
                dataField: "documentType",  
                caption: "Document Type",
                dataType: "number",
                lookup: {
                    dataSource: recordingDocumentTypes,
                    displayExpr: "name",
                    valueExpr: "id"
                }
            },
            {
                dataField: "description",
                dataType: "string",
            },
            {
                dataField: "submittedDate",
                caption: "Submitted Date",
                dataType: "date"  
            },
            {
                dataField: "recordingDate",
                caption: "Recorded Date",
                dataType: "date"
            },
            {
                dataField: "liber",
                dataType: "string"
            },
            {
                dataField: "volume",
                dataType: "string"
            },
            {
                dataField: "book",
                dataType: "string"
            },
            {
                dataField: "page",
                dataType: "string"
            },
            {
                dataField: "instrumentNumber",
                caption: "Instrument No.",
                dataType: "string",
            },
            {
                dataField: "recordingType",  
                caption: "Recording Type",
                dataType: "number",
                lookup: {
                    dataSource: recordingTypes,
                    displayExpr: "name",
                    valueExpr: "id"
                }
            },
            {
                dataField: "amount",  
                caption: "Collected Fee",
                allowEditing: false,
                dataType: "number",
                format: {
                    type: "currency",
                    precision: 2
                },
            },
            {
                dataField: "recordingFee",  
                caption: "Actual Fee",
                dataType: "number",
                format: {
                    type: "currency",
                    precision: 2
                },
            },
            {
                dataField: "feeDifference",  
                caption: "Fee Difference",
                allowEditing: false,
                dataType: "number",
                format: {
                    type: "currency",
                    precision: 2
                },
            },
            {
                dataField: "rejectedDate",
                caption: "Rejected Date",
                dataType: "date",
                setCellValue(rowData, value) {
                    rowData.rejectedDate = value;
                    if(_.isNil(rowData.rejectedDate)){
                        rowData.recordingRejectionID = null;
                        rowData.rejectedClearedDate = null;
                    }
                }  
            },
            {
                dataField: "recordingRejectionID",  
                caption: "Rejected Reason",
                dataType: "number",
                lookup: {
                    dataSource(options) {
                        let rejReasons = recordingRejectionReasons.value;
                        if(_.isEmpty(options?.data)) return rejReasons;
                        let selectedValue = options?.data.recordingRejectionID;
                        let filteredResults = _.filter(rejReasons, item => item != null && (item.inactive !== true || item.recordingRejectionID == selectedValue));
                        return filteredResults;
                    },
                    displayExpr: "name",
                    valueExpr: "id"
                }
            },
            {
                dataField: "rejectedClearedDate",
                caption: "Rejected Cleared Date",
                dataType: "date"  
            },
            { type: "buttons", visible: false, showInColumnChooser: false }
        ]
    });
    const gridDataSource = ref(
        {
            key: "clientKey",
            load (loadOptions) {
                return Promise.resolve(recordingStore.recordingDocuments);
            },
            insert: onGridInsert,
            update: onGridUpdate
        });
    const selectionActions = computed(() => [
            {
                name: "delete",
                text: "Delete",
                eventName: "delete",
                requireSelection: true,
                allowMultiSelection: true,
                tooltip: `Delete Recording Document(s)`
            }
        ]);

    async function fetchData() {
        try {
            let storePromise = recordingStore.getDocuments(true);
            await waitFor(storePromise);
            refreshGrid();
        }
        catch(err) {
            toastError("An issue occurred while retrieving recording documents.");
            console.error(err);
        }
    }

    function onAdd() {
        dataGrid.value?.gridInstance?.addRow();
    }

    function onDelete(e) {
        if(!e || !e.data) return;
        let deletedItems = e.data;
        _.forEach(deletedItems, (item) => {
            recordingStore.removeDocument(item.clientKey);
        });
        refreshGrid();
    }

     async function onGridInsert(values) {
        recordingStore.addDocument(values);
    }

    async function onGridUpdate(key, values) {
        let itemIndex = _.findIndex(recordingStore.recordingDocuments, item => item.clientKey === key);
        if(itemIndex < 0) return onGridInsert(values);

        let originalItem = _.cloneDeep(recordingStore.recordingDocuments[itemIndex]);
        let updatedItem = _.assign(recordingStore.recordingDocuments[itemIndex], values);
    }

    async function onSave() {
        if(!recordingStore.hasChanges) {
            toastInfo("No changes detected.");
            GlobalEventManager.saveCompleted({ success: true });
            return;
        }
        let storePromise = recordingStore.saveDocuments();
        await waitFor(storePromise);
        GlobalEventManager.saveCompleted({success: true});
        refreshGrid();
        toastSuccess("Recording documents saved.")
    }

    function onCancel() {
        if(!recordingStore.hasChanges) {
            toastInfo("No changes detected.");
            return;
        }
        const onOk = () => {
            recordingStore.cancelChanges();
            refreshGrid();
            return true;
        };
        showConfirm("Confirm Cancel", "Are you sure you want to discard your current changes?", onOk);
    }

    function refreshGrid() {
        if (dataGrid.value) {
            dataGrid.value.gridInstance.refresh(); 
        }
    }

    async function onReorderDocuments() {
        if(readOnly.value) return;
        let orderId = recordingStore.currentOrderId;
        let items = _.map(recordingStore.recordingDocuments, r => {
            return {
                id: r.id,
                description: r.description,
                ordinal: r.recordingInformationOrder
            };
        });
        
        openDialog({
            title: "Reorder Recording Documents",
            width: 600,
            adaptive: true,
            component: SortableList,
            props: { orderId, items},
            onOk (e)  {
                let changes = e.component.getChanges();
                if(_.isEmpty(changes)) return true;
                let request = { orderId, changes };
                waitFor(recordingStore.reorderDocuments(request))
                    .then(() => fetchData());
            }
        });
    }

    function prepareEditor(e) {
        if(e.type === "selection" || e.row.rowType !== "data") return;

        e.editorOptions.disabled = isColumnDisabled(e.dataField, e.row.data);
    }
    
    function isColumnDisabled(dataField, data) {
        if (readOnly.value) return true;
        // Stub out for any future logic for disabling columns
        var rejectedDate = data?.rejectedDate;
        if(dataField === "recordingRejectionID" && _.isNil(rejectedDate)) return true;
        if(dataField === "rejectedClearedDate" && _.isNil(rejectedDate)) return true;

        return false;
    }

    fetchData();

    const EVENT_SOURCE = "file-recording-docs"
    onMounted(() => {
        GlobalEventManager.onSave(EVENT_SOURCE, onSave);
        GlobalEventManager.onCancel(EVENT_SOURCE, onCancel);
    });
    onUnmounted(() => {
        GlobalEventManager.unregister(EVENT_SOURCE);
    });

</script>