
import { ORDER_ACTIONS, PROPERTY_ACTIONS, SET_PAGE } from "@/store/actions";
import { ROOT_MUTATIONS } from "@/store/mutations";
// import { UserScreenAccessLevel } from "../../shared/models/enums";
import Store from "@/store";

// const setConsolidatedFileAccess = () => {
//     let isConsolidatedFile = _.getBool(Store, "state.orders.orderSummary.isConsolidatedFile", 0);
//     if(!isConsolidatedFile) return;
//     Store.commit(ROOT_MUTATIONS.SET_PAGE_READ_ONLY, true);
//     Store.commit(ROOT_MUTATIONS.SET_PAGE_ACCESS_LEVEL, { key: "isConsolidatedFile", value: UserScreenAccessLevel.Read });
// };

let orderBeforeEach = function (to, from) {
    return new Promise(resolve => {

        let getOrder = (orderId, routeTo) => {
            //RQO-16774: setting refresh to true, always pulling order data to check for file in use
            // Store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, { orderId: orderId, refresh: true })
            let refresh = !_.includes(from?.path, "order") || to?.name == "oe:main";

            let refreshProperties =  _.includes(to?.name, "oe:op");
            
            if(!refresh && refreshProperties){
                Store.dispatch(PROPERTY_ACTIONS.GET_PROPERTIES, false);
            }

            Store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, { orderId, refresh })
                .then(resultId => {
                    // setConsolidatedFileAccess();
                    if (_.gt(resultId, -2)) {
                        if(_.isNil(routeTo) || _.getNumber(routeTo, "params.orderId") === resultId) {
                            resolve(true);
                        }
                        else {
                            routeTo.params.orderId = resultId;
                            resolve(routeTo);
                        }
                        Store.dispatch(SET_PAGE, to);
                    }
                    else {
                        if(_.isNull(from.name) || from.name === "Login")
                            resolve({ name:"home" });
                        else
                            resolve(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                    let message = error.errorMessage || "A problem occurred while retrieving the requested order information";
                    Store.commit(ROOT_MUTATIONS.SET_ERROR, `INVALID_ORDER::${message}`);
                    resolve({ name:"home" });
                });
        };

        const lastOrderId = _.parseNumber(Store.getters.lastOrderId, 0);

        if (!to.params.orderId) {
            let routeTo = _.pick(to, ["name", "params", "query", "hash"]);
            if (lastOrderId !== 0 ) {
                getOrder(lastOrderId, routeTo);
            } else {
                Store.commit(ROOT_MUTATIONS.SET_ERROR, `NO_LAST_ORDER::Please select an file from "Recent Files" or search for one by clicking the search icon at the top of the page.`);
                resolve({ name:"home" });
            }
        } else {
            getOrder(to.params.orderId);
        }

    });
};

export default orderBeforeEach;