<template>
    <div class="rq-master-detail-container">
        <section @click="onGridClick">
            <rqdx-action-data-grid
                ref="dataGrid"
                :automation_id="automationId"
                :actions="selectionActions"
                :config="gridConfig"
                :read-only="readOnly"
                class="rq-tab-content-grid"
                :data-source="gridDataSource"
                @delete="onDeleteDeposit"
                @edit-deposit="onEditDeposit"
                @rowDoubleClick="onEditDeposit"
                @print-deposit="onPrintDeposit"
                @investment-transfer="onTransferToInvestment"
                focus-after-insert="first-row"
                export-file-name="check_writing_deposits_data"
                integrated-search
                rq-filters
            />
        </section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DateTime } from "luxon";
    import { ORDER_ACTIONS } from '@/store/actions';
    import AddEditDepositForm from "./AddEditDepositForm.vue";
    import InvestmentAccountTransferForm from "./InvestmentAccountTransferForm.vue";
    import { CheckShortDto, CheckLineDto, DepositShortDto, DepositLineDto, InvestmentDepositRequestDto }  from "../models";
    import { DepositStatus, FileBalanceStatus, FundOption } from '../enums';
    import { DepositEditingOptions } from '../../../configuration/enums';
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    // import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    const FINALIZED_RECON_WARNING = "<div class='alert alert-danger mt-1'><svg class='rq-icon-symbol-lg alert-icon pe-1'><use href='#rq-fas-exclamation-triangle'></use></svg>One or more Item is dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.</div>";

    export default {
        name:"CheckWritingDepositList",
        mixins: [GridSystemLookupMixin],
        props: {
            bank: { type: Object, required: true, default: () => {} },
            summary: { type: Object, required: true, default: () => {} },
            items: { type: Array, required: true, default: () => [] },
            itemDetail: { type: Array, required: true, default: () => [] },
            investments: { type: Array, default: () => [] },
            depositLineLookups: { type: Array, required: false, default: () => [] },
        },
        data () {
            return {
                errorMessage: "",
                selectionActions: [],
                popover: { target: null, isCheck: false, item: {}, itemDetail: [], visible: false }
            };
        },

        watch: {
            bank:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                }
            },
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
            errorMessage(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                this.$emit("update-error-message", this.errorMessage);
            },
        },

        created() {
            this.$events.on("edit-deposit", this.onEditDeposit);
            this.$events.on("order-summary::collapse-toggled", () => this.updateDimensions());
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        beforeUnmount () {
            this.$events.off("edit-deposit");
            this.$events.off("order-summary::collapse-toggled");
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked) || _.parseBool(state.orders.orderSummary.isEscrowLocked),
                isConsolidatedFile: state => _.parseBool(state.orders.orderSummary.isConsolidatedFile),
                order: state => state.orders.order,
                systemDefaults: state => state.system.systemDefaults,
                user: state => state.authentication.session.user
            }),
            automationId() {return this.isConsolidatedFile ? 'tbl_consolidated_deposits' : 'tbl_deposits'},
            filteredItems() { return _.isEqual(this.bank.companyID, 0) ? _.clone(this.items) : _.filter(_.clone(this.items), ["bankCompanyID", this.bank.companyID]); },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            readOnly() { return this.isReadOnly || this.isFileLocked || _.getBool(this, "summary.readOnly", false); },
            isFileBalanced() { return _.get(this.summary, "balanceStatus", -1) != FileBalanceStatus.NotBalanced; },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowDepositDelete",
                    "AllowFinalizedReconModifications",
                    "AllowTransferToInvestmentAccount",
                    "AllowWires",
                    "CanDeleteBookedDeposits",
                    "DepositChangeOption",
                    "DepositPreDateDays",
                    "DepositPostDateDays",
                    "IncludeAdjustmentTypeFunds",
                    ]);
            },
            showExpectedWireDates() { return _.parseBool(_.get(this, 'systemDefaults.showExpectedWireDates', false), false); },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },

            initGridConfig(){
                const self = this;
                let defaultSort = _.parseBool(self.systemDefaults.receiptsAndDisbursementsSortedByDate, false) ? {sortIndex: 0, sortOrder: "asc"} : {};
                self.gridConfig = {
                    allowColumnReordering: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "originalGFNo",
                            dataType: "string",
                            caption: "Original File#",
                            visible: self.isConsolidatedFile,
                            showInColumnChooser: self.isConsolidatedFile,
                        },
                        {
                            dataField: "depositDate",
                            dataType: "date",
                            caption: "Deposit Date",
                            width: 160,
                            ...defaultSort
                        },
                        {
                            dataField: "wireNumber",
                            caption: "Wire #",
                        },
                        {
                            dataField: "expectedWireDate",
                            dataType: "date",
                            caption: "Expected Wire Date",
                            width: 160,
                            editorOptions: {
                                showClearButton: true
                            },
                            visible: self.showExpectedWireDates,
                            showInColumnChooser: self.showExpectedWireDate,
                        },
                        {
                            dataField: "payor",
                            caption: "Payor",
                            minWidth: 175
                        },
                        {
                            dataField: "description",
                            cellTemplate: DxGridUtils.textPlusCountCellTemplate({
                                idAppend: "deposit-popup-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updatePopover(cellInfo.data, e.target);
                                        e.stopPropagation();
                                    },
                                }
                            })
                        },
                        {
                            dataField: "numberDisplay",
                            caption: "Number",
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false,
                        },
                        {
                            dataField: "amount",
                            caption: "Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "typeFundID",
                                dataType: "number",
                                caption: "Type of Funds"
                            },
                            lookupKey: self.lookupItems.TYPE_FUNDS
                        }),
                        // {
                        //     dataField: "typeOfFundsDisplay",
                        //     caption: "Type of Funds",
                        // },
                        {
                            dataField: "depositStatus",
                            lookup: {
                                dataSource: self.statuses,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            visible: false,
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "receiptOfDepositID",
                            caption: "Receipt ID",
                            visible: false,
                        },
                        {
                            dataField: "reconciliationDate",
                            dataType: "date",
                            caption: "Recon Date",
                            cellTemplate: DxGridUtils.reconDateCellTemplate
                        },
                        {
                            dataField: "referenceNumber",
                            visible: false,
                        },
                        {
                            dataField: "bankCompanyID",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            visible: false,
                        },
                        {
                            dataField: "memo",
                            visible: false,
                            showInColumnChooser: false
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "PostedTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "PostedTotalLabel",
                                column: "payor",
                                alignment: "left",
                                cssClass: "rq-summary-label",
                                displayFormat: "POSTED",
                                summaryType: "sum"
                            },
                            {
                                name: "AnticipatedTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "AnticipatedTotalLabel",
                                column: "payor",
                                alignment: "left",
                                cssClass: "rq-summary-label",
                                displayFormat: "ANTICIPATED",
                                summaryType: "sum"
                            },
                            {
                                name: "DepositTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "DepositTotalLabel",
                                column: "payor",
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                        ],
                        calculateCustomSummary(options) {
                            if (options.name == "AnticipatedTotal") {
                                options.totalValue = self.summary.anticipatedDeposits;
                            } else if (options.name == "PostedTotal") {
                                options.totalValue = self.summary.receipts;
                            } else {
                                options.totalValue = self.summary.anticipatedDeposits + self.summary.receipts;
                            }
                        }
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.filteredItems);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Receipt";
                self.itemTypeNamePlural = "Receipts";
                self.itemKey = "depositID";
                self.statuses = DepositStatus.lookupItems;
                self.fundTypes = self.lookupHelpers.getLookupItems(self.lookupItems.TYPE_FUNDS);
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_ACCOUNTS);
                if (!self.localSecurity.AllowWires) {
                    let wireOption = _.find(self.fundTypes, ["id", FundOption.Wire]);
                    if (wireOption) wireOption.disabled = true;
                }
                if (!self.localSecurity.IncludeAdjustmentTypeFunds) {
                    let adj = _.find(self.fundTypes, ["id", FundOption.Adjustment]);
                    if (adj) adj.disabled = true;
                }
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit-deposit",
                        requireSelection: true,
                        tooltip: `Edit Receipt`,
                        disabled: function(e) {
                            return (self.readOnly || self.localSecurity.DepositChangeOption === DepositEditingOptions.NoChange) ? 'Access Restricted' : false;
                        }
                    },
                    {
                        name: "transfer",
                        text: "Transfer",
                        eventName: "investment-transfer",
                        requireSelection: true,
                        tooltip: `Transfer ${self.itemTypeName} to an Investment Account`,
                        disabled: function(e) {
                            return self.transferDisabled(e);
                        }
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) {
                            return self.deleteDisabled(e);
                        }
                    },
                    {
                        name: "print",
                        text: "Print",
                        eventName: "print-deposit",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Print ${self.itemTypeName}`,
                        disabled: function(e) {
                            return self.printDisabled(e);
                        }
                    }
                ];
            },

            depositDateLocked(c) {
                const self = this;
                //if a user is not allowed to alter finalized recons then if the check date falls before the last recon date then it's locked
                return !_.isNil(c.depositDate) && !this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(c.depositDate).diff(DateTime.fromISO(self.order.lastFinalizedReconDate), "days").days <= 0
            },

            transferDisabled(e){
                const self = this;
                if (self.depositDateLocked(e.data)) return "Access Restricted. One or more of the items selected is on a finalized recon.";
                if (!self.localSecurity.AllowTransferToInvestmentAccount || self.readOnly) {
                    return "Access Restricted";
                }
                if (_.lte(self.summary.fileBalance, 0)) {
                    return "File Balance must be greater than 0.";
                }
                return  _.get(e.data, "typeFundID", 0) == FundOption.Transfer;
            },

            deleteDisabled(e){
                const self = this;
                if (_.some(e.data, c => self.depositDateLocked(c))) return "Access Restricted. One or more of the items selected is on a finalized recon.";
                if ((self.readOnly
                    || (!self.localSecurity.AllowDepositDelete && _.some(e.data, x => x.depositStatus === DepositStatus.Reconciled || x.depositStatus === DepositStatus.Anticipated))
                    || (!self.localSecurity.CanDeleteBookedDeposits && _.some(e.data, ["depositStatus", DepositStatus.Booked])))) {
                    return "Access Restricted";
                }
                // if (!self.localSecurity.CanDeleteBookedDeposits && _.some(e.data, ["depositStatus", DepositStatus.Booked])) {
                //     return "Insufficient Privileges to Delete Booked Deposits.";
                // }
                else if (_.some(e.data, ["isVerified", true])) {
                    return "One or more of the selected Deposits are Verified and cannot be deleted.";
                }
                else if (_.some(e.data, ["isReconciled", true])) {
                    return "One or more of the selected Deposits are Reconciled and cannot be deleted.";
                }
                else
                    return false;
            },

            printDisabled(e){
                const self = this;
                //RQO-18532 - allow printing while RO only if they all already have receipt IDs
                let allHaveReciptID = _.every(e.data, i => !_.isNullOrEmpty(i.receiptID));
                return (self.readOnly && allHaveReciptID) ? false : self.readOnly;
            },

            onGridClick(e) {
                //used as a click off for the check popover
                const self = this;
                if (_.getBool(self, "popover.visible", false)) {
                    self.popover.visible = false;
                    self.$events.$emit("show-popover", self.popover);
                }
            },

            onPrintDeposit(e){
                if(!e || !e.data) return;
                const self = this;

                let printDisabled = self.printDisabled(e);
                if(printDisabled) return;

                let selectedItems = e.data;
                let allHaveReciptID = _.every(selectedItems, i => !_.isNullOrEmpty(i.receiptID));
                //RQO-18532 - allow printing while RO only if they all already have receipt IDs
                if (self.readOnly && !allHaveReciptID) return;
                self.$emit("print-receipt-of-deposit", selectedItems);
            },

            onDeleteDeposit(e) {
                if(!e || !e.data) return;
                const self = this;
                let deleteDisabled = self.deleteDisabled(e);
                if(deleteDisabled) return;
                let items = e.data;
                let dateWarning = "";
                if (_.some(e.data, c => self.depositDateLocked(c))) {
                    dateWarning = FINALIZED_RECON_WARNING;
                }
                let depositIDs = _.map(items, "depositID");
                let itemLines = _.filter(self.itemDetail, d => _.includes(depositIDs, d.depositID));
                let hasEarnestMoney = _.some(itemLines, ['description', 'Earnest Money']);
                let ok = function (args) {
                    let ids = _.map(items, self.itemKey);

                    let apiPromise = self.$api.CheckWritingApi.deleteDeposits(self.order.ordersID, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(data => {
                            self.$emit("update-data", data);
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return true;
                        })
                        .finally(() => {
                            if (hasEarnestMoney) self.$store.dispatch(ORDER_ACTIONS.GET_ORDER, { orderId: self.order.ordersID, refresh: true });//deleting earnest money affects the order, so refresh
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?${dateWarning}`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditDeposit(e) {
                if(!e || !e.data) return;
                const self = this;
                if(self.readOnly || (self.localSecurity.DepositChangeOption === DepositEditingOptions.NoChange && e.data.depositID !== 0)) return;
                self.errorMessage = "";
                let depositID = _.parseNumber(e.data.depositID, 0);
                let deposit = {};
                let depositLines = [];
                if (depositID === 0)
                    deposit = new DepositShortDto(e.data);
                else if (self.localSecurity.DepositChangeOption === DepositEditingOptions.NoChange)
                    return;
                else {
                    deposit = _.find(self.items, [self.itemKey, depositID]);
                    depositLines = _.filter(self.itemDetail, [self.itemKey, depositID]);
                }
                self.showEditDepositDialog(deposit, depositLines);
            },

            onTransferToInvestment(e) {
                if(!e || !e.data) return;
                let transferDisabled = this.transferDisabled(e);
                if(transferDisabled) return;
                this.showInvestmentTransferDialog(e.data);
            },

            refresh(clear=true) {
                if(!this.gridInstance) return;
                if (clear) {
                    this.clear();
                }
                this.gridInstance.option("pager.visible", this.items.length > 50);
                this.gridInstance.refresh();
                this.updateDimensions("master");
            },

            setData(data){
                const self = this;
                let deposits = _.clone(self.items);
                let depositLines = _.clone(self.itemDetail);
                let itemIndex = _.findIndex(deposits, [self.itemKey, _.get(data.deposits[0], self.itemKey)]);
                if (itemIndex === -1) { //add deposit
                    deposits.push(new DepositShortDto(data.deposits[0]));
                } else {                //update deposit
                    _.assign(deposits[itemIndex], new DepositShortDto(data.deposits[0]));
                    _.pullAllBy(depositLines, [{depositID: data.deposits[0].depositID}], self.itemKey);
                }
                _.each(data.depositLines, dl => {
                    depositLines.push(new DepositLineDto(dl));
                });
                let updateData = {deposits, depositLines, investments: data.investments, orderSummary: data.orderSummary};
                self.$emit("update-data", updateData);
            },

            showEditDepositDialog(deposit, depositLines) {
                const self = this;
                let onOk = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    return form.save()
                            .then(data => {
                                self.setData(data);
                                return true;
                            }).catch(e => {
                                form.errorMessage = e.errorMessage;
                                return false;
                            })
                            .finally(() => {
                                if (form.refreshOrder) self.$store.dispatch(ORDER_ACTIONS.GET_ORDER, { orderId: self.order.ordersID, refresh: true });
                                self.refresh();
                            });
                };
                let onCancel = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    if (form.detailUpdates) {
                        let data = {deposits: [form.item], depositLines: form.itemDetail, orderSummary: form.ledgerSummary};
                        self.setData(data);
                    }
                    self.refresh(false);
                    return true;
                };
                let payor = _.isNil(deposit.payor) ? '(Blank)' : _.size(deposit.payor) > 44 ? `${deposit.payor.substring(0, 44)}...` : deposit.payor;
                let title = _.isEqual(deposit.depositID, 0) ? `Add Receipt` : `Edit Receipt: ${payor} - ${self.formatMoney(deposit.amount)}`;
                self.$dialog.open({
                    title: title,
                    width: "950",
                    adaptive: true,
                    closeOnEsc: false,
                    component: AddEditDepositForm,
                    onOk: onOk,
                    onCancel: onCancel,
                    okTitle: "Save",
                    props: {
                        deposit: deposit,
                        depositLines: depositLines,
                        depositLineLookups: self.depositLineLookups,
                        bank: self.bank,
                        summary: self.summary
                    }
                });
            },

            showInvestmentTransferDialog(item) {
                const self = this;
                let investmentID = self.investments.length >= 1 ? self.investments[0].investmentID : 0;
                let investmentAccounts = [];
                investmentAccounts.push({investmentID: 0, bankName: '...Create New Account', accountNumber: 0});
                _.each(self.investments, investment => {
                    investmentAccounts.push({investmentID: investment.investmentID, bankName: investment.bankName, accountNumber: investment.accountNumber});
                });
                let dto = new InvestmentDepositRequestDto({amount: item.amount, investmentID: investmentID, ordersID: self.order.ordersID, useCheck: false, depositID: item.depositID});
                let onOk = (e) => {
                    let form = e.component;
                    let investment = _.find(self.investments, ["investmentID", dto.investmentID]) || {};
                    let toastBank = form.item.bankName != null ? form.item.bankName : investment.bankName;
                    return form.save()
                        .then(result => {
                            self.setData(result);
                            self.$emit("add-check", {check: new CheckShortDto(result.checks[0]), checkLine: new CheckLineDto(result.checkLines[0]), autoPrint: form.item.useCheck});
                            self.$toast.success({ message: `Transferred Deposit to ${toastBank}.` });
                            return true;
                        })
                        .catch(error => {
                            form.errorMessage = error.errorMessage;
                            return false;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                };
                let title = _.truncate(self.investments.length >= 1 ? `Transfer to ${self.investments[0].bankName}` : "Transfer to New Investment Account", {'length': 50});
                self.$dialog.open({
                    title: title,
                    width: "650",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    closeOnEsc: true,
                    component: InvestmentAccountTransferForm,
                    props: {
                        item: dto,
                        investmentAccounts: investmentAccounts,
                        fileBalance: self.summary.fileBalance
                    },
                    onOk: onOk,
                    okTitle: "Save"
                });
            },

            updateDimensions(gridName=null) {
                this.$nextTick(() => {
                    if(_.isNil(gridName) || gridName === "master")
                        _.invoke(this, "gridInstance.updateDimensions");
                });
            },

            updatePopover(item=null, target=null) {
                const self = this;
                let newID = _.get(target, "id") || null;
                let lastID = _.get(self.popover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        self.popover.visible = false;
                        self.$events.$emit("show-popover", self.popover);
                    }
                    let itemDetail = _.filter(self.itemDetail, [self.itemKey, item.depositID]) || [];
                    self.popover.target = newID;
                    self.popover.item = item;
                    self.popover.itemDetail = itemDetail;
                    self.popover.visible = true;
                    self.popover.isCheck = false;
                } else {
                    self.popover.visible = !self.popover.visible;
                }
                self.$nextTick(() => {
                    self.$events.$emit("show-popover", self.popover);
                });
            },
        }
    }
</script>
<style lang="scss">
.rq-tab-content-grid-wrapper {
    padding-top: 5px
}
</style>