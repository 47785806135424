<template>
    <div class="content-wrapper payoff-detail">
        <rq-banner
            variant="error"
            message="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showInvalidBanner"/>

        <rq-page-section :title="sectionTitle" header-size="lg">
            <template #header-actions>
                <div class="header-container">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-3">
                            <ul class="nav">
                                <li class="nav-item">
                                    <button automation_id="btn_add_payoff"
                                        type="button"
                                        class="btn btn-default btn-theme"
                                        v-if="!addMode && !isDisplayModal"
                                        @click="onAddPayoffClicked"
                                        :disabled="readOnly">Add
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button automation_id="btn_calculate"
                                        type="button"
                                        class="btn btn-default btn-theme"
                                        @click="onCalculate"
                                        :disabled="readOnly">Calculate
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="row">
                                <div class="col-3">DO NOT MAP TO:</div>
                                <div class="col-auto">
                                    <b-form-checkbox automation_id="chk_pc_email_events" v-model="payoff.doNotMapCDF" :disabled="readOnly">
                                        CDF
                                    </b-form-checkbox>
                                </div>
                                <div class="col-auto">
                                    <b-form-checkbox automation_id="chk_pc_email_events" v-model="payoff.doNotMapHUD" :disabled="readOnly">
                                        HUD
                                    </b-form-checkbox>
                                </div>
                                <div class="col-auto">
                                    <b-form-checkbox automation_id="chk_pc_email_events" v-model="payoff.doNotMapCSS" :disabled="readOnly">
                                        CSS
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3">
                            <div v-if="showPayoffDropdown">
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_payoff', id: 'drp_payoff' }"
                                    :items="payoffDropdownData"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="selectedPayoffId"
                                    @value-changed="onPayoffDropdownChange"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #header-secondary>
                <router-link automation_id="btn_view_payoff_list"
                    v-if="!isDisplayModal"
                    :to="{ name:'settlement:payoffs:list', params: { loanId: loanId} }"
                    class="btn btn-link btn-theme">View Payoffs
                </router-link>
            </template>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="drp_payoff_type">Payoff Type</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_payoff_type', id: 'drp_payoff_type' }"
                        :items="[
                            { id:0, name:'Written Payoff from Lender' },
                            { id:1, name:'Detailed Payoff Calculator' }
                        ]"
                        value-expr="id"
                        display-expr="name"
                        v-model="payoff.payoffType"
                        @value-changed="onPayoffTypeChanged"
                        :disabled="readOnly"
                        />
                    </div>
                <div class="col-12 col-md-6 col-lg-3 form-group form-required" :class="{'has-error' : v$.payoff.description.$error }">
                    <label for="txt_description">Payoff Description</label>
                    <input id="txt_description"
                        automation_id="txt_description"
                        ref="payoffDescriptionField"
                        type="text"
                        class="form-control"
                        maxlength="40"
                        :disabled="readOnly"
                        v-model="v$.payoff.description.$model">
                        <rq-validation-feedback>Payoff Description is required</rq-validation-feedback>
                </div>

                <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group"
                    :class="{'has-error' : v$.payoff.principalBalance.$error, 'form-required': isPayoffBalanceRequired}"
                >
                    <label for="txt_payoff_amount">{{ payoffAmountLabel }}</label>
                    <rq-input-money
                        id="txt_payoff_amount"
                        automation_id="txt_payoff_amount"
                        v-model="v$.payoff.principalBalance.$model"
                        :disabled="readOnly" />
                    <rq-validation-feedback>Principal Balance cannot be negative</rq-validation-feedback>
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="pic_payee">Payee
                        <CompanyInfoPopover :company="selectedPayeeCompany" />
                    </label>
                    <company-picker
                        id="pic_payee"
                        automation_id="pic_payee"
                        ref="refPayee"
                        companyRoleName="Lender"
                        dialogTitle="Select a Payee"
                        :disabled="readOnly"
                        v-model="selectedPayeeCompany"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_loan_number">Payoff Loan Number</label>
                    <input id="txt_loan_number"
                        automation_id="txt_loan_number"
                        type="text"
                        class="form-control"
                        maxlength="20"
                        placeholder="Loan #"
                        v-model="payoff.loanNumber"
                        :disabled="readOnly">
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="drp_section">Section</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_section', id: 'drp_section' }"
                        :items="payoffSectionOptions"
                        value-expr="id"
                        display-expr="name"
                        v-model="payoff.section"
                        :disabled="!canEditSection || isWithOutSeller || readOnly"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <rq-action-form-group
                        label="Total Payoff Amount"
                        action-automation-id="btn_restore_default_total"
                        action-label="Restore"
                        :show-action="payoff.totalPayoffAmountOverride"
                        :class="{'overridden': payoff.totalPayoffAmountOverride}"
                        @action-click="overrideTotalPayout(false)">
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber
                                id="txt_total_payoff_amount"
                                automation_id="txt_total_payoff_amount"
                                v-model="payoff.payoutAmount"
                                :disabled="readOnly"
                                @change="overrideTotalPayout(true)"
                                decimals="2"
                            />
                        </div>
                    </rq-action-form-group>
                </div>

                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_loan">Loan</label>
                    <input automation_id="txt_loan"
                        id="txt_loan"
                        class="form-control"
                        type="text"
                        v-model="loanDescription"
                        disabled
                    />
                </div>
            </div>
        </rq-page-section>
        <rq-page-section title="Payoff Dates" header-size="lg">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group"
                    :class="{'has-error' : invalidFromDate }"
                >
                    <rq-action-form-group
                        label="Date From"
                        action-automation-id="btn_restore_default_to_date"
                        action-label="Revert"
                        :show-action="showDateFromRevert"
                        :class="{'overridden': showDateFromRevert, 'has-error': v$.payoff.interestFromDate.$error, 'form-required': isPayoffDatesRequired}"
                        @action-click="overrideFromDate()">
                        <rqdx-date-box
                            id="dtp_from_date"
                            v-model="v$.payoff.interestFromDate.$model"
                            :disabled="readOnly"
                        />
                        <rq-validation-feedback>Date From is required</rq-validation-feedback>
                    </rq-action-form-group>
                </div>
                <div class="col-12 col-md-6 col-lg-3" :class="{'has-error' : invalidThruDate }">
                    <rq-action-form-group
                        label="To Date"
                        action-automation-id="btn_restore_default_to_date"
                        action-label="Revert"
                        :show-action="showThruDateRevert"
                        :class="{'overridden': showThruDateRevert, 'has-error': v$.payoff.interestThruDate.$error, 'form-required': isPayoffDatesRequired}"
                        @action-click="overrideThruDate()">
                        <rqdx-date-box
                            id="dtp_thru_date"
                            v-model="v$.payoff.interestThruDate.$model"
                            :disabled="readOnly"
                        />
                        <rq-validation-feedback>To Date is required</rq-validation-feedback>
                    </rq-action-form-group>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <rq-action-form-group
                        label="Per Diem"
                        action-automation-id="btn_restore_default_per_diem"
                        action-label="Restore"
                        :show-action="isPerDiemOverridden"
                        :class="{'overridden': isPerDiemOverridden}"
                        @action-click="overridePerDiem(false)">
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber
                                id="txt_per_diem"
                                automation_id="txt_per_diem"
                                decimals="4"
                                v-model="payoff.perDiem"
                                maxValue="99999.9999"
                                @change="overridePerDiem(true)"
                                :disabled="readOnly"
                            />
                        </div>
                    </rq-action-form-group>
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_cushion_days">Cushion Days</label>
                    <rqInputNumber
                        id="txt_cushion_days"
                        placeholder="0"
                        defaultValue="0"
                        decimals="0"
                        v-model="payoff.cushionDays"
                        :disabled="readOnly"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="drp_interest_calculation">Interest Calculation</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_interest_calculation', id: 'drp_interest_calculation' }"
                        :items="[
                            { id:0, name:'365/366 Days' },
                            { id:1, name:'360 Days' }
                        ]"
                        value-expr="id"
                        display-expr="name"
                        v-model="payoff.daysInYearType"
                        :disabled="readOnly"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group" v-show="isDetailedPayoffCalculator">
                    <label for="txt_interest_rate">Interest Rate</label>
                    <rqInputNumber
                        id="txt_interest_rate"
                        defaultValue="0"
                        decimals="3"
                        minValue="0"
                        maxValue="100"
                        v-model="payoff.interestRate"
                        :disabled="readOnly"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3" v-show="isDetailedPayoffCalculator">
                    <rq-action-form-group
                        label="Interest Amount"
                        action-automation-id="btn_restore_default_interest"
                        action-label="Restore"
                        :show-action="payoff.ovrTotalInterest"
                        :class="{'overridden': payoff.ovrTotalInterest}"
                        @action-click="overrideTotalInterest(false)">
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber
                                id="txt_interest_amount"
                                defaultValue="0"
                                decimals="2"
                                v-model="payoff.totalInterest"
                                prefix="$"
                                @change="overrideTotalInterest(true)"
                                :disabled="readOnly"
                            />
                        </div>
                    </rq-action-form-group>
                </div>
            </div>
        </rq-page-section>
        <rq-page-section title="Settlement Information" header-size="lg">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="drp_poc_whom">POC</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_poc_whom', id: 'drp_poc_whom' }"
                            :items="pocWhoIntOptions"
                            value-expr="id"
                            display-expr="name"
                            v-model="payoff.pocWhom"
                            @value-changed="onPOCWhomChanged"
                            :disabled="readOnly"
                        />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_poc_amount">POC Amount</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rqInputNumber id="txt_poc_amount" automation_id="txt_poc_amount" v-model="payoff.amountPOC"
                            :disabled="readOnly || !canSetPocAmount" decimals="2" />
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="drp_net_fund">Net Fund</label>
                    <dx-select-box
                            :input-attr="{ automation_id: 'drp_net_fund', id: 'drp_net_fund' }"
                            :items="netFundOptions"
                            value-expr="id"
                            display-expr="name"
                            v-model="payoff.netFund"
                            @value-changed="onNetFundChanged"
                            :disabled="readOnly"
                        />
                </div>
                <!-- <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_net_fund_amount">Net Fund Amount</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rqInputNumber id="txt_net_fund_amount" automation_id="txt_net_fund_amount" v-model="payoff.netAmount"
                            :disabled="readOnly || !canSetNetFundAmount" decimals="2" />
                    </div>
                </div> -->
            </div>
        </rq-page-section>
        <rq-page-section borderless>
            <div class="row">
                <div class="col-6">
                    <additional-charge-list
                        ref="refCharges"
                        :charge="payoff"
                        :read-only="readOnly"
                        @calculate="onCalculate"
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
</template>
<script>
   import { POCWhoIntOptions, POCNetFundOptions, PayoffSectionOptions, PayoffModel, SETTLEMENT_TYPE } from "@settlement/models";
   import { GlobalEventManager } from "@/app.events";
   import { CompanyPicker } from "@/shared/components/rq";
   import CompanyInfoPopover from "@/shared/components/rq/CompanyInfoPopover";
   import { SETTLEMENT_ACTIONS } from "@/store/actions";
   import AdditionalChargeList from "./AdditionalChargeList";
   import { mapState, mapGetters } from "vuex";
   import { useVuelidate } from "@vuelidate/core";
   import { required, minValue } from "@vuelidate/validators";
   import { DateTime } from "luxon";

   const PayoffType = {
       WrittenPayoffFromLender: 0,
       DetailedPayoffCalculator: 1
   }
   export default {
       components: {
           CompanyPicker, AdditionalChargeList, CompanyInfoPopover
       },
       props: {
        displayMode: {type: String, default: "route",},
        dashboardPayoffId: { type: Number,  default: 0 },
        dashboardLoanId: { type: Number,  default: 0 },
        dashboardOrderId: { type: Number,  default: 0 },
        },
        setup: () => ({ v$: useVuelidate() }),
        data: function() {
           return {
               payoff: new PayoffModel(),
               isCancelAdd: false,
               jumpToTotal: 0,
               currentIndex: 0,
               jumpToItems: [],
               showInvalidBanner: false,
               orderCloseDate: null,
               invalidDescription: false,
               invalidFromDate: false,
               invalidThruDate: false,
               incorrectPayoffBalance: false,
               validationError: [],
               selectedPayoffId: 0,
               isLoadingPayoffType: false,
               lastInterestFromDate: null
            }
       },

       validations() {
           const self = this;

           var result = {
               payoff: {
                   description: {
                       required
                   },
                   interestFromDate: {},
                   interestThruDate: {},
                   principalBalance: {}
               }
           }

           if(self.isPayoffDatesRequired){
               result.payoff.interestFromDate = { required };
               result.payoff.interestThruDate = { required };
           }

           result.payoff.principalBalance = { required: self.isPayoffBalanceRequired, minValue: minValue(0) }
           return result;

       },

       computed: {
           ...mapGetters([
               "lookupHelpers",
               "lookupItems"
           ]),
           ...mapState({
               orderId: state => state.orders.orderId,
               readOnly: state => state.isPageReadOnly,
               payoffs: state => state.settlement.payoffs,
               selectedView: state => _.parseNumber(state.orders.orderSummary.settlementStatementType, SETTLEMENT_TYPE.CDF),
               isWithOutSeller: state => _.parseBool(state.orders.orderSummary.isWithOutSeller, false),
               closeDate: state => state.orders.order.closeDate,
           }),
           filteredPayoffs() {
               return this.isWithOutSeller ? _.filter(this.payoffs, item => item.section !== 500) : this.payoffs;
           },
           loanDescription() {
               if (this.payoff.loanID == null) return '';
               let selectedLoanItem = _.find(this.lookupHelpers.getLoanOptions(), l => l.id == this.payoff.loanID);
               return selectedLoanItem.name;
           },
           selectedPayeeCompany: {
               get() { return { companyID: this.payoff.payeeCompanyID, companyName: this.payoff.payeeCompanyName, lastPossibleEdit: this.payoff.lastPossibleEdit } },
               set(val) {
                   this.payoff.payeeCompanyID = val && val.companyID;
                   this.payoff.payeeCompanyName = val && val.companyName;
                   this.payoff.lastPossibleEdit = val && val.lastPossibleEdit;
               }
           },
           payoffSectionOptions(){
               return PayoffSectionOptions.lookupItems;
           },
           canSetNetFundAmount(){
               const self = this;
               let netFund = self.payoff.netFund || 0;
               return netFund > 0;
           },
           netFundOptions(){
               return POCNetFundOptions.lookupItems;
           },
           canSetPocAmount(){
               const self = this;
               let poc = self.payoff.pocWhom || 0;
               return poc > 0;
           },
           pocWhoIntOptions(){
               return POCWhoIntOptions.lookupItems;
           },
           addMode() {
               return this.payoff.payoffID === -1 || this.payoff.payoffID === 0;
           },
           sectionTitle() {
               return this.addMode ? "Add New Payoff" : "Payoff Detail"
           },
           isPerDiemOverridden() {
               return this.payoff.payoffType === PayoffType.DetailedPayoffCalculator && this.payoff.ovrPerDiem;
           },
           showRequiredFieldBanner() {
               return false;
           },
           isPayoffDatesRequired() {
               return this.payoff.principalBalance > 0 && this.payoff.payoffType === PayoffType.DetailedPayoffCalculator
           },
           isPayoffBalanceRequired() {
               return this.payoff.payoffType === PayoffType.DetailedPayoffCalculator
           },
           //RQO-31880 - removed, since it doesn't appear to be used
           //formattedToDate() {
           //    return moment(this.payoff.interestThruDate).format('MM/DD/YYYY');
           //},
           isFormValid() {
               return !this.v$.$error;
           },
           payoffAmountLabel() { // Add new item, "Written Payoff from Lender" to logic.  Logic is preserved in case new payoff types are added.
               return (this.payoff.payoffType !== PayoffType.DetailedPayoffCalculator && this.payoff.payoffType !== PayoffType.WrittenPayoffFromLender) ? 'Payoff Amount' : 'Principal Balance'
           },
           isDetailedPayoffCalculator() {
               return this.payoff.payoffType === PayoffType.DetailedPayoffCalculator;
           },
           showThruDateRevert() {
                if (this.payoff.interestThruDate == null && this.orderCloseDate == null) {
                    return false;
                }
                return !DateTime.fromISO(this.payoff.interestThruDate).hasSame(DateTime.fromISO(this.orderCloseDate), "day");
           },
           showDateFromRevert() {
               var result = !(this.payoff.interestFromDate == null && this.startOfTheMonthDate == null) && this.payoff.interestFromDate != this.startOfTheMonthDate;
               if (this.payoff.payoffType !== PayoffType.DetailedPayoffCalculator)
                   result = false;
               return result;
           },
           payoffDropdownData() {
               return _.map(this.filteredPayoffs, (item, index) => {
                   return {
                       id: item.payoffID,
                       name: `Payoff ${index + 1} of ${this.filteredPayoffs.length}: ${item.description}`
                   }
               });
           },
           startOfTheMonthDate() {
               if (this.payoffs[0]?.startOfMonthDate) 
                    return this.payoffs[0]?.startOfMonthDate;
                let orderCloseDate = new Date(this.closeDate);
                return this.closeDate ? `${new Date(Date.UTC(orderCloseDate.getUTCFullYear(), orderCloseDate.getUTCMonth(), 1)).toISOString().split('T')[0]}T00:00:00` : null;
           },
           loanId() {
               return _.parseInt(_.get(this.$route.params, "loanId", 0));
           },
           payoffId() {
            if(this.isDisplayModal){
                return this.payoffId;
            }
            else
               return _.parseInt(_.get(this.$route.params, "payoffId", 0));
           },
           showPayoffDropdown() {
               return !this.addMode && this.payoffs.length > 1;
           },
           canEditSection() {
               return this.payoff.sequence !== 1 && this.payoff.sequence !== 2;
           },
           isDisplayModal() {
                return this.displayMode === "modal";
            }
       },
       watch: {
           payoffId: function () {
               this.initializePayoffData();
           }
       },
       created() {
           if (!this.readOnly) {
               GlobalEventManager.onSave(this, this.onSaveClicked);
               GlobalEventManager.onCancel(this, this.onCancelClicked);
           }
           this.orderCloseDate = this.closeDate;
           this.initializePayoffData();
       },
       beforeUnmount () {
           GlobalEventManager.unregister(this);
       },
       beforeRouteLeave(to, from, next) {
           if(this.isCancelAdd) {
               next();
           }
           else
           {
               this.v$.$touch();
               this.showInvalidBanner = this.v$.$error;
               if(!this.showInvalidBanner)
               {
                   if(this.addMode)
                   {
                       this.add({
                           moveAway: true
                       }).then(response => {
                           if(response === true)
                               next();
                       });
                   }
                   else
                   {
                       next();
                   }
               }
               else
                   return;

           }
       },
       beforeRouteUpdate(to, from, next) {
           this.save()
               .then(() => {
                   next();
               });
       },
       methods: {
           initializePayoffData() {
               this.selectedPayoffId = this.isDisplayModal ? this.dashboardPayoffId : this.payoffId;

               if(this.selectedPayoffId === 0) {
                   let newPayoff = this.createNewPayoff();
                   this.loadPayoff(newPayoff);
               }
               else {
                   this.getPayoff(this.selectedPayoffId);
               }
           },
           loadPayoff(data) {
               this.payoff = _.cloneDeep(data);
               this.original = _.cloneDeep(data);
               var setDate = !this.payoff.ovrThruDate;

               if(_.isEmpty(this.orderCloseDate))
                   this.orderCloseDate = data.orderCloseDate;

               if(setDate)
               {
                   this.original.interestThruDate = this.payoff.interestThruDate  = this.orderCloseDate; //RQO-7576
               }

               if (this.payoff.payoffType !== PayoffType.WrittenPayoffFromLender) {
                   this.isLoadingPayoffType = true;
               }

               this.resetValidation();
           },
           getPayoff(payoffId) {
               let self = this;
               self.$api.PayoffsApi.getPayoffById(payoffId)
                   .then((data) => {
                       self.loadPayoff(data);
                   })
                   .catch(() => {
                       self.$toast.error({ message: 'Error Getting Payoff' });
                   });
           },
           onAddPayoffClicked() {
               let self = this;
               this.$router
                   .push({
                       name: 'settlement:payoffs:detail',
                       params: {
                           orderId: self.orderId,
                           loanId: self.loanId
                       }
                   });
           },
           onCalculate() {
               this.calculate();
           },
           onCancelClicked: function() {
               const self = this;

               if(this.addMode) {
                   this.isCancelAdd = true;
                   this.goToList();
               }
               else {
                   let hasChanges = () => {
                       let current = self.payoff;
                       let changes = self.getChanges(current);
                       return changes.length > 0;
                   };
                   if(!hasChanges()) {
                       self.resetValidation();
                   }

                   GlobalEventManager.confirmCancel({
                       hasChanges,
                       callback: function () {
                           self.loadPayoff(self.original);
                       }
                   });
               }
           },
           onPOCWhomChanged(){
               if ((this.payoff.pocWhom || 0) === 0 ) {
                   this.payoff.amountPOC = null;
               }
           },
           onNetFundChanged() {
               if ((this.payoff.netFund || 0) === 0 ) {
                   this.payoff.netAmount = null;
               }
           },
           onPayoffTypeChanged(e) {
                if (this.isLoadingPayoffType) {
                    this.isLoadingPayoffType = false;
                    return;
                }

                if (this.payoff.payoffType !== PayoffType.DetailedPayoffCalculator) {
                    this.payoff.interestFromDate = this.lastInterestFromDate;
                    this.payoff.interestRate = null;
                    this.payoff.totalInterest = null;
                }
                else
                {
                    this.lastInterestFromDate = this.payoff.interestFromDate;
                    this.payoff.interestFromDate = this.startOfTheMonthDate;
                    this.payoff.interestThruDate = this.orderCloseDate;
                }
           },
           onSaveClicked(args) {
               this.v$.$touch();
               if(!this.isCancelAdd)
               {
                   this.showInvalidBanner = !this.isValid();

                   if(this.showInvalidBanner)
                   {
                       return;
                   }
               }
               if(this.addMode) {
                   if(!this.isCancelAdd)
                       return this.add(args);
                   else
                   {
                       GlobalEventManager.saveCompleted({success: true});
                       return;
                   }
               }
               else {
                   return this.save(args);
               }
           },

           calculate() {
               const self = this;

               if (self.readOnly) return;
               self.v$.$touch();
               if (self.isFormValid) {
                   let request = { payoff: self.payoff, details: self.payoff.details };
                   let promise = self.$api.PayoffsApi.calculatePayoff(request);
                   self.$rqBusy.wait(promise)
                       .then(payoff => {
                           self.payoff.totalInterest = payoff.totalInterest;
                           self.payoff.perDiem = payoff.perDiem;
                           self.payoff.payoutAmount = payoff.payoutAmount;
                           self.$toast.success({ message: "Payoffs calculated successfully" });
                       })
                       .catch(error => {
                           let message = 'Error calculating payoff information';
                           self.$log.fatal(`PayOffDetail - ${message}`, error);
                           self.$toast.error({ message: message });
                       });
               }
           },

           isValid() {
               let errorList = [];
               this.validationError = "";
               this.incorrectPayoffBalance = false;

               this.invalidDescription = this.invalidFromDate = this.invalidThruDate = false;

               if (_.isEmpty(this.payoff.description)) {
                   this.validationError = "Missing Required Field(s).";
                   errorList.push("Payoff Description is required.");
                   this.invalidDescription = true;
               }

               if(this.isPayoffDatesRequired)
               {
                   if(_.isEmpty(this.payoff.interestFromDate))
                   {
                       this.validationError = "Missing Required Field(s).";
                       errorList.push("Date From is required.");
                       this.invalidFromDate = true;
                   }
                   if(_.isEmpty(this.payoff.interestThruDate))
                   {
                       this.validationError = "Missing Required Field(s).";
                       errorList.push("To Date is required.");
                       this.invalidThruDate = true;
                   }
               }

               if(this.payoff.principalBalance < 0)
               {
                   var message = "Invalid Principal Balance. Negative numbers are not allowed.";
                   if(errorList.length > 0)
                       this.validationError += "  " + message;
                   else
                       this.validationError = message;
                   errorList.push(message);
                   this.incorrectPayoffBalance = true;
               }
               return errorList.length === 0;
           },
           overridePerDiem: function(override) {
               if (this.payoff.payoffType === PayoffType.DetailedPayoffCalculator) {
                   this.payoff.ovrPerDiem = override;
                   if (!override) {
                       this.calculate();
                   }
               }
           },
           overrideThruDate: function() {
               this.payoff.ovrThruDate = false;
               this.payoff.interestThruDate = this.orderCloseDate;
               this.calculate();
           },
           overrideFromDate: function() {
               this.payoff.interestFromDate = this.startOfTheMonthDate;
               this.calculate();
           },
           overrideTotalInterest: function(override) {
               this.payoff.ovrTotalInterest = override;
               if (!override) {
                   this.calculate();
               }
           },
           overrideTotalPayout: function(override) {
               this.payoff.totalPayoffAmountOverride = override;
               if (!override) {
                   this.calculate();
               }
           },
           getChanges(current) {
               return this.getAuditChanges(this.original, current);
           },
           createNewPayoff() {
               let self = this;

               return new PayoffModel({
                   ordersID: self.orderId,
                   loanID: self.isDisplayModal ? self.dashboardLoanId : self.loanId,
                   section: self.isWithOutSeller ? 100 : 500
               });
           },
           add(args) {
               let self = this;
               return self.save(args)
                   .then((response) => {
                       if(response === true && !args.moveAway) {
                           self.$router
                               .push({
                                   name: 'settlement:payoffs:detail',
                                   params: {
                                       orderId: self.orderId,
                                       payoffId: self.payoff.payoffID
                                   }
                               });
                       }
                       return response;
                   });
           },
           checkThruDate() {
               const self = this;
               if(self.payoff.interestThruDate != self.orderCloseDate)
                   self.payoff.ovrThruDate = true;
           },
           save(args) {
               const self = this;
               if(this.isDisplayModal)
                    self.payoff.loanId = args.dashboardLoanId;
               self.v$.$touch();
               self.checkThruDate();
               self.$refs.refCharges.commitData()
               return new Promise((resolve, reject) => {
                   if (!self.isFormValid || _.isEmpty(self.payoff)) {
                       GlobalEventManager.saveCompleted({success: false});
                       resolve(false);
                   } else {
                       let changes = self.getChanges(self.payoff);
                       if (changes && changes.length > 0) {
                           // Ignored changes to details adding because HTTP request could not serialize on server side below
                           let effectiveChanges = self.getAuditChanges(this.original, self.payoff, ["details"]);
                           let request = { data: { payoff: self.payoff, details: self.payoff.details }, changes: effectiveChanges};
                           let promise = self.$store.dispatch(SETTLEMENT_ACTIONS.SAVE_PAYOFF, request);
                           self.$rqBusy.wait(promise)
                               .then((response) => {
                                   self.selectedPayoffId = response.payoffID;
                                   self.loadPayoff(response);
                                   self.$toast.success({message: 'Payoff saved'});
                                   GlobalEventManager.saveCompleted({success: true});
                                   resolve(true);
                               })
                               .catch(error => {
                                   let message = 'Error saving payoff information';
                                   self.$log.fatal(`PayOffDetail - ${message}`, error);
                                   self.$toast.error({ message: message });
                                   GlobalEventManager.saveCompleted({success: false});
                                   reject(error);
                               });
                       } else {
                           if (args && args.userInitiated) self.$toast.info({ message: 'No changes detected' });
                           GlobalEventManager.saveCompleted({success: true});
                           resolve(true);
                       }
                   }
               });
           },
           goToList() {
               this.$router.push({ name:'settlement:payoffs:list', params: { orderId: self.orderId, loanId: this.loanId } });
           },
           onPayoffDropdownChange(e) {
               if(!_.isNil(e.event))
               {
                   let self = this;
                   let isValid = self.isValid();
                   this.showInvalidBanner = !isValid;
                   if (isValid) {
                    if(this.isDisplayModal)
                        this.getPayoff(e.value);
                    else{
                       this.$router
                           .push({
                               name: 'settlement:payoffs:detail',
                               params: {
                                   orderId: self.orderId,
                                   loanId: self.loanId,
                                   payoffId: e.value,
                               }
                           });
                        }
                   }
                   else {
                       this.selectedPayoffId = e.previousValue;
                   }
               }
           },
           resetValidation() {
               this.invalidDescription = false,
               this.invalidFromDate = false,
               this.invalidThruDate = false,
               this.incorrectPayoffBalance = false,
               this.showInvalidBanner = false;
               this.$nextTick(() => {
                   this.v$.$reset();
               });
           },
            //RQO-31880 - removed, since it doesn't appear to be used
            // interestDateRequired(date) {
            //     if (this.payoff.principalBalance > 0 && this.payoff.payoffType === PayoffType.DetailedPayoffCalculator) {
            //         return !date ? false : moment(date).isValid();
            //     }
            //     return true;
            // }
       }
   }
</script>

