<template>
    <div class="content-wrapper h-100">
        <div class="current-assigned-label" v-if="showSelectionSummary && hasSelectionSummary && !showContactForm && !showCompanyForm">
            <span>CURRENTLY ASSIGNED:</span>
            {{currentlySelected}}
        </div>
        <div class="company-contact-list" v-show="!showCompanyForm && !showContactForm">
            <company-contact-list
                ref="companyContactList"
                v-model:tab-index="currentTabIndex"
                :search-term="searchTerm"
                :region-id="regionId"
                :specific-region-only="specificRegionOnly"
                :target-company="targetCompany"
                v-model:selected-company-keys="selectedCompanyIds"
                v-model:selected-contact-keys="selectedContactIds"
                :include-role-types="includeRoleTypes"
                :show-selection-summary="showSelectionSummary"
                :hide-contact-tab="hideContactTab"
                :show-inactive="showInactive"
                @view-company-detail="onAddEditCompanyClick"
                @view-contact-detail="onAddEditContactClick"
                @row-double-click="onGridRowDoubleClick"
                dialog-mode
                single-select
                allow-add
                allow-edit
                allow-activate
                show-inactivate>
                <template #search-row>
                    <div class="col col-3 form-group">
                        <label for="drp_role">Role</label>
                        <dx-select-box
                            :element-attr="{ id:'drp_role_filter', automation_id:'drp_role_filter' }"
                            :items="roleTypes"
                            value-expr="id"
                            display-expr="name"
                            :disabled="currentTabIndex > 1"
                            :search-enabled="true"
                            :show-clear-button="true"
                            :defer-rendering="false"
                            v-model="roleTypeIdValue"
                        />
                    </div>
                </template>
            </company-contact-list>
        </div>
        <div class="company-contact-add">
            <company-edit
                v-if="showCompanyForm"
                ref="companyForm"
                :company-id="editingCompanyId"
                :role-type-id="roleTypeId"
                modal-mode
            />
            <contact-edit
                v-if="showContactForm"
                ref="contactForm"
                :key="contactFormKey"
                :company-id="editingCompanyId"
                :contact-id="editingContactId"
                :company-name="contactCompanyName"
                :company-sales-rep-staff-id="companySalesRepStaffId"
                modal-mode
            />
        </div>
    </div>
</template>
<script>
    import { CompanyContactList, ContactEdit, CompanyEdit } from "@utilities/manage-companies/components";
    import { mapGetters } from "vuex";
    import { CompanyPickerModel } from "@/shared/models/models";
    import { RoleType } from "@/shared/models/enums";
    import CompanyContactLookupFooter from "./CompanyContactLookupFooter.vue";

    export default {
        name: "CompanyContactLookup",
        components: {
            CompanyContactList,
            CompanyEdit,
            ContactEdit
        },
        props: {
            pickerSearchTerm: { type: String, default: "" },
            tabIndex: { type: Number, default: 0 },
            roleTypeId: { type: Number },
            regionId: { type: Number, default: null },
            selectedCompanyId: { type: Number, default: 0 },
            selectedContactId: { type: Number, default: 0 },
            targetCompany: { type: Object, default: () => ({}) },
            hideContactTab: { type: Boolean, default: false },
            showInactive: { type: Boolean, default: false },
            showSelectionSummary: {type: Boolean, default: false},
            companyDisplayID: {type: String, default: ""},
            companyDisplay: {type: String, default: ""},
            contactDisplay: {type: String, default: ""},
            specificRegionOnly: {type: Boolean, default: false},
        },
        data() {
            return {
                searchTerm: this.pickerSearchTerm,
                roleTypeIdValue: this.roleTypeId,
                showCompanyForm: false,
                showContactForm: false,
                contactFormKey: _.uniqueId(),
                addAnother: false,
                selectedCompanyIds: [],
                selectedContactIds: [],
                currentTabIndex: 0,
                editingCompanyId: 0,
                editingContactId: 0,
                contactCompanyName: null,
                updatedCompanySalesRep: null,
            };
        },
        computed: {
            ...mapGetters(["lookupHelpers"]),
            roleTypes() { return _.filter(this.lookupHelpers.getRoles(), item =>
                    !_.includes([RoleType.Bank], item.id)
                    && (item.enabled)); },
            hasCompanySelected() { return !_.isEmpty(this.selectedCompanyIds); },
            hasContactSelected() { return !_.isEmpty(this.selectedContactIds); },
            includeRoleTypes() { return _.lte(this.roleTypeIdValue, 0) ? [] : [this.roleTypeIdValue]; },
            isCompanyActive() { return this.currentTabIndex === 0; },
            currentlySelected(){
                const self = this;
                let company = _.joinParts([self.companyDisplayID, self.companyDisplay], "-");
                var displayContent = _.joinParts([company, self.contactDisplay], " | ");
                return displayContent;
            },
            hasSelectionSummary() { return !_.isEmpty(this.currentlySelected); },
            footerState() {
                const self = this;
                let state = { visible: ["cancel"], disabled: [] };
                let isEditing = self.showCompanyForm || self.showContactForm;

                if(self.showContactForm && self.editingContactId === 0)
                    state.visible.push("add-another");
                if(self.isCompanyActive && !isEditing)
                    state.visible.push("assign-company");
                if(!self.isCompanyActive && !isEditing)
                    state.visible.push("assign-contact");
                if(isEditing)
                    state.visible.push("save");

                if(!self.hasCompanySelected)
                    state.disabled.push("add-another");
                if(self.isCompanyActive && !self.hasCompanySelected)
                    state.disabled.push("assign-company");
                if(!self.isCompanyActive && !self.hasContactSelected)
                    state.disabled.push("assign-contact");

                return state;
            }
        },
        watch: {
            footerState: {
                handler(val) {
                    this.$events.emit(this.footerEvents.setState, val);
                },
                deep: true
            }
        },
        created() {
            this.init();
            this.initCustomFooter();
            this.passedInCompany = this.selectedCompanyIds[0];
            this.passedInContact = this.selectedContactIds[0];
        },
        beforeUnmount() {
            this.$events.off(this.footerEvents.action, this.onFooterAction);
        },
        methods: {
            onAddEditCompanyClick(e) {
                this.editingCompanyId = _.getNumber(e, "companyId", 0);
                this.showCompanyForm = true;
            },

            onAddEditContactClick(e) {
                this.editingCompanyId = _.getNumber(e, "companyId", 0);
                this.editingContactId = _.getNumber(e, "contactId", 0);
                this.contactCompanyName = _.get(e, "companyName", null);
                this.companySalesRepStaffId = this.updatedCompanySalesRep?.companyId == this.editingCompanyId ?  this.updatedCompanySalesRep.salesRepresentativeStaffId ?? _.get(e, "companySalesRepStaffId", null) : _.get(e, "companySalesRepStaffId", null);
                this.$emit("update-dialog-options", { title: this.editingContactId > 0 ? "Edit Contact" : "Add Contact" });
                this.showContactForm = true;
            },

            onGridRowDoubleClick(e) {
                this.emitData(e.data);
            },

            init() {
                this.selectedCompanyIds = _.parseNumber(this.selectedCompanyId, 0) > 0 ? [this.selectedCompanyId] : [];
                this.selectedContactIds = _.parseNumber(this.selectedContactId, 0) > 0 ? [this.selectedContactId] : [];
            },

            initCustomFooter () {
                const self = this;
                self.footerEvents = {
                    setState: "company-contact-footer:set-state",
                    action: "company-contact-footer:action"
                };
                self.$emit("use-custom-footer", {
                    component: CompanyContactLookupFooter,
                    props: {
                        setStateEvent: self.footerEvents.setState,
                        actionEvent: self.footerEvents.action,
                        initialState: self.footerState
                    }
                });
                self.$events.on(self.footerEvents.action, self.onFooterAction);
            },

            onFooterAction(e) {
                switch(e.action.name) {
                    case "add-another":
                        this.saveCompanyContact(true);
                        break;
                    case "cancel":
                        this.cancel();
                        break;
                    case "assign-company":
                    case "assign-contact":
                        this.emitSelectedData();
                        break;
                    case "save":
                        this.saveCompanyContact();
                        break;
                }
            },

            emitSelectedData() {
                let selectedData = this.isCompanyActive
                    ? this.getSelectedCompany()
                    : this.getSelectedContact();
                this.emitData(selectedData);
            },

            emitData(selectedData) {
                let data = new CompanyPickerModel(selectedData);
                this.$emit("ok", { data });
            },

            saveCompanyContact(addAnother=false) {
                const self = this;
                if(self.showCompanyForm) {
                    self.$refs.companyForm.modalSave()
                        .then(result => {
                            if(!result) return;
                            self.updatedCompanySalesRep = { companyId: self.$refs.companyForm.companyInfo.companyID, salesRepresentativeStaffId: self.$refs.companyForm.companyInfo.salesRepresentativeStaffID }
                            self.$emit("revert-dialog-options");
                            self.showCompanyForm = false;
                            self.editingCompanyId = 0;
                            self.refreshCompanies()
                                .then(()=>{
                                    self.selectedCompanyIds = [result.companyInfo.companyID];
                                });
                        });
                }
                else if(self.showContactForm) {
                    self.$refs.contactForm.modalSave()
                        .then(result => {
                            if(!result) return;
                            if(addAnother) {
                                self.reloadContactForm();
                            }
                            else {
                                self.$emit("revert-dialog-options");
                                self.showContactForm = false;
                                self.editingCompanyId = 0;
                                self.contactCompanyName = null;
                                self.refreshContacts()
                                    .then(() => {
                                        self.selectedContactIds = [result.contactInfo.contactID];
                                        return result;
                                    });
                            }
                        });
                }
            },
            cancel() {
                if(!this.showCompanyForm && !this.showContactForm) {
                    this.$emit("cancel");
                }
                else {
                    this.$emit("revert-dialog-options");
                    this.showCompanyForm = false;
                    this.showContactForm = false;
                    this.editingContactId = 0;
                    this.editingCompanyId = 0;
                    this.contactCompanyName = null;
                }
            },
            reloadContactForm() {
                this.contactFormKey = _.uniqueId();
            },
            getSelectedCompany() {
                return _.invoke(this, "$refs.companyContactList.getSelectedCompanies")[0] || null;
            },
            getSelectedContact() {
                return _.invoke(this, "$refs.companyContactList.getSelectedContacts")[0] || null;
            },
            refreshCompanies(){
                return _.invoke(this, "$refs.companyContactList.refreshCompanies");
            },
            refreshContacts(){
                return _.invoke(this, "$refs.companyContactList.refreshContacts");
            }
        }
    }
</script>