<template>
    <tr>
        <td scope="row" class="col-num">
            <label>{{formatNumber(localLineData.line)}}</label>
        </td>
        <td v-if="expandDetail && includeAlta" :class="{ 'rq-col-input rq-col-category':true, 'rq-col-readonly': readOnly }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_category' }"
                :items="altaCategories"
                value-expr="name"
                display-expr="name"
                :search-enabled="false"
                :read-only="readOnly"
                v-model="localLineData.category"
            />
        </td>
        <td :class="{ 'rq-col-input': true, 'overridden': localLineData.isDescriptionOverridden, 'rq-col-readonly': !canEditDescription }" :colspan="expandDetail && isProrationLine ? '3' : '1'">
            <input
                :id="`txt_debit_credit_description_`+localLineData.debitCreditSectionDataID"
                automation_id="txt_debit_credit_description"
                ref="descriptionInput"
                type="text"
                maxlength="150"
                :readonly="!canEditDescription"
                v-model="localLineData.descriptionValue"
                v-focus-select-all
                :style="showProrationDate ? 'width: auto; min-width: 52%;' : 'width: 100%'"
            />
            <rq-icon-button
                v-if="localLineData.isDescriptionOverridden && canEditDescription"
                icon="fas fa-undo"
                tooltip-text="Revert"
                class="rq-table-revert"
                @click="localLineData.revertOverrideDescription(localLineData)"
            />
            <span v-if="showProrationDate" style="float: right;line-height: 2.3;padding: 0 0.375rem;"> {{fromToDate}} </span>            
        </td>
        <td v-if="expandDetail && !isProrationLine" :class="{'rq-col-input': true, 'rq-col-readonly': readOnly }">
            <company-picker
                ref="refPayeeCompany"
                automation_id="pic_Payee_company"
                dialogTitle="Select Payee"
                :disabled="readOnly"
                v-model="localLineData.payeeCompany"
            />
        </td>
        <td class="rq-col-checkbox" v-if="expandDetail && !isProrationLine">
            <b-form-checkbox
                v-if="canEditDebitCredit"
                automation_id="chk_debit_credit"
                :disabled="readOnly"
                v-model="localLineData.isDebitCredit"
            />
        </td>
        <td :class="{ 'col-currency rq-col-input': true, 'overridden': localLineData.isAmountOverridden, 'rq-col-readonly': !canEditAmount }" :colspan="expandDetail && isProrationLine ? '4' : '1'">
            <rqInputNumber
                automation_id="txt_debit_credit_section_line_amount"
                defaultValue="0"
                prefix="$"
                decimals="2"
                :readonly="!canEditAmount"
                @blur="onEditAmount"
                v-model="localLineData.amountValue"
                no-form-control-class
                v-focus-select-all
            />
            <rq-icon-button
                v-if="localLineData.isAmountOverridden && canEditAmount"
                icon="fas fa-undo"
                tooltip-text="Revert"
                class="rq-table-revert"
                @click="localLineData.revertOverrideAmount(localLineData)"
            />
        </td>
        <td v-if="expandDetail && !isProrationLine" :class="{'rq-col-input': true, 'rq-col-readonly': !canEditAdjustmentCredit }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_adj_credit' }"
                :items="adjCreditOptions"
                value-expr="id"
                display-expr="name"
                :read-only="!canEditAdjustmentCredit"
                v-model="localLineData.adjustmentCredit"
            />
        </td>
        <td v-if="expandDetail && !isProrationLine" :class="{'rq-col-input': true, 'rq-col-readonly': readOnly }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_net_fund' }"
                :items="netFundOptions"
                value-expr="id"
                display-expr="name"
                :read-only="readOnly"
                v-model="localLineData.netFund"
            />
        </td>
        <td v-if="expandDetail && !isProrationLine" :class="{'rq-col-input': true, 'rq-col-readonly': !canEditAccountCode }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_acct_code' }"
                :items="accountCodeOptions"
                value-expr="id"
                display-expr="name"
                :disabled="!canEditAccountCode"
                v-model="localLineData.accountCodeID"
            />
        </td>
        <td v-if="expandDetail && includeAlta" class="rq-col-selection rq-col-print col-lg-12">
            <b-form-checkbox
                automation_id="chk_do_not_print"
                :disabled="readOnly"
                v-model="localLineData.doNotPrint"
            />
        </td>
    </tr>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { AltaCategories } from '@settlement/models';
    import { CompanyPicker } from '@/shared/components/rq';
    import { CdfSection } from '../models';
    import { useCommonSettlementSecurity } from "@settlement/helpers";

    export default {
        name: "DebitCreditSectionLine",
        components: { CompanyPicker },
        props: {
            expandDetail: { type: Boolean, default: false },
            lineData: { type: Object, default: () => ({}) },
            readOnly: { type: Boolean, default: false },
            isCdf: { type: Boolean, default: false }
        },
        setup() {
            return useCommonSettlementSecurity();
        },
        data() {
            return {
                localLineData: this.lineData,
                adjCreditOptions: [
                    { id: 1, name: 'Adjustments and Other Credits' },
                    { id: 2, name: 'Seller Credits' },
                ],
                printOptions: [
                    { id: 0, name: 'No' },
                    { id: 1, name: 'Yes' }
                ]
            }
        },
        computed: {
            ...mapState({
                loans: state => state.orders.loans || [],
                includeAltaValue: state => _.getBool(state, "orders.orderSummary.includeAlta"),
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            includeAlta() { return this.isCdf && this.includeAltaValue; },
            isProrationLine() { return _.parseBool(this.localLineData.isProration); },
            showProrationDate() { return _.parseBool(this.localLineData.isProration) && this.localLineData.amount != 0 && this.localLineData.fromDate != null && this.localLineData.prorationMethod != null; },
            fromToDate() {return this.getFormattedDateString(this.localLineData.fromDate) + ' ' + this.localLineData.prorationMethod + ' ' + this.getFormattedDateString(this.localLineData.toDate)},
            isPayoffLine() { return _.parseBool(this.localLineData.isPayOff); },
            isPayoffAssociatedLine() { return _.parseBool(this.localLineData.isPayoffAssociated); },
            isAutoDebitCredit() { return _.parseBool(this.localLineData.isAdjustmentForOwnerPolicyPaidbySeller) },
            canEditDescription() {
                const self = this;

                if (self.readOnly) return false;

                if (self.localLineData.isAdjustmentForOwnerPolicyPaidbySeller) return true;

                return !(self.localLineData.required || self.isProrationLine || self.isPayoffLine || self.isAutoDebitCredit); // cannot edit if one of these lines
            },
            canEditAccountCode() {
                // Check account code configuration permission
                // If onlySysAdminCanEditAccountCode is set and if default line, only System Admin allowed permission to edit account code
                if (!_.isNil(this.localLineData.defaultedSettlementType) && !this.allowEditAccountCode)
                    return false;

                return !this.readOnly;
            },
            canEditDebitCredit() {
                const self = this;

                if (self.readOnly) return false;

                // CDF specific lines that debit credit apply to below
                if (self.isCdf) {
                    if (self.isProrationLine) return false;
                    if (self.isPayoffLine) return false;
                    if (self.isPayoffAssociatedLine) return false;

                    if (self.localLineData.cdfSection === CdfSection.K) {
                        switch(self.localLineData.line) {
                            case 1:
                            case 3:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.cdfSection === CdfSection.M) {
                        switch(self.localLineData.line) {
                            case 1:
                            case 3:
                            case 5:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.cdfSection === CdfSection.L) {
                        switch(self.localLineData.line) {
                            case 1:
                            case 2:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.cdfSection === CdfSection.N) {
                        switch(self.localLineData.line) {
                            case 1:
                            case 2:
                            case 4:
                            case 5:
                                return false;
                            default:
                                return true;
                        }
                    }
                }

                return true;
            },
            canEditAdjustmentCredit() {
                const self = this;

                if (self.readOnly) return false;

                // Specific CDF lines require adjustment credit input
                if (self.isCdf) {
                    if (self.isAutoDebitCredit) return false;
                    if (self.localLineData.cdfSection === CdfSection.K && self.localLineData.line >= 5)
                        return true;

                    if (self.localLineData.cdfSection === CdfSection.L && self.localLineData.line >= 4)
                        return true;
                }

                return false;
            },
            canEditAmount() {
                const self = this;

                if (self.readOnly) return false;

                if (self.localLineData.isAdjustmentForOwnerPolicyPaidbySeller) return true;

                if (self.isProrationLine) return true;

                if (self.isPayoffLine) return false;

                if (self.isAutoDebitCredit) return false;

                if (self.isAdditionalDeposit) return false;

                // Specific CDF lines that allow amount editing
                if (self.isCdf === true) {
                    if (self.localLineData.cdfSection === CdfSection.K) {
                        switch(self.localLineData.line) {
                            case 1:
                            case 3:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.cdfSection === CdfSection.M) {
                        switch(self.localLineData.line) {
                            case 1:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.cdfSection === CdfSection.L) {
                        switch(self.localLineData.line) {
                            case 2:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.cdfSection === CdfSection.N) {
                        switch(self.localLineData.line) {
                            case 2:
                            case 4:
                            case 5:
                                return false;
                            default:
                                return true;
                        }
                    }
                }

                return true;
            },
            altaCategories() { return AltaCategories.lookupItems; },
            netFundOptions() { return this.lookupHelpers.getLookupItems(this.lookupItems.NET_FUND); },
            accountCodeOptions() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, null, this.localLineData.accountCodeID); },
        },
        watch: {
            "localLineData.isDebitCredit": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.canEditDebitCredit)
                        this.$events.emit('toggle-debit-credit', {
                            item:this.lineData
                        });
                }
            },
            "localLineData.amount": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.localLineData.isDebitCredit === true && this.canEditDebitCredit)
                        this.$events.emit('toggle-debit-credit', {
                            item: this.lineData
                        });
                    if (this.localLineData.cdfSection === CdfSection.L && this.localLineData.line === 1) {
                        this.$events.emit('update-earnest-money-cdf', {
                            item: this.lineData
                        });
                    }
                }
            },
            "localLineData.overrideAmount": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.localLineData.isDebitCredit === true && this.canEditDebitCredit)
                        this.$events.emit('toggle-debit-credit', {
                            item: this.lineData,
                            activeSection: this.localLineData.cdfSection
                        });
                }
            },
            "localLineData.description": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.localLineData.isDebitCredit === true && this.canEditDebitCredit)
                        this.$events.emit('toggle-debit-credit', {
                            item: this.lineData
                        });
                }
            },
            "localLineData.overrideDescription": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.localLineData.isDebitCredit === true && this.canEditDebitCredit)
                        this.$events.emit('toggle-debit-credit', {
                            item: this.lineData,
                            activeSection: this.localLineData.cdfSection
                        });
                }
            },
            lineData: {
                handler: function(newValue, oldValue) {
                    this.localLineData = newValue;
                }
            }
        },

        mounted() {
            this.resetFocus();
        },

        methods: {
            onEditDepositAmount(e) {
                const self = this;
                let loan = _.find(self.loans, l => l.loanID === self.localLineData.loanID);
                let newAmount = e.value;
                let origAmount = self.localLineData.amount;

                let handleOk = function(e) {
                    return true;
                }

                let handleCancel = function(e) {
                    self.localLineData.amount = origAmount;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newAmount !== origAmount && loan.loanOrder == 1) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Deposit/Earnest money for Loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },
            onEditAmount(e) {
                let isDepositLine = this.localLineData.cdfSection === 'L' && this.localLineData.line === 1;

                if (isDepositLine)
                    this.onEditDepositAmount(e);
            },
            formatNumber(lineNumber) {
                return lineNumber < 10 ? `0${lineNumber}` : `${lineNumber}`
            },
            formatAmount(amount) {
                return amount != null ? accounting.formatMoney(amount) : "";
            },
            rowClick(e) {
                let t = e;
            },
            resetFocus() {
                let line = this.localLineData;
                if (this.loans.length === 1 && line.cdfSection === 'K' && line.line === 1)
                    this.$refs.descriptionInput.focus();
            },
            getFormattedDateString(inputDate) {
                if(inputDate == null || inputDate == '') 
                    return '';

                var date = new Date(inputDate);
                return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
            }
        }

    }
</script>

