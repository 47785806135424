<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="showBanner"
            @dismiss="errorMessage=''"
            dismissable
        />
        <div class="row ms-3 me-3">
            <div class="col col-4 form-group">
                <label for="drp_escrow_acct">Escrow Account</label>
                <dx-select-box
                    automation_id="drp_escrow_acct"
                    value-expr="id"
                    display-expr="name"
                    :items="escrowBanks"
                    :disabled="readOnly"
                    v-model="selectedBankCompanyID"
                    @value-changed="onEscrowAccountChange"
                />
            </div>
            <div class="col col-3 form-group">
                <label for="chk_unverified_only">&nbsp;</label>
                <b-form-checkbox
                    automation_id="chk_unverified_only"
                    @change="onUnverifiedOnlyChange"
                    v-model="unVerifiedOnly"
                    :disabled="readOnly">Unverified Only</b-form-checkbox>
            </div>
        </div>
        <div class="rq-master-detail-container">
            <section>
                <rqdx-action-data-grid
                    ref="dataGrid"
                    title="Transfer Slips"
                    automation_id="tbl_transfer_slips"
                    :actions="selectionActions"
                    :config="gridConfig"
                    search-mode="field"
                    title-size="sm"
                    :data-source="gridDataSource"
                    @delete="onDeleteItem"
                    @selectionChanged="onGridSelectionChanged"
                    export-file-name="transfer_slips_data"
                    integrated-search
                    :rq-editable="!readOnly"
                    rq-filters>
                    <template #toolbar>
                        <ul class="nav me-auto">
                            <li class="nav-item" v-rq-tooltip.top.hover :title="readOnly ? 'Access Restricted' : 'Add Transfer Slip'">
                                <b-btn automation_id="btn_add" variant="theme" @click="onAddItem" :disabled="readOnly">Add</b-btn>
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </section>
            <section v-if="showGridSelector">
                <transfer-selector id="transferSelector"
                    ref="transferSelector"
                    :transfer-slip="selectedItem"
                    @update-transfer-ids="onUpdateTransferIDs"
                    :disabled="readOnly"
                />
            </section>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { TransferSlipDto }  from "../models";
    import TransferSelector from "../components/TransferSelector";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";

    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import DocumentViewer from "@documents/views/DocumentViewer";

    export default {
        name: "TransferSlipMaintenance",
        mixins: [GridSystemLookupMixin],
        components: { TransferSelector },
        data () {
            return {
                addEventName: "",
                errorMessage: "",
                items: [],
                unassignedDeposits: [],
                selectedBankCompanyID: 0,
                depositIDs: [],
                selectedItem: {},
                selectionActions: [],
                unVerifiedOnly: true,
                showGridSelector: false,
                bankDataList: [],
                bankDocTemplates: []
            };
        },

        watch: {
            selectedItem(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit(`${this.isSelectedItemReadOnly ? "disable" : "enable"}-ok`);
            },
        },

        created() {
            this.selectedBankCompanyID = this.bankCompanyID;
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                allBranches: state => _.get(state, "system.lookups.branches", []),
                order: state => state.orders.order,
                user: state => state.authentication.session.user
            }),
            bankCompanyID() { return _.get(this, "order.bankCompanyID", 0); },
            hasEscrowAccount() { return _.get(this, "bankCompanyID", 0) > 0; },
            isSelectedItemReadOnly() { return !_.isNil(_.get(this.selectedItem, "dateVerified", null)); },
            localSecurity(){ return this.securitySettings.findValues(["AllowDepositSlipMaintenance", "DepositSlip_ScreenAccess"]); },
            selectedKey() {
                if (_.isEmpty(this.selectedItem)) return 0;
                return _.get(this.selectedItem, this.itemKey, 0);
            },
            showBanner() { return _.size(_.get(this, "errorMessage", null)) > 0; },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            readOnly() { return !this.localSecurity.AllowDepositSlipMaintenance || this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.Read ; },
            selectedBankData() { return _.find(this.bankDataList, b => b.companyID === this.selectedBankCompanyID); },
            isTransferSlipTemplateConfigured() { return !_.isNullOrEmpty(this.selectedBankData?.transferSlipTemplateFile); },
            actionDisabled(){ return !this.localSecurity.AllowDepositSlipMaintenance || this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.Read;},
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData(clear=false) {
                const self = this;
                if (!self.hasEscrowAccount || _.isEqual(self.selectedBankCompanyID, 0)) return;
                let apiPromises = [
                    self.$api.TransferSlipsApi.get(self.selectedBankCompanyID, self.unVerifiedOnly),
                    _.isNil(self.selectedBankData) ? self.$api.CompaniesApi.getEscrowAccount(self.selectedBankCompanyID) : Promise.resolve(null)
                ];
                self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(results => {
                        self.items = _.map(results[0], ds => new TransferSlipDto(ds));
                        if(_.isNil(results?.[1])) return;
                        self.bankDataList.push(results[1]);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Transfer Slips.` });
                    })
                    .finally(() => {
                        self.refresh(clear);
                    });
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    onEditorPreparing: self.onEditorPreparing,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    scrolling: { useNative: true },
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "bankCompanyID",
                                dataType: "number",
                                caption: "Escrow Account",
                                validationRules: [{ type: "required" }],
                            },
                            lookupKey: self.lookupItems.ESCROW_ACCOUNTS,
                            regionId: self.order.regionID
                        }),
                        // {
                        //     dataField: "bankCompanyID",
                        //     caption: "Bank",
                        //     lookup: {
                        //         dataSource: self.escrowBanks,
                        //         valueExpr: "id",
                        //         displayExpr: "name"
                        //     },
                        //     validationRules: [
                        //         { type: "required" },
                        //     ],
                        // },
                        DxGridUtils.dateColumn({
                            dataField: "transferDate",
                            sortIndex: 0,
                            sortOrder: "desc",
                        }),
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        DxGridUtils.dateColumn({
                            dataField: "dateVerified",
                        }),
                        {
                            dataField: "verifiedByName",
                        },
                        {
                            dataField: "reconID",
                            caption: "Recon ID",
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "Total",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "Total: {0}",
                                summaryType: "sum"
                            },
                        ]
                    },
                    onInitNewRow(e) {
                        e.data.bankCompanyID = self.selectedBankCompanyID;
                        e.data.isNew = true;
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    update: self.onGridUpdate
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Transfer Slip";
                self.itemTypeNamePlural = "Transfer Slips";
                self.itemKey = "transferSlipID";
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_ACCOUNTS);
                self.readOnlyColumns = ["dateVerified", "bankCompanyID", "amount", "branchID", "reconID", "verifiedByName", "verifiedByUsersID"];

                self.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled(){ return self.actionDisabled; }
                    },
                    // {
                    //     name: "print",
                    //     text: "Print",
                    //     eventName: "print",
                    //     requireSelection: true,
                    //     allowMultiSelection: true,
                    //     tooltip: `Print ${self.itemTypeName}`,
                    //     disabled(){ return self.actionDisabled; }
                    // },
                    // {
                    //     name: "micr-slip",
                    //     text: "MICR Slip",
                    //     eventName: "micr-slip",
                    //     requireSelection: true,
                    //     allowMultiSelection: true,
                    //     tooltip: "Print MICR Transfer Slip",
                    //     disabled() {
                    //         return self.micrSlipDisabled;
                    //     }
                    // }
                ];
            },

            onAddItem(e) {
                const self = this;
                let values = {transferDate: DateTimeHelper.nowTenant('MM/dd/yyyy'), bankCompanyID: self.selectedBankCompanyID};
                let newItem = new TransferSlipDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                self.save(newItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} was created.` });
                        self.items.push(new TransferSlipDto(result));
                        let transferSlipID = _.get(result, self.itemKey);
                        self.onSelectItem({transferSlipID});
                    }).catch(error => {
                        self.$toast.error({ message: `Error creating ${self.itemTypeName}.` });
                    });
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if(self.actionDisabled) return;
                let items = e.data;
                let ok = function (args) {
                    let ids = _.map(items, self.itemKey);
                    let apiPromise = self.$api.TransferSlipsApi.delete(self.selectedBankCompanyID, self.unVerifiedOnly, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.items = _.map(result, c => new TransferSlipDto(c));
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh(true);
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), 0) === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new TransferSlipDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                        self.items[itemIndex] = updatedItem;
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh(false);
                    });
            },

            onGridSelectionChanged(e) {
                this.showGridSelector = false;
                this.selectedItem = (_.get(e, "selectedRowsData.length", 0) == 1) ? e.selectedRowsData[0] : {};
                this.showGridSelector = !_.isEmpty(this.selectedItem);
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                if(_.indexOf(this.readOnlyColumns, e.dataField) >= 0) {e.editorOptions.readOnly = true; return;}
            },

            onEscrowAccountChange(e) {
                if(_.isNil(e?.event)) return;
                this.fetchData(true);
            },

            onSelectItem(data) {
                const self = this;
                self.refresh(true);
                self.$nextTick().then(() => {
                    self.gridInstance.selectRows([data.transferSlipID], false);
                    self.gridInstance.navigateToRow(data.transferSlipID);
                });
            },

            onUpdateTransferIDs(e) {
                this.transferIDs = e || [];
                this.saveDetail();
            },

            onUnverifiedOnlyChange(e) {
                this.unVerifiedOnly = e;
                this.fetchData(true);
            },

            printTransferSlips(transferSlipIDs) {
                const self = this;
                let apiPromise = self.getActiveTransferSlipDocTemplateId()
                    .then(docTemplateId => {
                        if(docTemplateId <= 0) {
                            self.$dialog.messageBox("Transfer Slip Error", "<p>The transfer slip document template associated with the current escrow account could not be found or is not assigned.</p><p>Please contact your administrator.</p>");
                            return Promise.resolve(false);
                        }
                        return self.$store.dispatch(DOCUMENT_ACTIONS.ADD_TRANSFER_SLIPS_TO_QUEUE, {
                            orderId: self.orderId,
                            transferSlipIDs,
                            docTemplateId
                        }).then(() => true);
                    });
                return self.$rqBusy.wait(apiPromise)
                    .then(docReady => {
                        if(!docReady) return;
                        self.launchDocumentViewer();
                    });
            },

            getActiveTransferSlipDocTemplateId() {
                const self = this;
                let docTemplateInfo = _.find(self.bankDocTemplates, item => item.companyId === self.selectedBankCompanyID);
                let docTemplateId = _.getNumber(docTemplateInfo, "data.documentTemplateID", 0);
                if(docTemplateId > 0) return Promise.resolve(docTemplateId);
                return self.$api.CompaniesApi.getTransferSlipTemplateByCompanyId(self.selectedBankCompanyID)
                    .then(docTemplate => {
                        docTemplateId = _.getNumber(docTemplate, "documentTemplateID", 0);
                        if(docTemplateId <= 0) return 0;
                        self.bankDocTemplates.push({
                            companyId: self.selectedBankCompanyID,
                            data: docTemplate
                        })
                        return docTemplateId;
                    })
            },

            launchDocumentViewer() {
                const self = this;
                self.$dialog.open({
                    title: "Preview Transfer Slips",
                    height: "95%",
                    width: "95%",
                    resizable: false,
                    component: DocumentViewer,
                    closeOnly: true,
                    closeTitle: "Close"
                });
            },

            refresh(clear=false) {
                if(!this.gridInstance) return;
                if (clear) {
                    this.gridInstance.option("focusedRowIndex", -1);
                    this.gridInstance.clearSelection();
                }
                this.gridInstance.refresh();
            },

            save(item, changes){
                const self = this;
                if(self.readOnly) return Promise.resolve(false);
                if(changes.length === 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.TransferSlipsApi.save(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise);
            },

            saveDetail(){
                const self = this;
                if (_.isEqual(self.selectedKey, 0)) return Promise.resolve(true);
                let apiPromise = self.$api.TransferSlipsApi.saveDetail(self.selectedKey, self.transferIDs);
                return self.$rqBusy.wait(apiPromise).then(() => {
                    self.fetchData(false);
                });
            },
        }
    }
</script>
