<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="loanDataGrid"
            automation_id="tbl_loans"
            title="Available Loans"
            title-size="sm"
            :data-source="gridDataSource"
            :config="gridConfig"
            @selectionChanged="onSelectionChanged"
            export-file-name="loan-data"
            hide-show-column-chooser
            hide-search
            fixed-header
        />
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default{
        name: "InvoiceLoanPicker",
        props:{
            loans:{type: Array},
        },
        data(){
            return{
                selectedLoans:[]
            }
        },
        computed:{
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
            }),
        },
        created(){
            this.loadGridConfig();
        },
        methods:{
            loadGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "loanOrder",
                            caption: "Loan Order",
                            dataType: "number",
                            width: 150
                        },
                        {
                            dataField: "number",
                            caption: "Loan Number",
                            dataType: "string",
                            width: 150
                        },

                        {
                            dataField: "amount",
                            caption: "Amount",
                            allowEditing: false,
                            alignment: "right",
                            width: 150,
                            cellTemplate: DxGridUtils.moneyCellTemplate
                        },
                    ]
                }
                self.gridDataSource = {
                    key: "loanID",
                    load (loadOptions) {
                        return Promise.resolve(self.loans);
                    },
                }
            },
            onSelectionChanged(e){
                this.selectedLoans = e.selectedRowsData;
            }
        }
    }
</script>