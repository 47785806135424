<template>
    <tr>
        <td class="rq-col-selection">
            <b-form-checkbox
                automation_id="chk_item_select"
                :disabled="readOnly"
                v-model="prepaidLineData.isSelected"
            />
        </td>
        <td v-if="includeAlta" class="rq-col-input rq-col-category">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_category' }"
                :items="altaCategories"
                value-expr="name"
                display-expr="name"
                :search-enabled="false"
                :read-only="readOnly"
                v-model="prepaidLineData.category"
            />
        </td>
        <td v-if="isHud" class="rq-col-input rq-col-line-number rq-col-readonly">
            <rq-input-number
                v-model="prepaidLineData.hudLine"
                automation_id="txt_hudLine"
                no-form-control-class
                v-focus-select-all
                readonly
            />
        </td>
        <td :class="{
            'rq-col-prepaid-description': true,
            'with-date-range': cdfLineNo === 3 || hudLineNo === 901,
            'rq-col-input': !multiInputDescription,
            'rq-col-multi-input': multiInputDescription
        }">
            <input
                :id="`txt_prepaid_line_description_${prepaidLineData.prepaidSectionDataID}`"
                :automation_id="`txt_prepaid_line_description_${prepaidLineData.prepaidSectionDataID}`"
                ref="lineDescriptionInput"
                type="text"
                maxlength="150"
                :disabled="prepaidLineData.required || isPaidByOtherLine"
                v-model="prepaidLineData.description"
                v-focus-select-all
            />
            <rq-input-number v-if="cdfLineNo === 1 || hudLineNo === 903"
                v-model="prepaidLineData.prepaidHIPMonths"
                defaultValue="0"
                minValue="0"
                maxValue="100"
                class="rq-clear-input-group"
                cssClass="month-input"
                automation_id="txt_hip_months"
                append-text="mo."
                input-group
                v-focus-select-all
                :disabled="readOnly"
            />
            <rq-input-number v-else-if="cdfLineNo === 2 || hudLineNo === 902"
                v-model="prepaidLineData.prepaidMIPMonths"
                defaultValue="0"
                minValue="0"
                maxValue="100"
                class="rq-clear-input-group"
                cssClass="month-input"
                automation_id="txt_mip_months"
                append-text="mo."
                input-group
                v-focus-select-all
                :disabled="readOnly"
            />
            <div v-else-if="multiInputDescription" class="container">
                <div v-if="cdfLineNo === 3 || hudLineNo === 901" class="row">
                    <div class="col-12" :class="{'overridden': prepaidLineData.isPrepaidIntAmountOverridden }">
                        <rq-input-number v-model="prepaidLineData.prepaidIntAmountValue"
                            defaultValue="0"
                            prefix="$"
                            decimals="4"
                            class="rq-clear-input-group"
                            cssClass="currency-input"
                            automation_id="txt_borrower_at_closing_amount"
                            append-text="per day"
                            input-group
                            v-focus-select-all
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col-12">
                        <rq-date-range
                            input-class="form-control-sm"
                            v-model:start-date="prepaidLineData.prepaidIntFromDateValue"
                            v-model:end-date="prepaidLineData.prepaidIntToDateValue"
                            :overriddenStartDate="prepaidLineData.isPrepaidIntFromDateOverridden"
                            :overriddenEndDate="prepaidLineData.isPrepaidIntToDateOverridden"
                            no-calendars
                            show-calendar-drop-downs
                            borderless
                            borderless-input
                            allow-back-date
                            :disabled="readOnly"
                        />
                    </div>
                    <!-- <div v-if="hudLineNo === 901" class="col-3">
                        <rq-input-number v-model="prepaidLineData.prepaidIntPercent"
                            defaultValue="0"
                            decimals="3"
                            minValue="0"
                            maxValue="100"
                            automation_id="txt_prepaid_percentage_rate"
                            class="rq-clear-input-group"
                            cssClass="percent-input"
                            append-text="%"
                            input-group
                            v-focus-select-all
                        />
                    </div> -->
                    <!-- <div v-if="hudLineNo === 901" class="col-6">
                        <rq-input-number v-model="prepaidLineData.prepaidIntTotalDays"
                            defaultValue="0"
                            minValue="0"
                            maxValue="100"
                            class="rq-clear-input-group"
                            cssClass="month-input"
                            automation_id="txt_prepaid_total_days"
                            append-text="# of days override"
                            input-group
                            v-focus-select-all
                        />
                    </div> -->
                </div>
                <div v-if="cdfLineNo === 4" class="row">
                    <div class="col">
                        <rq-input-number v-model="prepaidLineData.propertyTaxMonths"
                            defaultValue="0"
                            minValue="0"
                            maxValue="99"
                            class="rq-clear-input-group"
                            cssClass="month-input"
                            automation_id="txt_property_tax_months"
                            append-text="mo."
                            input-group
                            v-focus-select-all
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </div>
        </td>
        <td :class="{'rq-col-input rq-col-prepaid-payee': true, 'rq-col-readonly': isReadOnlyLine }">
            <company-picker
                ref="refPayeeCompany"
                automation_id="pic_Payee_company"
                dialogTitle="Select Payee"
                :disabled="isReadOnlyLine"
                v-model="prepaidLineData.payeeCompany"
            />
        </td>

        <td :class="{'rq-col-input rq-col-currency':true, 'rq-col-readonly': isReadOnlyLine, 'overridden': prepaidLineData.isBorrowerAtClosingOverridden && prepaidLineData.isOverridableLine}">
            <rq-input-number
                v-model="prepaidLineData.borrowerAtClosingValue"
                defaultValue="0"
                prefix="$"
                decimals="2"
                automation_id="txt_borrower_at_closing_amount"
                :readonly="isReadOnlyLine"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td v-if="isCdf" :class="{'rq-col-input rq-col-currency': true, 'rq-col-readonly': readOnly }">
            <rq-input-number
                v-model="prepaidLineData.borrowerBeforeClosing"
                defaultValue="0"
                prefix="$"
                decimals="2"
                automation_id="txt_borrower_before_closing_amount"
                :readonly="readOnly"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td v-if="!isWithOutSeller" :class="{'rq-col-input rq-col-currency': true, 'rq-col-readonly': isReadOnlyLine }">
            <rq-input-number
                v-model="prepaidLineData.sellerAtClosing"
                defaultValue="0"
                prefix="$"
                decimals="2"
                automation_id="txt_borrower_at_closing_amount"
                :readonly="isReadOnlyLine"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td v-if="isCdf && !isWithOutSeller" :class="{'rq-col-input rq-col-currency': true, 'rq-col-readonly': readOnly }">
            <rq-input-number
                v-model="prepaidLineData.sellerBeforeClosing"
                defaultValue="0"
                prefix="$"
                decimals="2"
                automation_id="txt_borrower_at_closing_amount"
                :readonly="readOnly"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td v-if="isCdf" class="rq-col-input rq-col-readonly">
            <input type="text" class="text-right" :value="formatAmount(prepaidLineData.paidByOthers)" v-focus-select-all readonly>
        </td>
        <td v-if="isCdf" class="rq-col-cdf-section">
            <span class="text-nowrap">{{cdfSections[0].name}}</span>
        </td>
        <td v-if="isHud" :class="{'rq-col-input rq-col-poc-whom': true, 'rq-col-readonly': isReadOnlyLine }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_poc_who' }"
                :items="pocWhoOptions"
                value-expr="id"
                display-expr="name"
                :read-only="isReadOnlyLine"
                v-model="prepaidLineData.poc"
            />
        </td>
        <td v-if="isHud" :class="{'rq-col-input rq-col-currency': true, 'rq-col-readonly': isReadOnlyLine }">
            <rq-input-number
                v-model="prepaidLineData.pocAmount"
                defaultValue="0"
                minValue="0"
                prefix="$"
                decimals="2"
                automation_id="txt_poc_amount"
                :readonly="isReadOnlyLine"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td :class="{'rq-col-input rq-col-net-fund': true, 'rq-col-readonly': isReadOnlyLine }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_net_fund' }"
                :items="netFundOptions"
                value-expr="id"
                display-expr="name"
                :read-only="isReadOnlyLine"
                v-model="prepaidLineData.netFund"
            />
        </td>
        <td :class="{'rq-col-input rq-col-prepaid-acct-code': true, 'rq-col-readonly': !canEditAccountCode }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_acct_code' }"
                :items="accountCodeOptions"
                value-expr="id"
                display-expr="name"
                :read-only="!canEditAccountCode"
                v-model="prepaidLineData.accountCodeID"
            />
        </td>
        <td v-if="isHud2010" class="rq-col-input rq-col-line-type rq-col-readonly">
            <input :automation_id="`txt_hud_line_type_`+prepaidLineData.prepaidSectionDataID" :id="`txt_hud_line_type_`+prepaidLineData.prepaidSectionDataID" class="form-control" type="text" readonly v-model="prepaidLineData.hudLineTypeDescription" />
        </td>
        <td v-if="includeAlta" class="rq-col-selection rq-col-print">
            <b-form-checkbox
                automation_id="chk_do_not_print"
                v-model="prepaidLineData.doNotPrint"
            />
        </td>
    </tr>
</template>
<script>
    import { DateTime } from "luxon";
    import { mapState, mapGetters } from "vuex";
    import { CompanyPicker } from '@/shared/components/rq';
    import { AltaCategories, SETTLEMENT_TYPE, POCWhoOptions } from '@settlement/models';
    import { useCommonSettlementSecurity } from "@settlement/helpers";

    export default {
        name: "PrepaidSectionLine",
        components: {
            CompanyPicker
        },
        props: {
            expandDetail: { type: Boolean, default: false },
            lineData: { type: Object, default: () => ({}) },
            readOnly: { type: Boolean, default: false },
            settlementType: { type: Number, default: SETTLEMENT_TYPE.CDF }
        },
        setup() {
            return useCommonSettlementSecurity();
        },
        data() {
            return {
                prepaidLineData: this.lineData,
                defaultPayeeCompany:null,
                closeDate: "",
                fundDate: "",
                cdfSections: [
                    { id: 'F', name: 'F' }
                ],
                printOptions: [
                    { id: 0, name: 'No' },
                    { id: 1, name: 'Yes' }
                ]
            }
        },
        computed: {
            ...mapState({
                loans: state => state.orders.loans || [],
                isWithOutSeller: state => _.getBool(state, "orders.orderSummary.isWithOutSeller"),
                includeAltaValue: state => _.getBool(state, "orders.orderSummary.includeAlta"),
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            includeAlta() { return this.isCdf && this.includeAltaValue; },
            netFundOptions() { return this.lookupHelpers.getLookupItems(this.lookupItems.NET_FUND); },
            accountCodeOptions() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, null, this.prepaidLineData.accountCodeID); },
            altaCategories() { return AltaCategories.lookupItems; },
            pocWhoOptions() { return POCWhoOptions.lookupItems; },
            isCdf() { return this.settlementType === SETTLEMENT_TYPE.CDF; },
            isHud1974() { return this.settlementType === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.settlementType === SETTLEMENT_TYPE.HUD_2010; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            // Reference: In case we decide to add back override days in UI
            // overrideDates() { return _.parseNumber(this.prepaidLineData.prepaidIntTotalDays, 0) > 0; },
            numOfDays() {

                if (this.isCdf && this.prepaidLineData.line != 3) return 0;
                if (this.isHud && this.prepaidLineData.hudLine != 901) return 0;

                if (this.prepaidLineData.prepaidIntFromDateValue === this.prepaidLineData.prepaidIntToDateValue) return 0; // same day

                let startDate = DateTime.fromISO(this.prepaidLineData.prepaidIntFromDateValue).isValid ? DateTime.fromISO(this.prepaidLineData.prepaidIntFromDateValue) : DateTime.now();
                let endDate = DateTime.fromISO(this.prepaidLineData.prepaidIntToDateValue).isValid ? DateTime.fromISO(this.prepaidLineData.prepaidIntToDateValue) : DateTime.now();
                let daysFromDate = endDate.diff(startDate, 'days').days;

                // Reference: In case we decide to add back override days in UI
                // let useOverrideDays = _.parseNumber(this.prepaidLineData.prepaidIntTotalDays, 0) > 0;
                // let days = useOverrideDays ? this.prepaidLineData.prepaidIntTotalDays : daysFromDate;

                if (daysFromDate === 0) return 1; // moment() has issue with projecting one day difference when dates are within one day

                return daysFromDate; // Note: allow for negative days in calculation
            },
            // disableDailyAmount() {
            //     return _.parseNumber(this.prepaidLineData.prepaidIntPercent, 0) > 0;
            // },
            cdfLineNo() { return _.parseNumber(this.prepaidLineData.line, null); },
            hudLineNo() { return _.parseNumber(this.prepaidLineData.hudLine, null); },
            multiInputDescription() {
                return _.includes([1,2,3,4], this.cdfLineNo) || _.includes([901, 902, 903], this.hudLineNo);
            },
            isReadOnlyLine() { return this.readOnly || this.isHud2010DisclosureLine || this.isPaidByOtherLine},
            isHud2010DisclosureLine() { return this.isHud2010 && this.prepaidLineData.isDisclosureLine; },
            isPaidByOtherLine() { return !_.isNil(this.prepaidLineData.paidBySource) },
            hasPaidByOtherLine() { return !_.isNil(this.prepaidLineData.paidByCompanyID) },
            canEditAccountCode() {
                // Check account code configuration permission
                // If onlySysAdminCanEditAccountCode is set and if default line, only System Admin allowed permission to edit account code
                if (!_.isNil(this.prepaidLineData.defaultedSettlementType) && !this.allowEditAccountCode)
                    return false;

                return !this.isReadOnlyLine;
            },
        },
        watch: {
            lineData: {
                handler: function(newVal, oldVal) {
                    this.prepaidLineData = newVal;
                }
            },
            numOfDays(newValue, oldValue) {
                if (newValue === oldValue) return;

                if (this.prepaidLineData.line === 3 || this.prepaidLineData.hudLine === 901) {
                    let amountPerDay = _.get(this, "prepaidLineData.prepaidIntAmountValue", 0);
                    let paidAtSeller = _.get(this, "prepaidLineData.sellerAtClosing", 0);
                    let paidByOthers = _.get(this, "prepaidLineData.paidByOthers", 0);
                    let percentage = _.get(this, "prepaidLineData.prepaidIntPercent", 0);
                    this.calculateBorrowerAtClosing(amountPerDay, newValue, paidAtSeller, paidByOthers, percentage);
                }
            },
            "prepaidLineData.prepaidIntAmountValue"(newValue, oldValue) {
                const self = this;
                if (newValue === oldValue) return;

                if (self.prepaidLineData.line === 3 || self.prepaidLineData.hudLine === 901) {
                    let paidAtSeller = _.get(self, "prepaidLineData.sellerAtClosing", 0);
                    let paidByOthers = _.get(self, "prepaidLineData.paidByOthers", 0);
                    let percentage = _.get(self, "prepaidLineData.prepaidIntPercent", 0);
                    self.calculateBorrowerAtClosing(newValue, self.numOfDays, paidAtSeller, paidByOthers, percentage);

                    // Set default if payee empty and prepaid int amount has value
                    let prepaidIntPayee = _.getNumber(self, "prepaidLineData.payeeCompanyID", 0);
                    if (_.isNaN(prepaidIntPayee) || prepaidIntPayee === 0) {
                        if (newValue > 0) {
                            let selectedLoan = _.find(self.loans, function(l) { return l.loanID === self.prepaidLineData.loanID; });
                            self.prepaidLineData.payeeCompanyID = selectedLoan.lenderID;
                            self.prepaidLineData.payeeCompanyName = selectedLoan.lender;
                        }
                    }
                }
            },
            "prepaidLineData.paidByOthers"(newValue, oldValue) {
                if (newValue === oldValue) return;

                if (this.prepaidLineData.line === 3 || this.prepaidLineData.hudLine === 901) {
                    let amountPerDay = _.get(this, "prepaidLineData.prepaidIntAmountValue", 0);
                    let paidAtSeller = _.get(this, "prepaidLineData.sellerAtClosing", 0);
                    let percentage = _.get(this, "prepaidLineData.prepaidIntPercent", 0);
                    this.calculateBorrowerAtClosing(amountPerDay, this.numOfDays, paidAtSeller, newValue, percentage);
                }
            },
            "prepaidLineData.sellerAtClosing"(newValue, oldValue) {
                if (newValue === oldValue) return;

                if (this.prepaidLineData.line === 3 || this.prepaidLineData.hudLine === 901) {
                    let amountPerDay = _.get(this, "prepaidLineData.prepaidIntAmountValue", 0);
                    let paidByOthers = _.get(this, "prepaidLineData.paidByOthers", 0);
                    let percentage = _.get(this, "prepaidLineData.prepaidIntPercent", 0);
                    this.calculateBorrowerAtClosing(amountPerDay, this.numOfDays, newValue, paidByOthers, percentage);
                }
            }
        },

        created() {
            const self = this;

            // Default logic for Dates when creating a Prepaid Interest item
            self.closeDate = _.get(self,"$store.state.orders.order.closeDate", '');
            self.fundDate = _.get(self,"$store.state.orders.order.fundDate", '');

            // Default logic for prepaid interest - payee, date
            if (this.prepaidLineData.line === 3 || this.prepaidLineData.hudLine === 901) {
                let fromDate = (!_.isEmpty(self.fundDate)) ? self.fundDate : self.closeDate;
                self.prepaidLineData.prepaidIntFromDate = (!_.isEmpty(fromDate)) ? fromDate : null;

                let fromDt = fromDate && DateTime.fromISO(fromDate);
                self.prepaidLineData.prepaidIntToDate = !_.isNil(fromDt) && fromDt.isValid ? fromDt.plus({ months: 1 }).startOf("month").toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;

                // Commented updates for default payee as this is handled on server side whenever lender changes
                // let selectedLoan = _.find(self.loans, function(l) { return l.loanID === self.prepaidLineData.loanID; });

                // if (_.isNil(self.prepaidLineData.payeeCompanyID)) {
                //     self.prepaidLineData.payeeCompanyID = selectedLoan.lenderID;
                //     self.prepaidLineData.payeeCompanyName = selectedLoan.lender;
                // }
            }

            this.recalculateBorrowerAtClosing(); // auto calculate the borrowerAtClosing prospective value when entering component

            this.$events.on("prepaids::focus-line", this.onFocusLineEvent);
        },

        beforeUnmount() {
            this.$events.off("prepaids::focus-line", this.onFocusLineEvent);
        },

        methods: {
            onFocusLineEvent(e) {
                if(e.id !== this.prepaidLineData.prepaidSectionDataID) return;
                this.focusDescription();
            },
            recalculateBorrowerAtClosing() {
                if (this.prepaidLineData.line === 3 || this.prepaidLineData.hudLine === 901) {
                    let amountPerDay = _.get(this, "prepaidLineData.prepaidIntAmountValue", 0);
                    let paidAtSeller = _.get(this, "prepaidLineData.sellerAtClosing", 0);
                    let paidByOthers = _.get(this, "prepaidLineData.paidByOthers", 0);
                    let percentage = _.get(this, "prepaidLineData.prepaidIntPercent", 0);
                    this.calculateBorrowerAtClosing(amountPerDay, this.numOfDays, paidAtSeller, paidByOthers, percentage);
                }
            },
            calculateBorrowerAtClosing(amountPerDay, numOfDays, paidAtSeller, paidByOthers, percentage, overriddenDays) {
                // Calculation for borrowerAtClosing
                // borrowerAtClose = (Amount per day x number of days) - Paid By Others - Paid From Seller At Closing

                // Hud prepaid interest line can potentially factor in percentage values into calculations here
                // [RQO-7687] We are capturing percentage parameterized value but have decided not to factor it in calculation and remove it from
                // input by design
                let isHudPrepaidIntLine = this.prepaidLineData.hudLine === 901;
                //RQO-31880 next 4 lines were not being used so I commented them out
                // let useOverrideDays = isHudPrepaidIntLine && (_.parseNumber(overriddenDays, 0) > 0);
                // let daysInCurrentYear = _.parseBool(moment(this.prepaidLineData.prepaidIntFromDate).isLeapYear(), false) ? 366 : 365;
                // let loanId = this.prepaidLineData.loanID;
                // let selectedLoan = _.find(this.loans, function(l) { return l.loanID === loanId; });

                // Hud doesn't deduct PBO values from original parent line for borrower Amount since PBO line is a separate line added
                let deductions = isHudPrepaidIntLine ? paidAtSeller : (paidByOthers + paidAtSeller);
                let dailyAmount = amountPerDay;
                let totalBorrowerAmount = dailyAmount * numOfDays;

                // Note: allow for negative values
                this.prepaidLineData.borrowerAtClosing = totalBorrowerAmount - deductions;
            },
            formatAmount(amount) {
                return amount != null ? accounting.formatMoney(amount) : "";
            },
            focusDescription() {
                _.invoke(this, "$refs.lineDescriptionInput.focus");
            }
        }

    }
</script>
