import {DateTime} from "luxon";
import {UserSecuritySettings} from "../../../shared/models/models";

export class InvoiceDetail{
    constructor(options) {
        options = options || {};
        this.agentPremiumCalculated = _.parseNumber(options.agentPremiumCalculated, 0);
        this.agentPremiumOverride = _.parseNumber(options.agentPremiumOverride, 0);
        this.balance  = _.parseNumber(options.balance, 0);
        this.creditMemos = options.creditMemos;
        this.creditTotal = _.parseNumber(options.creditTotal, 0);
        this.customerCompanyID = options.customerCompanyID;
        this.customerCompanyName = options.customerCompanyName;
        this.customerContactID = options.customerContactID;
        this.customerContactName = options.customerContactName;
        this.date1 = options.date1;
        this.date2 = options.date2;
        this.defaultPayeeCompanyID = options.defaultPayeeCompanyID;
        this.defaultPayeeCompanyName = options.defaultPayeeCompanyName;
        this.deletedCreditMemos = options.deletedCreditMemos
        this.deletedInvoiceLines = options.deletedInvoiceLines
        this.deletedInvoicePayments = options.deletedInvoicePayments
        this.grandTotal = _.parseNumber(options.grandTotal, 0);
        this.initials = options.initials;
        this.invoiceDate = DateTime.fromISO(options.invoiceDate).toFormat('MM/dd/yyyy');
        this.invoiceID = options.invoiceID;
        this.invoiceLines = options.invoiceLines;
        this.invoiceNumUser = options.invoiceNumUser;
        this.invoicePayments = options.invoicePayments;
        this.isAgentPremiumOverridden = _.parseBool(options.isAgentPremiumOverridden, false);
        this.isDeleted = _.parseBool(options.isDeleted, false);
        this.isUnderwriterPremiumOverridden = _.parseBool(options.isUnderwriterPremiumOverridden, false);
        this.note1 = options.note1;
        this.note2 = options.note2;
        this.ordersID = options.ordersID;
        this.payeeCompanyID = options.payeeCompanyID;
        this.payeeCompanyName = options.payeeCompanyName;
        this.payeeContactID = options.payeeContactID;
        this.payeeContactName = options.payeeContactName;
        this.payeeOverride = options.payeeOverride;
        this.payment = options.payment;
        this.paymentDate = options.paymentDate;
        this.printPremiumSplit =  _.parseBool(options.printPremiumSplit, true);
        this.psLockDate = options.psLockDate;
        this.psLockFlag = options.psLockFlag;
        this.psLockSource = options.psLockSource;
        this.sequence = options.sequence;
        this.string1 = options.string1;
        this.string2 = options.string2;
        this.string3 = options.string3;
        this.string4 = options.string4;
        this.string5 = options.string5;
        this.string6 = options.string6;
        this.string7 = options.string7;
        this.taxRate = options.taxRate;
        this.defaultTaxRate = _.parseNumber(options.defaultTaxRate, 0);
        this.taxes = options.taxes;
        this.totalNonTaxable = options.totalNonTaxable;
        this.totalTaxable = options.totalTaxable;
        this.underwriterPremiumCalculated = _.parseNumber(options.underwriterPremiumCalculated, 0);
        this.underwriterPremiumOverride = _.parseNumber(options.underwriterPremiumOverride, 0);
        this.usersIDLock = options.usersIDLock;
        this.hasHud = _.parseBool(options.hasHud);
        this.hasCdf = _.parseBool(options.hasCdf);
        this.hasCss = _.parseBool(options.hasCss);
        this.hasCalculatedPremiums = _.parseBool(options.hasCalculatedPremiums);
    }

    get isTaxRateOverridden(){
        return this.taxRate !== this.defaultTaxRate;
    }
}

export class InvoiceLineModel{
    constructor(options){
        this.clientKey = _.uniqueId("invoice-line-");
        this.invoiceLineID = _.parseNumber(options.invoiceLineID, 0);
        this.description = options.description;
        this.quantity = _.parseNumber(options.quantity, null);
        this.taxable = _.parseBool(options.taxable, false);
        this.amount = _.parseNumber(options.amount, null);
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
        this.invoiceID = _.parseNumber(options.invoiceID, 0);
        this.sequence = _.parseNumber(options.sequence, null);
        this.isTemplate = _.parseBool(options.isTemplate, false);
        this.endorsementID = _.parseNumber(options.endorsementID, null);
        this.hudLineID = _.parseNumber(options.hudLineID, null);
        this.isPremium = _.parseBool(options.isPremium, false);
        this.loanID = _.parseNumber(options.loanID, null);
        this.cssLineID = _.parseNumber(options.cssLineID, null);
        this.cdfLineID = _.parseNumber(options.cdfLineID, null);
        this.isNew = _.parseBool(options.isNew, false);
        this.usersID = _.parseNumber(options.usersID, null);
    }

    get total(){
        if(_.isNil(this.quantity)) return this.amount;
        return this.amount * this.quantity;
    }
}

export class InvoicePaymentModel{
    constructor(options){
        this.clientKey = _.uniqueId("invoice-payment-");
        this.invoicePaymentID = _.parseNumber(options.invoicePaymentID, 0);
        this.psLockSource = options.psLockSource;
        this.psLockFlag = _.parseBool(options.psLockFlag, false);
        this.dateTimeRecorded = options.dateTimeRecorded;
        this.paymentDate = options.paymentDate;
        this.psLockDate = options.psLockDate;
        this.payment = _.parseNumber(options.payment, null);
        this.invoiceID = _.parseNumber(options.invoiceID, null);
        this.sequence = _.parseNumber(options.sequence, null);
        this.usersID = _.parseNumber(options.usersID, null);
        this.note = options.note;
        this.referenceNumber = options.referenceNumber;
        this.typeFundID = _.parseNumber(options.typeFundID, null);
        this.isNew = _.parseBool(options.isNew, false);
    }
}

export class CreditMemoModel{
    constructor(options){
        this.clientKey = _.uniqueId("credit-memo-");
        this.creditMemoID = _.parseNumber(options.creditMemoID, 0);
        this.number = options.number;
        this.psLockSource = options.psLockSource;
        this.psLockFlag = _.parseBool(options.psLockFlag, false);
        this.dateTimeRecorded = options.dateTimeRecorded;
        this.psLockDate = options.psLockDate;
        this.amount = _.parseNumber(options.amount, null);
        this.invoiceID = _.parseNumber(options.invoiceID, null);
        this.sequence = _.parseNumber(options.sequence, null);
        this.note = options.note;
        this.isNew = _.parseBool(options.isNew, false);

        this.details = _.map(options.details, d => {return new CreditMemoDetailModel(d);});
        this.deletedDetails = [];
    }
}

export class CreditMemoDetailModel{
    constructor(options){
        this.clientKey = _.uniqueId("credit-memo-detail-");
        this.creditMemoDetailID = options.creditMemoDetailID;
        this.description = options.description;
        this.quantity = _.parseNumber(options.quantity, null);
        this.taxRate = _.parseNumber(options.taxRate, null);
        this.taxable = _.parseBool(options.taxable, false);
        this.amount = _.parseNumber(options.amount, null);
        this.total = _.parseNumber(options.total, null);
        this.accountCode = _.parseNumber(options.accountCode, null);
        this.creditMemoID = _.parseNumber(options.creditMemoID, null);
        this.trackLineNumber = _.parseNumber(options.trackLineNumber, null);
        this.premiumIndicator = options.premiumIndicator;
        this.isNew = _.parseBool(options.isNew, false);
    }
}

const invoiceFillType = { Premiums: 0, PremiumsAndHUD: 1, PremiumsAndCSS: 2, CDFPremiumsAndCDF: 3, CDFPremiums: 4, PremiumsAndCDF: 5, RecordingFeesAndTransferTaxes: 6 };
export class InvoiceFillType {
    static get Premiums () {return invoiceFillType.Premiums;}
    static get PremiumsAndHUD () {return invoiceFillType.PremiumsAndHUD;}
    static get PremiumsAndCSS () {return invoiceFillType.PremiumsAndCSS;}
    static get CDFPremiumsAndCDF () {return invoiceFillType.CDFPremiumsAndCDF;}
    static get CDFPremiums () {return invoiceFillType.CDFPremiums;}
    static get PremiumsAndCDF () {return invoiceFillType.PremiumsAndCDF;}
    static get RecordingFeesAndTransferTaxes () {return invoiceFillType.RecordingFeesAndTransferTaxes;}
}