<template>
    <tr>
        <td scope="row" class="col-num">
            <label>{{formatNumber(localLineData.hudLine)}}</label>
        </td>
        <td :class="{ 'rq-col-input': true, 'overridden': localLineData.isDescriptionOverridden, 'rq-col-readonly': !canEditDescription }" :colspan="expandDetail && isProrationLine ? '3' : '1'">
            <input
                :id="`txt_debit_credit_description_`+localLineData.debitCreditSectionDataID"
                automation_id="txt_debit_credit_description"
                ref="descriptionInput"
                type="text"
                maxlength= "70"
                :readonly="!canEditDescription"
                v-model="localLineData.descriptionValue"
                v-focus-select-all
            />
            <rq-icon-button
                v-if="localLineData.isDescriptionOverridden && canEditDescription"
                icon="fas fa-undo"
                tooltip-text="Revert"
                class="rq-table-revert"
                @click="localLineData.revertOverrideDescription(localLineData)"
            />
        </td>
        <td v-if="expandDetail && !isProrationLine" :class="{'rq-col-input':true,  'rq-col-readonly': !canEditPayee}">
            <company-picker
                ref="refPayeeCompany"
                automation_id="pic_Payee_company"
                dialogTitle="Select Payee"
                :disabled="!canEditPayee"
                v-model="localLineData.payeeCompany"
            />
        </td>
        <td class="rq-col-checkbox" v-if="expandDetail && !isProrationLine">
            <b-form-checkbox
                v-if="canEditDebitCreditCheckBox"
                automation_id="chk_debit_credit"
                :disabled="readOnly"
                v-model="localLineData.isDebitCredit"
            />
        </td>
        <td :class="{ 'col-currency rq-col-input': true, 'overridden': localLineData.isAmountOverridden, 'rq-col-readonly': !canEditAmount }" :colspan="expandDetail && isProrationLine ? '6' : '1'">
            <rq-input-number
                automation_id="txt_debit_credit_section_line_amount"
                defaultValue="0"
                prefix="$"
                decimals="2"
                :readonly="!canEditAmount"
                @blur="onEditAmount"
                v-model="localLineData.amountValue"
                no-form-control-class
                v-focus-select-all
            />
            <rq-icon-button
                v-if="localLineData.isAmountOverridden && canEditAmount"
                icon="fas fa-undo"
                tooltip-text="Revert"
                class="rq-table-revert"
                @click="localLineData.revertOverrideAmount(localLineData)"
            />
        </td>
        <!-- TODO: Remove this reference as it may not apply to HUD lines -->
        <td v-if="isCdf && expandDetail && !isProrationLine" :class="{ 'rq-col-input': true, 'rq-col-readonly': !canEditAdjustmentCredit }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_adj_credit' }"
                :items="adjCreditOptions"
                value-expr="id"
                display-expr="name"
                :search-enabled="false"
                :read-only="!canEditAdjustmentCredit"
                v-model="localLineData.adjustmentCredit"
            />
        </td>
        <td v-if="expandDetail && !isProrationLine" :class="{'rq-col-input': true, 'rq-col-readonly': !canEditPoc }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_poc_who' }"
                :items="pocWhoOptions"
                value-expr="id"
                display-expr="name"
                :read-only="!canEditPoc"
                v-model="localLineData.poc"
            />
        </td>
        <td v-if="expandDetail && !isProrationLine" :class="{'rq-col-input': true, 'rq-col-readonly': !canEditPoc }">
            <rq-input-number
                v-model="localLineData.pocAmount"
                defaultValue="0"
                minValue="0"
                prefix="$"
                decimals="2"
                automation_id="txt_poc_amount"
                :readonly="!canEditPoc"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td v-if="expandDetail && !isProrationLine" :class="{'rq-col-input': true, 'rq-col-readonly': !canEditNetFund }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_net_fund' }"
                :items="netFundOptions"
                value-expr="id"
                display-expr="name"
                :read-only="!canEditNetFund"
                v-model="localLineData.netFund"
            />
        </td>
        <td v-if="expandDetail && !isProrationLine" :class="{'rq-col-input': true, 'rq-col-readonly': !canEditAccountCode }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_acct_code' }"
                :disabled="!canEditAccountCode"
                :defaultItemVisible="false"
                :items="accountCodeOptions"
                v-model="localLineData.accountCodeID"
                value-expr="id"
                display-expr="name"
            />
        </td>
    </tr>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { SETTLEMENT_TYPE, POCWhoOptions } from '@settlement/models'
    import { CompanyPicker } from '@/shared/components/rq';
    import { useCommonSettlementSecurity } from "@settlement/helpers";

    export default {
        name: "DebitCreditSectionHudLine",
        components: { CompanyPicker },
        props: {
            expandDetail: { type: Boolean, default: false },
            lineData: { type: Object, default: () => ({}) },
            readOnly: { type: Boolean, default: false },
            hideDebitCredit: { type: Boolean, default: false }
        },
        setup() {
            return useCommonSettlementSecurity();
        },
        data() {
            return {
                localLineData: this.lineData,
                adjCreditOptions: [
                    { id: 1, name: 'Adjustments and Other Credits' },
                    { id: 2, name: 'Seller Credits' },
                ]
            }
        },
        computed: {
            ...mapState({
                loans: state => state.orders.loans || [],
                selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            selectedLoan() { return _.find(this.loans, l => l.loanID === this.localLineData.loanID); },
            pocWhoOptions() { return POCWhoOptions.lookupItems; },
            netFundOptions() { return this.lookupHelpers.getLookupItems(this.lookupItems.NET_FUND); },
            accountCodeOptions() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, null, this.localLineData.accountCodeID); },
            isProrationLine() { return _.parseBool(this.localLineData.isProration); },
            isPayoffLine() { return _.parseBool(this.localLineData.isPayOff); },
            isAutoDebitCredit() { return _.parseBool(this.localLineData.isAdjustmentForOwnerPolicyPaidbySeller) },
            canEditDescription() {
                const self = this;

                if (self.readOnly) return false;

                if (self.localLineData.isAdjustmentForOwnerPolicyPaidbySeller) return true;

                if (self.isHud) {
                    if (_.includes([203, 204], self.localLineData.hudLine)) return true; // exception for these required lines
                }

                return !(self.localLineData.required || self.isProrationLine || self.isPayoffLine || self.isAutoDebitCredit); // cannot edit if one of these lines
            },
            canEditDebitCreditCheckBox() {
                const self = this;
                if (_.includes([204, 205], self.localLineData.hudLine)) return false;

                return self.canEditDebitCredit;
            },
            canEditDebitCredit() {
                const self = this;

                if (self.readOnly) return false;

                // HUD specific lines that debit credit apply to below
                if (self.isHud) {
                    if (self.isProrationLine) return false;
                    if (self.isAutoDebitCredit) return false;
                    if (self.isPayoffLine) return false;
                    if (self.localLineData.hideDebitCredit) return false;

                    if (self.localLineData.hudSection === 100) {
                        switch(self.localLineData.hudLine) {
                            case 101:
                            case 103:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.hudSection === 400) {
                        switch(self.localLineData.hudLine) {
                            case 401:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.hudSection === 200) {
                        switch(self.localLineData.hudLine) {
                            case 201:
                            case 202:
                            case 205:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.hudSection === 500) {
                        switch(self.localLineData.hudLine) {
                            case 501:
                            case 502:
                            case 504:
                            case 505:
                                return false;
                            default:
                                return true;
                        }
                    }
                }

                return true;
            },
            canEditAdjustmentCredit() {
                const self = this;
                // HUD lines do not require adjustment credit input???
                return false;
            },
            canEditPayee() {
                const self = this;

                if (self.readOnly) return false;

                // [RQO-12629] - Enable Payee for Payoffs
                // switch (self.localLineData.hudLine) {
                //     case 504:
                //     case 505:
                //         return false;
                //     default:
                //         return self.canEditDebitCredit; // check for special lines elsewise
                // }
                if (self.isPayoffLine || self.localLineData.hudLine === 205)
                    return true;
                else
                    return self.canEditDebitCredit; // check for special lines elsewise
            },
            canEditPoc() {
                const self = this;

                if (self.readOnly) return false;

                switch (self.localLineData.hudLine) {
                    case 101:
                    case 103:
                    case 401:
                    case 201:
                    case 202:
                    case 501:
                    case 502:
                    case 504:
                    case 505:
                        return false;
                    default:
                        return true;
                }
            },
            canEditAccountCode() {
                const self = this;

                if (self.readOnly) return false;

                // Check account code configuration permission
                // If onlySysAdminCanEditAccountCode is set and if default line, only System Admin allowed permission to edit account code
                if (!_.isNil(this.localLineData.defaultedSettlementType) && !this.allowEditAccountCode)
                    return false;

                switch(self.localLineData.hudLine) {
                    case 101:
                    case 103:
                    case 401:
                    case 201:
                    case 202:
                    case 501:
                    case 502:
                    case 504:
                    case 505:
                        return false;
                    default:
                        return true;
                }
            },
            canEditAmount() {
                const self = this;

                if (self.readOnly) return false;

                if (self.localLineData.isAdjustmentForOwnerPolicyPaidbySeller) return true;

                if (self.isProrationLine) return true;

                if (self.isPayoffLine) return false;

                if (self.isAutoDebitCredit) return false;

                // Specific HUD lines that allow amount editing
                if (self.isHud === true) {
                    if (self.localLineData.hudSection === 100) {
                        switch(self.localLineData.hudLine) {
                            case 101:
                            case 103:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.hudSection === 400) {
                        switch(self.localLineData.hudLine) {
                            case 401:
                                return false;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.hudSection === 200) {
                        switch(self.localLineData.hudLine) {
                            case 201:
                                return self.selectedLoan.loanOrder === 1;
                            default:
                                return true;
                        }
                    }

                    if (self.localLineData.hudSection === 500) {
                        switch(self.localLineData.hudLine) {
                            case 502:
                            case 504:
                            case 505:
                                return false;
                            default:
                                return true;
                        }
                    }
                }

                return true;
            },
            canEditNetFund() {
                const self = this;
                return self.canEditDebitCredit;
            },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
            isHud() { return this.isHud1974 || this.isHud2010; },
        },
        watch: {
            "localLineData.isDebitCredit": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.canEditDebitCredit)
                        this.$events.emit('toggle-debit-credit', {
                            item:this.lineData
                        });
                }
            },
            "localLineData.amount": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.localLineData.isDebitCredit === true && this.canEditDebitCredit)
                        this.$events.emit('toggle-debit-credit', {
                            item: this.lineData
                        });

                    if (this.localLineData.hudLine === 201) {
                        this.$events.emit('update-earnest-money-hud', {
                            item: this.lineData
                        });
                    }
                }
            },
            "localLineData.overrideAmount": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.localLineData.isDebitCredit === true && this.canEditDebitCredit) {
                        this.$events.emit('toggle-debit-credit', {
                            item: this.lineData,
                            activeSection: this.localLineData.hudSection
                        });
                    }
                }
            },
            "localLineData.description": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.localLineData.isDebitCredit === true && this.canEditDebitCredit)
                        this.$events.emit('toggle-debit-credit', {
                            item: this.lineData
                        });
                }
            },
            "localLineData.overrideDescription": {
                handler: function(newVal, oldVal) {
                    if (newVal === oldVal) return;

                    if (this.localLineData.isDebitCredit === true && this.canEditDebitCredit)
                        this.$events.emit('toggle-debit-credit', {
                            item: this.lineData,
                            activeSection: this.localLineData.hudSection
                        });
                }
            },
            lineData: {
                handler: function(newValue, oldValue) {
                    this.localLineData = newValue;
                }
            },
        },
        mounted() {
            this.resetFocus();
        },
        methods: {
            onEditDepositAmount(e) {
                const self = this;
                let loan = _.find(self.loans, l => l.loanID === self.localLineData.loanID);
                let newAmount = e.value;
                let origAmount = self.localLineData.amount;

                let handleOk = function(e) {
                    return true;
                }

                let handleCancel = function(e) {
                    self.localLineData.amount = origAmount;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newAmount !== origAmount && loan.loanOrder == 1) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Deposit/Earnest money for Loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },
            onEditAmount(e) {
                let isDepositLine = this.localLineData.hudSection === 200 && this.localLineData.hudLine === 201;

                if (isDepositLine)
                    this.onEditDepositAmount(e);
            },
            formatNumber(lineNumber) {
                return lineNumber < 10 ? `0${lineNumber}` : `${lineNumber}`
            },
            formatAmount(amount) {
                return amount != null ? accounting.formatMoney(amount) : "";
            },
            resetFocus() {
                let line = this.localLineData;
                if (this.loans.length === 1 && line.hudSection === 100 && line.hudLine === 101)
                    this.$refs.descriptionInput.focus();
            }
        }

    }
</script>
