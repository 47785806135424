<template>
    <div class="content-wrapper">
        <rq-banner
            variant="warn"
            icon="fas fa-exclamation-triangle"
            message="Some invoices could not be deleted because you do not have permission to delete payments or invoices with payments applied."
            :visible="showBanner"
            dismissable
            @dismiss="onDismissBanner"
        />
        <rq-page-section title="Invoices" headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add_invoice" variant="theme" :disabled="!canAddInvoice" @click="onAddInvoice">Add Invoice</b-btn>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_invoices"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            export-file-name="invoice-data"
            :rq-editable="!readOnly && canEditInvoices"
            @select="onEditInvoice"
            @delete="onDeleteAction"
            @rowDoubleClick="onEditInvoice"
            hide-search
        />
    </div>
</template>
<script>

    import { mapState, mapGetters } from "vuex";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { INVOICE_MUTATIONS } from '@/store/mutations';
    import { INVOICE_ACTIONS } from '@/store/actions';

    const InvoiceDeletion = {
        CannotDelete: 0,
        UnpaidOnly: 1,
        Unrestricted: 2
    }
    const InvoiceEditing = {
        NewOnly: 0,
        UnpaidOnly: 1,
        Unrestricted: 2
    }
    const InvoicePayments = {
        CannotEnter: 0,
        NewOnly: 1,
        Unrestricted: 2
    }

    export default {
        name: "InvoiceList",
        mixins: [GridInvokerMixin({ grid: "dataGrid" }), GridCompanyPickerMixin],
        data(){
            return{
                invoices: [],
                showBanner: false
            }
        },
        computed:{
            ...mapState({
                order: state => state.orders.order,
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                orderInvoices: state => state.orders.invoices,
                readOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
            }),
            localSecurity(){ return this.securitySettings.findValues(["InvoicePayments", "InvoiceEditing", "InvoiceDeletion", "Invoices_ScreenAccess",]); },
            canAddInvoice() {
                if (this.isOrderLocked) return false;
                return  this.localSecurity.InvoiceEditing === InvoiceEditing.NewOnly ||
                        this.localSecurity.InvoiceEditing === InvoiceEditing.Unrestricted ||
                        this.localSecurity.Invoices_ScreenAccess === UserScreenAccessLevel.Full;
            },
            canDeletePayments(){ return !this.readOnly && (this.localSecurity.InvoicePayments == InvoicePayments.Unrestricted); },
            canEditInvoices() { return this.localSecurity.InvoiceEditing === InvoiceEditing.Unrestricted; },
            canEditUnpaidInvoicesOnly(){ return this.localSecurity.InvoiceEditing === InvoiceEditing.UnpaidOnly; },
            canDeleteInvoices() { return this.localSecurity.InvoiceDeletion !== InvoiceDeletion.CannotDelete; },
            canDeleteUnpaidInvoicesOnly() { return this.localSecurity.InvoiceDeletion === InvoiceDeletion.UnpaidOnly; }
        },
        watch: {
            orderInvoices: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.invoices = _.cloneDeep(newValue);
                },
                immediate: true
            },
            invoices(newValue, oldValue) {
                const self = this;
                if (newValue === oldValue) return;
                self.invokeGridMethod("refresh");
            }
        },
        created() {
            const self = this;
            self.loadGridActions();
            self.loadGridConfig();
        },
        methods:{
            loadGridConfig() {
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Customer/Invoicee",
                    companyIDExpr:"customerCompanyID",
                    companyNameExpr:"customerCompanyName",
                    contactIDExpr:"customerContactID",
                    contactNameExpr:"customerContactName",
                    showContactPicker: false
                };
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "invoiceID",
                            caption: "Invoice ID",
                            allowEditing: false,
                        },
                        {
                            dataField: "invoiceDate",
                            dataType: "date",
                            allowEditing: false,
                        },
                        {
                            dataField: "payment",
                            caption: "Payments",
                            allowEditing: false,
                            alignment: "right",
                            cellTemplate: DxGridUtils.moneyCellTemplate
                        },
                        {
                            dataField: "creditTotal",
                            caption: "Credits",
                            allowEditing: false,
                            alignment: "right",
                            cellTemplate: DxGridUtils.moneyCellTemplate
                        },
                        {
                            dataField: "balance",
                            caption: "Balance",
                            allowEditing: false,
                            alignment: "right",
                            cellTemplate: DxGridUtils.moneyCellTemplate
                        },
                        {
                            dataField: "taxRate",
                            caption: "Tax Rate",
                            allowEditing: true,
                            alignment: "right",
                            format: "fixedPoint",
                            precision: 2,
                            dataType: "number",
                            customizeText: function (cellInfo) {
                                let value = _.parseNumber(cellInfo.value, 0);
                                return value.toString() + "%";
                            }
                        },
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "customerCompanyName",
                                caption: "Customer/Invoicee"
                            },
                            ...payeePickerInfo
                        })
                    ]
                };

                self.gridDataSource = {
                    key: "invoiceID",
                    load: () => Promise.resolve(self.invoices),
                    update: self.onGridUpdate
                };
            },
            onGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.invoices, i => i.invoiceID === key);
                _.assign(item, values);
                self.showBanner = false;
                self.update(item);
            },

            hasEditPermission(item) {
                return _.isNil(item) ? false : this.canEditInvoices || (this.canEditUnpaidInvoicesOnly && _.parseNumber(item.payment, 0) === 0);
            },

            loadGridActions() {
                const self = this;
                self.selectionActions = [
                    {
                        name: "edit", text: "Edit", eventName: "select", requireSelection: true,
                        visible: e => !self.readOnly && self.hasEditPermission(e.data)
                    },
                    {
                        name: "view", text: "View", eventName: "select", requireSelection: true,
                        visible: e => self.readOnly || !self.hasEditPermission(e.data)
                    },
                    {
                        name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true,
                        disabled(e) {
                            if(self.readOnly) return true;
                            if(!self.localSecurity) return false;
                            if(!self.canDeleteInvoices) return "Access Restricted";
                            if(_.every(e.data, i => i.payment > 0) && self.canDeleteUnpaidInvoicesOnly) return "Access restricted for paid invoices";
                            return false;
                        }
                    }
                ];
            },

            onDeleteAction(e){
                const self = this;
                if(!e || !e.data || self.readOnly || !self.canDeleteInvoices || (_.every(e.data, i => i.payment > 0) && self.canDeleteUnpaidInvoicesOnly)) return;

                let okHandler = function (args) {
                    let eligibleForDeletion = self.canDeleteUnpaidInvoicesOnly
                        ? _.filter(e.data, item => _.parseNumber(item.payment, 0) === 0)
                        : e.data;
                    let toBeDeletedIds =  _.map(eligibleForDeletion, "invoiceID");
                    self.delete(toBeDeletedIds)
                        .then(() => {
                            self.showBanner = e.data.length !== toBeDeletedIds.length;
                        });
                    return true;
                };

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete the selected Invoice${e.data.length > 1 ? "s" : ""}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditInvoice(e){
                const self = this;
                if(!e || !e.data) return;
                self.navigateInvoiceDetail(e.data.invoiceID);
            },

            onAddInvoice(){
                if(this.readOnly) return;
                this.navigateInvoiceDetail();
            },

            update(item){
                const self = this;
                if(_.isNil(item) || self.readOnly || !self.hasEditPermission(item)) return;

                let invoiceData = self.invoices.slice();
                let promise = self.$api.InvoiceApi.update(self.orderId, item);
                return self.$rqBusy.wait(promise)
                    .then(result =>{
                        let index = _.findIndex(invoiceData, i => { return i.invoiceID === item.invoiceID;});
                        let current = invoiceData[index];
                        _.assign(current, result);
                        self.$store.commit(INVOICE_MUTATIONS.SET_ORDER_INVOICES, invoiceData);
                        return result;
                    }).catch(error => {
                        let message = `Error updating Invoice: ${error.message}`;
                        self.$log.fatal(`InvoiceList - ${message}`, error);
                        self.$toast.error(message);
                        return error;
                    });
            },

            delete(toBeDeletedIds){
                const self = this;
                if(_.isNil(toBeDeletedIds) || toBeDeletedIds.length === 0) return Promise.resolve(false);

                let invoiceData = self.invoices.slice();
                let promise = self.$api.InvoiceApi.deleteInvoices(self.orderId, toBeDeletedIds);
                return self.$rqBusy.wait(promise)
                    .then((deleteIds) =>{
                        let newList = _.filter(invoiceData, x => {
                            if(!_.some(deleteIds, y => x.invoiceID === y)) return x;
                        });
                        self.$store.commit(INVOICE_MUTATIONS.SET_ORDER_INVOICES, newList);
                        if(newList.length === 1) {
                            self.navigateInvoiceDetail(newList[0].invoiceID);
                        }
                        else {
                            self.invoices = newList;
                            self.invokeGridMethod("clearSelection");
                        }
                        return deleteIds;
                    })
                    .catch(error => {
                        let message = `Error deleting Invoice: ${error.message}`;
                        self.$log.fatal(`InvoiceList - ${message}`, error);
                        self.$toast.error(message);
                        return error;
                    });
            },

            navigateInvoiceDetail(invoiceId=0) {
                this.$router.push({ name:"o-inv:invoice", params: { invoiceId } });
            },

            onDismissBanner() {
                this.showBanner = false;
            }
        }
    }
</script>