<template>
<div>
    <div class="row subdivision-search">
        <div class="col-4 form-group">
            <label>Search:</label>
            <rq-search-input-group
                id="txt_subdivision_search"
                ref="txt_subdivision_search"
                v-model="searchText"
                placeholder="Search..."
                v-focus
                style="margin-bottom: -10px"
                tabindex="0"
                @keyup="onSearch"
                @search="onSearch"
                @clear="onSearchClear"
            />
        </div>
        <div class="col-8 form-group">
            <b-button automation_id="btn_add_subdivision"
                    ref="btn_add_subdivision"
                    variant="theme"
                    style="margin-top: 23px"
                    @click="onAddAction"
                    v-rq-tooltip.hover.top="{ title: `Add ${itemTypeName}` }"
                    :disabled="!hasFullAccess"
                    >Add {{itemTypeName}}</b-button>
        </div>
    </div>
    <div class="content-wrapper item-selection">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_subdivisions"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            export-file-name="property-subdivision-data"
            @edit="onEditAction"
            @delete="onDeleteAction"
            @contentReady="onGridContentReady"
            @selectionChanged="onSelectionChanged"
            @rowDoubleClick="onRowDoubleClick"
            hide-search
        />
    </div>
</div>
</template>

<script>
    import { mapState } from "vuex";
    import { LookupItem, SubdivisionDto, SystemLookupItemDto } from "@/shared/models/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"SubdivisionList",
        props: {
            selectedId: { type: Number, default: -1 },
            readOnly: { type: Boolean, default: false }
        },
        data () {
            return {
                itemTypeName: "Subdivision",
                gridReady: false,
                searchText: "",
            };
        },

        computed: {
            ...mapState({
                orderRegionID: state => state.orders.orderSummary.regionID,
                globalRegionId: state => state.system.globalRegionId,
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            selectionActions() {
                const self = this;
                return this.readOnly
                    ? [] : [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}`,
                            disabled: function(e) {
                                if (!self.hasFullAccess) {
                                    return "Access Restricted";
                                }
                                return false;
                            }
                        },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, tooltip: `Delete ${this.itemTypeName}`,
                            disabled: function(e) {
                                let selectedItem = _.get(e, "data", null) || [];

                                if (!self.hasFullAccess || (selectedItem.regionID !== self.user.regionID && self.user.regionID != self.globalRegionId)) {
                                    return "Access Restricted";
                                }
                                return false;
                            }
                        }];
            },
            localSecurity() { return this.securitySettings.findValues(["Subdivision_ScreenAccess"]); },
            hasFullAccess(){ return this.localSecurity.Subdivision_ScreenAccess === 2; }
        },

        created() {
            this.initGridConfig();
        },

        methods: {

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: 'subdivisionID', caption: 'SubdivisionID', visible: false, allowSearch: false },
                        { dataField: 'name', caption: 'Subdivision', sortIndex: 0, sortOrder: "asc", allowSearch: true },
                        { dataField: 'regionID', caption: 'RegionID', visible: false, allowSearch: false },
                        DxGridUtils.dateColumn({
                            dataField: 'patentDate',
                            caption: 'Date',
                            allowSearch: false,
                        }),
                        { dataField: 'plat', caption: 'Plat', allowSearch: false },
                        { dataField: 'notes', caption: 'Notes', allowSearch: false },
                        { dataField: 'region', caption: 'Region', allowSearch: false }
                    ],
                    selection: { mode: 'multiple' },
                    focusedRowEnabled: false,
                    height: "340"
                };
                self.gridDataSource = {
                    key: "subdivisionID",
                    load: self.fetchData
                };
            },

            fetchData () {
                const self = this;
                let apiPromise = self.$api.SubdivisionsApi.getSubdivisionsByRegion(self.orderRegionID);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        let dto = _.map(response, s => new SubdivisionDto(s));
                        return { data: dto, totalCount: dto.length };
                    }).catch(e => {
                        console.log(e);
                        return { data: null, totalCount: 0 };
                    });
            },

            dialogResult () {
                const self = this;
                if(!self.selectedItem) {
                    return { dialogMode: "selection", data: null };
                }
                else {
                    self.searchText = "";
                    self.gridInstance.searchByText(self.searchText);
                    let subdivisions = self.gridInstance.getDataSource().items();
                    let lookupItems = subdivisions
                        ? _.map(subdivisions, subdivision =>
                            new SystemLookupItemDto({
                                id: subdivision.subdivisionID,
                                name: subdivision.name,
                                regionID: subdivision.regionID,
                                data: null
                            })
                        )
                        : [];
                    return {
                        dialogMode: "selection",
                        data: self.selectedItem,
                        lookupItems
                    };
                }
            },

            onAddAction(e) {
                if(!this.hasFullAccess) return;
                this.$emit("add");
            },

            onGridContentReady(e) {
                const self = this;
                if(self.gridReady) return;

                if (self.selectedId > 0) {
                    e.component.option("selectedRowKeys", [self.selectedId]);
                    self.selectedItem = e.component.getSelectedRowsData()[0];
                }
                self.gridReady = true;
            },

            onDeleteAction(e) {
                const self = this;
                let item = e?.data;
                if(!item || !self.hasFullAccess) return;
                let ok = function (args) {
                    if(!item.canDelete) {
                        self.$toast.info(`${self.itemTypeName} ${item.name} cannot be deleted since it is assigned to a Property.`);
                        return;
                    }
                    let apiPromise = self.$api.SubdivisionsApi.deleteSubdivision(item.subdivisionID);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.refresh();
                            self.$toast.success({ message: `${self.itemTypeName} was deleted.` });
                            return true;}
                        )
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `${self.itemTypeName} ${item.name} cannot be deleted since it is assigned to a Property.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        });
                }

                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditAction(e) {
                this.$emit("edit", { data:e.data });
            },

            onRowDoubleClick(e){
                this.selectedItem = e.data;
                this.$emit("select", {dialogMode: "selection", data: this.selectedItem});
            },

            onSelectionChanged(e) {
                if(!this.gridReady) return;
                let removeKeys = [];
                if (!_.isEqual(e.selectedRowKeys, e.currentSelectedRowKeys) && e.currentSelectedRowKeys.length > 0) {
                    removeKeys = _.without(e.selectedRowKeys, e.currentSelectedRowKeys[0]);
                    if (removeKeys.length > 0) e.component.deselectRows(removeKeys);
                }
                this.selectedItem = _.findLast(e.selectedRowsData);
            },

            refresh() {
                this.gridInstance.refresh();
            },

            onSearch: _.debounce(function (e) {
                this.gridInstance.searchByText(this.searchText);
            }, 300, {trailing: true, leading: false}),

            onSearchClear() {
                this.searchText = "";
                this.gridInstance.searchByText(this.searchText);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .item-selection { height: 100%; max-height: 100%;}
    .subdivision-search {margin-left: .5rem;}
    .dx-datagrid-search-text {font-weight: bold; color: inherit; background-color: transparent;}
</style>
