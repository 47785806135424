<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner && hasErrors"
            dismissable
        />
        <rq-page-section title="Escrows/Impounds" header-size="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.hover.top="{ title: !canAddItem ? 'Max escrow items is 11' : '' }">
                        <b-button automation_id="btn_add_escrow_charge"
                            ref="btn_add_escrow_charge"
                            variant="theme"
                            @click="onAddItemClicked"
                            :disabled="!canAddItem || readOnly"
                            v-focus>
                            Add
                        </b-button>
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
            <div class="row">
                <div class="col-3 form-group mb-0">
                    <label for="payee_company">Payee</label>
                    <company-picker
                        ref="refPayeeCompany"
                        automation_id="pic_Payee_company"
                        dialogTitle="Select Payee"
                        :disabled="readOnly"
                        :showClearButton="false"
                        v-model="settlementData.payeeCompany">
                    </company-picker>
                </div>
                <div class="col pt-4">
                    <rq-radio-group
                        name="override-escrows_impounds"
                        v-model="settlementData.escrowOverrideStatus"
                        inline
                        @change="onChangeOverrideSelected"
                        :disabled="readOnly"
                        :options="overrideOptions"
                    />
                </div>
            </div>
        </rq-page-section>

        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="dg_escrow_data"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="escrow-data"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['isInactive']"
            :rq-editable="!readOnly"
            @delete="onDeleteItems"
            @netfund="onChangeNetFundItems"
            @editorPreparing="onEditorPreparing"
            focus-after-insert="first-row"
            hide-search
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { CompanyPicker } from '@/shared/components/rq';
    import { GlobalEventManager } from '@/app.events';
    import { AltaCategories, EscrowModel, EscrowTypes, SettlementStatementData, POCWhoOptions, SETTLEMENT_TYPE, SettlementTypeOption, EscrowOverrideOptions, HudLineTypes, SsGridActions } from '@settlement/models';
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { useCommonSettlementSecurity } from "@settlement/helpers";

    export default {
        name: "escrows",
        mixins: [BaseSettlementMixin, GridSystemLookupMixin, GridCompanyPickerMixin],
        components: { CompanyPicker },
        setup() {
            return useCommonSettlementSecurity();
        },
        data(){
            return {
                itemTypeNamePlural: "Charges" ,
                itemKey: "escrowSectionDataID",
                itemTypeName: "Charge",
                selectionActions: [],
                items: [],
                deletedItems: [],
                settlementData: {},
                originalData: [],
                originalSettlementData: {},
                validationErrors: [],
                selectedLoanId: 0,
                escrowCdfSections: [
                    { id: 'G', name: 'G' }
                ],
                EscrowTypes: EscrowTypes,
                overrideOptions: [
                    { automation_id: "radio_none", text: "No Override", tooltip: "No Override Applied", value: EscrowOverrideOptions.None },
                    { automation_id: "radio_partial", text: "Partial Override", tooltip: "Override Descriptions<br/>and Monthly Amounts", value: EscrowOverrideOptions.Partial },
                    { automation_id: "radio_full", text: "Full Override", tooltip: "Override Total in<br/>Borrower At Closing columns", value: EscrowOverrideOptions.Full }
                ],
                maxItems: 11
            };
        },

        computed:{
            ...mapState({
                prepaids: state => state.prepaids.prepaidSections
            }),
            altaCategories() { return AltaCategories.lookupItems; },
            gridStorageKey() { return `settlement_escrow_charges_${this.selectedView}_${this.isWithOutSeller}_${this.includeAlta}`; },
            gridInstance () { return this.$refs.dataGrid.gridInstance; },
            pocWhoOptions() { return POCWhoOptions.lookupItems; },
            canAddItem() {
                // max limit of escrows
                return this.items.length < this.maxItems;
            },
            hud2010BorrowerAtClosing() {
                let hud2010Line1001 =  _.find(this.items, item => item.hudLine === 1001 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                return _.get(hud2010Line1001, "borrowerAtClosingValue", 0);
            }
        },

        watch:{
            selectedLoanId(newValue, oldValue) {
                const self = this;
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                self.save();
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                let selectedViewIsHud = _.includes([SETTLEMENT_TYPE.HUD_2010, SETTLEMENT_TYPE.HUD_1974], newValue);
                // If settlement type is switching to a Hud version we refresh data to ensure
                // UI model is cleared of hudLineIDs from previous data model for
                // proper Hud mapping sake in data integrity. Also perform a reorder considering
                // that user may be switching between HUD versions to address gaps
                this.onChangeSSViewSelected(newValue);
                if (selectedViewIsHud)
                    this.fetchData();
            },
            isWithOutSeller(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeWithOutSeller(newValue);
            }
        },
        created() {
            const self = this;
            self.baseInit();
            self.selectionActions = [
                {
                    name: "Delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if(_.some(e.data, item => item.required === true)) {
                            return "Unselect required lines to delete";
                        } else {
                            return false;
                        }
                    },
                    tooltip: `Delete ${self.itemTypeName}`
                },
                {
                    name: "Apply-Net-Fund",
                    text: "Apply Net Fund",
                    eventName: "netfund",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if(self.isHud2010 && _.some(e.data, item => item.hudLine === 1001)) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    children: [
                        SsGridActions.NETFUND_NONE,
                        SsGridActions.NETFUND_POSITIVE,
                        SsGridActions.NETFUND_NEGATIVE
                    ]
                },
            ];

            self.initGridConfig();
            self.initGridDataSource();
            self.fetchData();
        },

        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },

        methods:{
            initNewRow(e){
                e.data = {
                    category: AltaCategories.Impounds
                };
            },
            sortItems(collection, sortBy) {
                const self = this;

                if (_.isNil(sortBy))
                    sortBy = (self.selectedView === SETTLEMENT_TYPE.CDF) ? 'cdfSection' : 'hudLine';

                return _.sortBy(collection, sortBy);
            },
            getNextAvailableHudLine() {
                const self = this;
                let hudData = self.isHud ? self.items : self.originalData;
                let hudItems = _.filter(hudData, i => i.hudLine > 1000 && i.hudLine < 1011); // exclude aggregate hud line
                let maxHudLineItem = _.maxBy(hudItems, function(i) { return i.hudLine; });
                return _.parseNumber(maxHudLineItem.hudLine, 0) + 1;
            },
            initGridConfig() {
                const self = this;
                self.gridConfig = {
                    onInitNewRow: self.initNewRow,
                    focusedRowEnabled: false,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            dataField: "category",
                            caption: 'Category',
                            width: 150,
                            lookup: {
                                dataSource: self.altaCategories,
                                displayExpr: "name",
                                valueExpr: "name"
                            },
                            visible: self.includeAlta,
                            showInColumnChooser: self.includeAlta
                        },
                        {
                            dataField: "hudLine",
                            showInColumnChooser: self.isHud,
                            allowEditing: false,
                            caption: "HUD Line",
                            visible: self.isHud,
                            width: 60
                        },
                        {
                            dataField: "descriptionValue",
                            caption: "Description",
                            editorOptions: {
                                disabled: true,
                                maxLength: 150
                            },
                            cellTemplate(cellElement, cellInfo) {
                                if(cellInfo.data.isDescriptionOverridden)
                                    cellElement.addClass("rq-overridden");
                                cellElement.append(cellInfo.text);
                            }
                        },
                        {
                            dataField: "numberOfMonthsValue",
                            caption: "Number of Months",
                            width: 100,
                            dataType: "number",
                            allowEditing: !self.readOnly,
                            setCellValue(newData, value, currentData) {
                                //Setting this within "setCellValue" allows the model setter to recalculate amount
                                newData.numberOfMonthsValue = value;
                            },
                        },
                        {
                            dataField: "monthAmountValue",
                            caption: "Monthly Amount",
                            width: 90,
                            dataType: "number",
                            format: { type: "currency", precision: 2 },
                            editorOptions: {
                                format: { type: "currency", precision: 2 },
                                disabled: true
                            },
                            setCellValue(newData, value, currentData) {
                                //Setting this within "setCellValue" allows the model setter to recalculate amount
                                newData.monthAmountValue = value;
                            },
                            cellTemplate(cellElement, cellInfo) {
                                if(cellInfo.data.isMonthAmountOverridden)
                                    cellElement.addClass("rq-overridden");
                                cellElement.append(cellInfo.text);
                            }
                        },
                        {
                            dataField: "borrowerOutsideValue",
                            caption: "Borrower Outside",
                            width: 90,
                            dataType: "number",
                            format: { type: "currency", precision: 2 },
                            editorOptions: {
                                format: { type: "currency", precision: 2 },
                                disabled: true
                            },
                            cellTemplate(cellElement, cellInfo) {
                                if(cellInfo.data.isBorrowerOutsideOverridden)
                                    cellElement.addClass("rq-overridden");

                                cellElement.append(accounting.formatMoney(cellInfo.text));
                            },
                            visible: self.isHud2010
                        },
                        {
                            caption: "Borrower",
                            alignment: "center",
                            columns: [
                            {
                                dataField: "borrowerAtClosingValue",
                                caption: "At Closing",
                                width: 90,
                                dataType: "number",
                                format: { type: "currency", precision: 2 },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 },
                                    disabled: true
                                },
                                cellTemplate(cellElement, cellInfo) {
                                    let displayText = cellInfo.text;
                                    // 2010 HUD - Hide values from UI for borrowerAtClosing from user suspecting there is values here
                                    // however there really is so that we don't lose data in case user switches between CDF, 1974 HUD
                                    if (self.isHud2010) {
                                        let isHudLine1001 = _.parseBool(cellInfo.data.hudLine === 1001, false);
                                        displayText = isHudLine1001 ? cellInfo.text : "";
                                    }

                                    if(cellInfo.data.isBorrowerAtClosingOverridden)
                                        cellElement.addClass("rq-overridden");

                                    cellElement.append(accounting.formatMoney(displayText));
                                }
                            },
                            {
                                dataField: "borrowerBeforeClosing",
                                caption: "Before Closing",
                                width: 105,
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                                showInColumnChooser: self.isCdf,
                                visible: self.isCdf,
                                allowEditing: !self.readOnly,
                            }]
                        },
                        {
                            caption: "Seller",
                            alignment: "center",
                            visible: !self.isWithOutSeller,
                            dataField: "",
                            columns: [{
                                dataField: "sellerAtClosingValue",
                                caption: "At Closing",
                                width: 90,
                                dataType: "number",
                                showInColumnChooser: !self.isWithOutSeller,
                                visible: !self.isWithOutSeller,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                setCellValue(newData, value, currentData) {
                                    //Setting this within "setCellValue" allows the model setter to recalculate amount
                                    newData.sellerAtClosingValue = value;
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                                allowEditing: !self.readOnly,
                            },
                            {
                                dataField: "sellerBeforeClosing",
                                caption: "Before Closing",
                                width: 105,
                                dataType: "number",
                                allowEditing: !self.readOnly,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                                showInColumnChooser: self.isCdf && !self.isWithOutSeller,
                                visible: self.isCdf && !self.isWithOutSeller,

                            }]

                        },
                        {
                            dataField: "paidByOthers",
                            caption: "Paid By Others",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 },
                                disabled: true
                            },
                            allowEditing: false,
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            showInColumnChooser: self.isCdf,
                            visible: self.isCdf
                        },
                        {
                            dataField: "poc",
                            caption: 'POC Whom',
                            width: 75,
                            editorOptions: {
                                searchMode: "startswith"
                            },
                            lookup: {
                                dataSource: self.pocWhoOptions,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            showInColumnChooser: self.isHud,
                            visible: self.isHud,
                            allowEditing: !self.readOnly,
                        },
                        {
                            dataField: "pocAmount",
                            caption: "POC Amount",
                            width: 90,
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            showInColumnChooser: self.isHud,
                            visible: self.isHud,
                            allowEditing: !self.readOnly,
                        },
                        {
                            dataField: "netFund",
                            caption: 'Net Fund',
                            width: 60,
                            allowEditing: !self.readOnly,
                            lookup: {
                                dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.NET_FUND),
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountCodeID",
                                dataType: "number",
                                caption: "Account Code",
                                allowEditing: !self.readOnly,
                                editorOptions: { showClearButton: true },
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            regionId: self.order.regionID,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        }),
                        // {
                        //     dataField: "accountCodeID",
                        //     caption: 'Account Code',
                        //     allowEditing: !self.readOnly,
                        //     editorOptions: {
                        //         showClearButton: true
                        //     },
                        //     lookup: {
                        //         dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES),
                        //         displayExpr: "name",
                        //         valueExpr: "id"
                        //     }
                        // },
                        {
                            dataField: "cdfSection",
                            caption: 'CDF Section',
                            width: 60,
                            editorOptions: {
                                searchMode: "startswith"
                            },
                            lookup: {
                                dataSource: self.escrowCdfSections,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            showInColumnChooser: self.isCdf,
                            visible: self.isCdf,
                            allowEditing: !self.readOnly,
                        },
                        {
                            dataField: "doNotPrint",
                            caption: "Do Not Print",
                            width: 100,
                            dataType: "boolean",
                            visible: self.includeAlta,
                            showInColumnChooser: self.includeAlta
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    storageKey: self.gridStorageKey,

                    summary:
                    {
                        totalItems: [
                        {
                            column: "descriptionValue",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data) {
                                return "TOTALS";
                            }
                        },
                        {
                            column: "borrowerOutsideValue",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "borrowerAtClosingValue",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data) {
                                return self.isHud2010 ? self.formatMoney(self.hud2010BorrowerAtClosing) : self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "borrowerBeforeClosing",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "sellerAtClosingValue",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "sellerBeforeClosing",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "paidByOthers",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            },
                            visible: self.isCdf
                        },
                        {
                            column: "pocAmount",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data) {
                                return self.formatMoney(data.value);
                            }
                        },
                        ]
                    }
                }
            },

            initGridDataSource() {
                const self = this;
                self.gridDataSource = {
                    key: self.itemKey,
                    load(){
                        return Promise.resolve(self.items);
                    },
                    insert(values){
                        self.onGridInsert(values);
                    },
                    update(key, values) {
                        self.onGridUpdate(key, values);
                    }
                };
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new EscrowModel(values);
                newItem.description = values["descriptionValue"];
                newItem.escrowSectionDataID = -self.items.length;
                newItem.loanID = self.selectedLoanId;

                newItem.lineType = EscrowTypes.EscrowItem;
                newItem.defaultedSettlementType = null;
                newItem.hudLineType = HudLineTypes.GFE_9; // Only 2010 HUD cares about hudLineType, hardcode

                newItem.hudLine = self.getNextAvailableHudLine();

                // New lines entered within the UI application is considered common among all settlement statements so
                // set all the sections
                newItem.hudSection = 1000;
                newItem.cdfSection = "G";

                if (_.has(values, "category"))
                    newItem.category = values.category;
                else
                    newItem.category = AltaCategories.TitleCharges;

                // Commit values of MonthAmount
                if (_.has(values, "monthAmountValue")) {
                    newItem.monthAmountValue = values.monthAmountValue;
                }

                // Commit values of numberOfMonths
                if (_.has(values, "numberOfMonthsValue")) {
                    newItem.numberOfMonthsValue = values.numberOfMonthsValue;
                }

                if (_.has(values, "borrowerAtClosingValue")) {
                    newItem.borrowerAtClosing = values.borrowerAtClosingValue;
                    newItem.overrideAmountOfPaidForBorrowerAtClosing = null;
                }

                if(_.has(values, "sellerAtClosingValue")) {
                    newItem.sellerAtClosingValue = values.sellerAtClosingValue; // Update using Setter
                }

                if (self.isHud2010){
                    let borrowerResult = newItem.calculateBorrowerOutsideResult(newItem.monthAmountValue, newItem.numberOfMonths);
                    newItem.misc_Dollar = borrowerResult;
                }

                self.items.push(newItem);

                if (self.isCdf)
                    self.updateCdfAggAdjLine();

                if (self.isHud) {
                    self.items = self.sortItems(self.items); // Sort for HUD since we want ordered hud lines, we don't do anything for CDF
                }

                if (self.isHud2010)
                    self.updateRollUpLine();

                return Promise.resolve(newItem);
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.escrowSectionDataID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new EscrowModel(_.assign(originalItem, values));
                // let updatedItem = Object.assign(originalItem, values);

                if (updatedItem.lineType !== EscrowTypes.EscrowAggregateAdjustment) {
                    // Commit values of Description
                    if (values.descriptionValue !== undefined) {
                        updatedItem.descriptionValue = values.descriptionValue;
                    }

                    // Commit values of MonthAmount
                    if (values.monthAmountValue !== undefined) {
                        updatedItem.monthAmountValue = values.monthAmountValue;
                    }

                    // Commit values of numberOfMonths
                    if (values.numberOfMonthsValue !== undefined) {
                        updatedItem.numberOfMonthsValue = values.numberOfMonthsValue;
                    }

                    if (values.sellerAtClosingValue !== undefined) {
                        updatedItem.sellerAtClosingValue = values.sellerAtClosingValue;
                    }

                    // Commit values of borrowerAtClosing
                    if (values.borrowerAtClosingValue !== undefined) {
                        updatedItem.borrowerAtClosingValue = values.borrowerAtClosingValue;
                    }
                    else {
                        /// When grid row is updated we need to auto calculate borrowerAtClosing values based on some values
                        // CDF, 1974 HUD
                        if (self.isCdf || self.isHud1974) {
                            let paidByOthers = _.parseNumber(updatedItem.paidByOthers, 0);
                            let borrowerResult = updatedItem.calculateBorrowerAtClosingResult(updatedItem.monthAmountValue, updatedItem.numberOfMonths, updatedItem.sellerAtClosingValue, paidByOthers);
                            updatedItem.borrowerAtClosing = borrowerResult;
                        }

                        // 2010 HUD
                        if (self.isHud2010) {
                            let borrowerResult = updatedItem.calculateBorrowerOutsideResult(updatedItem.monthAmountValue, updatedItem.numberOfMonths);
                            updatedItem.misc_Dollar = borrowerResult;
                        }
                        ///
                    }
                }

                self.items[itemIndex] = updatedItem;

                if (self.isHud2010)
                    self.updateRollUpLine();

                return Promise.resolve(updatedItem);
                // return self.save(self.items);
            },
            updateCdfAggAdjLine() { // update cdf line number for agg line since this is used to track for cdf mapping later
                const self = this;
                if (!self.isCdf) return;
                let itemIndex = _.findIndex(self.items, item => item.lineType === EscrowTypes.EscrowAggregateAdjustment);
                if (itemIndex < 0) return;
                self.items[itemIndex].line = self.items.length;
            },
            updateRollUpLine() {
                const self = this;
                if (!self.isHud2010) return; // Update roll up line for HUD 2010 line: 1001 only
                let itemIndex = _.findIndex(self.items, item => item.hudLine === 1001 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                if (itemIndex < 0) return;

                let total = _.sumBy(self.items, item => item.borrowerOutsideValue);
                self.items[itemIndex].borrowerAtClosing = total;
            },
            onChangeWithOutSeller(e) {
                const self = this;
                self.initGridConfig();
                self.$nextTick().then(() => {
                    _.invoke(self, "$refs.dataGrid.loadGrid");
                });
            },
            onChangeSSViewSelected(e) {
                // Following will set the proper columns per settlement view context
                let allowCdfOptions = _.parseBool(e === SETTLEMENT_TYPE.CDF);
                let allowHudOptions = !allowCdfOptions;

                // Operations that might occur when you switch view context (CDF, HUD)
                // Update and filter data in respect to the view we are changing to below
                this.items.length = 0; // reset items
                let escrowSectionsForSettlementType = _.filter(this.originalData, p => p.defaultedSettlementType === e || _.isNil(p.defaultedSettlementType));
                let sortBy = allowCdfOptions ? 'cdfSection' : 'hudLine';
                this.items = this.sortItems(_.map(escrowSectionsForSettlementType, result => new EscrowModel(result)), sortBy);

                // Refresh and respect the override status when switching between views
                this.onRevert(this.settlementData.escrowOverrideStatus);

                // CDF fields
                // this.gridInstance.columnOption("cdfSection", "visible", allowCdfOptions);
                // this.gridInstance.columnOption("cdfSection", "showInColumnChooser", allowCdfOptions);

                // this.gridInstance.columnOption("borrowerBeforeClosing", "visible", allowCdfOptions);
                // this.gridInstance.columnOption("borrowerBeforeClosing", "showInColumnChooser", allowCdfOptions);

                // this.gridInstance.columnOption("sellerBeforeClosing", "visible", allowCdfOptions);
                // this.gridInstance.columnOption("sellerBeforeClosing", "showInColumnChooser", allowCdfOptions);

                // this.gridInstance.columnOption("paidByOthers", "visible", allowCdfOptions);
                // this.gridInstance.columnOption("paidByOthers", "showInColumnChooser", allowCdfOptions);

                // HUD fields
                // this.gridInstance.columnOption("poc", "visible", allowHudOptions);
                // this.gridInstance.columnOption("poc", "showInColumnChooser", allowHudOptions);

                // this.gridInstance.columnOption("pocAmount", "visible", allowHudOptions);
                // this.gridInstance.columnOption("pocAmount", "showInColumnChooser", allowHudOptions);

                // this.gridInstance.columnOption("hudLine", "visible", allowHudOptions);
                // this.gridInstance.columnOption("hudLine", "showInColumnChooser", allowHudOptions);

                // this.gridInstance.refresh();

                this.initGridConfig();
                this.$nextTick().then(() => {
                    _.invoke(this, "$refs.dataGrid.loadGrid");
                })
            },

            onChangeOverrideSelected(e) {
                //this.gridInstance.cancelEditData(); //RQO-15659 removed this cancelEditData

                if(e === EscrowOverrideOptions.None || e === EscrowOverrideOptions.Partial) {
                    this.onRevert(e);
                }
            },
            onRevert(overrideStatus) {
                const self = this;

                _.forEach(self.items, (updatedItem) => {
                    if (updatedItem.lineType !== EscrowTypes.EscrowAggregateAdjustment) {

                        // Updated calculated and non override values
                        if (overrideStatus === EscrowOverrideOptions.None) {
                            updatedItem.monthAmountValue = updatedItem.monthAmount;
                            updatedItem.descriptionValue = updatedItem.description;

                            /// When grid row is updated we need to auto calculate borrowerAtClosing values based on some values
                            let paidAtSeller = _.parseNumber(updatedItem.sellerAtClosing, 0);
                            let paidByOthers = _.parseNumber(updatedItem.paidByOthers, 0);

                            if (self.isCdf || self.isHud1974) {
                                let borrowerResult = updatedItem.calculateBorrowerAtClosingResult(updatedItem.monthAmountValue, updatedItem.numberOfMonths, paidAtSeller, paidByOthers);
                                updatedItem.borrowerAtClosing = borrowerResult;
                                updatedItem.overrideAmountOfPaidForBorrowerAtClosing = null;
                            }

                            if (self.isHud2010) {
                                let borrowerResult = updatedItem.calculateBorrowerOutsideResult(updatedItem.monthAmountValue, updatedItem.numberOfMonths);
                                // updatedItem.borrowerAtClosing = borrowerResult;
                                // updatedItem.overrideAmountOfPaidForBorrowerAtClosing = null;
                                updatedItem.misc_Dollar = borrowerResult;
                                updatedItem.overrideBorrowerOutside = null
                            }
                            ///
                        }

                        if (overrideStatus === EscrowOverrideOptions.Partial) {
                            /// When grid row is updated we need to auto calculate borrowerAtClosing values based on some values
                            let paidAtSeller = _.parseNumber(updatedItem.sellerAtClosing, 0);
                            let paidByOthers = _.parseNumber(updatedItem.paidByOthers, 0);

                            if (self.isCdf || self.isHud1974) {
                                let borrowerResult = updatedItem.calculateBorrowerAtClosingResult(updatedItem.monthAmountValue, updatedItem.numberOfMonths, paidAtSeller, paidByOthers);
                                updatedItem.borrowerAtClosing = borrowerResult;
                                updatedItem.overrideAmountOfPaidForBorrowerAtClosing = null;
                            }

                            if (self.isHud2010) {
                                let borrowerResult = updatedItem.calculateBorrowerOutsideResult(updatedItem.monthAmountValue, updatedItem.numberOfMonths);
                                // updatedItem.borrowerAtClosing = borrowerResult;
                                // updatedItem.overrideAmountOfPaidForBorrowerAtClosing = null;
                                updatedItem.misc_Dollar = borrowerResult;
                                updatedItem.overrideBorrowerOutside = null;
                            }
                            ///
                        }

                        if (overrideStatus === EscrowOverrideOptions.Full) {
                            // Full override nothing to calculate
                        }

                    }

                    let itemIndex = _.findIndex(self.items, item => item.escrowSectionDataID === updatedItem.escrowSectionDataID);
                    self.items[itemIndex] = updatedItem;
                });

                // On reverting we need to take care to update borrower at closing for 2010 Hud calculated values
                if (self.isHud2010)
                    self.updateRollUpLine();

                self.gridInstance.refresh();
            },

            onSave(e){
                let userInitiated = _.getBool(e, "userInitiated");
                if(userInitiated) {
                    this.gridInstance.saveEditData();
                }
                this.save(userInitiated);
            },

            onCancel(){
                if(!this.hasEscrowChanges() && !this.hasSettlementDataChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            onAddItemClicked(lineType) {
                const self = this;
                if(!self.gridInstance) return;
                self.gridInstance.addRow();
            },

            onDeleteItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteItems(selectedItems);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected charge(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onChangeNetFundItems(e) {
                const self = this;
                if (!e || !e.data) return;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    item.netFund = _.getNumber(e.action, "key", 0);
                });

                self.gridInstance.clearSelection();
                self.gridInstance.refresh();
            },

            deleteItems(items) {
                const self = this;

                _.forEach(items, (item) => {
                    let key = _.get(item, self.itemKey);
                    self.removeItems(key);

                    if(key > 0){
                        self.deletedItems.push(item);
                    }
                });

                if (self.isCdf)
                    self.updateCdfAggAdjLine();

                if (self.isHud2010)
                    self.updateRollUpLine();

                // Since we maintain HUD lines we also have to maintain the line ordering for UI purpose
                self.reorderHudItems();
            },

            removeItems(key) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                self.gridInstance.refresh();
            },

            reorderHudItems() {
                const self = this;
                let hudItems = _.filter(self.items, i => i.hudLine > 1000 && i.hudLine < 1011); // exclude aggregate hud line

                if (hudItems.length === 0) return; // No hud items

                let minLine = _.minBy(hudItems, function(i) { return i.hudLine; });
                let maxLine = _.maxBy(hudItems, function(i) { return i.hudLine; });

                let lineNumber = _.parseNumber(minLine.hudLine, 0);
                _.forEach(_.sortBy(hudItems, h => h.hudLine), (hudItem) => {
                    if (hudItem.hudLine != lineNumber) {
                        let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === hudItem.escrowSectionDataID);
                        self.items[itemIndex].hudLine = lineNumber;
                        hudItem.hudLine = lineNumber;
                    }
                    lineNumber = lineNumber + 1;
                });
            },

            fetchData() {
                const self = this;
                self.deletedItems = [];

                return self.fetchSettlementStatementData()
                    .then(() => {
                        return self.fetchEscrowCharges().then(() => {
                            this.reorderHudItems();
                        });
                    });
            },

            fetchEscrowCharges() {
                const self = this;
                if(self.selectedLoanId === 0) return Promise.resolve(true);
                let getPromise = self.$api.EscrowChargesApi.getRecordsByLoan(self.selectedLoanId);

                return self.$rqBusy.wait(getPromise).then(results=>{
                    let escrowCharges = _.filter(results, r => r.defaultedSettlementType === self.selectedView || _.isNil(r.defaultedSettlementType));
                    self.items = self.sortItems(_.map(escrowCharges, result => new EscrowModel(result)));
                    self.originalData = _.map(results, result => new EscrowModel(result));
                    self.gridInstance.refresh();
                });
            },

            fetchSettlementStatementData() {
                const self = this;
                let getPromise = self.$api.EscrowChargesApi.getSettlementDataByLoan(self.selectedLoanId);

                return self.$rqBusy.wait(getPromise).then(result=>{

                    if (result == null) return Promise.resolve(true);
                    self.settlementData = new SettlementStatementData(result, self.loans);
                    self.originalSettlementData = new SettlementStatementData(self.settlementData);
                });
            },

            isValidToAdd(linetype) {
                const self = this;
                return true;
            },

            save(refreshData=true){
                const self = this;

                // RQO-14523 - Don't allow a blank payee to be saved; revert back to the previous payee if it exists
                if (_.isNil(self.settlementData.payeeCompanyID) && !_.isNil(self.originalSettlementData.payeeCompanyID)) {
                    self.settlementData.payeeCompanyID = self.originalSettlementData.payeeCompanyID;
                    self.settlementData.payeeCompanyName = self.originalSettlementData.payeeCompanyName;
                }

                if(!self.hasEscrowChanges() && !self.hasSettlementDataChanges()){
                    if(refreshData) self.fetchData();
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                self.showErrorBanner = self.hasErrors;
                if (self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false, abort: true});
                    return;
                }

                if (self.hasSettlementDataChanges())
                    self.saveSettlementData(refreshData);

                if (self.hasEscrowChanges())
                    return self.saveEscrowCharges(refreshData);

                return Promise.resolve(true);
            },

            saveEscrowCharges(refreshData=true) {
                const self = this;
                // let savePromise = self.$api.EscrowChargesApi.saveRecords(self.items);
                let savePromise = self.$api.EscrowChargesApi.saveDeleteRecords(self.items, self.deletedItems);
                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchEscrowCharges();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Escrow Charges Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Escrow Charges");
                        console.error(err);
                    });

            },

            saveSettlementData(refreshData=true) {
                const self = this;

                if (self.validationErrors.length > 0) return;

                let savePromise = self.$api.EscrowChargesApi.saveSettlementData(self.settlementData);
                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchSettlementStatementData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Escrow Lender Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Escrow Lender");
                        console.error(err);
                    });
            },

            hasEscrowChanges(){
                let viewItems = _.filter(this.originalData, i => i.defaultedSettlementType === this.selectedView || _.isNil(i.defaultedSettlementType));

                //original data was empty and user added something0
                if(viewItems.length === 0 && this.items.length > 0){
                    return true;
                }
                //if the arrays are different length, then there are changes. Items have been deleted and need to save
                if(this.items.length !== viewItems.length){
                    return true;
                }
                //need to compare the two arrays for changes
                let changes = this.getAuditChanges(viewItems, this.items);
                return changes.length > 0;
            },

            hasSettlementDataChanges() {
                //need to compare the two arrays for changes
                let changes = this.getAuditChanges(this.originalSettlementData, this.settlementData);
                return changes.length > 0;
            },

            onEditorPreparing(e) {
                const self = this;

                if(e.type === "selection" || e.row.rowType !== "data") return;

                e.editorOptions.disabled = self.isColumnDisabled(e.dataField, e.row.data);
            },

            isColumnDisabled(dataField, data) {
                if (data.defaultedSettlementType == null) return false; // Non defaulted lines are enabled

                if (data.lineType === EscrowTypes.EscrowAggregateAdjustment) {
                    if (dataField === "borrowerAtClosingValue") {
                        if (this.isHud2010) return true; // 2010 HUD we cannot edit borrower at closing on aggregate line
                        return false;
                    }

                    if (dataField === "borrowerOutsideValue") {
                        if (this.isHud2010) return false; // 2010 HUD we allow borrowerOutsideValue edit on aggregate line
                        return true;
                    }

                    if (dataField === "monthAmountValue" || dataField === "descriptionValue" || dataField === "numberOfMonthsValue")
                        return true;
                }

                // Check account code configuration permission
                // If onlySysAdminCanEditAccountCode is set and if default line, only System Admin allowed permission to edit account code
                if (dataField === "accountCodeID" && !_.isNil(data.defaultedSettlementType) && !this.allowEditAccountCode)
                    return true;

                switch (dataField) {
                    case 'descriptionValue':
                        return this.settlementData.escrowOverrideStatus === EscrowOverrideOptions.None;
                    case 'numberOfMonthsValue':
                    case 'sellerAtClosingValue':
                        if (this.isHud2010 && data.hudLine === 1001) return true; // 2010 Hud always disabled for this field
                        return false;
                    case 'monthAmountValue':
                        if (this.isHud2010 && data.hudLine === 1001) return true; // 2010 Hud always disabled for this field
                        return this.settlementData.escrowOverrideStatus === EscrowOverrideOptions.None;
                    case 'borrowerAtClosingValue':
                        if (this.isHud2010) return true; // 2010 Hud we cannot edit borrower at closing
                        return this.settlementData.escrowOverrideStatus === EscrowOverrideOptions.None
                            || this.settlementData.escrowOverrideStatus === EscrowOverrideOptions.Partial;
                    case 'borrowerOutsideValue':
                        return this.settlementData.escrowOverrideStatus === EscrowOverrideOptions.None
                            || this.settlementData.escrowOverrideStatus === EscrowOverrideOptions.Partial;
                    case 'netFund':
                    case 'poc':
                    case 'pocAmount':
                        if (this.isHud2010 && _.includes([1001, 1011], data.hudLine)) return true; // 2010 Hud always disabled for this field
                        return false;
                    default:
                        return false;
                }
            },
        }
    }
</script>

