<template>
    <div class="rq-container content-wrapper">
        <div class="row survey-search mb-3">
            <div class="col-4 form-group">
                <label>Search:</label>
                <rq-search-input-group
                    id="txt_survey_search"
                    ref="txt_survey_search"
                    v-model="searchText"
                    placeholder="Search..."
                    v-focus
                    style="margin-bottom: -10px"
                    tabindex="0"
                    @keyup="onSearch"
                    @search="onSearch"
                    @clear="onSearchClear"
                />
            </div>
            <div v-rq-tooltip.hover.html.top :title="hasSurveyAccess ? 'Add Survey' : 'Add Survey<br/>(Access Restricted)'" class="col-auto">
                <b-btn
                    automation_id="btn_add_survey"
                    variant="theme"
                    style="margin-top: 23px"
                    :disabled="!this.hasSurveyAccess"
                    @click="onAddAction">Add {{itemTypeName}}
                </b-btn>
            </div>
        </div>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_surveys"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            export-file-name="property-survey-data"
            @edit="onEditAction"
            @delete="onDeleteAction"
            @contentReady="onGridContentReady"
            @selectionChanged="onSelectionChanged"
            @rowDoubleClick="onRowDoubleClick"
            hide-search
        />
    </div>
</template>

<script>
    import { LookupItem, SurveyDto, SystemLookupItemDto } from "@/shared/models/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"SurveyList",
        props: {
            selectedId: { type: Number, default: -1 },
            propertyState: { type: String, default: "" },
            readOnly: { type: Boolean, default: false }
        },
        data () {
            return {
                itemTypeName: "Survey",
                gridReady: false,
                searchText: "",
            };
        },

        computed: {
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            selectionActions() {
                return this.readOnly
                    ? []
                    : [
                        { name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}`, disabled: !this.hasSurveyAccess},
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: !this.hasSurveyAccess }
                    ];
            },
            hasSurveyAccess() { return this.securitySettings.hasAccess("Survey") && !this.securitySettings.isReadOnly("Survey"); }
        },

        created() {
            this.initGridConfig();
        },

        methods: {

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: 'surveyID', caption: 'SurveyID', visible: false, allowSearch: false },
                        { dataField: 'name', caption: 'Abstract', sortIndex: 0, sortOrder: "asc", allowSearch: true },
                        { dataField: 'abstractNumber', caption: 'Abstract Number', allowSearch: false },
                        { dataField: 'acres', caption: 'Acres', allowSearch: false },
                        { dataField: 'patentNumber', caption: 'Patent Number', allowSearch: false },
                        DxGridUtils.dateColumn({
                            dataField: 'patentDate',
                            caption: 'Patent Date',
                            allowSearch: false,
                        }),
                        { dataField: 'county', caption: 'County', visible: false, allowSearch: false },
                        { dataField: 'state', caption: 'State', visible: false, allowSearch: false }
                    ]
                };
                self.gridDataSource = {
                    key: "surveyID",
                    load: self.fetchData
                };
            },

            fetchData () {
                const self = this;
                let apiPromise = self.propertyState.length == 2
                    ? self.$api.SurveysApi.getSurveysByState(self.propertyState)
                    : self.$api.SurveysApi.getSurveys();
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        let dto = _.map(response, s => new SurveyDto(s));
                        return { data: dto, totalCount: dto.length };
                    }).catch(e => {
                        console.log(e);
                        return { data: null, totalCount: 0 };
                    });
            },

            dialogResult () {
                const self = this;

                if(!self.selectedItem) {
                    return { dialogMode: "selection", data: null };
                } else {
                    self.searchText = "";
                    self.gridInstance.searchByText(self.searchText);
                    let surveys = self.gridInstance.getDataSource().items();
                    let lookupItems = surveys ? _.map(surveys, survey => new SystemLookupItemDto({id: survey.surveyID, name: survey.name, regionID: 0, data: null})) : [];
                    return { dialogMode: "selection", data: self.selectedItem, lookupItems } ;
                }
            },

            onAddAction(e) {
                this.$emit("add");
            },

            onGridContentReady(e) {
                const self = this;
                if(self.gridReady) return;

                if (self.selectedId > 0) {
                    e.component.option("selectedRowKeys", [self.selectedId]);
                    self.selectedItem = e.component.getSelectedRowsData()[0];
                }
                self.gridReady = true;
            },

            onDeleteAction(e) {
                if(!e || !e.data) return;
                const self = this;
                let item = e.data;
                let ok = function (args) {
                    if(!item.canDelete) {
                        self.$toast.info(`${self.itemTypeName} ${item.name} cannot be deleted since it is assigned to a Property.`);
                        return;
                    }
                    let apiPromise = self.$api.SurveysApi.deleteSurvey(item.surveyID);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.refresh();
                            self.$toast.success({ message: `${self.itemTypeName} was deleted.` });
                            return true;}
                        )
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `${self.itemTypeName} ${item.name} cannot be deleted since it is assigned to a Property.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        });
                }

                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditAction(e) {
                this.$emit("edit", { data:e.data });
            },

            onRowDoubleClick(e){
                this.selectedItem = e.data;
                this.$emit("select", {dialogMode: "selection", data: this.selectedItem});
            },

            onSelectionChanged(e) {
                if(!this.gridReady) return;
                let removeKeys = [];
                if (!_.isEqual(e.selectedRowKeys, e.currentSelectedRowKeys) && e.currentSelectedRowKeys.length > 0) {
                    removeKeys = _.without(e.selectedRowKeys, e.currentSelectedRowKeys[0]);
                    if (removeKeys.length > 0) e.component.deselectRows(removeKeys);
                }
                this.selectedItem = _.findLast(e.selectedRowsData);
            },

            refresh() {
                this.gridInstance.refresh();
            },

            onSearch: _.debounce(function (e) {
                this.gridInstance.searchByText(this.searchText);
            }, 300, {trailing: true, leading: false}),

            onSearchClear() {
                this.searchText = "";
                this.gridInstance.searchByText(this.searchText);
            }
        }
    };
</script>
