<template>
    <div class="task-notes">
        <rqdx-action-data-grid
            ref="dataGrid"
            title="Task Notes"
            title-size="sm"
            :automation_id="elementName('tbl', itemTypeName)"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', itemTypeName, 'data')"
            v-model:search-value="searchTerm"
            search-mode="field"
            :read-only="readOnly"
            @delete="onDeleteNote"
            @edit="onEditNote"
            @rowDoubleClick="onEditNote"
            fixed-header
            hide-show-column-chooser>
            <template #toolbar>
                <div class="rq-toolbar-slot">
                    <ul class="nav">
                        <li class="nav-item">
                            <b-btn
                                :automation_id="elementName('btn_add', itemTypeName)"
                                variant="theme"
                                :disabled="readOnly"
                                @click="onAddNote">
                                Add
                            </b-btn>
                        </li>
                    </ul>
                </div>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { OrderWorkflowTaskNote } from '../models';
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {
        props: {
            taskId: { type: Number, default: 0 },
            taskName: { type: String, default: "" },
            readOnly: { default: false }
        },

        data () {
            return {
                searchTerm: '',
                notes: [],
                validationErrors: []
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
        },

        watch: {
            taskId(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.fetchData();
            },
            searchTerm(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.searchNotes();
            }
        },

        created() {
            this.fetchData();
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            fetchData() {
                const self = this;
                if(self.taskId <= 0) {
                    self.notes = [];
                    return;
                }
                let apiPromise = self.$api.OrderWorkflowApi.getNotes(self.taskId);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.notes = _.map(result, n => new OrderWorkflowTaskNote(n));
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },
            getGrid() {
                return _.get(this.$refs, "dataGrid.gridInstance", {});
            },
            initNonReactiveVariables() {
                const self = this;
                self.itemKey = "orderWorkflowTaskNoteID";
                self.itemTypeName = "Workflow Task Note";
                self.itemTypeNamePlural = "Workflow Task Notes";
                self.selectionActions = [
                    { name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}`, disabled: function(e) { return self.readOnly || !_.isEqual(e.data.usersID, self.user.usersID);} },
                    { name: "delete", text: `Delete`, eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) { return self.readOnly || !_.every(e.data, ['usersID', self.user.usersID]);} }
                    ];
            },
            initGridConfig() {
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: self.itemKey, visible: false, allowEditing: false },
                        { dataField: "noteDate", dataType: "datetime", caption: "Date / time", width: 140, allowEditing: false, sortIndex: 0, sortOrder: "desc" },
                        { dataField: "notes", caption: "Note", width: "65%", cellTemplate: DxGridUtils.truncateCellTemplate },
                        { dataField: "author", width: "auto", allowEditing: false },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                    noDataText: `No notes found for this Workflow Task.`,
                    paging: { enabled: false },
                };
                self.gridDataSource = {
                    key: self.itemKey,
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.notes);
                    },
                };
            },
            onAddNote() {
                this.clear();
                this.editNote(new OrderWorkflowTaskNote({orderWorkflowTaskID: this.taskId, usersID: this.user.usersID, author: this.user.displayName, noteDate: DateTimeHelper.now()}));
            },
            onDeleteNote(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);
                    let apiPromise = self.$api.OrderWorkflowApi.deleteNote(toBeDeletedKeys);
                    self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.removeGridItems(items);
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            self.$emit("change", { data: self.notes });
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                    self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onEditNote(e) {
                if(!e || !e.data || this.readOnly) return;
                if (!_.isEqual(e.data.usersID, this.user.usersID)) {
                    this.$toast.error("You do not have permission to edit this note.");
                    return;
                }
                this.clear();
                this.editNote(e.data);
            },
            searchNotes: _.debounce(function (e) {
                let grid = this.getGrid();
                if(_.isEmpty(grid)) return;
                grid.searchByText(this.searchTerm);
            }, 300, {trailing: true, leading: false}),
            clear() {
                let grid = this.getGrid();

                if (!_.isEmpty(grid)) {
                    grid.clearSelection();
                    grid.option("focusedRowIndex", -1);
                }
            },
            refresh() {
                let grid = this.getGrid();

                if (!_.isEmpty(grid)) {
                    grid.clearSelection();
                    grid.option("focusedRowIndex", -1);
                    grid.refresh();
                    grid.updateDimensions();
                }
            },
            removeGridItems(items) {
                _.pullAllBy(this.notes, items, this.itemKey);
            },
            editNote(note) {
                const self = this;
                self.$dialog.promptInput({
                    title: `Task ${self.taskName}: Note`,
                    label: "Note",
                    value: note.notes,
                    multiline: true,
                    onOk (e) {
                        note.notes = e.value;
                        self.save(note);
                        return true;
                    }
                });
            },
            addNote(note) {
                this.notes.push(new OrderWorkflowTaskNote(note));
            },
            updateNote(note) {
                let originalNote = _.find(this.notes, (i) => {
                    return _.parseNumber(_.get(i, this.itemKey, -1), -1) == _.parseNumber(_.get(note, this.itemKey, -1), -1);
                });
                _.assign(originalNote, note);
            },
            save(note, form){
                const self = this;
                let isNew = note.isNew ? true : false;
                let apiPromise = self.$api.OrderWorkflowApi.saveNote(note);
                self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        if (isNew) {
                            self.addNote(data);
                        } else {
                            self.updateNote(data);
                        }
                        self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                        self.$emit("change", { data: self.notes });
                    }).catch(e => {
                        self.$toast.error(e.errorMessage);
                    }).finally(() => {
                        self.refresh();
                    });
            },
        }
    };
</script>

<style lang="scss">
    .rq-grid-toolbar {
        .rq-toolbar-slot {
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-start;
        }
    }
</style>