<template>
    <div class="content-wrapper payoff-list">
        <rq-page-section title="Payoffs" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add_payoff"
                            variant="theme"
                            v-focus
                            @click="onAddPayoffClicked"
                            :disabled="readOnly">Add
                        </b-btn>
                    </li>
                </ul>
                <ul class="nav ms-auto grid-tools-offset">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_payoffs"
            :actions="selectionActions"
            :data-source="filteredPayoffs"
            :config="gridConfig"
            :export-file-name="exportFileName"
            @select="onSelectAction"
            @delete="onDeleteAction"
            @rowDoubleClick="onGridRowDoubleClick"
            hide-search
        />
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { SETTLEMENT_ACTIONS } from '@/store/actions';
    import { PayoffSectionOptions, PayoffModel } from "../../models";
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { useSettlementStatementCalculators, CALCULATOR_TYPE } from '@settlement/SettlementStatementCalculators';

    const PayoffSection = {
        Seller: 500,
        Buyer: 100
    }
    export default {
        name: "PayoffList",
        mixins: [BaseSettlementMixin],
        setup() {
            const { launchCalculator } = useSettlementStatementCalculators();

            async function onLaunchCalculator(properties) {
                launchCalculator({ calculator: CALCULATOR_TYPE.PAYOFF_DETAIL, title: "Payoff Detail", properties: properties, onOk: ()=>this.fetchData(this.selectedLoanId), onCancel: ()=>true });
            }

            return {
                onLaunchCalculator
            }
       },
        props: {
                displayMode: {type: String, default: "route"}
            },
        computed: {
            ...mapState({
                loans: state => state.orders.loans || []
            }),
            filteredPayoffs() {
                return this.isWithOutSeller ? _.filter(this.payoffs, item => item.section !== 500) : this.payoffs;
            },
            exportFileName() { return `_payoff` },
            payOffGrid() {
                return this.$refs.dataGrid.getGridInstance();
            },
            loanId() {
                return _.parseInt(_.get(this.$route.params, "loanId", 0));
            },
            isDisplayModal() {
                return this.displayMode === "modal";
            }
        },
        watch: {
            selectedLoanId(newValue, oldValue){
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                this.fetchData(newValue);
            },
        },
        created(){
            this.baseInit(false);
            this.fetchData(this.selectedLoanId);
            this.loadGridActions();
            this.loadGridConfig();
        },
        methods: {
            fetchData(loanId) {
                const self = this;
                let storePromise = self.$store.dispatch(SETTLEMENT_ACTIONS.GET_PAYOFF_DATA, { loanId: loanId })
                return self.$rqBusy.wait(storePromise)
                    .catch((err) => {
                        let message = 'Error retrieving payoff information';
                        self.$toast.error({ message: message });
                    });
            },
            loadGridConfig() {
                const self = this;
                self.gridConfig = {
                    keyExpr: "payoffID",
                    scrolling: { useNative: self.isDisplayModal },
                    columns: [
                        { dataField: "payoffID", caption: "No.", width:50 },
                        { dataField: "description" },
                        {
                            dataField: "payoffType",
                            caption: "Type",
                            alignment: "left",
                            calculateDisplayValue(rowData) {
                                return rowData.payoffType===0
                                    ? "Written Payoff From Lender"
                                    : "Detailed Payoff Calculator";
                            }
                        },
                        { dataField: "loanNumber", caption:"Loan Number" },
                        { dataField: "payoutAmount", caption:"Payoff Amount", alignment: "right", cellTemplate: DxGridUtils.moneyCellTemplate },
                        { dataField: "payeeCompanyName", caption: "Payee" },
                        {
                            dataField: "section",
                            caption:"Section",
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: PayoffSectionOptions.lookupItems
                            }
                        }
                    ]
                };
            },
            loadGridActions() {
                const self = this;
                self.selectionActions = [
                    { name: "view-edit",
                      text: self.readOnly ? "View" : "Edit",
                      eventName: "select",
                      requireSelection: true
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: false,
                        disabled: function(e) {
                           return _.some(e.data, item => item.sequence === 1 || item.sequence === 2) || self.readOnly;
                        },
                    }
                ];
            },
            onLoanDropdownChange(e) {
                this.$router
                    .push({
                        name: 'settlement:payoffs:list',
                        params: {
                            orderId: self.orderId,
                            loanId: e.selectedValue
                        }
                    });
            },
            onAddPayoffClicked() {
                let self = this;
                if(self.isDisplayModal){
                    const properties = {
                        dashBoardOrderId: self.orderId,
                        dashboardLoanId: self.selectedLoanId,
                        dashboardPayoffId: 0,
                    }
                    self.onLaunchCalculator(properties);
                }
                else{
                this.$router
                    .push({
                        name: 'settlement:payoffs:detail',
                        params: {
                            orderId: self.orderId,
                            loanId: self.selectedLoanId
                        }
                    });
                }
            },
            onGridRowDoubleClick(e) {
                this.goToDetail(e.data);
            },
            onDeleteAction() {
                const self = this;
                if (self.readOnly) return;
                let toBeDeletedList = this.payOffGrid.getSelectedRowsData();
                let hasRequiredPayOff = toBeDeletedList.some(payoff => {
                    let isRequired = self.isRequiredPayoff(payoff);
                    return isRequired;
                });

                let loan = _.find(self.loans, l => l.loanID === self.selectedLoanId);

                let confirmMessage = hasRequiredPayOff && loan.loanOrder ==1 ? `The first 2 Payoffs are required and cannot be deleted.  This will instead clear all the values in the payoff.  Would you like to continue?` : `Delete selected Payoff(s)?`;

                var ok = function () {
                    self.isBusy = true;
                    let payOffIds = toBeDeletedList.map(data => data.payoffID);
                    let promise = self.$store.dispatch(SETTLEMENT_ACTIONS.DELETE_PAYOFF, { payOffIds });
                    self.$rqBusy.wait(promise)
                        .then(() => {
                            self.$toast.success({message: 'Payoff(s) deleted'});
                            return true;
                        })
                        .catch(error => {
                            let message = 'Error deleting payoff';
                            self.$toast.error({ message: message });
                        });
                };
                self.$dialog.confirm('Confirm', confirmMessage, ok);
            },
            onSelectAction(e) {
                this.goToDetail(e.data);
            },
            goToDetail(data) {
                let self = this;
                if(this.isDisplayModal){
                    const properties = {
                        dashBoardOrderId: self.orderId,
                        dashboardLoanId: self.selectedLoanId,
                        dashboardPayoffId: data.payoffID,
                    }
                    self.onLaunchCalculator(properties);
                }
                else{
                this.$router
                    .push({
                        name: 'settlement:payoffs:detail',
                        params: {
                            orderId: self.orderId,
                            loanId: self.selectedLoanId,
                            payoffId: data.payoffID,
                        }
                    });
                }
            },
            isRequiredPayoff(payoff) {
                let index = this.payoffs
                    .map(data => data.payoffID)
                    .indexOf(payoff.payoffID);
                return payoff.section === PayoffSection.Seller && index <= 1;
            },
        }
    }
</script>

