<template>
    <div class="rq-master-detail-container">
        <section @click="onGridClick">
            <rqdx-action-data-grid
                ref="dataGrid"
                automation_id="tbl_checks"
                :actions="selectionActions"
                :config="gridConfig"
                :read-only="readOnly"
                class="rq-tab-content-grid"
                :data-source="gridDataSource"
                :strikethrough-if-true="['inactive']"
                @consolidated-adjustment="onConsolidatedAdjustment"
                @advance="onAdvanceCheck"
                @advance-recover="onAdvanceRecoverCheck"
                @loss="onLossCheck"
                @loss-recover="onLossRecoverCheck"
                @delete="onDeleteCheck"
                @edit-check="onEditCheck"
                @view-payee="onViewPayeeInfo"
                @void-check="onVoidCheck"
                @split-check="onSplitMoveCheck"
                @stop-pay-check="onStopPayCheck"
                @hold-check="onHoldCheck"
                @print-check="onPrintCheck"
                @print-cover-letter="onPrintCoverLetter"
                @undo-check="onUndoCheck"
                @escrow-account-transfer="onTransferToEscrowAccount"
                @file-transfer="onTransferToFile"
                @wire-transfer="onTransferToWire"
                @change-payee-file-contact="onChangePayeeToFileContact"
                @change-payee-company="onChangePayeeToCompany"
                @change-payee-manual="onChangePayeeToManual"
                @transfer-details="onViewTransferDetails"
                @rowDoubleClick="onEditCheck"
                export-file-name="check_writing_checks_data"
                integrated-search
                rq-filters
            />
        </section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DateTime } from "luxon";
    import AddEditCheckForm from "./AddEditCheckForm.vue";
    import CheckWritingSplitMoveCheckForm from "./CheckWritingSplitMoveCheckForm.vue";
    import CheckWritingWireTransferForm from "./CheckWritingWireTransferForm.vue";
    import CheckWritingTransferCheckToFile from "./CheckWritingTransferCheckToFile.vue";
    import CheckWritingTransferCheckToEscrowAccount from "./CheckWritingTransferCheckToEscrowAccount.vue";
    import CheckWritingPrintForm from "./CheckWritingPrintForm.vue";
    import CheckWritingLossAdvanceForm from './CheckWritingLossAdvanceForm.vue';
    import FileContactSelection from "../../shared/components/FileContactSelection";
    import CheckWritingManualPayee from "../components/CheckWritingManualPayee";
    import CompanyContactLookup from "@order-entry/contacts/components/CompanyContactLookup";
    import ConsolidatedCheckAdjustmentForm from "../components/ConsolidatedCheckAdjustmentForm";
    import MoneyMovementApprovalForm from "../../../escrow-accounting/components/MoneyMovementApprovalForm.vue";
    import { MoneyMovementApprovalDto, CheckShortDto, CheckLineDto, CheckRequestDto, CheckPrintRequestDto }  from "../models";
    import { CheckStatus, CheckStatusFilter, FileBalanceStatus } from '../enums';
    import { MoneyMovementType } from '@config/enums';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    const FINALIZED_RECON_WARNING = "<div class='alert alert-danger mt-1'><svg class='rq-icon-symbol-lg alert-icon pe-1'><use href='#rq-fas-exclamation-triangle'></use></svg>One or more Item is dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.</div>";

    export default {
        name:"CheckWritingCheckList",
        props: {
            bank: { type: Object, required: true, default: () => ({}) },
            summary: { type: Object, required: true, default: () => ({}) },
            items: { type: Array, required: true, default: () => [] },
            itemDetail: { type: Array, required: true, default: () => [] },
            checkLineLookups: { type: Array, required: false, default: () => [] },
        },
        data () {
            return {
                errorMessage: "",
                selectionActions: [],
                approvalPopover: { target: null, item: {}, visible: false },
                popover: { target: null, isCheck: true, item: {}, itemDetail: [], visible: false }
            };
        },

        watch: {
            bank:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                }
            },
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
            errorMessage(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                this.$emit("update-error-message", this.errorMessage);
            },
        },

        created() {
            this.$events.on("edit-check", this.onEditCheck);
            this.$events.on("select-check", this.onSelectCheck);
            this.$events.on("order-summary::collapse-toggled", () => this.updateDimensions());
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        beforeUnmount () {
            this.$events.off("edit-check");
            this.$events.off("select-check");
            this.$events.off("order-summary::collapse-toggled");
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked) || _.parseBool(state.orders.orderSummary.isEscrowLocked),
                order: state => state.orders.order,
                systemDefaults: state => state.system.systemDefaults,
                user: state => state.authentication.session.user
            }),
            filteredItems() { return _.isEqual(this.bank.companyID, 0) ? _.clone(this.items) : _.filter(_.clone(this.items), ["bankCompanyID", this.bank.companyID]); },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            readOnly() { return this.isReadOnly || this.isFileLocked || _.parseBool(this.summary.readOnly, false); },
            isFileBalanced() { return _.get(this.summary, "balanceStatus", -1) != FileBalanceStatus.NotBalanced; },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowChangePayee",
                    "AllowCheckStop",
                    "AllowCheckVoid",
                    "AllowChecksWhenUnbalanced",
                    "AllowConsolidatedDisbursementAdjustment",
                    "AllowCreateAdvance",
                    "AllowCreateLoss",
                    "AllowDeleteCheck",
                    "AllowDisbursementWithoutGoodFunds",
                    "AllowFinalizedReconModifications",
                    "AllowFileFundTransfer",
                    "AllowPrintChecks",
                    "AllowUndoCheck",
                    "AllowVoidTransfers",
                    "AnticipatedDeposits",
                    "ForcePayeeOnChecks",
                    "SendChecksToFileScan",
                    "VoidCheckDateDays",
                    ]);
            },
            allowPrintAnticipatedDeposits() {return this.localSecurity.AnticipatedDeposits === 1},//full,
            allowLossAdvance() {return this.localSecurity.AllowCreateAdvance || this.localSecurity.AllowCreateLoss; }
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },

            initGridConfig(){
                const self = this;
                let defaultSort = _.parseBool(self.systemDefaults.receiptsAndDisbursementsSortedByDate, false) ? {sortIndex: 0, sortOrder: "asc"} : {};
                self.gridConfig = {
                    allowColumnReordering: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    wordWrapEnabled: true,
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "checkDate",
                            dataType: "date",
                            caption: "Date",
                            ...defaultSort
                        },
                        {
                            dataField: "numberDisplay",
                            caption: "Number",
                            width: 100,
                            minWidth: 100
                        },
                        {
                            dataField: "payee",
                            caption: "Payee",
                            minWidth: 175
                        },
                        {
                            dataField: "description",
                            cellTemplate: DxGridUtils.textPlusCountCellTemplate({
                                idAppend: "check-popup-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updatePopover(cellInfo.data, e.target);
                                        e.stopPropagation();
                                    },
                                }
                            })
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            caption: "Amount",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "expectedWireOutDate",
                            dataType: "date",
                            caption: "Expected Wire Date",
                            visible: false,
                        },
                        {
                            dataField: "checkStatus",
                            caption: "Status",
                            cellTemplate: DxGridUtils.checkStatusCellTemplate({
                                idLookup: "checkStatus",
                                idAppend: "check-approval-popup-info-",
                                statuses: self.statuses,
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updateApprovalPopover(cellInfo.data.moneyMovementApprovalID, e.target);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                            lookup: {
                                dataSource: self.statuses,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "id",
                                filterType: "tags",
                                dataSource: self.statusFilter,
                            },
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "reconciliationDate",
                            dataType: "date",
                            caption: "Recon Date",
                            cellTemplate: DxGridUtils.reconDateCellTemplate
                        },
                        {
                            dataField: "bankCompanyID",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            visible: false
                        },
                        {
                            dataField: "memo",
                            visible: false
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "IssuedTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "IssuedTotalLabel",
                                column: "payee",
                                alignment: "left",
                                cssClass: "rq-summary-label",
                                displayFormat: "ISSUED",
                                summaryType: "sum"
                            },
                            {
                                name: "AnticipatedTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "AnticipatedTotalLabel",
                                column: "payee",
                                alignment: "left",
                                displayFormat: "ANTICIPATED",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "CheckTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "CheckTotalLabel",
                                column: "payee",
                                alignment: "left",
                                cssClass: "rq-summary-label",
                                displayFormat: "TOTAL",
                                summaryType: "sum"
                            },
                        ],
                        calculateCustomSummary(options) {
                            if (options.name == "AnticipatedTotal") {
                                options.totalValue = self.summary.anticipatedDisbursements;
                            } else if (options.name == "IssuedTotal") {
                                options.totalValue = self.summary.disbursements;
                            } else {
                                options.totalValue = self.summary.disbursements + self.summary.anticipatedDisbursements;
                            }
                        }
                    },
                    onRowPrepared (e) {
                        if (e.rowType !== "data") return;
                        let rowBackgroundColorVariant = e.data?.useRowRedStrikeThrough;
                        if(!rowBackgroundColorVariant) return;
                        e.rowElement.addClass("rq-strike-through-red");
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.filteredItems);
                    },
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Check";
                self.itemTypeNamePlural = "Checks";
                self.itemKey = "checksID";
                self.statuses = CheckStatus.lookupItems;
                self.statusFilter = CheckStatusFilter.lookupItems;
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_ACCOUNTS);
                self.selectionActions = [
                    {
                        name: "edit-check",
                        text: "Edit",
                        eventName: "edit-check",
                        requireSelection: true,
                        allowMultiSelection: false,
                        tooltip: `Edit ${self.itemTypeName}`,
                        disabled: function(e) {
                            return self.readOnly;
                        }
                    },
                    {
                        name: "print-options",
                        text: "Print",
                        children: [
                            { name: "print-check", text: "Print Check", eventName: "print-check", requireSelection: true, allowMultiSelection: true, tooltip: `Print ${self.itemTypeName}`, disabled: function(e) { return self.printCheckDisabled(e);} },
                            { name: "print-cover-letter", text: "Print Cover Letter", eventName: "print-cover-letter", requireSelection: true, allowMultiSelection: false, disabled: function(e) { return self.printCoverLetterDisabled(e); } },
                        ]
                    },
                    {
                        name: "consolidated-adjustment",
                        text: "Adjustment",
                        eventName: "consolidated-adjustment",
                        requireSelection: true,
                        allowMultiSelection: false,
                        disabled: function(e) {
                            return self.consolidateAdjustmentDisabled(e);
                        },
                        visible: function(e) {
                            return _.getBool(e.data, "isConsolidated");
                        }
                    },
                    { name: "loss-advance", text: "Loss/Advance", visible: function(e) { return self.allowLossAdvance && _.includes([CheckStatus.None, CheckStatus.Issued], _.getNumber(e, "data[0].checkStatus", -1)) },
                        children: [
                            { name: "advance", text: "Advance", eventName: "advance", requireSelection: true, disabled: function(e) { return self.advanceDisabled(e);} },
                            { name: "advance-recover", text: "Advance Recover", eventName: "advance-recover", requireSelection: true, disabled: function(e) { return self.advanceRecoverDisabled(e); } },
                            { name: "loss", text: "Loss", eventName: "loss", requireSelection: true, disabled: function(e) { return self.lossDisabled(e);} },
                            { name: "loss-recover", text: "Loss Recover", eventName: "loss-recover", requireSelection: true, disabled: function(e) { return self.lossRecoverDisabled(e); } },
                        ],
                        disabled: function(e) {
                            return (self.readOnly) ? 'Access Restricted' : false;
                        }
                    },
                    {
                        name: "change-payee",
                        text: "Change Payee",
                        children: [
                            { name: "change-payee-file-contact", text: "File Contact", eventName: "change-payee-file-contact", requireSelection: true, disabled: function(e) {  if (self.readOnly) {return "Access Restricted";} return e.data.isWire || !_.includes([CheckStatus.None, CheckStatus.Rejected], e.data.checkStatus)} },
                            { name: "change-payee-company", text: "Company", eventName: "change-payee-company", requireSelection: true, disabled: function(e) { if (self.readOnly) {return "Access Restricted";} return e.data.isWire || !_.includes([CheckStatus.None, CheckStatus.Rejected], e.data.checkStatus)} },
                            { name: "change-payee-manual", text: "Manual", eventName: "change-payee-manual", requireSelection: true, disabled: function(e) { if (self.readOnly) {return "Access Restricted";} return e.data.isWire || !_.includes([CheckStatus.None, CheckStatus.Rejected], e.data.checkStatus)} },
                        ],
                        disabled: function(e) {
                            return (self.readOnly || !self.localSecurity.AllowChangePayee) ? 'Access Restricted' : false;
                        }
                    },
                    {
                        name: "view-payee",
                        text: "View Payee",
                        eventName: "view-payee",
                        requireSelection: true,
                        tooltip: `View ${self.itemTypeName} Payee`,
                    },
                    { name: "transfer", text: "Transfer",
                        children: [
                            { name: "escrow-account-transfer", text: "To Escrow Account", eventName: "escrow-account-transfer", requireSelection: true, disabled: function(e) { return self.escrowAccountTransferDisabled(e);} },
                            { name: "file-transfer", text: "To File", eventName: "file-transfer", requireSelection: true, disabled: function(e) { return self.fileTransferDisabled(e);} },
                            { name: "wire-transfer", text: "To Wire Transfer", eventName: "wire-transfer", requireSelection: true, disabled: function(e) { return self.wireTransferDisabled(e)} },
                            { name: "transfer-details", text: "Wire Transfer Details", eventName: "transfer-details", requireSelection: true, disabled: function(e) { return self.readOnly || e.data.isWire == false} },
                            { name: "view-slips", text: "View Slips", eventName: "view-slips", requireSelection: true, disabled: true },
                        ]
                    },
                    {
                        name: "split-check",
                        text: "Split / Move",
                        eventName: "split-check",
                        requireSelection: true,
                        tooltip: `Split / Move ${self.itemTypeName}`,
                        disabled: function(e) {
                            if (self.readOnly) {
                                return "Access Restricted";
                            }
                            return !_.includes([CheckStatus.None, CheckStatus.Approved, CheckStatus.Rejected], e.data.checkStatus);
                        }
                    },
                    {
                        name: "void-check",
                        text: "Void",
                        eventName: "void-check",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Void ${self.itemTypeName}`,
                        disabled: function(e) {
                            return self.voidCheckDisabled(e);
                        }
                    },
                    {
                        name: "stop-pay-check",
                        text: "Stop Pay",
                        eventName: "stop-pay-check",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Place Stop Pay on the selected ${self.itemTypeName}`,
                        disabled: function(e) {
                            return self.stopPayCheckDisabled(e);
                        }
                    },
                    {
                        name: "hold-check",
                        text: "Hold",
                        eventName: "hold-check",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Place or Reverse a Hold on a ${self.itemTypeName}`,
                        disabled: function(e) {
                            return self.holdCheckDisabled(e);
                        }
                    },
                    {
                        name: "undo-check",
                        text: "Undo",
                        eventName: "undo-check",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Undo ${self.itemTypeName}`,
                        disabled: function(e) {
                            return self.undoCheckDisabled(e);
                        }
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) {
                            return self.deleteDisabled(e);
                        }
                    }
                ];
            },

            advanceDisabled(e){
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowCreateAdvance) {
                    return "Access Restricted";
                }
                return !_.isEqual(CheckStatus.None, e.data.checkStatus) || e.data.isWire;
            },

            advanceRecoverDisabled(e){
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowCreateAdvance) {
                    return "Access Restricted";
                }
                return !_.isEqual(CheckStatus.Issued, e.data.checkStatus) || e.data.isWire || !(_.parseNumber(e.data.moneyMovementApprovalID, 0) > 0 && _.isEqual(e.data.moneyMovementTypeID, MoneyMovementType.Advance) && e.data.isRecoverable && !e.data.isRecovered);
            },

            checkDateLocked(c){
                const self = this;
                //if a user is not allowed to alter finalized recons then if the check date falls before the last recon date then it's locked
                return !_.isNil(c.checkDate) && !self.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(c.checkDate).diff(DateTime.fromISO(self.order.lastFinalizedReconDate), "days").days <= 0;
            },

            consolidateAdjustmentDisabled(e){
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowConsolidatedDisbursementAdjustment) {
                    return "Access Restricted";
                }
                let totalAdj = _.sumBy(_.filter(self.itemDetail, ["checksID", e.data.checksID]), "totalAdjustedAmount");
                if (totalAdj >= e.data.amount) {
                    return "Can't adjust more than the original check amount.";
                }
                return !_.isEqual(CheckStatus.Issued, e.data.checkStatus) || _.lte(e.data.amount, 0);
            },

            escrowAccountTransferDisabled(e){
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowFileFundTransfer) {
                    return "Access Restricted";
                }
                return !_.parseBool(self.order.multipleBankAccount) || !_.includes([CheckStatus.None, CheckStatus.Approved], e.data.checkStatus);
            },

            fileTransferDisabled(e){
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowFileFundTransfer) {
                    return "Access Restricted";
                }
                return !_.includes([CheckStatus.None, CheckStatus.Approved], e.data.checkStatus);
            },

            lossDisabled(e){
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowCreateLoss) {
                    return "Access Restricted";
                }
                return !_.isEqual(CheckStatus.None, e.data.checkStatus) || e.data.isWire;
            },

            lossRecoverDisabled(e){
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowCreateLoss) {
                    return "Access Restricted";
                }
                return !_.isEqual(CheckStatus.Issued, e.data.checkStatus) || e.data.isWire || !(_.parseNumber(e.data.moneyMovementApprovalID, 0) > 0 && _.isEqual(e.data.moneyMovementTypeID, MoneyMovementType.Loss) && e.data.isRecoverable && !e.data.isRecovered);
            },

            wireTransferDisabled(e){
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowFileFundTransfer) {
                    return "Access Restricted";
                }
                return !_.includes([CheckStatus.None, CheckStatus.Approved], e.data.checkStatus) || _.parseBool(e.data.isWire, false) == true;
            },

            printCheckDisabled(e){
                const self = this;
                if (!self.localSecurity.AllowPrintChecks) return "Access Restricted";
                if (_.some(e.data, ["payee", "No Payee Indicated"])) return "Some checks do not have a Payee";
                if (_.some(e.data, ["checkStatus", CheckStatus.Hold])) return "Some checks are on Hold";
                if (_.some(e.data, ["checkStatus", CheckStatus.PendingApproval])) return "Some checks are Pending Approval";
                if (_.some(e.data, ["checkStatus", CheckStatus.Rejected])) return "Some checks are Rejected";
                if (_.some(e.data, ["hasValidAccountCode", false])) return `Some checks require an Account Code.`;
                if (_.some(e.data, ["checkStatus", CheckStatus.Void])) return "Some checks are Void";
                if (_.some(e.data, ["checkStatus", CheckStatus.StopPay])) return "Some checks are Stop Pay";
                return self.readOnly || _.some(e.data, ["checkStatus", CheckStatus.Issued]) || _.some(e.data, ["checkStatus", CheckStatus.Cleared]) ||
                    _.some(e.data, ["isWire", true]) || _.some(e.data, ["isTransfer", true]);
            },

            printCoverLetterDisabled(e) {
                const self = this;
                if (e.data.checkStatus === CheckStatus.Issued ||
                    e.data.checkStatus === CheckStatus.Wire)
                    return false;
                return (
                    self.readOnly ||
                    e.data.checkStatus === CheckStatus.ExpectedWireOut ||
                    e.data.checkStatus === CheckStatus.Hold ||
                    e.data.checkStatus === CheckStatus.Void ||
                    e.data.checkStatus === CheckStatus.StopPay ||
                    e.data.checkStatus === CheckStatus.None
                );
            },

            voidCheckDisabled(e){
                const self = this;
                if (_.some(e.data, c => self.checkDateLocked(c))) return "Access Restricted. One or more of the items selected is on a finalized recon.";
                if (self.readOnly || (!self.localSecurity.AllowCheckVoid && _.every(e.data, ["isTransfer", false])) || (!self.localSecurity.AllowVoidTransfers && _.every(e.data, ["isTransfer", true]))) {
                    return "Access Restricted";
                }
                if (self.localSecurity.AllowVoidTransfers && _.every(e.data, ["isTransfer", true]) && _.some(e.data, ["consolidatedCheckStatus", CheckStatus.Issued]) && _.every(e.data, ["checkStatus", CheckStatus.Issued])) {
                    return "Unable to void items disbursed from Accounting";
                }
                return _.some(e.data, ['isEFER', true]) || _.some(e.data, ['isReconciled', true]) || !_.every(e.data, ["checkStatus", CheckStatus.Issued]);
            },

            stopPayCheckDisabled(e){
                const self = this;
                if (_.some(e.data, c => self.checkDateLocked(c))) return "Access Restricted. One or more of the items selected is on a finalized recon.";
                if (self.readOnly || !self.localSecurity.AllowCheckStop) {
                    return "Access Restricted";
                }
                return _.some(e.data, ['isConsolidated', true]) || _.some(e.data, ["isTransfer", true]) || _.some(e.data, ['isEFER', true]) || _.some(e.data, ['isReconciled', true]) || !(_.every(e.data, ["checkStatus", CheckStatus.Issued]));
            },

            undoCheckDisabled(e){
                const self = this;
                if (_.some(e.data, c => self.checkDateLocked(c))) return "Access Restricted. One or more of the items selected is on a finalized recon.";
                if (self.readOnly || !self.localSecurity.AllowUndoCheck) {
                    return "Access Restricted";
                }
                return _.some(e.data, ['isEFER', true]) || _.some(e.data, ['isTransfer', true]) || _.some(e.data, ['isReconciled', true]) || _.some(e.data, ['isConsolidated', true]) || !_.every(e.data, i => _.includes([CheckStatus.Issued, CheckStatus.Cleared, CheckStatus.Wire, CheckStatus.ExpectedWireOut], i.checkStatus));
            },

            holdCheckDisabled(e){
                const self = this;
                if (_.some(e.data, c => self.checkDateLocked(c))) return "Access Restricted. One or more of the items selected is on a finalized recon.";
                return self.readOnly || _.some(e.data, ['isReconciled', true]) || !_.every(e.data, i => _.includes([CheckStatus.None, CheckStatus.Hold, CheckStatus.Approved, CheckStatus.Rejected], i.checkStatus));
            },

            deleteDisabled(e){
                const self = this;
                if (_.some(e.data, c => self.checkDateLocked(c))) return "Access Restricted. One or more of the items selected is on a finalized recon.";
                if (self.readOnly || !self.localSecurity.AllowDeleteCheck) {
                    return "Access Restricted";
                }
                return !_.every(e.data, i => _.includes([CheckStatus.None, CheckStatus.Approved, CheckStatus.Rejected], i.checkStatus));
            },

            onAdvanceCheck(e) {
                if(this.advanceDisabled(e)) return;
                if(!e || !e.data) return;
                this.getLossAdvanceLookupData(e.data.ordersID, e.data.checksID, MoneyMovementType.Advance)
                    .then(data => {
                        this.showLossAdvanceCheckDialog(e.data, MoneyMovementType.Advance, data);
                    })
                    .catch(error => {
                        console.error(error);
                        this.$toast.error({ message: `Error loading Lookup Data for Advance Request.` });
                    });
            },

            onAdvanceRecoverCheck(e) {
                if(this.advanceRecoverDisabled(e)) return;
                if(!e || !e.data) return;
                this.getLossAdvanceRequest(e.data.moneyMovementApprovalID)
                    .then(data => {
                        this.showLossAdvanceRecoverCheckDialog(data);
                    })
                    .catch(error => {
                        console.error(error);
                        this.$toast.error({ message: `Error loading Advance Request.` });
                    });
            },

            onChangePayeeToFileContact(e) {
                if(this.readOnly || !this.localSecurity.AllowChangePayee) return;
                if(!e || !e.data) return;
                this.showFileContactsDialog(e.data);
            },

            onChangePayeeToCompany(e) {
                if(this.readOnly || !this.localSecurity.AllowChangePayee) return;
                if(!e || !e.data) return;
                this.showCompanyDialog(e.data);
            },

            onChangePayeeToManual(e) {
                if(this.readOnly || !this.localSecurity.AllowChangePayee) return;
                if(!e || !e.data) return;
                this.showManualPayeeDialog(e.data);
            },

            onConsolidatedAdjustment(e) {
                if(this.readOnly || !this.localSecurity.AllowConsolidatedDisbursementAdjustment) return;
                if(!e || !e.data) return;
                let checksID = _.parseNumber(e.data.checksID, 0);
                let check = _.find(this.items, [this.itemKey, checksID]);
                let checkLines = _.filter(this.itemDetail, [this.itemKey, checksID]);
                this.showConsolidatedCheckAdjustmentFormDialog(check, checkLines);
            },

            onGridClick(e) {
                //used as a click off for the check popover
                const self = this;
                if (_.getBool(self, "popover.visible", false)) {
                    self.popover.visible = false;
                    self.$events.$emit("show-popover", self.popover);
                }
                if (_.getBool(self, "approvalPopover.visible", false)) {
                    self.approvalPopover.visible = false;
                    self.$events.$emit("show-approval-popover", self.approvalPopover);
                }
            },

            onDeleteCheck(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    self.errorMessage = "";
                    let ids = _.map(items, self.itemKey);
                    let apiPromise = self.$api.CheckWritingApi.deleteChecks(self.order.ordersID, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(data => {
                            self.$emit("update-data", data);
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditCheck(e) {
                if(!e || !e.data || this.readOnly) return;
                const self = this;
                self.errorMessage = "";
                let checksID = _.parseNumber(e.data.checksID, 0);
                let check = {};
                let checkLines = [];
                if (checksID === 0) {
                    check = new CheckShortDto(e.data);
                } else {
                    check = _.find(self.items, [self.itemKey, checksID]);
                    checkLines = _.filter(self.itemDetail, [self.itemKey, checksID]);
                }
                self.showEditCheckDialog(check, checkLines);
            },

            onHoldCheck(e) {
                if(!e || !e.data) return;
                const self = this;

                let holdCheckDisabled = self.holdCheckDisabled(e);
                if(holdCheckDisabled) return;

                let checkIDs = _.map(e.data, self.itemKey);
                let noun = checkIDs.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
                let action = _.some(e.data, ["checkStatus", CheckStatus.Hold]) ? "Reverse Hold" : "Hold";
                let ok = function (args) {
                    self.errorMessage = "";
                    self.saveCheckStatus(checkIDs, CheckStatus.Hold)
                        .then(result => {
                            self.$toast.success({ message: `${checkIDs.length} ${noun} set to ${action}.` });
                            self.$emit("update-data", result);
                        }).catch(error => {
                            self.$toast.error({ message: `${error.errorMessage}` });
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }
                self.$dialog.confirm("Confirm Hold", `Are you sure you want to ${action} the selected ${noun}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onLossCheck(e) {
                if(this.lossDisabled(e)) return;
                if(!e || !e.data) return;
                this.getLossAdvanceLookupData(e.data.ordersID, e.data.checksID, MoneyMovementType.Loss)
                    .then(data => {
                        this.showLossAdvanceCheckDialog(e.data, MoneyMovementType.Loss, data);
                    })
                    .catch(error => {
                        console.error(error);
                        this.$toast.error({ message: `Error loading Lookup Data for Loss Request.` });
                    });
            },

            onLossRecoverCheck(e) {
                if(this.lossRecoverDisabled(e)) return;
                if(!e || !e.data) return;
                this.getLossAdvanceRequest(e.data.moneyMovementApprovalID)
                    .then(data => {
                        this.showLossAdvanceRecoverCheckDialog(data);
                    })
                    .catch(error => {
                        console.error(error);
                        this.$toast.error({ message: `Error loading Loss Request.` });
                    });
            },

            onPrintCheck(e) {
                const self = this;
                let printDisabled = self.printCheckDisabled(e);
                if (printDisabled) return;
                if(!e || !e.data) return;
                this.$events.$emit('print-checks', e.data);
            },

            onPrintCoverLetter(e) {
                const self = this;
                let printDisabled = self.printCoverLetterDisabled(e);
                if (printDisabled) return;
                if(!e || !e.data) return;

                this.$events.$emit('print-cover-letters', e.data);
            },

            onSelectCheck(data) {
                const self = this;
                self.refresh();
                self.$nextTick().then(() => {
                    self.autoAddLine = data.autoAddLine;
                    self.gridInstance.selectRows([data.checksID], false);
                    self.gridInstance.navigateToRow(data.checksID);
                });
            },

            onStopPayCheck(e) {
                if(!e || !e.data) return;
                const self = this;
                let checkIDs = _.map(e.data, self.itemKey);
                let dateWarning = "";
                if (_.some(e.data, c => self.checkDateLocked(c))) {
                    dateWarning = FINALIZED_RECON_WARNING;
                }
                let noun = checkIDs.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
                let ok = function (args) {
                    self.errorMessage = "";
                    self.saveCheckStatus(checkIDs, CheckStatus.StopPay)
                        .then(result => {
                            self.$toast.success({ message: `${checkIDs.length} ${noun} set to Stop Pay.` });
                            self.$emit("update-data", result);
                        }).catch(error => {
                            self.$toast.error({ message: `${error.errorMessage}` });
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }
                self.$dialog.confirm("Confirm Stop Pay", `Are you sure you want to Stop Pay on the selected ${noun}?${dateWarning}`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onSplitMoveCheck(e) {
                const self = this;
                if(self.readOnly || !_.includes([CheckStatus.None, CheckStatus.Approved, CheckStatus.Rejected], e.data.checkStatus)) return;
                if(!e || !e.data) return;
                self.errorMessage = "";
                let checksID = _.parseNumber(e.data.checksID, 0);
                let anticipatedChecks = _.filter(self.filteredItems, c => (c.checkStatus === CheckStatus.None || c.checkStatus === CheckStatus.Approved) && c.checksID != checksID);
                let check = {};
                let checkLines = [];
                if (checksID === 0) {
                    check = new CheckShortDto({ordersID: this.order.ordersID, bankCompanyID: this.bank.companyID});
                } else {
                    check = _.find(self.items, [self.itemKey, checksID]);
                    checkLines = _.filter(self.itemDetail, [self.itemKey, checksID]);
                }

                let onOk = (e) => {
                    let data = e.originalEvent;
                    if (data.type === "split")
                        self.confirmSplit(data);
                    if (data.type === "move")
                        self.confirmMove(data);
                };

                let payee = _.isNil(check.payee) ? '(Blank)' : _.size(check.payee) > 44 ? `${check.payee.substring(0, 44)}...` : check.payee;
                self.$dialog.open({
                    title: `Split/Move Checks: ${payee} - ${self.formatMoney(check.amount)}`,
                    width: "950",
                    adaptive: true,
                    closeOnEsc: false,
                    component: CheckWritingSplitMoveCheckForm,
                    onOk: onOk,
                    okTitle: "Close",
                    okOnly: true,
                    props: {
                        check: check,
                        checkLines: checkLines,
                        checkLineLookups: self.checkLineLookups,
                        bank: self.bank,
                        summary: self.summary,
                        allChecks: anticipatedChecks
                    },
                });
            },

            splitCheck(checksID, checkLines) {
                const self = this;
                self.errorMessage = "";
                let apiPromise = self.$api.CheckWritingApi.splitCheck(checksID, checkLines);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success({ message: "Check split."})
                        self.$emit("update-data", result);
                    }).catch(error => {
                        self.$toast.error({ message: `${error.errorMessage}` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            moveCheck(checksID, checkLines) {
                const self = this;
                self.errorMessage = "";
                let apiPromise = self.$api.CheckWritingApi.moveCheck(checksID, checkLines);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success({ message: "Check moved."})
                        self.$emit("update-data", result);
                    }).catch(error => {
                        self.$toast.error({ message: `${error.errorMessage}` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            confirmSplit(data) {
                const self = this;
                let checksID = data.checksID;
                let checkLines = [];
                _.each(data.lines, c => checkLines.push(new CheckLineDto(c)));

                let dateWarning = "";
                if (_.some(checkLines, c => self.checkDateLocked(c))) {
                    dateWarning = FINALIZED_RECON_WARNING;
                }

                let ok = function (args) {
                    self.splitCheck(checksID, checkLines);
                    return true;
                }
                let message = self.getSplitConfirmationMessage(checkLines, checksID);
                self.$dialog.confirm("Confirm Split", message, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            confirmMove(data) {
                const self = this;
                let sourceChecksID = data.sourceChecksID;
                let targetChecksID = data.targetChecksID;
                let checkLines = [];
                _.each(data.lines, c => checkLines.push(new CheckLineDto(c)));

                let dateWarning = "";
                if (_.some(checkLines, c => self.checkDateLocked(c))) {
                    dateWarning = FINALIZED_RECON_WARNING;
                }

                let ok = function (args) {
                    self.moveCheck(targetChecksID, checkLines);
                    return true;
                }
                let message = self.getMoveConfirmationMessage(checkLines, sourceChecksID, targetChecksID);
                self.$dialog.confirm("Confirm move", message, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            async getLossAdvanceRequest(moneyMovementApprovalID){
                const self = this;
                let apiPromise = self.$api.MoneyMovementApprovalsApi.getApproval(moneyMovementApprovalID);
                return await self.$rqBusy.wait(apiPromise);
            },

            async getLossAdvanceLookupData(ordersID, checksID, moneyMovementTypeID){
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.getLossAdvanceLookupData(ordersID, checksID, moneyMovementTypeID);
                return await self.$rqBusy.wait(apiPromise);
            },

            getSplitConfirmationMessage(checkLines, checksID) {
                const self = this;
                let check = _.filter(this.items, c => c.checksID === checksID);
                let checkDescription = `${check[0].payee} - ${self.formatMoney(check[0].amount)}`;
                let message = `Split <b>${checkLines[0].description}</b> in Amount of <b>${self.formatMoney(checkLines[0].amount)}</b>`;
                if (checkLines.length > 1)
                    _.each(checkLines, (l, i) => {
                        if (i !== 0)
                            message += ` and <br><b>${l.description}</b> in Amount of <b>${self.formatMoney(l.amount)}</b>`;
                    })
                message += ` of the following Check:<br><br><b>${checkDescription}</b><br><br>Are you sure you want to split the selected check?`;
                return message;
            },

            getMoveConfirmationMessage(checkLines, sourceChecksID, targetChecksID) {
                const self = this;
                let sourceCheck = _.filter(this.items, c => c.checksID === sourceChecksID);
                let targetCheck = _.filter(this.items, c => c.checksID === targetChecksID);
                let sourceCheckDescription = `${sourceCheck[0].payee} - ${self.formatMoney(sourceCheck[0].amount)}`;
                let targetCheckDescription = `${targetCheck[0].payee} - ${self.formatMoney(targetCheck[0].amount)}`;
                let message = `Move <b>${checkLines[0].description}</b> in Amount of <b>${self.formatMoney(checkLines[0].amount)}</b>`;
                if (checkLines.length > 1)
                    _.each(checkLines, (l, i) => {
                        if (i !== 0)
                            message += ` and <br><b>${l.description}</b> in Amount of <b>${self.formatMoney(l.amount)}</b>`;
                    })
                message += ` of the following Check:<br><br><b>${sourceCheckDescription}</b><br><br>`;
                message += `to the following Check:<br><br><b>${targetCheckDescription}</b><br><br>`
                message += "Are you sure you want to move the selected check?";
                return message;
            },

            onTransferToEscrowAccount(e) {
                const self = this;
                if(self.readOnly  || !self.localSecurity.AllowFileFundTransfer || !_.includes([CheckStatus.None, CheckStatus.Approved], e.data.checkStatus)) return;
                if(!e || !e.data) return;
                this.showEscrowAccountTransferDialog(e.data);
            },

            onTransferToFile(e) {
                const self = this;
                if(self.readOnly  || !self.localSecurity.AllowFileFundTransfer || !_.includes([CheckStatus.None, CheckStatus.Approved], e.data.checkStatus)) return;
                if(!e || !e.data) return;
                this.showFileTransferDialog(e.data.checksID);
            },

            onTransferToWire(e) {
                const self = this;
                if(self.readOnly || !self.localSecurity.AllowFileFundTransfer || !_.includes([CheckStatus.None, CheckStatus.Approved], e.data.checkStatus) || _.parseBool(e.data.isWire, false) == true) return;
                if(!e || !e.data) return;
                this.showWireTransferDetailsDialog(e.data.checksID, false);
            },

            onUndoCheck(e) {
                if(!e || !e.data) return;
                const self = this;
                let undoCheckDisabled = self.undoCheckDisabled(e);
                if(undoCheckDisabled) return;

                let dateWarning = "";
                if (_.some(e.data, c => self.checkDateLocked(c))) {
                    dateWarning = FINALIZED_RECON_WARNING;
                }
                let checkIDs = _.map(e.data, self.itemKey);
                let noun = checkIDs.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
                let modifier = checkIDs.length > 1 ? "have" : "has";
                let ok = function (args) {
                    self.errorMessage = "";
                    self.undoChecks(checkIDs)
                        .then(result => {
                            self.$toast.success({ message: `${checkIDs.length} ${noun} ${modifier} been undone.` });
                            self.$emit("update-data", result);
                        }).catch(error => {
                            self.$toast.error({ message: `${error.errorMessage}` });
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }
                self.$dialog.confirm("Confirm Undo", `Are you sure you want to Undo the selected ${noun}?${dateWarning}`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onVoidCheck(e) {
                if(!e || !e.data) return;
                const self = this;

                let voidCheckDisabled = self.voidCheckDisabled(e);
                if(voidCheckDisabled) return;

                let dateWarning = "";
                let items = e.data;
                let hasErrors = false;
                let voidCheckDateDays = self.localSecurity.VoidCheckDateDays;
                if (_.some(e.data, c => self.checkDateLocked(c))) {
                    dateWarning = FINALIZED_RECON_WARNING;
                }
                if (voidCheckDateDays > -1) {
                    let now = DateTime.now().startOf("day");
                    let checkDate = DateTime.now().startOf("day");
                    _.each(items, c => {
                        if (!_.isNil(c.checkDate)) {
                            checkDate = DateTime.fromISO(c.checkDate).startOf("day");
                        }
                        let daysDiff = checkDate.diff(now, "days").days;
                        if(daysDiff < 0 && Math.abs(daysDiff) > voidCheckDateDays){
                            self.$toast.error({ message: `Your Void Check Date Days permissions prevent this check #${c.checkNumber} from being voided.` });
                            hasErrors = true;
                            return false;
                        }
                    });
                    if (hasErrors) return;
                }
                let ok = function (args) {
                    self.errorMessage = "";
                    let checkIDs = _.map(e.data, self.itemKey);
                    let noun = checkIDs.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
                    self.saveCheckStatus(checkIDs, CheckStatus.Void)
                        .then(result => {
                            self.$toast.success({ message: `${checkIDs.length} ${noun} set to Void.` });
                            self.$emit("update-data", result);
                        }).catch(error => {
                            self.$toast.error({ message: `${error.errorMessage}` });
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }
                self.$dialog.confirm("Confirm Void", `Are you sure you want to void the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?${dateWarning}`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onViewPayeeInfo(e) {
                if(!e || !e.data) return;
                this.showPayeeInfo(e.data);
            },

            onViewTransferDetails(e) {
                const self = this;
                if(self.readOnly || e.data.isWire == false) return;
                if(!e || !e.data) return;
                this.showWireTransferDetailsDialog(e.data.checksID, true);
            },

            updateApprovalPopover(moneyMovementApprovalID=null, target=null) {
                const self = this;
                self.$api.MoneyMovementApprovalsApi.getApproval(moneyMovementApprovalID)
                    .then(data => {
                        let newID = _.get(target, "id") || null;
                        let lastID = _.get(self.approvalPopover, "target") || null;
                        let isNewItem = !_.isEqual(newID, lastID);
                        if (isNewItem) {
                            if (!_.isNil(lastID)) {
                                self.approvalPopover.visible = false;
                                self.$events.$emit("show-approval-popover", self.approvalPopover);
                            }
                            self.approvalPopover.target = newID;
                            self.approvalPopover.item = new MoneyMovementApprovalDto(data);
                            self.approvalPopover.visible = true;
                        } else {
                            self.approvalPopover.visible = !self.approvalPopover.visible;
                        }
                        self.$nextTick(() => {
                            self.$events.$emit("show-approval-popover", self.approvalPopover);
                        });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error getting approval info.` });
                    });
            },

            updatePopover(item=null, target=null) {
                const self = this;
                let newID = _.get(target, "id") || null;
                let lastID = _.get(self.popover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        self.popover.visible = false;
                        self.$events.$emit("show-popover", self.popover);
                    }
                    let itemDetail = _.filter(self.itemDetail, [self.itemKey, item.checksID]) || [];
                    self.popover.target = newID;
                    self.popover.item = item;
                    self.popover.itemDetail = itemDetail;
                    self.popover.visible = true;
                    self.popover.isCheck = true;
                } else {
                    self.popover.visible = !self.popover.visible;
                }
                self.$nextTick(() => {
                    self.$events.$emit("show-popover", self.popover);
                });
            },

            refresh(clear=true) {
                if(!this.gridInstance) return;
                if (clear) {
                    this.gridInstance.option("focusedRowIndex", -1);
                    this.gridInstance.clearSelection();
                }
                this.gridInstance.option("pager.visible", this.items.length > 50);
                this.gridInstance.refresh();
                this.updateDimensions("master");
            },

            updateDimensions(gridName=null) {
                this.$nextTick(() => {
                    if(_.isNil(gridName) || gridName === "master")
                        _.invoke(this, "gridInstance.updateDimensions");
                });
            },

            saveCheckStatus(ids, checkStatus){
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.saveCheckStatus(ids, checkStatus);
                return self.$rqBusy.wait(apiPromise);
            },

            saveCheckPayee(item, changes){
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.saveCheckPayee(item, changes);
                return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            let checks = _.clone(self.items);
                            let checkLines = _.clone(self.itemDetail);
                            let itemIndex = _.findIndex(checks, [self.itemKey, _.get(result.checks[0], self.itemKey)]);
                            _.assign(checks[itemIndex], new CheckShortDto(result.checks[0]));
                            let data = {checks, checkLines, orderSummary: result.orderSummary};
                            self.$emit("update-data", data);
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error saving payee info.` });
                            return false;
                        })
                        .finally(() => {
                            self.refresh();
                        });
            },

            setData(data){
                const self = this;
                let checks = _.clone(self.items);
                let checkLines = _.clone(self.itemDetail);
                let itemIndex = _.findIndex(checks, [self.itemKey, _.get(data.checks[0], self.itemKey)]);
                if (itemIndex === -1) { //add check
                    checks.push(new CheckShortDto(data.checks[0]));
                } else {                //update check
                    _.assign(checks[itemIndex], new CheckShortDto(data.checks[0]));
                    _.pullAllBy(checkLines, [{checksID: data.checks[0].checksID}], self.itemKey);
                }
                _.each(data.checkLines, dl => {
                    checkLines.push(new CheckLineDto(dl));
                });
                let updateData = {checks, checkLines, orderSummary: data.orderSummary, needsApprover: _.parseBool(data.needsApprover) };
                self.$emit("update-data", updateData);
            },

            showLossAdvanceCheckDialog(check, moneyMovementTypeID, lookupData) {
                const self = this;
                let onOk = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    return form.save()
                            .then(data => {
                                var needsApprover = _.getBool(data, "needsApprover", false);
                                if (needsApprover) {
                                    self.$dialog.messageBox(`Approval Needed`, `${MoneyMovementType.displayValue(moneyMovementTypeID)} Request requires approval and will not be completed until approved.`);
                                    _.set(data, "needsApprover", false);
                                }
                                if (!_.isEmpty(data)) self.$emit("update-ledger-data", data);
                                return true;
                            }).catch(e => {
                                form.errorMessage = e.errorMessage;
                                return false;
                            })
                            .finally(() => {
                                self.refresh();
                            });
                };
                let onCancel = (e) => {
                    self.errorMessage = "";
                    self.refresh(false);
                    return true;
                };
                let title = `${MoneyMovementType.displayValue(moneyMovementTypeID)} Request`;
                self.$dialog.open({
                    title: title,
                    width: "80%",
                    adaptive: true,
                    closeOnEsc: false,
                    scrollable: false,
                    overflowVisible: true,
                    component: CheckWritingLossAdvanceForm,
                    onOk: onOk,
                    onCancel: onCancel,
                    okTitle: "Save",
                    props: {
                        check: check,
                        moneyMovementTypeID,
                        lookupData
                    }
                });
            },

            showLossAdvanceRecoverCheckDialog(item) {
                const self = this;
                let onOk = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    return form.saveRecovery()
                            .then(data => {
                                if (!_.isEmpty(data)) self.$emit("update-ledger-data", data);
                                return true;
                            }).catch(e => {
                                form.errorMessage = e.errorMessage;
                                return false;
                            })
                            .finally(() => {
                                self.refresh();
                            });
                };
                let onCancel = (e) => {
                    self.errorMessage = "";
                    self.refresh(false);
                    return true;
                };
                let title = `${MoneyMovementType.displayValue(item.moneyMovementTypeID)} Recover`;
                self.$dialog.open({
                    title: title,
                    width: "80%",
                    adaptive: true,
                    closeOnEsc: false,
                    scrollable: false,
                    overflowVisible: true,
                    component: MoneyMovementApprovalForm,
                    onOk: onOk,
                    onCancel: onCancel,
                    okTitle: "Recover",
                    props: {
                        approving: false, item: item, readOnly: true
                    }
                });
            },

            showCompanyDialog (item) {
                const self = this;
                let original = new CheckRequestDto({...item, ordersID: self.order.ordersID});
                let checksID = _.get(item, self.itemKey);
                let onOk = function (e) {
                    self.errorMessage = "";
                    let result = _.get(e, "originalEvent.data", e.data);
                    if (!result)
                        return false;
                    let request = new CheckRequestDto({...result, ordersID: self.order.ordersID, checksID: checksID} );
                    request.buyerSellerID = null;
                    let changes = self.getAuditChanges(original, request, ["useForwardingAddress"]);
                    return self.saveCheckPayee(request, changes);
                };

                self.$dialog.open({
                    title: "Select Company or Contact",
                    height: "90%",
                    width: "85%",
                    component: CompanyContactLookup,
                    props: { defaultSelectedCompanyId: original.payeeCompanyID },
                    closeOnEsc: true,
                    onOk: onOk
                });
            },

            showEditCheckDialog(check, checkLines) {
                const self = this;
                let onOk = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    return form.save()
                            .then(data => {
                                self.setData(data);
                                return true;
                            }).catch(e => {
                                form.errorMessage = e.errorMessage;
                                return false;
                            })
                            .finally(() => {
                                self.refresh();
                            });
                };
                let onCancel = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    if (form.detailUpdates) {
                        let data = {checks: [form.item], checkLines: form.itemDetail, orderSummary: form.ledgerSummary};
                        self.setData(data);
                    }
                    self.refresh(false);
                    return true;
                };
                let payee = _.isNil(check.payee) ? '(Blank)' : _.size(check.payee) > 44 ? `${check.payee.substring(0, 44)}...` : check.payee;
                let title = _.isEqual(check.checksID, 0) ? `Add Disbursement` : `Edit Disbursement: ${payee} - ${self.formatMoney(check.amount)}`;
                self.$dialog.open({
                    title: title,
                    width: "950",
                    adaptive: true,
                    closeOnEsc: false,
                    scrollable: false,
                    overflowVisible: true,
                    component: AddEditCheckForm,
                    onOk: onOk,
                    onCancel: onCancel,
                    okTitle: "Save",
                    props: {
                        check: check,
                        checkLines: checkLines,
                        checkLineLookups: self.checkLineLookups,
                        bank: self.bank,
                        summary: self.summary
                    }
                });
            },

            showConsolidatedCheckAdjustmentFormDialog(check, checkLines) {
                const self = this;
                let onOk = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    return form.save()
                            .then(data => {
                                var needsApprover = _.getBool(data, "needsApprover", false);
                                if (needsApprover) {
                                    self.$dialog.messageBox(`Approval Needed`, `One or more of the adjustments requires approval and will not be completed until approved.`);
                                    _.set(data, "needsApprover", false);
                                }
                                if (!_.isEmpty(data)) self.$emit("update-data", data);//bypass setData bc this one returns 2 added checks and does a full refresh of data
                                return true;
                            }).catch(e => {
                                form.errorMessage = e.errorMessage;
                                return false;
                            })
                            .finally(() => {
                                self.refresh();
                            });
                };
                let onCancel = (e) => {
                    return true;
                };
                let payee = _.isNil(check.payee) ? '(Blank)' : _.size(check.payee) > 44 ? `${check.payee.substring(0, 44)}...` : check.payee;
                let title = `Consolidated Disbursement Adjustment: ${payee}`;
                self.$dialog.open({
                    title: title,
                    width: "950",
                    adaptive: true,
                    closeOnEsc: false,
                    scrollable: false,
                    overflowVisible: true,
                    component: ConsolidatedCheckAdjustmentForm,
                    onOk: onOk,
                    onCancel: onCancel,
                    okTitle: "Save",
                    props: {
                        check: check,
                        checkLines: checkLines
                    }
                });
            },

            showEscrowAccountTransferDialog(check) {
                const self = this;
                let onOk = e => {
                    return e.component.save()
                        .then(data => {
                            self.$emit("update-data", data);
                            self.refresh();
                            return true;
                        }).catch(err => {
                            console.error(err);
                            e.component.errorMessage = err;
                            self.$toast.error("Error while saving escrow account transfer.");
                            return false;
                        });
                };
                self.$dialog.open({
                    title: `Transfer to Escrow Account`,
                    width: 400,
                    adaptive: true,
                    component: CheckWritingTransferCheckToEscrowAccount,
                    props: { check: check },
                    onOk: onOk
                });
            },

            showFileContactsDialog(item) {
                const self = this;
                let original = new CheckRequestDto({...item, ordersID: self.order.ordersID});
                let checksID = _.get(item, self.itemKey);
                let onOk = (e) => {
                    let request = new CheckRequestDto({...e.component.selectedContact, ordersID: self.order.ordersID, checksID: checksID} );
                    let changes = self.getAuditChanges(original, request, ["useForwardingAddress"]);
                    let hasForwarding = _.parseBool(e.component.selectedContact.hasForwardingAddress);
                    if (hasForwarding) {
                        let onYes = (e) => {
                            request.useForwardingAddress = true;
                            return self.saveCheckPayee(request, changes);
                        };
                        let onNo = (e) => {
                            request.useForwardingAddress = false;
                            return self.saveCheckPayee(request, changes);
                        };
                        self.$dialog.confirm("Address Selection", "Use forwarding address?", onYes, onNo, { cancelTitle: 'No', okTitle: 'Yes'});
                    } else {
                        return self.saveCheckPayee(request, changes);
                    }
                };
                self.$dialog.open({
                    title: "Select File Contact",
                    height: "700",
                    width: "1000",
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: FileContactSelection,
                    onOk: onOk
                });
            },

            showManualPayeeDialog(item) {
                const self = this;
                let original = new CheckRequestDto({...item, ordersID: self.order.ordersID});
                let onOk = (e) => {
                    let request = new CheckRequestDto(e.component.item);
                    if(_.isEmpty(request.payee.trim())) return false;
                    let changes = self.getAuditChanges(original, request, ["useForwardingAddress"]);
                    if (changes.length == 0 ) {
                        self.$toast.info({ message: `No Changes Detected.` });
                        return true;
                    }
                    request.buyerSellerID = null;
                    request.payeeCompanyID = null;
                    if(changes[0].new == null){
                        return false;
                    }
                    return self.saveCheckPayee(request, changes);
                };
                self.$dialog.open({
                    title: `Manual Payee`,
                    height: "400",
                    width: "900",
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: CheckWritingManualPayee,
                    props: {
                        item: _.clone(original)
                    },
                    onOk: onOk
                });
            },

            showPayeeInfo(item) {
                const self = this;
                self.$dialog.open({
                    title: "Payee Information",
                    width: "800",
                    adaptive: true,
                    closeOnEsc: true,
                    component: CheckWritingManualPayee,
                    okOnly: true,
                    props: {
                        item: item,
                        readOnly: true
                    }
                });
            },

            showPrintChecksDialog(checksIDs) {
                const self = this;
                let request = new CheckPrintRequestDto({ordersID: self.order.ordersID, checksIDs: checksIDs});
                let onOk = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    if (!form.isValid()) {
                       return false;
                    }
                    form.printChecks()
                        .then(data => {
                            self.$emit("update-data", data);
                        }).catch(e => {
                            self.errorMessage = e.errorMessage;
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                            return true;
                        });
                };
                self.$dialog.open({
                    title: `Print ${checksIDs.length} Checks`,
                    width: "800",
                    adaptive: true,
                    closeOnEsc: true,
                    component: CheckWritingPrintForm,
                    onOk: onOk,
                    props: {
                        item: request,
                        bank: self.bank,
                        summary: self.summary,
                        lastFinalizedReconDate: self.order.lastFinalizedReconDate
                    }
                });
            },

            showWireTransferDetailsDialog(checksID, view = false) {
                const self = this;
                let onOk = e => {
                    if (!e.component.isValid()) return false;
                    return e.component.save()
                        .then(result => {
                            if (_.isObject(result))
                                self.$emit("update-data", result);
                            return true;
                        }).catch(err => {
                            console.error(err);
                            if(err.errorCode === "BANKACCOUNTVALIDATIONERROR") {
                                return false;
                            }
                            if(err.errorCode === "BANKACCOUNTVALIDATIONREQUIRED") {
                                e.component.showUploadFileDialog('save', '', (result) => {
                                    if (_.isObject(result))
                                    self.$emit("update-data", result);
                                    self.$dialog.close();
                                });

                                return false;
                            }

                            self.$toast.error("Error while saving wire transfer.")
                            return err;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                };
                //only fire when creating a new one, not viewing an existing
                if (!view) {
                    let balance = 0;
                    let whichBalance = '';
                    let check = _.find(self.items, [self.itemKey, checksID]);
                    let checkTotal = check.amount;
                    if (self.localSecurity.AnticipatedDeposits == 1) {//Full
                        balance = self.summary.anticipatedBalance;
                        whichBalance = 'Anticipated';
                    } else {
                        balance = self.summary.preDisbursementBalance;
                        whichBalance = 'Pre-Disbursement';
                    }
                    if (self.localSecurity.AllowChecksWhenUnbalanced) {
                        balance += self.summary.anticipatedDisbursements;
                    }
                    if (balance != 0 && !self.localSecurity.AllowChecksWhenUnbalanced) {
                        self.errorMessage = `Unable to Post Wire - ${whichBalance} Balance Must be Zero.`;
                        return;
                    }
                    if (self.localSecurity.AllowChecksWhenUnbalanced && checkTotal > balance) {
                        self.errorMessage = "Unable to post wire, value of selected disbursement exceeds account balance.";
                        return;
                    }
                }
                self.$dialog.open({
                    title: `Wire Transfer:`,
                    width: "80%",
                    minWidth: 1200,
                    adaptive: true,
                    closeOnEsc: true,
                    component: CheckWritingWireTransferForm,
                    props: { checksID },
                    onOk: onOk
                });
            },

            showFileTransferDialog(checksID) {
                const self = this;
                let onOk = e => {
                    return e.component.save()
                        .then(data => {
                            self.$emit("update-data", data);
                            return true;
                        }).catch(err => {
                            console.error(err);
                            self.$toast.error("Error while saving file transfer.")
                            return err;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                };
                let onCancel = e => {};
                self.$dialog.open({
                    title: `Transfer to File`,
                    width: 400,
                    adaptive: true,
                    component: CheckWritingTransferCheckToFile,
                    props: { checksID },
                    onOk: onOk
                });
            },

            undoChecks(ids){
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.undoChecks(self.order.ordersID, ids);
                return self.$rqBusy.wait(apiPromise);
            },
        }
    }
</script>