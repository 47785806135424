<template>
    <div class="task-documents flush-container content-wrapper">
        <rq-page-section title="Assigned Documents" v-model:expanded="showUploadArea" borderless collapsible hide-collapse-icon>
            <template v-if="localSecurity.FileScanCanAttachFilesAndDocuments" #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <b-btn variant="icon"
                            @click="onShowUploadArea"
                            v-rq-tooltip.hover.top="{ title: 'Upload Documents', container: `#${$parent.$parent.dialogId}`, boundary: `#${$parent.$parent.dialogId}` }"
                            :disabled="!allowEdit">
                                <FontAwesomeIcon icon="fas fa-upload" />
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <!-- <b-btn variant="link" class="btn-theme" @click="onAssignDocumentsClick">Assign FileScan Documents</b-btn> -->
                        <!-- FA Pro Icon -->
                        <!-- <FontAwesomeIcon icon="fas fa-file-plus" /> -->
                        <b-btn variant="icon"
                            @click="onShowDocuments"
                            v-rq-tooltip.hover.top="{ title: 'Assign Documents', container: `#${$parent.$parent.dialogId}`, boundary: `#${$parent.$parent.dialogId}` }"
                            :disabled="!allowEdit">
                                <FontAwesomeIcon icon="fas fa-plus" />
                        </b-btn>
                    </li>
                </ul>
            </template>
            <!-- <div ref="downloadUrlElements" style="display:none">
                <a v-for="doc in documentDownloads" :key="doc.clientKey" :href="doc.downloadUrl" :download="doc.fileName"></a>
            </div> -->
            <div v-if="localSecurity.FileScanCanAttachFilesAndDocuments" class="document-upload-container">
                <div v-show="fileUploadInvalid" class="invalid-upload-alert">
                    <button type="button" class="invalid-upload-close-button" @click="fileUploadInvalid = false"><FontAwesomeIcon icon="fas fa-times" /></button>
                    <div class="invalid-upload-icon"><FontAwesomeIcon icon="fas fa-exclamation-triangle" /></div>
                    <div class="invalid-upload-content">
                        <div class="invalid-upload-title">Invalid file</div>
                        <div class="invalid-upload-message">Select a different file to upload.</div>
                    </div>
                </div>
                <div class="document-upload-form">
                    <form enctype="multipart/form-data" novalidate ref="fileForm">
                        <div class="dropbox" ondragenter="this.classList.add('dropTarget');" ondragleave="this.classList.remove('dropTarget');" ondrop="this.classList.remove('dropTarget')">
                            <span>Drag and drop or click to add a document</span>
                            <input
                                ref="fileInput"
                                type="file" multiple
                                automation_id="task_documents"
                                name="documents"
                                :disabled="!localSecurity.FileScanCanAttachFilesAndDocuments || !allowEdit"
                                @change="onFileInputChange"
                                :accept="acceptedAttr"
                                class="input-file"
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div v-else>
                <div class="instruction-text">Currently assigned documents.</div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="taskDocsDataGrid"
            automation_id="dg_wftasks_taskDocs"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            :persist-state="false"
            class="task-document-grid"
            @remove="onRemove"
            @download="onDownload"
            @rowDoubleClick="onGridRowDoubleClick"
            hide-toolbar
        />
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { UserSecuritySetting } from "@/shared/models/models";
    import { DocumentFileType, FileScanDocumentType }  from "../../../documents/enums";
    import { DocumentSaveRequest, DocumentSaveResult, DocumentDownloadModel, DocumentUserPermissions } from "../models";
    import { FileScanDocumentDto, FileScanDocumentShortDto }  from "../../../document-mgmt/models";
    import { FileScanDocumentDuplicateAction, FileScanDocumentAction }  from "../../../document-mgmt/enums";
    import UploadDocumentProperties from "./UploadDocumentProperties";
    import { DateTimeHelper } from "@/shared/utilities";

    export default {

        props: {
            items: {type: Array, required: true, default: () => []},
            orderId: { default: 0 },
            orderWorkflowTaskID: { default: 0 },
            taskName: { type: String, default: "order task" },
            allowEdit: { default: true }
        },

        data () {
            const self = this;
            return {
                acceptedFileTypes: [".bmp",".doc", ".docm",".docx", ".gif", ".htm", ".html", ".jpg", ".msg", ".pdf", ".png", ".pptx", ".rtf", ".tif", ".txt", ".xls", ".xlsx", ".xml", ".zip"],
                uploadDocument: null,
                uploadDocumentData: null,
                uploadDocumentList: null,
                uploadDocumentDataList: [],
                documents: [],
                errorMessage: "",
                fileUploadInvalid: false,
                failedDownloads: [],
                showUploadArea: false
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                order: state => state.orders.order,
                user: state => state.authentication.session.user
            }),
            localSecurity(){
                return this.securitySettings.findValues(["CanReplaceDocuments","FileScanCanAttachFilesAndDocuments"]);
            },
            acceptedAttr() { return _.join(this.acceptedFileTypes, ","); },
            fileForm() { return _.get(this.$refs, "fileForm", null) || {}; },
            fileInput() { return _.get(this.$refs, "fileInput", null) || {}; },
            taskDocuments() { return _.filter(this.items, ['orderWorkflowTaskID', this.orderWorkflowTaskID]); },
        },

        watch: {
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue) ) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
        },

        created () {
            this.initGridConfig();
            this.initNonReactiveVariables();
        },

        methods: {
            base64ToArrayBuffer(base64) {
                var binary_string = window.atob(base64);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                return bytes.buffer;
            },

            clearForm() {
                this.showUploadArea = false;
                if (!this.fileForm) return;
                this.fileForm.reset();
                if (!this.fileInput) return;
                this.fileInput.value = null;
            },

            downloadDocuments(action, ids) {
                const self = this;
                let apiPromise = self.$api.FileScanApi.getFileScanDocumentData(action, 0, ids);
                self.$rqBusy.wait(apiPromise)
                    .then(files => {
                        _.each(files, file => {
                            self.saveToClient(file);
                        });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error Downloading Documents.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            getExistingFileScanCategoryID(item){
                let dup = {};
                dup = _.find(this.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.description))
                           && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1);
                });
                return _.get(dup, "fileScanCategoryID", 0);
            },

            getSecuritySetting(name, type) {
                let perm = _.find(_.get(this.securitySettings, type), {name}) || new UserSecuritySetting();
                return perm.value;
            },

            initGridConfig () {
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "commonFileName",
                            caption: "Document Description",
                            cellTemplate (container, options) {
                                let fileIcon = DocumentFileType.iconSymbol(options.data.fileType);
                                $("<span>")
                                    .addClass("document-cell-content")
                                    .append(`<svg class="rq-icon-symbol-lg mx-2"><use href="#${fileIcon}"></use></svg>`)
                                    .append(options.text)
                                    .appendTo(container);
                            }
                        }
                    ],
                    noDataText: "No assigned documents",
                    focusedRowEnabled: false,
                    showColumnHeaders: false,
                    showBorders: false
                };
                self.gridDataSource = {
                    key: "fileScanDocumentID",
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.taskDocuments);
                    },
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Document";
                self.itemTypeNamePlural = "Documents";
                self.itemKey = "fileScanDocumentID";
                self.selectionActions = [
                    { name: "remove", text: "Remove", eventName: "remove", allowMultiSelection: true, disabled: !self.allowEdit },
                    { name: "download", text: "Download", eventName: "download", allowMultiSelection: true, disabled: !self.allowEdit }
                ];
            },

            isUniqueItemExisting(item){
                let dup = {};
                dup = _.find(this.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.description))
                           && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1);
                });
                return _.isEmpty(dup);
            },

            isValidFileExt(fileExt){
                this.fileUploadInvalid = _.includes(this.acceptedFileTypes, _.toLower(fileExt));
                return this.fileUploadInvalid;
            },

            onFileInputChange (e) {
                const self = this;
                if (e.target.files.length <= 0) return;
                let fileList = e.target.files;
                let docList = [];
                let fileDataList = [];
                for(let i=0; i < fileList.length; i++)
                {
                    docList.push(self.processFile(fileList[i]));
                    fileDataList.push(fileList[i])
                }
                self.uploadDocument = docList[0];
                self.uploadDocumentData = fileList[0];
                self.uploadDocumentList = docList;
                self.uploadDocumentDataList = fileDataList;
                self.clearForm();

                if (this.uploadDocument != null ) this.showDocumentProperties(self.uploadDocument);
            },

            onDownload(e) {
                if(!e || !e.data) return;
                const self = this;
                let ids = _.map(e.data, self.itemKey);
                let onCancel = function (args) {
                    self.downloadDocuments(FileScanDocumentAction.Download, ids);
                }
                let onOk = function (args) {
                    self.downloadDocuments(FileScanDocumentAction.DownloadZip, ids);
                }
                if (ids.length > 1) {
                    self.$dialog.confirm("Confirm Zip", `Would you like to zip the selected ${ids.length > 1 ? self.itemTypeNamePlural : self.itemTypeName} into one file?`, onOk, onCancel, { cancelTitle: 'No', okTitle: 'Yes'});
                } else {
                    self.downloadDocuments(FileScanDocumentAction.Download, ids);
                }
            },

            onGridRowDoubleClick (e) {
                if (!e || !e.data || !this.allowEdit) return;
                let ids = _.get(e.data, this.itemKey);
                this.downloadDocuments(FileScanDocumentAction.Download, [ids]);
            },

            onShowUploadArea(e) {
                if (!this.allowEdit || !this.localSecurity.FileScanCanAttachFilesAndDocuments) return;
                this.showUploadArea = !this.showUploadArea;

                //wait for collapse/expand transition to complete
                _.delay(() => { this.updateDimensions(); }, 350);
            },

            onShowDocuments () {
                const self = this;
                if (!self.allowEdit || !self.localSecurity.FileScanCanAttachFilesAndDocuments) return;
                self.$emit("show-documents");
            },

            onRemove (e) {
                if(!e || !e.data) return;
                const self = this;
                let documents = e.data;
                let okHandler = () => {
                    self.$emit("remove-documents", documents);
                    return true;
                };
                self.$dialog.confirm(`Remove Document${documents.length == 1 ? '' : 's'}`, `Are you sure you want to remove the selected document${documents.length == 1 ? '' : 's'} from this task?`, okHandler);
            },

            onDownloadSelected (e) {
                if(!e || !e.data) return;
                this.downloadDocuments(e.data);
            },

            saveToClient(file) {
                const self = this;
                var binary = self.base64ToArrayBuffer(file.content);
                var blob = new Blob([binary], {type: file.contentType});
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(blob, file.commonFileName);
                else { // Others
                    var a = document.createElement("a")
                    var url = URL.createObjectURL(blob);
                    a.href = url;
                    a.download = file.commonFileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            },

            showDocumentProperties (file) {
                const self = this;
                let onOk = (e) => {
                    let form = e.component;
                    let item = form.item;
                    form.v$.$touch();
                    if (form.v$.$error) {
                        form.errorMessage = 'Please provide all required fields.';
                        form.hasErrors = true;
                        return false;
                    }
                    if (!item.isValid) {
                        form.errorMessage = item.validationErrors[0];
                        form.hasErrors = true;
                        return false;
                    }
                    if (!self.isUniqueItem(item) && item.fileScanDocumentDuplicateAction == 0) {
                        item.requiresAttention = true;
                        form.errorMessage = `Document with file type [${item.fileTypeDisplay}] and Description [${item.description}] already exists.  Please change the description or choose the appropriate Action.`;
                        form.hasErrors = true;
                        return false;
                    }
                    self.uploadDocument = item;
                    let docList = self.uploadDocumentList;
                    let fileList = self.uploadDocumentDataList;

                    // Set the category for all documents dropped to be the same as the first document.
                    for(let i=0; i < docList.length; i++)
                    {
                        docList[i].fileScanCategoryID = item.fileScanCategoryID;
                        docList[i].fileScanCategoryDisplay = item.fileScanCategoryDisplay;
                    }
                    for(let i=0; i < fileList.length; i++)
                    {
                        fileList[i].fileScanCategoryID = item.fileScanCategoryID;
                        fileList[i].fileScanCategoryDisplay = item.fileScanCategoryDisplay;
                    }

                    // Save and set association to each document to workflow.
                    let newDocs = [];
                    let taskDocs = self.taskDocuments;
                    for(let i=0; i < docList.length; i++)
                    {
                        self.saveDocument(docList[i], fileList[i])
                            .then(documents => {
                                documents[0].orderWorkflowTaskID = self.orderWorkflowTaskID;
                                if(!self.isExistingItem(documents[0]))
                                    self.$emit("add-document", new FileScanDocumentShortDto(documents[0]));
                                self.$toast.success({ message: `Document was saved.` });
                                newDocs.push(new FileScanDocumentShortDto(documents[0]));
                            })
                            .catch(error => {
                                self.$toast.error({ message: `Error Saving Document.` });
                            })
                            .finally(() => {
                                // Moved Assign Document to Workflow Code here.  Using assignment via "emit" runs into async issues.
                                let fileScanDocumentIDs = _.map(newDocs, 'fileScanDocumentID');
                                for(let j=0; j < taskDocs.length; j++)
                                {
                                    if(fileScanDocumentIDs.indexOf(taskDocs[j].fileScanDocumentID) === -1)
                                        fileScanDocumentIDs.push(taskDocs[j].fileScanDocumentID);
                                }
                                self.$api.OrderWorkflowApi.assignDocuments(self.orderWorkflowTaskID, fileScanDocumentIDs)
                                    .then(result => {
                                        self.documents = _.map(result, i => new FileScanDocumentShortDto(i));
                                    })
                                    .catch(error => {
                                        self.$toast.error({ message: `Error assigning Document${fileScanDocumentIDs.length == 1 ? '' : 's'}.` });
                                    });

                                // self.clearForm();
                                // return true;
                            });
                    }
                    self.clearForm();
                    return true;

                };
                self.$dialog.open({
                    title: "Upload Document",
                    width: "600",
                    height: "auto",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    closeOnEsc: true,
                    component: UploadDocumentProperties,
                    props: {item: file},
                    onOk: onOk,
                    onCancel (e) {
                        self.clearForm();
                    }
                });
            },

            processFile (file) {
                if (!file) return null;
                const self = this;

                let fileExt = file.name.substr(file.name.lastIndexOf('.'));
                let description = file.name.substr(0, file.name.lastIndexOf('.'));
                let fileType = DocumentFileType.fromFileExtension(fileExt.toLowerCase());
                let doc = new FileScanDocumentDto(
                    {
                        fileScanDocumentID: 0,
                        ordersID: self.orderId,
                        fileScanPageFileName: file.name,
                        contentType: file.type,
                        fileSize: file.size,
                        description: description,
                        standardDescription: description,
                        numberOfPages: 1,
                        documentType: FileScanDocumentType.Attached,
                        fileScanDescriptionID: 0,
                        fileTypeDisplay: fileExt.toLowerCase(),
                        fileType: fileType,
                        useDefaultDescription: self.predefinedDescriptions,
                        dateModifiedNumber: file.lastModified,
                        tagIDs: [],
                        orderWorkflowTaskID: self.orderWorkflowTaskID
                    });
                if (!self.isUniqueItemExisting({description, fileType})) {
                    //this doc exists, so set the category and action type
                    doc.requiresAttention = true;
                    doc.fileScanCategoryID = self.getExistingFileScanCategoryID({description, fileType});
                    doc.fileScanDocumentDuplicateAction = self.localSecurity.CanReplaceDocuments ? FileScanDocumentDuplicateAction.ReplaceExisting : FileScanDocumentDuplicateAction.CreateNew;
                    doc.description = self.localSecurity.CanReplaceDocuments ? doc.description : `${doc.description}_${DateTimeHelper.now("yyyy-MM-DD hh:mm:ss a")}`;
                } else {
                    doc.requiresAttention = false;
                    doc.fileScanCategoryID = self.user.defaultCategory;
                    doc.fileScanDocumentDuplicateAction = 0;
                }

                return doc;
            },

            saveDocument(doc, fileData){
                const self = this;
                if (!self.localSecurity.FileScanCanAttachFilesAndDocuments || !self.allowEdit) return;
                const formData = new FormData();
                formData.append(`file-${fileData.name}`, fileData);
                formData.append('data-', JSON.stringify([doc]));
                let apiPromise = self.$api.FileScanApi.uploadToFileScan(formData);
                return self.$rqBusy.wait(apiPromise);
            },

            isUniqueItem(item){
                let trimLower = val => _.toLower(_.trim(val));
                let dup = _.find(this.items, (i) => trimLower(i.description) === trimLower(item.description) && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1));
                return _.isEmpty(dup);
            },

            isExistingItem(item){
                let dup = _.find(this.items, (i) => (i.fileScanDocumentID === item.fileScanDocumentID) && (i.orderWorkflowTaskID === item.orderWorkflowTaskID));
                return !_.isEmpty(dup);
            },

            updateDimensions () {
                this.invokeGridMethod("updateDimensions");
            },

            clear() {
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("option", "focusedRowIndex", -1);
            },

            refresh () {
                this.clear();
                this.invokeGridMethod("refresh");
            },

            invokeGridMethod(method, ...params) {
                return _.invoke(this, `$refs.taskDocsDataGrid.gridInstance.${method}`, ...params) || null;
            }
        }

    };
</script>
