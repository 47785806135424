<template>
    <rqdx-action-data-grid
        ref="additionalChargesGrid"
        automation_id="grid_additional_charges"
        :config="gridConfig"
        :data-source="gridDataSource"
        :actions="selectionActions"
        export-file-name="additional_charges"
        search-mode="field"
        :rq-editable="!readOnly"
        hide-search
        hide-show-column-chooser
        title="Additional Charges"
        focus-after-insert="first-row"
        @delete="onDeleteItem">
        <template #toolbar>
            <div class="rq-title navbar-text me-auto">
                <b-btn variant="theme" size="sm" @click="onAddItem" :disabled="readOnly">Add </b-btn>
            </div>
        </template>
    </rqdx-action-data-grid>
</template>

<script>
    import { PayoffDetail } from '../../models';
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { SystemLookupItem } from "@/shared/models/models";

    export default {
        props: {
            charge: { type: Object, default: null },
            readOnly: {type: Boolean, default: false}
        },
        data: function() {
            return {
                additionalCharges: []
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            selectionActions() {
                const self = this;
                return [
                    {
                        name: "delete", text: "Delete",
                        eventName: "delete", requireSelection: true,
                        allowMultiSelection: true, tooltip: "Delete Additional Charge",
                        disabled: function(e) {
                            return self.readOnly;
                        }
                    }
                ];
            },
            gridInstance() {
                return _.get(this.$refs, "additionalChargesGrid.gridInstance");
            }
        },
        watch: {
            charge: {
                handler: function(newValue, oldValue) {
                    this.additionalCharges = _.map(newValue.details, (item) => {
                        return new PayoffDetail(item);
                    });
                    this.refreshGrid();
                },
                immediate: false
            }
        },
        created(){
            this.initGridConfig();
        },
        methods: {
            initGridConfig(){
                const self = this;
                let payoffDescriptionList = self.lookupHelpers.getLookupItems(self.lookupItems.PAYOFF_DESCRIPTIONS);
                self.gridConfig = {
                    remoteOperations: { sorting: false, paging: false },
                    paging: { enabled: true, pageIndex: 0, pageSize: 10 },
                    columns: [
                        {
                            dataField: "payoffDescription",
                            caption: "Description",
                            width: "70%",
                            setCellValue: function(rowData, value) {
                                rowData.payoffDescriptionID = value.id;
                                rowData.payoffDescription = value.name;
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                let customPayoffDescriptionList = payoffDescriptionList.slice();
                                if(cellInfo.data.payoffDescriptionID === -1) {
                                    let customItem = new SystemLookupItem({
                                        id: cellInfo.data.payoffDescriptionID,
                                        name: cellInfo.data.payoffDescription
                                    });
                                    customPayoffDescriptionList = _.concat([customItem], customPayoffDescriptionList);
                                }
                                $("<div />").dxSelectBox({
                                    dataSource: customPayoffDescriptionList,
                                    searchEnabled: true,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.data.payoffDescriptionID,
                                    acceptCustomValue: true,
                                    onCustomItemCreating: function(e) {
                                        e.customItem = new SystemLookupItem({ id:-1, name:e.text });
                                        let index = _.findIndex(customPayoffDescriptionList, d => d.id === -1);
                                        if(index !== -1) {
                                            customPayoffDescriptionList.splice(index, 1, e.customItem);
                                            cellInfo.setValue(e.customItem);
                                        }
                                        else
                                            customPayoffDescriptionList.push(e.customItem);
                                    },
                                    onValueChanged: function(e) {
                                        let payoffDescription = _.find(customPayoffDescriptionList, ['id', e.value]);
                                        cellInfo.setValue(payoffDescription);
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                        {
                            dataField: "amount",
                            caption: "Amount",
                            alignment: "right",
                            dataType: "number",
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTextBox({
                                    value: cellInfo.value,
                                    showClearButton: false,
                                    valueChangeEvent: "change",
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                        self.commitData();
                                    }
                                }).appendTo(cellElement);
                            }
                        }
                    ],
                    summary:
                    {
                        totalItems: [
                            {
                                name: "TOTAL",
                                column: "payoffDescription",
                                alignment: "left",
                                summaryType: "sum",
                                cssClass: "rq-summary-label",
                                displayFormat: "TOTAL"
                            },
                            {
                                column: "amount",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            }
                        ]
                    }
                };
                self.gridDataSource = {
                    key: "payoffDetailID",
                    load (loadOptions) {
                        return Promise.resolve(self.additionalCharges.slice());
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },
            commitData() {
                let gridInstance = this.gridInstance;
                if(gridInstance)
                    return gridInstance.saveEditData();
            },
            refreshGrid() {
                let gridInstance = this.gridInstance;
                if(gridInstance)
                    gridInstance.refresh();
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new PayoffDetail(values);
                self.additionalCharges.push(newItem);
                self.charge.details = self.additionalCharges.slice();
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.additionalCharges, item => _.parseNumber(_.get(item, "payoffDetailID"), -1) === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let updatedItem = new PayoffDetail(_.assign({}, self.additionalCharges[itemIndex], values));
                self.additionalCharges[itemIndex] = updatedItem;
                self.charge.details = self.additionalCharges.slice();
            },
            onAddItem() {
                let gridInstance = this.gridInstance;
                if(gridInstance)
                    gridInstance.addRow();
            },
            onDeleteItem(e) {
                let self = this;
                let toBeDeletedIds = _.map(e.data, x => x.payoffDetailID);
                this.additionalCharges = _.filter(self.additionalCharges, x => {
                    if(!_.includes(toBeDeletedIds, x.payoffDetailID)) return x;
                });
                self.charge.details = self.additionalCharges.slice();
                self.refreshGrid();
                self.$emit('calculate');
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"-%s%v", zero:"%s%v" } }); },
        }
    }
</script>