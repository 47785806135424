<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner && hasErrors"
            dismissable
        />
        <rq-page-section title="Other Charges" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav">
                    <li v-if="isHud2010" class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly" v-focus>Add</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_disclosure_line" @click="onAddLoanCostLine(HudLineTypes.Disclosure)">Disclosure</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_general_line" @click="onAddLoanCostLine(HudLineTypes.General)">General</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_gfe6_line" @click="onAddLoanCostLine(HudLineTypes.GFE_6_Outside)">GFE #6</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li v-else class="nav-item">
                        <b-button automation_id="btn_add_other_charge"
                            ref="btn_add_other_charge"
                            variant="theme"
                            @click="onAddLoanCostLine(HudLineTypes.General)"
                            v-rq-tooltip.hover.top="{ title: `Add ${itemTypeName}` }"
                            :disabled="readOnly"
                            v-focus>
                            Add
                        </b-button>
                    </li>
                    <li><button automation_id="btn_view_premiums" type="button" class="btn btn-link btn-theme" @click="onShowPremiums" v-if="hasPremiumsAccess">View Premiums</button></li>
                </ul>
                <ul class="nav ms-auto grid-tools-offset">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="dg_available"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="othercharges-data"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['isInactive']"
            :rq-editable="!readOnly"
            @editorPreparing="onEditorPreparing"
            @delete="onDeleteItems"
            @revert="onRevertItems"
            @netfund="onChangeNetFundItems"
            @moveSection="onMoveSection"
            @setAddendum="onSetAddendumItem"
            focus-after-insert="first-row"
            hide-search
        />
    </div>
</template>

<script>
    import { GlobalEventManager } from '@/app.events';
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import PremiumsMain from "../premiums/PremiumsMain";
    import { AltaCategories, OtherChargeModel, LoanCostTypes, POCWhoOptions, SETTLEMENT_TYPE, SettlementTypeOption, HudLineTypes, SsGridActions } from '@settlement/models';
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { useCommonSettlementSecurity } from "@settlement/helpers";

    export default {
        name: "OtherCharges",
        mixins: [BaseSettlementMixin, GridCompanyPickerMixin],
        components: {},
        setup() {
            return useCommonSettlementSecurity();
        },
        data(){
            return {
                itemTypeNamePlural: "Charges" ,
                itemKey: "loanCostSectionDataID",
                itemTypeName: "Charge",
                selectionActions: [],
                items: [],
                movedItems: [],
                deletedItems: [],
                originalData: [],
                selectedLoanId: 0,
                otherCdfSections: [
                    { id: 'H', name: 'H' }
                ],
                LoanCostTypes: LoanCostTypes,
                selectedLineType: HudLineTypes.General,
            };
        },

        computed:{
            gridStorageKey() { return `settlement_other_charges_${this.selectedView}_${this.isWithOutSeller}_${this.includeAlta}`; },
            gridInstance () { return this.$refs.dataGrid.gridInstance; },
            pocWhoOptions() { return POCWhoOptions.lookupItems; },
            accountsCodes(){return this.lookupHelpers.getAllLookupItems(this.lookupItems.ACCOUNTING_CODES)},
            localSecurity() { return { PremiumsAccessible: this.securitySettings.hasAccess("Premiums") }; },
            hasPremiumsAccess() {return this.securitySettings.hasAccess("Premiums")},
            HudLineTypes() { return HudLineTypes; },
            altaCategories() { return AltaCategories.lookupItems; },
        },

        watch:{
            selectedLoanId(newValue, oldValue) {
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                this.save();
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeSSViewSelected(newValue);
                this.fetchData();
            },
            isWithOutSeller(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeWithOutSeller(newValue);
            }
        },

        created() {
            const self = this;
            self.baseInit();
            self.selectionActions = [
                {
                    name: "Delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if( _.some(e.data, item => item.required === true || item.isPayOff || _.parseNumber(item.premiumType, 0) > 0 ||
                            _.parseNumber(item.paidBySource, 0) > 0 || !_.isNil(item.realtorCommissionPayeeID) || item.isSettlementFeeLine)) {
                            return "Unselect required lines to delete";
                        } else {
                            return false;
                        }
                    },
                    tooltip: `Delete ${self.itemTypeName}`
                },
                {
                    name: "Revert",
                    text: "Revert",
                    eventName: "revert",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if(_.some(e.data, item => !item.canRevert || !item.isOverridableLine)) {
                            return "Unselect lines that are overridden";
                        }
                        else
                            return false;
                    },
                    tooltip: `Revert ${self.itemTypeName}`
                },
                {
                    name: "Apply-Net-Fund",
                    text: "Apply Net Fund",
                    eventName: "netfund",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if (_.some(e.data, item => item.isPremiumOrEndorsement)) return "Unselect lines that are premium";

                        if (self.isHud && _.some(e.data, item => !_.isNil(item.paidBySource))) return true;

                        if (self.isHud2010 && _.find(e.data, item => item.hudLine === 1301 || item.isDisclosureLine)) return true;

                        return false;
                    },
                    children: [
                        SsGridActions.NETFUND_NONE,
                        SsGridActions.NETFUND_POSITIVE,
                        SsGridActions.NETFUND_NEGATIVE
                    ]
                },
                {
                    name: "Move-To-Section",
                    text: "Move to Section",
                    eventName: "moveSection",
                    requireSelection: true,
                    allowMultiSelection: true,
                    visible: self.isCdf,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if (_.some(e.data, item => item.isPremiumOrEndorsement || item.isSettlementFeeLine || item.required || item.isAddendum)) return true;

                        return false;
                    },
                    children: [
                        SsGridActions.SECTION_B,
                        SsGridActions.SECTION_C
                    ]
                },
                {
                    name: "Set-Addendum",
                    text: `Set as Addendum Line`,
                    eventName: "setAddendum",
                    requireSelection: true,
                    allowMultiSelection: false,
                    visible: function(e) {
                        if (!e || !e.data) return false;
                        let item = e.data;
                        if (_.parseBool(item.required)
                            || item.premiumType > 0
                            || item.paidBySource > 0
                            || !_.isNil(item.orderSettlementFeeID)) return false;

                        return (self.isCdf && !_.parseBool(item.isAddendum)); // isCdf and elgible addendum item
                    },
                    tooltip: "Set as Addendum Line",
                    disabled(e) {
                        let item = e.data;
                        if (self.readOnly) return true;

                        return false;
                    }
                },
                {
                    name: "Remove-Addendum",
                    text: `Remove Addendum Line`,
                    eventName: "setAddendum",
                    requireSelection: true,
                    allowMultiSelection: false,
                    visible: function(e) {
                        if (!e || !e.data) return false;
                        let item = e.data;
                        if (_.parseBool(item.required)
                            || item.premiumType > 0
                            || item.paidBySource > 0
                            || !_.isNil(item.orderSettlementFeeID)) return false;

                        return (self.isCdf && _.parseBool(item.isAddendum)); // isCdf and elgible addendum item
                    },
                    tooltip: "Remove Addendum Line",
                    disabled(e) {
                        let item = e.data;
                        if (self.readOnly) return true;

                        return false;
                    }
                },
            ];

            self.initGridConfig();
            self.initGridDataSource();
            self.fetchData();
        },

        mounted(){
            if (this.isHud2010)
                this.resetFocus();
        },
        methods:{
            initNewRow(e) {
                e.data = {
                    hudLineType: this.selectedLineType,
                    category: AltaCategories.Miscellaneous
                }
            },
            sortItems(collection, sortBy) {
                const self = this;
                if (_.isNil(sortBy))
                    sortBy = (self.selectedView === SETTLEMENT_TYPE.CDF) ? 'cdfSection' : 'hudLine';

                return _.sortBy(collection, sortBy);
            },
            getNextAvailableHudLine() {
                const self = this;
                let hudData = self.isHud ? self.items : self.originalData;
                let hudItems = _.filter(hudData, i => i.hudSection === 1300);
                // return the first 1300 line if no lines in that section exist
                if (hudItems.length === 0) return 1301;

                let maxHudLineItem = _.maxBy(hudItems, function(i) { return i.hudLine; });
                return _.parseNumber(maxHudLineItem.hudLine, 0) + 1;
            },
            initGridConfig() {
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeCompanyID",
                    companyNameExpr: "payeeCompanyName",
                    contactIDExpr: "payeeContactID",
                    contactNameExpr: "payeeContactName",
                    showContactPicker: true,
                };

                self.gridConfig = {
                    onInitNewRow: self.initNewRow,
                    focusedRowEnabled: false,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            dataField: "category",
                            caption: 'Category',
                            width: 150,
                            lookup: {
                                dataSource: self.altaCategories,
                                displayExpr: "name",
                                valueExpr: "name"
                            },
                            visible: self.includeAlta,
                            showInColumnChooser: self.includeAlta,
                        },
                        {
                            dataField: "hudLine",
                            allowEditing: false,
                            caption: "HUD Line",
                            showInColumnChooser: self.isHud,
                            visible: self.isHud,
                            width: 60
                        },
                        {
                            dataField: "description",
                            editorOptions: {maxLength: 150},
                            allowEditing: !self.readOnly,
                            calculateDisplayValue(rowData) {
                                return self.getLineDescription(rowData)
                            }
                        },
                        //from GridCompanyPickerMixin
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "payeeCompanyName",
                                caption: "Payee",
                            },
                            disabled: cellInfo => {
                                return self.isPayeeColumnDisabled(cellInfo.data) || self.readOnly;
                            },
                            ...payeePickerInfo
                        }),
                        {
                            dataField: "misc_Dollar",
                            caption: "Borrower Outside",
                            width: 90,
                            dataType: "number",
                            format: { type: "currency", precision: 2 },
                            editorOptions: {
                                format: { type: "currency", precision: 2 },
                                disabled: true
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            visible: self.isHud2010
                        },
                        {
                            caption: "Borrower",
                            alignment: "center",
                            columns: [
                            {
                                dataField: "borrowerAtClosingValue",
                                caption: "At Closing",
                                width: 90,
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate(cellElement, cellInfo) {
                                    let displayText = cellInfo.text;

                                    // 2010 HUD - Hide values from UI for borrowerAtClosing from user suspecting there is values here
                                    // however there really is so that we don't lose data in case user switches between CDF, 1974 HUD
                                    let isOverridableField = _.parseBool(_.includes([1301], cellInfo.data.hudLine), false);
                                    if (self.isHud2010) {
                                        let isGeneralLine = cellInfo.data.isGeneralLine;
                                        let isPayoff = cellInfo.data.isPayOff;
                                        let isPaidByOther = cellInfo.data.isPaidByOther;
                                        displayText = (isOverridableField || isGeneralLine || isPayoff || isPaidByOther) ? cellInfo.text : "";
                                    }

                                    if(cellInfo.data.isBorrowerAtClosingOverridden) {
                                        let isCdfPremium = self.isCdf && cellInfo.data.isPremiumOrEndorsement;
                                        let isSettlementFeeLine = cellInfo.data.isSettlementFeeLine;

                                        // CDF allows override of premium, endorsement
                                        // so we have conditions here for styling differently but mapper respects the override fields
                                        // and should map fine
                                        if (isCdfPremium || isSettlementFeeLine || isOverridableField)
                                            cellElement.addClass("rq-overridden");
                                    }

                                    cellElement.append(accounting.formatMoney(displayText));
                                }
                            },
                            {
                                dataField: "borrowerBeforeClosing",
                                caption: "Before Closing",
                                width: 105,
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                                visible: self.isCdf
                            }]
                        },
                        {
                            caption: "Seller",
                            alignment: "center",
                            visible: !self.isWithOutSeller,
                            dataField: "",
                            columns: [{
                                dataField: "sellerAtClosingValue",
                                width: 90,
                                caption: "At Closing",
                                dataType: "number",
                                showInColumnChooser: !self.isWithOutSeller,
                                visible: !self.isWithOutSeller,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate(cellElement, cellInfo) {
                                    let displayText = cellInfo.text;
                                    if(cellInfo.data.isSellerAtClosingOverridden)
                                        cellElement.addClass("rq-overridden");

                                    cellElement.append(accounting.formatMoney(displayText));
                                }
                            },
                            {
                                dataField: "sellerBeforeClosing",
                                caption: "Before Closing",
                                width: 105,
                                dataType: "number",
                                disabled: !self.readOnly,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                                showInColumnChooser: self.isCdf && !self.isWithOutSeller,
                                visible: self.isCdf && !self.isWithOutSeller
                            }]
                        },
                        {
                            dataField: "hudLineTypeDescription",
                            allowEditing: false,
                            caption: "Line Type",
                            visible: self.isHud2010,
                            showInColumnChooser: self.isHud2010
                        },
                        {
                            dataField: "paidByOthers",
                            caption: "Paid By Others",
                            width: 90,
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            showInColumnChooser: self.isCdf,
                            visible: self.isCdf
                        },
                        {
                            dataField: "poc",
                            caption: 'POC Whom',
                            allowEditing: !self.readOnly,
                            width: 75,
                            editorOptions: {
                                searchMode: "startswith"
                            },
                            lookup: {
                                dataSource: self.pocWhoOptions,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            showInColumnChooser: self.isHud,
                            visible: self.isHud
                        },
                        {
                            dataField: "pocAmount",
                            caption: "POC Amount",
                            width: 90,
                            dataType: "number",
                            allowEditing: !self.readOnly,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            showInColumnChooser: self.isHud,
                            visible: self.isHud
                        },
                        {
                            dataField: "netFund",
                            caption: 'Net Fund',
                            width: 60,
                            allowEditing: !self.readOnly,
                            lookup: {
                                dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.NET_FUND),
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                         {
                            dataField: "accountCodeID",
                            caption: 'Account Code',
                            width: 140,
                            allowEditing: !self.readOnly,
                            lookup: {
                                dataSource(options) {
                                    if(_.isEmpty(options?.data)) return self.accountsCodes;

                                    let selectedValue = options?.data.accountCodeID;
                                    return _.filter(self.accountsCodes, item => !item.inactive || item.id === selectedValue);
                                },
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        {
                            dataField: "cdfSection",
                            caption: 'CDF Section',
                            allowEditing: false,
                            width: 60,
                            editorOptions: {
                                searchMode: "startswith"
                            },
                            lookup: {
                                dataSource: self.otherCdfSections,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            showInColumnChooser: self.isCdf,
                            visible: self.isCdf
                        },
                        {
                            dataField: "doNotPrint",
                            caption: "Do Not Print",
                            width: 100,
                            dataType: "boolean",
                            visible: self.includeAlta,
                            showInColumnChooser: self.includeAlta,
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    storageKey: self.gridStorageKey,

                    summary:
                    {
                        totalItems: [
                            {
                                column: "description",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return "TOTALS";
                                }
                            },
                            {
                                column: "misc_Dollar",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                            {
                                column: "borrowerAtClosingValue",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                            {
                                column: "borrowerBeforeClosing",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                            {
                                column: "sellerAtClosingValue",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                            {
                                column: "sellerBeforeClosing",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                            {
                                column: "paidByOthers",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                            {
                                column: "pocAmount",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                        ]
                    }
                }
            },
            initGridDataSource() {
                const self = this;
                self.gridDataSource = {
                    key: self.itemKey,
                    load(){
                        return Promise.resolve(self.items);
                    },
                    insert(values){
                        self.onGridInsert(values);
                    },
                    update(key, values) {
                        self.onGridUpdate(key, values);
                    }
                };
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new OtherChargeModel(values);
                newItem.loanCostSectionDataID = -self.items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.lineType = LoanCostTypes.AdditionalOtherCost;
                newItem.required = false;

                if(_.has(values, "borrowerAtClosingValue")) {
                    newItem.borrowerAtClosingValue = values.borrowerAtClosingValue;
                }

                if(_.has(values, "sellerAtClosingValue")) {
                    newItem.sellerAtClosingValue = values.sellerAtClosingValue; // Update using Setter
                }

                // We set hudLine values to push to server side versus cdf line (line) which is determined during mapping
                newItem.hudLine = self.getNextAvailableHudLine();

                // New lines entered within the UI application is considered common among all settlement statements so
                // set all the sections
                newItem.hudSection = 1300;
                newItem.cdfSection = "H";

                // By setting the default to null we know that this is common among all settlement statements versus being specifically initialized to CDF / HUD
                // as they can be different based on defaulted lines
                newItem.defaultedSettlementType = null;

                // Perform updates of specific fields for common settlement lines
                if (_.has(values, "pocAmount") || _.has(values, "poc")) {
                    self.updateCdfFieldsFromHudField(newItem);
                }

                // Perform updates of specific fields for common settlement lines
                if (_.has(values, "borrowerBeforeClosing") || _.has(values, "sellerBeforeClosing")) {
                    self.updateHudFieldsFromCdfField(newItem);
                }

                self.items.push(newItem);

                if (self.isHud2010)
                    self.updateRollUpLines();

                return Promise.resolve(newItem);
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.loanCostSectionDataID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let newUpdateItem = new OtherChargeModel((_.assign({}, self.items[itemIndex], values)));

                if(_.has(values, "borrowerAtClosingValue")) {
                    if ((self.isHud2010 && newUpdateItem.isGeneralLine) || newUpdateItem.realtorCommissionPayeeID > 0) {
                        newUpdateItem.borrowerAtClosing = values.borrowerAtClosingValue; // 2010 HUD General lines and commission lines don't have overridable borrowerAtClosing, set non-override value
                        if(newUpdateItem.realtorCommissionPayeeID > 0) {
                            let originalItems = this.mapData(this.originalData);
                            if(originalItems.length > 0) {
                                let commissionLine = _.find(originalItems, item => item.loanCostSectionDataID === key);
                                if(commissionLine){
                                    const commissionAmount = commissionLine.sellerAtClosing + commissionLine.borrowerAtClosing;
                                    newUpdateItem.sellerAtClosing = commissionAmount - newUpdateItem.borrowerAtClosing;
                                }
                            }
                        }
                    }
                    else{
                        newUpdateItem.borrowerAtClosingValue = values.borrowerAtClosingValue; // Update using Setter
                    }
                }

                if(_.has(values, "sellerAtClosingValue")) {
                    if (self.isHud2010 && newUpdateItem.hudLine == 1301)
                        newUpdateItem.sellerAtClosing = values.sellerAtClosingValue; // 2010 HUD - line 1301 don't have overridable sellerAtClosing, set non-override value
                    else
                        newUpdateItem.sellerAtClosingValue = values.sellerAtClosingValue; // Update using Setter
                }

                // Perform updates of specific fields for common settlement lines
                if (newUpdateItem.defaultedSettlementType === null) {

                    if (_.has(values, "pocAmount") || _.has(values, "poc")) {
                        self.updateCdfFieldsFromHudField(newUpdateItem);
                    }

                    if (_.has(values, "borrowerBeforeClosing") || _.has(values, "sellerBeforeClosing")) {
                        self.updateHudFieldsFromCdfField(newUpdateItem);
                    }
                }

                self.items[itemIndex] = newUpdateItem;

                if (self.isHud2010)
                    self.updateRollUpLines();

                return Promise.resolve(newUpdateItem);
            },
            updateCdfFieldsFromHudField(item) {
                // A Hud field being update here so we want to update corresponding appropriate Cdf fields on the same record to attempt to keep
                // business data in-sync between settelement types.
                // Note: These are fields that don't conflict or overlap between settlement types so we are able to adjust from UI since
                // they are hidden from one another. (borrowerAtClosing for instance can't be adjusted because all settlements commonly used that property and is handled
                // through switching settlement type on server side logic.)
                if (item.poc === 'B') {
                    item.borrowerBeforeClosing = item.pocAmount;
                }

                if (item.poc === 'S') {
                    item.sellerBeforeClosing = item.pocAmount;
                }
            },
            updateHudFieldsFromCdfField(item) {
                // A Cdf field being update here so we want to update corresponding appropriate Hud fields on the same record to attempt to keep
                // business data in-sync between settelement types.
                // Note: These are fields that don't conflict or overlap between settlement types so we are able to adjust from UI since
                // they are hidden from one another. (borrowerAtClosing for instance can't be adjusted because all settlements commonly used that property and is handled
                // through switching settlement type on server side logic.)
                if (item.poc === 'B') {
                    item.pocAmount = item.borrowerBeforeClosing;
                }

                if (item.poc === 'S') {
                    item.pocAmount = item.sellerBeforeClosing;
                }
            },
            updateRollUpLines() {
                const self = this;
                if (!self.isHud2010) return; // Roll up lines only apply to 2010 HUD

                // Line 1301 GFE6 line type
                let item1301 = _.find(self.items, item => item.hudLine === 1301 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                let item1301Index = _.findIndex(self.items, item => item.hudLine === 1301 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                if (item1301Index < 0) return;

                if (!item1301.isBorrowerAtClosingOverridden) {
                    let line1301total = _.sumBy(_.filter(self.items, l => l.isGFE6OutsideLine), item => item.misc_Dollar);
                    self.items[item1301Index].borrowerAtClosing = line1301total;
                }
            },
            onChangeWithOutSeller(e) {
                const self = this;
                self.initGridConfig();
                self.$nextTick().then(() => {
                    _.invoke(self, "$refs.dataGrid.loadGrid");
                });
            },
            onChangeSSViewSelected(ssType) {
                this.items = this.mapData(this.originalData, ssType);
                this.initGridConfig();
                this.$nextTick().then(() => {
                    _.invoke(this, "$refs.dataGrid.loadGrid");
                });
            },
            gridRefresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            onSave(e){
                this.gridInstance.saveEditData();
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            onAddLoanCostLine(lineType) {
                const self = this;
                if(!self.gridInstance) return;
                self.selectedLineType = lineType;
                self.gridInstance.addRow();
            },
            onRevertItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    if (item.canRevert) {
                        item.revertOverrides();
                    }
                });

                // On reverting we need to take care to update borrower at closing for 2010 Hud calculated values
                if (self.isHud2010)
                    self.updateRollUpLines();

                self.gridRefresh();
            },
            onDeleteItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteItems(selectedItems);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected charge(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onChangeNetFundItems(e) {
                const self = this;
                if (!e || !e.data) return;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    item.netFund = _.getNumber(e.action, "key", 0);
                });

                self.gridRefresh();
            },

            onSetAddendumItem(e) {
                const self = this;
                if (!e || !e.data) return;
                let lineItem = e.data;
                let isSetAddendum = !_.parseBool(lineItem.isAddendum); //if isAddendum is not true then we know we're setting an addendum line

                // When setting Addendum, since there can only be one addendum per section, wit's implied that the any
                // other lines (should just be one if any) that is an addendum line needs to be cleared from being one
                if (isSetAddendum) {
                    _.forEach(self.items, (item) => {
                        if (lineItem.loanCostSectionDataID === item.loanCostSectionDataID)
                            item.isAddendum = true;
                        else
                            item.isAddendum = false;
                    });
                }
                else // remove a specific addendum flag on a specific line
                    lineItem.isAddendum = !_.parseBool(lineItem.isAddendum);

                self.gridRefresh();
            },

            onMoveSection(e) {
                const self = this;
                if (!e || !e.data) return;
                let targetSection = _.get(e.action, "key");
                let okHandler = function (args) {
                    let selectedKeys = _.mapValues(e.data, self.itemKey);
                    let selectedItems = _.filter(self.items, item => _.includes(selectedKeys, item.loanCostSectionDataID));
                    _.forEach(selectedItems, item => {
                        self.updateMovedItem(item, targetSection);
                        self.movedItems.push(item);
                        self.removeItems(item.loanCostSectionDataID);
                    });
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Move",
                    `Are you sure you want to move the selected lines to Section ${targetSection}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});

                self.gridRefresh();
            },

            deleteItems(items) {
                const self = this;
                let isDeleteGFE6OutsideLine = false;

                _.forEach(items, (item) => {
                    let key = _.get(item, self.itemKey);
                    self.removeItems(key);

                    // Remove associated PBO line if it exists
                    if (item.paidByCompanyID > 0) {
                        let pboItem =  _.find(self.items, i => i.paidBySource === item.hudLine);
                        if (pboItem) self.removeItems(pboItem.loanCostSectionDataID);
                    }
                    // Check for updates to GFE rollup lines
                    if (!isDeleteGFE6OutsideLine && item.isGFE6OutsideLine)
                    {
                        isDeleteGFE6OutsideLine = true;
                    }
                    if(key > 0){
                        self.deletedItems.push(item);
                    }
                });

                // update borrower at closing for 2010 Hud calculated values
                if (isDeleteGFE6OutsideLine && self.isHud2010)
                    self.updateRollUpLines();

                // Since we maintain HUD lines we also have to maintain the line ordering for UI purpose
                self.reorderHudItems();
            },

            removeItems(key) {
                const self = this;

                let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                if (itemIndex >= 0)
                    self.items.splice(itemIndex, 1);

                self.gridRefresh();
            },

            updateMovedItem(item, targetSection) {
                item.cdfSection = targetSection;
                item.hudSection = 1100;
                item.hudLine = 1199;    //for correct sorting on the HUD Mapper
                item.lineType = LoanCostTypes.AdditionalTitleCharge;
                item.cdfLineID = null;
                item.line = null;
                item.hudLineID = null;
            },

            reorderHudItems() {
                const self = this;
                let hudItems = _.filter(self.items, i => i.hudLine > 1300 && i.hudLine < 1400);

                if (hudItems.length === 0) return; // no hud items to reorder
                let minLine = _.minBy(hudItems, function(i) { return i.hudLine; });
                let maxLine = _.maxBy(hudItems, function(i) { return i.hudLine; });

                let lineNumber = _.parseNumber(minLine.hudLine, 0);
                _.forEach(_.sortBy(hudItems, h => h.hudLine), (hudItem) => {
                    if (hudItem.hudLine != lineNumber) {
                        let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === hudItem.loanCostSectionDataID);
                        self.items[itemIndex].hudLine = lineNumber;
                        hudItem.hudLine = lineNumber;
                    }
                    lineNumber = lineNumber + 1;
                });
            },

            mapData(items, type=null) {
                let ssType = _.isNil(type) ? this.selectedView : type;
                let isCdf = ssType === SETTLEMENT_TYPE.CDF;
                let sortBy = isCdf ? "cdfSection" : "hudLine";
                let viewItems = _.filter(items, item => (item.defaultedSettlementType === ssType || _.isNil(item.defaultedSettlementType))
                        && (isCdf ? item.cdfSection === "H" : item.hudSection === 1300)
                );

                // Additional filter when SS is without seller we don't want to show payoff items
                if (this.isWithOutSeller)
                    viewItems = _.filter(viewItems, item => !item.isPayOff);

                return this.sortItems(_.map(viewItems, item => new OtherChargeModel(item)), sortBy);
            },

            fetchData() {
                const self = this;
                self.deletedItems = [];
                self.movedItems = [];
                if(self.selectedLoanId === 0) return Promise.resolve(true);
                let getPromise = self.$api.LoanCostChargesApi.getRecordsByLoan(self.selectedLoanId);
                return self.$rqBusy.wait(getPromise)
                    .then(results => {
                        // Keep track of hud items through out the component whether HUD view or not to maintain proper line numbers for HUD for common lines
                        self.items = self.mapData(results); // other charges related to the context of CDF or HUD
                        self.originalData = results; // all other charges between CDF and HUD
                        self.gridRefresh();
                        return results;
                    });
            },

            save(refreshData=true){
                const self = this;
                if (self.readOnly) return;

                self.showErrorBanner = self.hasErrors;
                if (self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false, abort: true});
                    return;
                }

                if(!self.hasChanges()){
                    if(refreshData) self.fetchData();
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let saveItems = self.items;
                if(self.movedItems.length > 0) saveItems.push.apply(saveItems, self.movedItems);

                let savePromise = self.$api.LoanCostChargesApi.saveDeleteRecords(saveItems, self.deletedItems);
                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Charges Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Charges");
                        console.error(err);
                    });

            },

            hasChanges(){

                // Further filter out the original data for items we are viewing in CDF/HUD context
                let originalItems = this.mapData(this.originalData);
                //original data was empty and user added something
                if(originalItems.length === 0 && this.items.length > 0){
                    return true;
                }
                //if the arrays are different length, then there are changes. Items have been deleted and need to save
                if(this.items.length !== originalItems.length){
                    return true;
                }
                //need to compare the two arrays for changes
                let changes = this.getAuditChanges(originalItems, this.items);
                return changes.length > 0;
            },

            onShowPremiums(){
                const self = this;

                let okHandler = function (e) {
                    return e.component.save(true).then(() => {
                        self.fetchData();
                        return true;
                    });
                };

                self.$dialog.open({
                    title: "Premium Rates",
                    width: "95%",
                    minWidth: 1200,
                    height: "95%",
                    minHeight: 700,
                    component: PremiumsMain,
                    props: { noTitle: true, showHeaderCalculate: true },
                    onOk: okHandler
                });
            },

            onEditorPreparing(e) {
                const self = this;

                if(e.type === "selection" || e.row.rowType !== "data") return;


                e.editorOptions.disabled = self.isColumnDisabled(e.dataField, e.row.data);
            },
            isPayeeColumnDisabled(lineData) {
                const self = this;

                if (lineData.isSettlementFeeLine) return true;

                if (lineData.isPremiumOrEndorsement) return true;

                if (self.isHud1974) {
                    if (lineData.isPaidByOther) return true;
                }

                if (self.isHud2010) {
                    if (lineData.isPaidByOther) return true;
                    if (lineData.hudLineType === self.HudLineTypes.Disclosure) return true;
                }

                return false;
            },
            isColumnDisabled(dataField, data) {

                 if (this.readOnly) {
                    return true;
                }

                // Alta columns are editable
                if (dataField === "category" || dataField === "doNotPrint")
                    return false;

                // Check account code configuration permission
                // If onlySysAdminCanEditAccountCode is set and if default line, only System Admin allowed permission to edit account code
                if (dataField === "accountCodeID" && !_.isNil(data.defaultedSettlementType) && !this.allowEditAccountCode)
                    return true;

                if (dataField === "accountCodeID" && (data.hudLineType !== HudLineTypes.Disclosure))
                    return false;

                // Common logic for all SS here before specific ones addressed below
                // Realtor Commission
                if (data.realtorCommissionPayeeID > 0 && dataField !== "netFund" && dataField !== "borrowerAtClosingValue") return true;

                // PBO
                if (data.isPaidByOther) return true;

                // PayOff
                if (_.parseBool(data.isPayOff, false)) {
                    if (dataField === "description" || dataField === "borrowerAtClosing" || dataField === "sellerAtClosing")
                        return true;
                }

                // CDF
                if (this.isCdf)
                    return this.isCdfColumnDisabled(dataField, data);

                // 1974 HUD
                if (this.isHud1974)
                    return this.is1974HudColumnDisabled(dataField, data);

                // 2010 HUD has additional columns that disable that differs from CDF, HUD 1974
                if (this.isHud2010)
                    return this.is2010HudColumnDisabled(dataField, data);

                return false;
            },

            isCdfColumnDisabled(dataField, data) {
                if (data.isPremiumOrEndorsement || data.isSettlementFeeLine) {
                    switch (dataField) {
                        case 'accountCodeID':
                        case 'borrowerAtClosingValue':
                        case 'borrowerBeforeClosing' :
                        case 'sellerAtClosingValue':
                        case 'sellerBeforeClosing' :
                            return false;
                        default:
                            return true;
                    }
                }

                return false;
            },
            is1974HudColumnDisabled(dataField, data) {
                if (data.isPremiumOrEndorsement || data.isSettlementFeeLine) {
                    switch (dataField) {
                        case 'accountCodeID':
                            return data.isSettlementFeeLine; // enable for premium, disable for settlement fee
                        case 'borrowerAtClosingValue':
                        case 'sellerAtClosingValue':
                            return false;
                        default:
                            return true;
                    }
                }

                return false;
            },
            is2010HudColumnDisabled(dataField, data) {
                const self = this;

                switch (dataField) {
                    case 'borrowerAtClosingValue':
                        return !(_.includes([1301], data.hudLine) || data.hudLineType === self.HudLineTypes.General);
                    case 'misc_Dollar':
                        return data.hudLineType !== self.HudLineTypes.GFE_6_Outside;
                    case 'netFund':
                    case 'accountCodeID':
                    case 'poc':
                    case 'pocAmount':
                        return data.hudLine === 1301 || data.hudLineType === self.HudLineTypes.Disclosure;
                    case 'sellerAtClosingValue':
                        return data.hudLineType === self.HudLineTypes.Disclosure;
                    default:
                        return false;
                }
            },

            getLineDescription(rowData) {
                const self = this;

                let result = rowData.description || '';

                // currently only needed for CDF Outside Party to Pay Lines
                if (!self.isCdf || _.isNil(rowData.orderOutsidePartyToPayID))
                    return result;

                if (rowData.orderOutsidePartyToPayID > 0)
                    return `Title - ${result}`;
            }
        }
    }
</script>
