<template>
    <div id="order-tasks-selection" :class="{'order-tasks-selection':true, 'dialog-content':dialogMode, 'content-wrapper rq-wo-tasks': true}">
        <rq-banner
            :message="validationBannerMessage"
            variant="error"
            icon="fas fa-exclamation-circle"
            :visible="showValidationBanner"
            dismissable
        />
        <rqdx-action-data-grid
            ref="dgTasks"
            automation_id="dg_tasks"
            title="Tasks"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            :check-action-permission="executionService.canExecuteAction"
            :read-only="readOnly"
            export-file-name="OrderTasks-Grid-State"
            @action="handleAction"
            @rowDoubleClick="onRowDoubleClick"
            hide-search
            rq-grouping
            rq-filters>
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <span id="disabled-wrapper_add">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="disableAddDropdown">Add</button>
                            <b-tooltip target="disabled-wrapper_add">{{getTooltip(addAction)}}</b-tooltip>
                            <ul class="dropdown-menu">
                                <li>
                                    <span id="disabled-wrapper_add_task" tabindex="0">
                                    <button automation_id="btn_add_task"
                                        class="dropdown-item"
                                        variant="theme"
                                        v-focus
                                        @click="handleAddTask"
                                        :disabled="!canExecuteAction(addAction)">
                                        Task
                                    </button>
                                    </span>
                                </li>
                                <li>
                                    <span id="disabled-wrapper_add_task_package" tabindex="0">
                                        <button automation_id="btn_add_task_package"
                                        class="dropdown-item"
                                        variant="theme"
                                        v-focus
                                        @click="handleAddTaskPackage"
                                        :disabled="!canExecuteAction(addTaskPackageAction)">
                                        Task Package
                                        </button>
                                    </span>
                                </li>
                            </ul>
                        </span>
                        </div>
                    </li>
                    <li class="nav-item">
                        <span id="disabled-wrapper_reassign" tabindex="0">
                            <b-btn automation_id="btn_assign-tasks"
                                variant="theme"
                                @click="handleReassignTasks"
                                :disabled="!canExecuteAction(assignStaffAction)">
                                Reassign Staff
                            </b-btn>
                        </span>
                        <b-tooltip target="disabled-wrapper_reassign">{{getTooltip(assignStaffAction)}}</b-tooltip>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
        <notes-popover
                container="#order-tasks-selection"
                :popover="notesPopover"
                v-model:visible="notesPopover.visible"
            />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { WorkflowTaskStatus } from "@/shared/models/enums";
    import TaskActions from "@/router/actions/order-workflow";
    import WorkflowMixin from "../workflow-mixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import NotesPopover from "@/shared/components/rq/NotesPopover";
import { DateTimeHelper } from '@/shared/utilities';

    export default {
        name: "OrderTasks",
        mixins: [WorkflowMixin],
        components: { NotesPopover },
        data () {
            const self = this;
            return {
                departments: [],
                tasks: [],
                notesPopover: {
                    visible: false,
                    target: null,
                    notes: null,
                    description: null
                },
            };
        },
        computed: {
            ...mapState({
                storedOrderId: state => state.orders.orderId,
                readOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                currentSession: state => state.authentication.session,
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "executionService",
                "currentSession"
            ]),
            currentUserId() { return this.currentSession.userId; },
            disableAddDropdown() {return !this.canExecuteAction(this.addAction) && !this.canExecuteAction(this.addTaskPackageAction); },
            routeOrderId() { return _.get(this, "$route.params.orderId", null); },
            orderId() { return this.routeOrderId ? this.routeOrderId : this.storedOrderId; },
            gridInstance () { return _.get(this, "$refs.dgTasks.gridInstance", {}); },
            addAction() { return TaskActions.ADD; },
            addTaskPackageAction() {return TaskActions.ADD_TASK_PACKAGE},
            assignStaffAction() { return TaskActions.ASSIGN_TASKS_BASED_ON_FILE_MAIN; }
        },

        watch: {
            readOnly() { this.gridInstance.repaint(); },
            orderId(newVal, oldVal) {
                if(newVal === oldVal) return;
                this.refresh();
            }
        },

        created () {
            this.initGrid();
        },

        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },

        methods: {

            async fetchData() {
                const self = this;
                let apiPromise = self.$api.OrderWorkflowApi.getTasks(self.orderId);
                let result = await self.$rqBusy.wait(apiPromise);
                return self.mapTasks(result);
            },

            initGrid() {
                const self = this;
                self.setSelectionActions();
                const dateTextCellTemplate = function(container, date, text) {
                    // DxGridUtils.dateTimeWithZoneCellTemplate({
                    //     fallbackText: text,
                    // })(container, { value: date });
                    let cellElement = date
                        ? `<span>${DateTimeHelper.getDateFromString(date).toFormat('MM/dd/yyyy, hh:mm a')}</span>`
                        : `<span>${text}</span>`;
                    container.append(cellElement);
                };
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "status",
                            caption: "Status",
                            width: 80,
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: WorkflowTaskStatus.filterLookupItems
                            },
                            cellTemplate: DxGridUtils.statusCellTemplate,
                            calculateGroupValue(rowData) {
                                if(_.includes([0,1,2,3], rowData.status)) return 0;
                                return rowData.status;
                            },
                            groupCellTemplate(element, options) {
                                let displayText = "Open";
                                switch(_.parseNumber(options.value)) {
                                    case 4:
                                        displayText = "Complete";
                                        break;
                                    case 5:
                                        displayText = "Overdue";
                                        break;
                                    case 6:
                                        displayText = "Not Applicable";
                                        break;
                                }
                                element.text(displayText);
                            }
                        },
                        {
                            dataField: "taskName",
                            dataType: "string",
                            caption: "Task Name",
                            cellTemplate: DxGridUtils.orderTasksNotesCellTemplate({
                                idAppend: "order-tasks-notes-popup-info-",
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updateNotesPopover(cellInfo.data.taskNote, cellInfo.data.taskName, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updateNotesPopover();
                                    },
                                    click(cellElement, cellInfo, e) {
                                        e.stopPropagation();
                                    },
                                }
                            })
                        },
                        {
                            dataField: "workflowDepartmentID",
                            caption: "Department",
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: {
                                    loadMode: "raw",
                                    load:() => self.lookupHelpers.getLookupItems(self.lookupItems.WORKFLOW_DEPARTMENTS)
                                }
                            },
                            rqFilter:{
                                filterType: "tags"
                            },
                            calculateSortValue: "department"
                        },
                        { dataField: "user", caption: "Assigned To" },
                        {
                            dataField: "startDate",
                            dataType: "datetime",
                            minWidth: 150,
                            cellTemplate (container, options) {
                                return dateTextCellTemplate(
                                    container,
                                    options.data.startDate,
                                    options.data.startText);
                            }
                        },
                        {
                            dataField: "dueDate",
                            dataType: "datetime",
                            minWidth: 150,
                            cellTemplate (container, options) {
                                return dateTextCellTemplate(
                                    container,
                                    options.data.dueDate,
                                    options.data.dueText);
                            }
                        },
                        {
                            dataField: "completedDate",
                            dataType: "datetime",
                            minWidth: 150
                        },
                        {
                            dataField: "notes",
                            visible: false,
                            caption: "Last Note"
                        },
                        {
                            dataField: "completedByName",
                            visible: false,
                            caption: "Completed By"
                        },
                        {
                            dataField: "outsideParty",
                            caption: "Outside Party"
                        },
                        {
                            dataField: "documentAttached",
                            visible: false,
                            caption: "Documents",
                            cellTemplate (container, options) {
                                if (!options.data.documentAttached) return;
                                container.append(`<span>${options.data.documentCount}</span>`);
                            }
                        },
                        {
                            dataField: "autoEmailYN",
                            visible: false,
                            caption: "Auto Email",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        { dataField: "publishYN", visible: false, caption: "Publish", cellTemplate: DxGridUtils.boolCellTemplate },
                        { dataField: "pushToHudYN", visible: false, caption: "Push to CDF/HUD/SS", cellTemplate: DxGridUtils.boolCellTemplate },
                        { dataField: "sendToVendorYN", visible: false, caption: "Send to Vendor", cellTemplate: DxGridUtils.boolCellTemplate },
                        { dataField: "vendorAssigned", visible: false, caption: "Vendor Assigned", cellTemplate: DxGridUtils.boolCellTemplate },
                        {
                            dataField: "completedDuration",
                            visible: false,
                            caption: "Duration"
                        },
                        DxGridUtils.dateColumn({
                            dataField: "pendingDate",
                            caption: "Pending",
                            visible: false,
                        }),
                        {
                            dataField: "notApplicableYN",
                            visible: true,
                            caption: "N/A",
                            width: 80,
                            minWidth: 80,
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    onRowPrepared: self.onRowPrepared
                };
                self.gridDataSource = {
                    key: "orderWorkflowTaskID",
                    load: () => self.fetchData()
                }
            },

            mapTasks(items) {
                const self = this;
                self.tasks = _.map(items, item => {
                    let status = WorkflowTaskStatus.getItemStatus(item);
                    return { ...item, status };
                });
                return self.tasks;
            },

            setSelectionActions(){
                this.selectionActions = [
                    {
                        name: "update-tasks",
                        text: "Update",
                        children: [
                            TaskActions.UPDATE_DUEDATE,
                            TaskActions.MARK_COMPLETE,
                            TaskActions.UPDATE_STARTDATE,
                            TaskActions.TOGGLE_NA,
                            TaskActions.REOPEN
                        ]
                    },
                    {
                        name: "manage-tasks",
                        text: "Manage",
                        children: [
                            TaskActions.EDIT,
                            TaskActions.COPY,
                            TaskActions.DELETE,
                            TaskActions.EMAIL
                        ]
                    },
                    {
                        name: "assign-tasks",
                        text: "Assign",
                        children: [
                            TaskActions.ASSIGN_ME,
                            TaskActions.ASSIGN
                        ]
                    },
                    TaskActions.ADD_NOTE
                ];
            },

            getTooltip (a) {
                let accessRestricted = (this.executionService && !this.canExecuteAction(a));

                if (!a.tooltip) return accessRestricted ? "Access Restricted)" : "";

                let tooltipText = a.tooltip.replace("[entity]", this.$route.meta.entity);
                return accessRestricted ? 'Access Restricted' : tooltipText;
            },

            canExecuteAction(action) {
                let canExecuteReadOnly = !this.readOnly || !_.parseBool(action.disableWhenReadOnly);
                return this.executionService.canExecuteAction(action) && canExecuteReadOnly;
            },

            // grid methods

            onRowPrepared (e) {
                if (e.rowType !== "data" || !e.data.notApplicableYN) return;
                e.rowElement.addClass("rq-strike-through");
            },

            onRowDoubleClick(e) {
                this.handleEditTask(e.data, this.readOnly);
            },

            onFilterGrid (args) {
                if (!args) this.gridInstance.clearFilter();
                else this.gridInstance.filter(args, true);
            },

            // Action Event Handlers
            handleAction (e) {
                const self = this;
                let action = e.action;
                let data = e.data;

                self.messageBanner();
                let message = `No handler for action - ${e.action.key}`;
                if(!action || !data) return;

                switch (e.action.key) {
                    case TaskActions.EDIT.key:
                        self.handleEditTask(data, self.isOrderLocked);
                        break;
                    case TaskActions.DELETE.key:
                        self.handleDeleteTasks(data);
                        break;
                    case TaskActions.COPY.key:
                        self.handleCopyTasks(data);
                        break;
                    case TaskActions.ASSIGN_ME.key:
                        self.handleAssignTasksToMe(data);
                        break;
                    case TaskActions.ASSIGN.key:
                        self.handleAssignTasks(data);
                        break;
                    case TaskActions.ASSIGN_TASKS_BASED_ON_FILE_MAIN.key:
                        self.handleReassignTasks(data);
                        break;
                    case TaskActions.MARK_COMPLETE.key:
                        self.handleMarkTasksComplete(data);
                        break;
                    case TaskActions.UPDATE_DUEDATE.key:
                        self.handleUpdateDueDate(data);
                        break;
                    case TaskActions.UPDATE_STARTDATE.key:
                        self.handleUpdateStartDate(data);
                        break;
                    case TaskActions.REOPEN.key:
                        self.handleReopenTasks(data);
                        break;
                    case TaskActions.TOGGLE_NA.key:
                        self.handleToggleTasksNonApplicable(data);
                        break;
                    case TaskActions.ADD_NOTE.key:
                        self.handleEditNote(data);
                        break;
                    case TaskActions.EMAIL.key:
                        self.handleEmailTask(data);
                        break;
                    default:
                        self.$toast.error(message);
                        console.error(message);
                        break;
                }
            },

            handleReassignTasks() {
                const self = this;
                self.$dialog.confirm("Reassign", "Reassign tasks based on Staff Assignments?", () => {
                    let apiPromise = self.$api.OrderWorkflowApi.reassign(self.orderId);
                    self.$rqBusy.wait(apiPromise)
                        .then(()=> {
                            self.refresh();
                        });
                });
            },

            refresh() {
                const self = this;
                self.gridInstance.clearSelection();
                self.gridInstance.refresh();
            },
            updateNotesPopover(notes=null, description=null, target=null) {
                const self = this;
                let newID = _.get(target, "id") || null;
                let lastID = _.get(self.notesPopover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let notesPopover = _.clone(self.notesPopover);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        notesPopover.visible = false;
                    }
                    notesPopover.target = newID;
                    notesPopover.notes = notes;
                    notesPopover.description = description;
                    notesPopover.visible = true;
                } else {
                    notesPopover.visible = !notesPopover.visible;
                }
                self.$nextTick(() => {
                    self.notesPopover = notesPopover;
                });
            },
        }
    };
</script>