<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            title="Voided Policies"
            :automation_id="gridName"
            :data-source="gridDataSource"
            :config="gridConfig"
            :export-file-name="exportFileName"
            hide-search
            hide-clear-filters>
            <template #toolbar>
                <span class="navbar-text me-auto">This is a read-only list of the voided policies on this file</span>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters} from "vuex";
    import { LoanHolderType } from "../enums";

    export default {
        name: "VoidedPolicies",
        data: function() {
            return {
                gridDataSource: [],
                gridConfig: {}
            }
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary
            }),
            ...mapGetters(["currentSession"]),
            exportFileName() { return `${this.orderSummary.fileNumber}_voided_policies` },
            gridStorageKey() {
                return `${this.gridName}::${_.get(this, "$route.name", "")}::${_.get(this, "currentSession.username", "")}`
            }
        },
        created () {
            this.gridName = "dg_voidedpolicies";
            this.loadGrid();
        },
        methods: {
            loadGrid(){
                const self = this;
                self.gridConfig = {
                    storageKey: self.gridStorageKey,
                    columns: [
                        {
                            dataField: "voidDate",
                            dataType: "date",
                            caption: "Date Voided"
                        },
                        {
                            dataField: "policyType",
                            caption: "Policy Type",
                            alignment: "center",
                            cellTemplate (container, options) {
                                $("<a/>")
                                    .append(`<span>${LoanHolderType.displayValue(options.data.policyType)}</span>`)
                                    .appendTo(container);
                            }
                        },
                        { dataField: "loanOrder" },
                        { dataField: "policyNumber" },
                        //{ dataField: "policyDate" },
                        //{ dataField: "policyDate", caption: "Policy Time", cellTemplate: DxGridUtils.timeCellTemplate }
                        {
                            dataField: "policyDate",
                            dataType: "datetime",
                            caption: "Policy Date / Time",
                        }
                    ],
                    selection: { mode: "none" },
                };
                self.gridDataSource = {
                    key: "voidedPolicyID",
                    load: self.fetchData
                };
            },
            fetchData() {
                let apiPromise = this.$api.CommitmentsApi.getVoidedPolicies(this.orderId);
                return this.$rqBusy.wait(apiPromise);
            },
            getPolicyType(policyType) {
                return LoanHolderType.LoanHolderText(policyType);
            }
        }
    }
</script>