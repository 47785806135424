<template>
    <div class="content-wrapper">
        <rq-validation-summary class="mb-5" :data-source="validationErrors" dismissible/>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_outside_party_allocation"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            class="mt-0"
            export-file-name="Outside_Party_Data"
            focus-after-insert="none"
            @delete="onDeleteItem"
            hide-search
            hide-show-column-chooser
            hide-clear-filters
            rq-editable>
            <template #toolbar>
                <ul class="nav me-auto">
                    <li class="nav-item">
                        <!-- <b-dropdown variant="theme" class="rq-section-dropdown" toggle-class="mx-0" text="Add Party">
                            <b-dropdown-item-button
                                automation_id="btn_add_outside_party_percent"
                                @click="onAddOutsideParty(1)">Percentage
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                automation_id="btn_add_outside_party_amount"
                                @click="onAddOutsideParty(2)">Amount
                            </b-dropdown-item-button>
                        </b-dropdown>  -->
                        <!--  Change -->
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" toggle-class="mx-0" aria-expanded="false">Add Party</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item"
                                        automation_id="btn_add_outside_party_percent"
                                        @click="onAddOutsideParty(1)">Percentage
                                    </button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item"
                                        automation_id="btn_add_outside_party_amount"
                                        @click="onAddOutsideParty(2)">Amount
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { RATE_ACTIONS } from '@/store/actions';
import { RATE_MUTATIONS } from '@/store/mutations';
import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
import { OrderOutsidePartyToPayDto, OUTSIDE_PARTY_PERCENT_AMOUNT } from '@settlement/models';
import DxGridUtils from "@/shared/utilities/DxGridUtils";

export default {
    name: "OutsidePartyAllocations",
    mixins: [GridCompanyPickerMixin],
    props: {
        args: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            outsideParties: [],
            validationErrors: [],
            agentTotal: null,
            defaultOutsidePartyType: OUTSIDE_PARTY_PERCENT_AMOUNT.AMOUNT
        }
    },
    computed: {
        ...mapState({
            orderId: state => state.orders.orderId
        }),
        gridInstance() {
            return _.get(this.$refs, "dataGrid.gridInstance", null) || {};
        },
        selectionActions() {
            return [
                {
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    allowMultiSelection: true,
                    tooltip: "Delete Outside Party"
                }
            ];
        }
    },
    watch: {
        "$store.state.rateEngine.outsideParties":{
            handler(newValue, oldValue){
                this.outsideParties = _.map(newValue, item => new OrderOutsidePartyToPayDto(item));
                this.refreshGrid();
            },
            deep:true,
            immediate:true
        }
    },
    created(){
        this.initGridConfig();
        this.agentTotal = _.get(this, "args.agentTotal", null);
    },
    methods: {
        initGridConfig(){
            const self = this;

            let outsidePartyPickerInfo = {
                dialogTitle: "Select Outside Party",
                companyIDExpr: "orderOutsidePartyToPayCompanyID",
                companyNameExpr: "companyName",
                showContactPicker: false,
            };

            self.gridConfig = {
                columns: [
                    self.getCompanyContactGridColumn({
                        column: {
                            dataField: "companyName",
                            caption: "Outside Party",
                            width: 250
                        },
                        ...outsidePartyPickerInfo
                    }),
                    {
                        dataField: "orderOutsidePartyToPayPCT",
                        width: 100,
                        caption: "Percentage",
                        dataType: "number",
                        format: "##0.## %",
                        editorOptions: { format: "##0.##'%'", max: 100, min: 0 },
                        cellTemplate: function(cellElement, cellInfo){
                            if(cellInfo.data.valueType !== OUTSIDE_PARTY_PERCENT_AMOUNT.PERCENT) {
                                cellElement.addClass('text-muted');
                            }
                            cellElement.text(`${cellInfo.value}%`);
                        },
                        setCellValue(newData, value, currentRowData) {
                            newData.orderOutsidePartyToPayPCT = value;
                            newData.orderOutsidePartyToPayTotal = self.calculateTotal(value, currentRowData);
                        }
                    },
                    {
                        dataField: "orderOutsidePartyToPayAmount",
                        width: 100,
                        caption: "Amount",
                        format: {
                            type: "currency",
                            precision: 2
                        },
                        editorOptions: {
                            format: { type: "currency", precision: 2 }
                        },
                        cellTemplate: function(cellElement, cellInfo){
                            if(cellInfo.data.valueType !== OUTSIDE_PARTY_PERCENT_AMOUNT.AMOUNT) {
                                cellElement.addClass("text-muted");
                            }
                            DxGridUtils.moneyCellTemplate(cellElement, cellInfo);
                        },
                        setCellValue(newData, value, currentRowData) {
                            newData.orderOutsidePartyToPayAmount = value;
                            newData.orderOutsidePartyToPayTotal = value;
                        }
                    },
                    {
                        dataField: "orderOutsidePartyToPayTotal",
                        allowEditing: false,
                        width: 100,
                        caption: "Total",
                        cellTemplate: DxGridUtils.moneyCellTemplate
                    },
                    {
                        dataField: "orderOutsidePartyToPayComment",
                        dataType: "string",
                        caption: "Comment"
                    }
                ],
                remoteOperations: { sorting: false, paging: false },
                onEditorPreparing: self.onEditorPreparing,
                onInitNewRow: function(e) {
                    e.data = new OrderOutsidePartyToPayDto({valueType: self.defaultOutsidePartyType});
                }
            };

            self.gridDataSource = {
                key: "orderOutsidePartyToPayID",
                load (loadOptions) {
                    return Promise.resolve(self.outsideParties.slice());
                },
                insert: self.onGridInsert,
                update: self.onGridUpdate
            };
        },
        validate() {
            let errors = [];
            let totalAmount = _.reduce(this.outsideParties, (total, item) => {
                return total + _.parseNumber(item.orderOutsidePartyToPayTotal, 0);
            }, 0);

            if(totalAmount > this.agentTotal) {
                errors.push(`Combined outside party totals exceeds the Agent Total: ${this.formatMoney(this.agentTotal)}`);
            }

            this.validationErrors = errors;

            return _.isEmpty(this.validationErrors);
        },
        save() {
            const self = this;
            let isValid = self.validate();

            // if input isn't valid, don't save; returning a promise resolving false prevents the dialog from closing
            // -------------------------------------------------------------------------------------------------------
            if(!isValid) return Promise.resolve(false);

            let promises = _.map(self.outsideParties, outsideParty => self.$store.dispatch(RATE_ACTIONS.SAVE_OUTSIDE_PARTIES, outsideParty));

            return this.$rqBusy.wait(Promise.all(promises))
                .then(() => {
                    if (isValid == false) return false;

                    let storeData = _.map(self.outsideParties, outsideParty => _.toPlainObject(outsideParty));
                    self.$store.commit(RATE_MUTATIONS.SET_OUTSIDE_PARTIES, storeData);
                    self.$toast.success({ message: `Outside Party updated successfully.` });
                    return true;
                })
                .catch(error => {
                    self.$toast.error({ message: `Failed to update Outside Party.` });
                });
        },
        refreshGrid() {
            let gridInstance = _.get(this.$refs, "dataGrid.gridInstance");
            if(!gridInstance) return;
                gridInstance.refresh();
        },
        onAddOutsideParty(type) {
            let gridInstance = _.get(this.$refs, "dataGrid.gridInstance");
            if(!gridInstance) return;

            this.defaultOutsidePartyType = type;
            gridInstance.addRow();
        },
        onAddItem(newItem) {
            const self = this;
            newItem.ordersID = self.orderId;
            let storePromise = self.$store.dispatch(RATE_ACTIONS.ADD_OUTSIDE_PARTY, newItem);
            return this.$rqBusy.wait(storePromise)
                .then(() => {
                    self.$toast.success({ message: `Outside Party added successfully.` });
                })
                .catch(() => {
                    self.$toast.error({ message: `Failed to add Outside Party.` });
                });
        },
        onGridInsert(values) {
            const self = this;
            let originalItem = new OrderOutsidePartyToPayDto();
            let newItem = new OrderOutsidePartyToPayDto(values);

            return self.onAddItem(newItem);
        },
        onGridUpdate(key, values) {
            const self = this;
            let itemIndex = _.findIndex(self.outsideParties, item => item.orderOutsidePartyToPayID === key);
            if(itemIndex < 0) return self.onGridInsert(values);

            let originalItem = self.outsideParties[itemIndex];
            let updatedItem = new OrderOutsidePartyToPayDto(_.assign({}, originalItem, values));

            self.outsideParties[itemIndex] = updatedItem;

            return self.save();
        },
        onEditorPreparing(e) {
            if(e.parentType !== "dataRow") return;
            if(e.dataField === "orderOutsidePartyToPayPCT") {
                e.editorOptions.readOnly = e.row.data && e.row.data.valueType === OUTSIDE_PARTY_PERCENT_AMOUNT.AMOUNT;
            }
            if(e.dataField === "orderOutsidePartyToPayAmount") {
                e.editorOptions.readOnly = e.row.data && e.row.data.valueType === OUTSIDE_PARTY_PERCENT_AMOUNT.PERCENT;
            }
        },
        delete(ids) {
            const self = this;
            let apiPromise = self.$store.dispatch(RATE_ACTIONS.DELETE_OUTSIDE_PARTY, {
                orderId: self.orderId,
                ids
            });
            return self.$rqBusy.wait(apiPromise)
                .then(() => {
                    self.$toast.success({ message: `Outside Party deleted successfully.` });
                })
                .catch(() => {
                    self.$toast.error({ message: `Failed to delete Outside Party.` });
                });
        },
        onDeleteItem(e){
            if(!e || !e.data) return;
                const self = this;
                let items = e.data;

                let okHandler = function (args) {
                    let keys = _.map(items, "orderOutsidePartyToPayID");
                    self.delete(keys);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete the selected Outside Parties(s)?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
        },
        calculateTotal (value, currentRowData) {
            if(currentRowData.valueType === OUTSIDE_PARTY_PERCENT_AMOUNT.PERCENT)
            {
                return this.agentTotal * (value/100);
            }
            else if(currentRowData.valueType === OUTSIDE_PARTY_PERCENT_AMOUNT.AMOUNT){
                return value;
            }
        },
        formatMoney(v) {
            return accounting.formatMoney(_.parseNumber(v, 0));
        }
    }
}
</script>